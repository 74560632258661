import React, { useState } from 'react';
import { LoadingPage } from '../../../Components/LoadingOverlay';
import { Text } from '../../../globalStyles';
import { CampusResourcesQuery, ResourceType, useCampusResourcesQuery } from '../../../graphQL';
import { getClickableLink } from '../../../utils';
import { UnexpectedError } from '../../Shared';
import { ResourceModal } from '../Modals/CampusResourceModal';
import { SectionRow, SectionRowItem } from './SectionRow';

type Props = {
  organizationId: number;
  editable?: boolean;
  verticalDisplay?: boolean;
};

export function CampusResourcesSection({ organizationId, editable, verticalDisplay }: Props) {
  const { data, refetch, error, loading } = useCampusResourcesQuery({
    variables: { organizationId },
  });

  type Resource = CampusResourcesQuery['organization']['resources'][number];
  const [modalResource, setModalResource] = useState<Resource | 'new' | null>(null);
  const [modalResourceType, setModalResourceType] = useState<Partial<ResourceType> | null>(null);

  if (loading) return <LoadingPage />;
  if (!data || error) return <UnexpectedError />;

  // show this if provider is not Mantra Admin and there are no resources of a particular type
  const NoResources = () => {
    if (editable) {
      return null;
    }
    return (
      <SectionRowItem>
        <Text.bodySmallItalicized>None listed</Text.bodySmallItalicized>
      </SectionRowItem>
    );
  };

  const openAddResourceModal = (category: Partial<ResourceType>) => {
    setModalResource('new');
    setModalResourceType(category);
  };

  const closeResourceModal = () => {
    setModalResource(null);
    setModalResourceType(null);
  };

  type AddResourceProp = {
    category: Partial<ResourceType>;
  };
  const AddResource = ({ category }: AddResourceProp) => {
    if (!editable) {
      return null;
    }
    return (
      <SectionRowItem>
        <Text.linkButton onClick={() => openAddResourceModal(category)}>
          + Add Resource
        </Text.linkButton>
      </SectionRowItem>
    );
  };

  const onEdit = editable
    ? (id: number) => () => {
        const filteredResource = data?.organization.resources.find(
          ({ id: resourceId }) => resourceId === id
        );
        if (!filteredResource) {
          setModalResource('new');
          return;
        }
        if (filteredResource.resourceType in NewResourceTypes) {
          setModalResource(filteredResource);
          return;
        }
        setModalResource({ ...filteredResource, resourceType: ResourceType.Other }!);
      }
    : () => undefined;

  // CscHandbook - this can be removed once CscHandbook feature flag is removed
  const NewResourceTypes: Partial<Record<ResourceType, string>> = {
    [ResourceType.HealthServices]: 'Health Services',
    [ResourceType.ResidenceLife]: 'Residence Life',
    [ResourceType.PublicSafetyCampusPolice]: 'Public Safety/Campus Police',
    [ResourceType.UrgentCareCenter]: 'Urgent Care Center',
    [ResourceType.LocalHospitalCommunityProvider]: 'Local Hospitals or Other Community Providers',
    [ResourceType.PreferredCampusPharmacy]: 'Preferred Campus Pharmacy',
    [ResourceType.DisabilityAccessibilityCenter]: 'Disability/Accesibility Center',
    [ResourceType.LgbtqiaCenter]: 'LGBTQIA+ Center',
  };

  const renderResources = (resources: CampusResourcesQuery['organization']['resources']) => {
    if (resources.length <= 0) {
      return <NoResources />;
    }
    return resources.map(filteredResource => (
      <SectionRowItem
        key={`campus-resource-row-${filteredResource.id}`}
        onEditClick={onEdit(filteredResource.id)}
      >
        <Text.bodyBold>{filteredResource.name}</Text.bodyBold>
        {filteredResource.description ? (
          <Text.bodySmall>Description: {filteredResource.description}</Text.bodySmall>
        ) : null}
        {filteredResource.contact ? (
          <Text.bodySmall>Contact: {filteredResource.contact}</Text.bodySmall>
        ) : null}
        {filteredResource.email ? (
          <Text.bodySmall>Email: {filteredResource.email}</Text.bodySmall>
        ) : null}
        {filteredResource.hours ? (
          <Text.bodySmall>Hours: {filteredResource.hours}</Text.bodySmall>
        ) : null}
        {filteredResource.website ? (
          <Text.bodySmall>
            Website:{' '}
            <Text.externalLink href={getClickableLink(filteredResource.website)} target="_blank">
              {filteredResource.website}
            </Text.externalLink>
          </Text.bodySmall>
        ) : null}
      </SectionRowItem>
    ));
  };

  const sections = [
    {
      label: 'Health Services',
      category: ResourceType.HealthServices,
      resources: data.organization.resources.filter(
        ({ resourceType }) => resourceType === ResourceType.HealthServices
      ),
    },
    {
      label: 'Residence Life',
      category: ResourceType.ResidenceLife,
      resources: data.organization.resources.filter(
        ({ resourceType }) => resourceType === ResourceType.ResidenceLife
      ),
    },
    {
      label: 'Public Safety/Campus Police',
      category: ResourceType.PublicSafetyCampusPolice,
      resources: data.organization.resources.filter(
        ({ resourceType }) => resourceType === ResourceType.PublicSafetyCampusPolice
      ),
    },
    {
      label: 'Urgent Care Center',
      category: ResourceType.UrgentCareCenter,
      resources: data.organization.resources.filter(
        ({ resourceType }) => resourceType === ResourceType.UrgentCareCenter
      ),
    },
    {
      label: 'Local Hospitals or Other Community Providers',
      category: ResourceType.LocalHospitalCommunityProvider,
      resources: data.organization.resources.filter(
        ({ resourceType }) => resourceType === ResourceType.LocalHospitalCommunityProvider
      ),
    },
    {
      label: 'Preferred Campus Pharmacy',
      category: ResourceType.PreferredCampusPharmacy,
      info: 'If added to org, the location will show up as suggested pharmacy for patients during onboarding.',
      resources: data.organization.resources.filter(
        ({ resourceType }) => resourceType === ResourceType.PreferredCampusPharmacy
      ),
    },
    {
      label: 'Disability/Accessibility Center',
      category: ResourceType.DisabilityAccessibilityCenter,
      resources: data.organization.resources.filter(
        ({ resourceType }) => resourceType === ResourceType.DisabilityAccessibilityCenter
      ),
    },
    {
      label: 'LGBTQIA+ Center',
      category: ResourceType.LgbtqiaCenter,
      resources: data.organization.resources.filter(
        ({ resourceType }) => resourceType === ResourceType.LgbtqiaCenter
      ),
    },
    {
      label: 'Other',
      category: ResourceType.Other,
      resources: data.organization.resources.filter(
        ({ resourceType }) =>
          resourceType === ResourceType.Other || !(resourceType in NewResourceTypes)
      ),
    },
  ];

  return (
    <>
      {sections.map((section, idx) => (
        <SectionRow
          key={`${idx}-${section.category}`}
          verticalDisplay={verticalDisplay}
          label={section.label}
          info={section.info}
        >
          {renderResources(section.resources)}
          <AddResource category={section.category} />
        </SectionRow>
      ))}
      {modalResource && (
        <ResourceModal
          modalResourceType={modalResourceType}
          organizationId={organizationId}
          resource={modalResource === 'new' ? undefined : modalResource}
          onClose={() => closeResourceModal()}
          refetch={refetch}
        />
      )}
    </>
  );
}
