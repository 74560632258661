import React from 'react';
import { EditOrganizationHandbookInput } from '../../../graphQL';
import { EditHandbookInput } from '../EditHandbookInput';
import { parseTextForLinks } from '../Handbook';
import { SectionRow, SectionRowItem } from './SectionRow';
import type { EdibleSectionProps, EditableSectionRowInfo, HandBookInfo } from './types';

type Props = EdibleSectionProps & {
  handbookInfo: HandBookInfo;
  editableSectionRowInfo: EditableSectionRowInfo[];
  submitFunction: (
    editFieldName: keyof EditOrganizationHandbookInput
  ) => (value: string) => Promise<void>;
  verticalDisplay?: boolean;
};

export function AccommodationsSection({
  editable,
  handbookInfo,
  selectedField,
  editableSectionRowInfo,
  verticalDisplay,
  onCancelEdit,
  onSave,
  onStartEdit,
}: Props) {
  return (
    <>
      {editableSectionRowInfo.map(row => (
        <SectionRow verticalDisplay={verticalDisplay} label={row.label} info={row.info}>
          <SectionRowItem
            onEditClick={editable && onStartEdit ? () => onStartEdit(row.displayKey) : undefined}
          >
            {selectedField === row.displayKey ? (
              <EditHandbookInput
                handbookRowInfo={String(handbookInfo[row.displayKey])}
                selectedField={row.displayKey}
                onCancel={onCancelEdit}
                onSave={onSave ? value => onSave(row.editInputKey, value) : undefined}
              />
            ) : (
              parseTextForLinks(handbookInfo[row.displayKey])
            )}
          </SectionRowItem>
        </SectionRow>
      ))}
    </>
  );
}
