import { useParams } from 'react-router-dom';
import { titleCase } from '../../utils';
import { Entitlement, EntitlementDefinition, EntitlementDefinitionConfig } from '../../graphQL';

export function formatActivityStatus(org: { activityStatus: string }) {
  return titleCase(org.activityStatus.replace(/-/g, ' '));
}

export function useOrganizationId() {
  const { organizationId } = useParams<{ organizationId: string }>();
  return Number(organizationId);
}

export interface OrgTabProps {
  setActiveTab: (tab: string) => void;
  refetchHeader: () => void;
}

export type SettingsState = Record<
  string,
  { enabled: boolean; config: any; requiredField?: EntitlementDefinitionConfig['key'] }
>;

type OrgEntitlement = {
  key: string;
  config?: { [key: string]: Entitlement };
};

export const createInitialEntitlementValues = (
  entitlementDefinitions: EntitlementDefinition[],
  entitlements: OrgEntitlement[]
): SettingsState => {
  return entitlementDefinitions.reduce(
    (acc, val) => ({
      ...acc,
      [val.key]: {
        enabled: entitlements.some(i => i.key === val.key),
        config: entitlements.find(i => i.key === val.key)?.config ?? {},
        requiredField:
          entitlementDefinitions
            .find(ent => ent.key === val.key)
            ?.config.find(field => field.required)?.key ?? undefined,
      },
    }),
    {}
  );
};
