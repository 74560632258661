import moment from 'moment-timezone';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '../../Components/Icons';
import { AsyncListView, AsyncListViewFetcher } from '../../Components/ListView/AsyncListView';
import { hasConnectNow, hasDdh } from '../../Components/Organization/organizationUtils';
import { McpOnly, isMantraAdmin, useCurrentProvider } from '../../Components/Permissions';
import { Text } from '../../globalStyles';
import {
  AppView,
  ConnectNowSession,
  useConnectNowCallReportsQuery,
  useOrganizationsListQuery,
} from '../../graphQL';
import { isProviderSuperAdmin } from '../../modelUtils/provider';
import { OrganizationSmallGrayDisplay } from '../Organizations/OrganizationTopDisplay';
import { ColumnText, ColumnTextBold, ColumnTextSmallGray } from './components/ColumnText';
import { DownloadFileButton } from './components/DownloadButton';
import { useShowConnectNowCallReports } from './useShowConnectNowCallReport';
import { useRedirectFromOnDemand } from './hooks/useRedirectFromOnDemand';

type ReportHeaderProps = {
  organization: {
    name: string;
    abbreviation: string;
  };
};

type DisplayOrganization = {
  id: number;
  label: string;
};

type DisplayOrganizationsList = DisplayOrganization[];

const ReportHeader = ({ organization }: ReportHeaderProps) => {
  return (
    <>
      <McpOnly>
        <OrganizationSmallGrayDisplay organization={organization} />
      </McpOnly>
      <Text.h1 className="fw4">ConnectNow Call Reports</Text.h1>
      <Text.body className="mb2">
        Students can connect with mental health professionals to address in-the-moment emotional
        needs.
      </Text.body>
    </>
  );
};

type ConnectNowSessionData = {
  id: number;
  callStartTime?: Date | null;
  firstName: string;
  lastName: string;
  healthieNoteId: string;
  customerId?: string;
  userId?: number;
  orgName?: string;
  orgId?: number;
  type: string;
};

export function ConnectNowCallReports() {
  useRedirectFromOnDemand();
  const { currentProvider, appView } = useCurrentProvider();
  const history = useHistory();

  const shouldRedirect = !useShowConnectNowCallReports(); // Calling the hook at the top level

  useEffect(() => {
    if (shouldRedirect) {
      history.push('/'); // Using the value obtained from the hook inside useEffect
    }
  }, [shouldRedirect]);

  const showDdhLink = appView === AppView.Mcp && hasDdh(currentProvider.organizations[0]);

  const { refetch } = useConnectNowCallReportsQuery({ variables: { params: {} } });
  const { data: dataOrganizationsList } = useOrganizationsListQuery({
    skip: !isMantraAdmin(currentProvider) && !isProviderSuperAdmin(currentProvider),
  });

  const organizationsList: DisplayOrganizationsList = useMemo(() => {
    return (dataOrganizationsList?.organizations ?? [])
      .filter(org => org.entitlements && hasConnectNow(org))
      .map(org => ({
        id: org.id,
        label: org.name,
      }));
  }, [dataOrganizationsList]);

  const fetchData = useCallback(
    async (uiState, prevPage) => {
      const res = await refetch({
        params: {
          cursor: prevPage?.cursor,
          limit: uiState.limit,
          search: uiState.debouncedSearchValue,
          organizationId: uiState.filterValues?.organization,
        },
      });
      if (!res.data || !res.data.connectNowOrgSessions) throw new Error('Error fetching');

      const transformed = transformConnectNowSessions(res.data.connectNowOrgSessions.items);

      return {
        items: transformed,
        cursor: res.data.connectNowOrgSessions.cursor,
        hasMore: res.data.connectNowOrgSessions.hasMore,
        count: res.data.connectNowOrgSessions.count,
        limitCountExceeded: res.data.connectNowOrgSessions.limitCountExceeded,
      };
    },
    [refetch]
  );

  return (
    <div className="mw5 mw8-ns center pa2 pa4-ns">
      <ReportHeader organization={currentProvider.organizations[0]} />
      {showDdhLink && (
        <McpOnly>
          <div className="flex align-items mb4">
            <Text.link to="/crisisreports" kind="danger" className="b">
              Didi Hirsch 24/7 Crisis Reports
            </Text.link>
            <Icon icon="iconsExternalLinkRedSvg" size={16} className="ml2 mt1" />
          </div>
        </McpOnly>
      )}
      {isMantraAdmin(currentProvider) || isProviderSuperAdmin(currentProvider) ? (
        <MultiOrgListView fetchData={fetchData} organizationsFilter={organizationsList} />
      ) : (
        <SingleOrgListView fetchData={fetchData} />
      )}
    </div>
  );
}

type ListViewProps = {
  fetchData: AsyncListViewFetcher<ConnectNowSessionData>;
  organizationsFilter?: DisplayOrganizationsList;
};

const formattedStartTime = (callStartTime: Date | undefined) => {
  const tz = moment.tz.guess();
  return `${moment(callStartTime, 'YYYY-MM-DD HH:mm:ssZ').tz(tz).format('M/D/YYYY hh:mm a z')}`;
};

const SingleOrgListView = ({ fetchData }: ListViewProps) => (
  <AsyncListView
    fetchData={fetchData}
    persist
    searchable
    searchPlaceholder="Search name, Mantra ID, or email"
    empty="No calls reported"
    pageSize={10}
    getKey={session => session.id}
    columns={[
      {
        title: 'Call Start Time',
        key: 'callStartTime',
        render: session => {
          return <ColumnText>{formattedStartTime(session.callStartTime ?? undefined)} </ColumnText>;
        },
      },
      {
        title: 'Student',
        key: 'role',
        render: session => {
          return (
            <div>
              <ColumnTextBold>
                {session.lastName}, {session.firstName}
              </ColumnTextBold>
              <ColumnTextSmallGray>{session.customerId}</ColumnTextSmallGray>
            </div>
          );
        },
      },
      {
        title: 'Type',
        key: 'callType',
        render: session => {
          return (
            <div>
              <ColumnText>{session.type}</ColumnText>
            </div>
          );
        },
      },
      {
        title: '',
        key: 'profile',
        render: session => {
          return (
            <>
              {session.userId && (
                <Text.link to={`/users/${session.userId}/?tab=overview`} className="link">
                  <div className="flex items-center gray">
                    <Icon icon="iconsUserGreySvg" size={20} className="mr2" />
                    <ColumnTextBold>Profile</ColumnTextBold>
                  </div>
                </Text.link>
              )}
            </>
          );
        },
      },
      {
        title: '',
        key: 'download',
        render: session => <DownloadFileButton healthieNoteId={session.healthieNoteId} />,
      },
    ]}
  />
);

const MultiOrgListView = ({ fetchData, organizationsFilter }: ListViewProps) => (
  <AsyncListView
    fetchData={fetchData}
    persist
    pageSize={10}
    searchable
    searchPlaceholder="Search name, Mantra ID, or email"
    empty="No calls reported"
    getKey={session => session.id}
    columns={[
      {
        title: 'Call Start Time',
        key: 'callStartTime',
        render: session => (
          <ColumnText>{formattedStartTime(session.callStartTime ?? undefined)} </ColumnText>
        ),
      },
      {
        title: 'Student',
        key: 'student',
        render: session => {
          return (
            <div>
              <ColumnTextBold>
                {session.lastName}, {session.firstName}
              </ColumnTextBold>
              <ColumnTextSmallGray>{session.customerId}</ColumnTextSmallGray>
            </div>
          );
        },
      },
      {
        title: 'Campus',
        key: 'campus',
        render: session => (
          <div>
            <ColumnText>{session.orgName}</ColumnText>
          </div>
        ),
      },
      {
        title: 'Type',
        key: 'callType',
        render: session => {
          return (
            <div>
              <ColumnText>{session.type}</ColumnText>
            </div>
          );
        },
      },
      {
        title: '',
        key: 'profile',
        render: session => {
          return (
            <>
              {session.userId && (
                <Text.link to={`/users/${session.userId}/?tab=overview`} className="link">
                  <div className="flex items-center gray">
                    <Icon icon="iconsUserGreySvg" size={20} className="mr2" />
                    <ColumnTextBold>Profile</ColumnTextBold>
                  </div>
                </Text.link>
              )}
            </>
          );
        },
      },
      {
        title: '',
        key: 'download',
        render: session => <DownloadFileButton healthieNoteId={session.healthieNoteId} />,
      },
    ]}
    filters={[
      {
        key: 'organization',
        options: organizationsFilter ?? [],
        placeholder: 'All Campuses',
      },
    ]}
  />
);

export const transformConnectNowSessions = (
  connectNowSessions: ConnectNowSession[] | undefined
): ConnectNowSessionData[] => {
  if (!connectNowSessions) return [];

  return connectNowSessions
    .map(session => ({
      id: session.id,
      callStartTime: session.callStartTime,
      firstName: session.callerFirstName,
      lastName: session.callerLastName,
      healthieNoteId: session.healthieNoteId,
      customerId: session.customerId ?? undefined,
      userId: session.userId ?? undefined,
      orgName: session.organization?.name,
      orgId: session.organization?.id,
      type: session.callType,
    }))
    .sort((prev, next) => {
      if (!prev.callStartTime || !next.callStartTime) return 0;
      return prev.callStartTime > next.callStartTime ? -1 : 1;
    });
};
