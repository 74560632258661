/* eslint-disable */
/* THIS IS AN AUTO-GENERATED FILE */
/*
    ____            .    _  .
   /# /_\_          |\_|/__/|
  |  |/o\o\        / / \/ \  \
  |  \\_/_/       /__|O||O|__ \
 / |_   |        |/_ \_/\_/ _\ |
|  ||\_ ~|       | | (____) | ||
|  ||| \/        \/\___/\__/  //
|  |||_          (_/         ||
 \//  |           |          ||
  ||  |           |          ||\
  ||_  \           \        //_/
  \_|  o|           \______//
  /\___/          __ || __||
 /  ||||__       (____(____)
    (___)_)
run 'yarn codegen' to create a new one
*/
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: Date;
  DateTime: Date;
  JSON: { [key: string]: any };
  LocalDate: string;
  LooseLocalDate: string;
  UserBirthdate: string;
};

export interface Address {
  city: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<StateCodes>;
  updatedAt: Scalars['DateTime'];
  zip: Scalars['String'];
}

export interface AddressInput {
  city?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<StateCodes>;
  zip?: InputMaybe<Scalars['String']>;
}

export interface AdminAlert {
  careType?: Maybe<CareType>;
  message: Scalars['String'];
  resolveBy?: Maybe<Scalars['String']>;
  type: AdminAlertType;
}

export const AdminAlertType = {
  ConfirmPatientReactivation: 'ConfirmPatientReactivation',
  MissingPharmacy: 'MissingPharmacy',
  ReactivatePatient: 'ReactivatePatient',
  SponsoredCareExpiring: 'SponsoredCareExpiring',
  UnfinishedNote: 'UnfinishedNote',
} as const;

export type AdminAlertType = typeof AdminAlertType[keyof typeof AdminAlertType];
export interface AdminEditUserResult {
  email: Scalars['String'];
  id: Scalars['Int'];
}

export interface Allocation {
  appointmentTypes?: Maybe<Array<Scalars['String']>>;
  childOrganizations?: Maybe<Array<Organization>>;
  endTime: Scalars['DateTime'];
  id: Scalars['Float'];
  isFeeForServiceTime?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Organization>;
  provider: Provider;
  providerId: Scalars['Float'];
  purpose: TimeAllocationPurpose;
  repeatsUntil?: Maybe<Scalars['DateTime']>;
  startTime: Scalars['DateTime'];
  timezone: Scalars['String'];
  weekly: Scalars['Boolean'];
}

/** The different kinds of alternative care */
export const AlternativeCare = {
  CampusCrisisLine: 'campusCrisisLine',
  CollegeCounselor: 'collegeCounselor',
  EmergencyRoom: 'emergencyRoom',
  MantraTherapy: 'mantraTherapy',
  NoSupport: 'noSupport',
  OffCampusTherapy: 'offCampusTherapy',
  Other: 'other',
  OtherOr998: 'otherOr998',
  Unsure: 'unsure',
} as const;

export type AlternativeCare = typeof AlternativeCare[keyof typeof AlternativeCare];
export interface AnonymousNavigateFromPatientPortalInput {
  reason?: InputMaybe<AnonymousNavigateFromPatientPortalReason>;
  target: AnonymousNavigateFromPatientPortalTarget;
}

export const AnonymousNavigateFromPatientPortalReason = {
  InvalidLogin: 'invalidLogin',
  Reauth: 'reauth',
} as const;

export type AnonymousNavigateFromPatientPortalReason =
  typeof AnonymousNavigateFromPatientPortalReason[keyof typeof AnonymousNavigateFromPatientPortalReason];
export interface AnonymousNavigateFromPatientPortalResult {
  url: Scalars['String'];
}

export const AnonymousNavigateFromPatientPortalTarget = {
  Login: 'Login',
  LoginActivationResend: 'LoginActivationResend',
  ResetPassword: 'ResetPassword',
  Signup: 'Signup',
} as const;

export type AnonymousNavigateFromPatientPortalTarget =
  typeof AnonymousNavigateFromPatientPortalTarget[keyof typeof AnonymousNavigateFromPatientPortalTarget];
export interface Answer {
  answer?: Maybe<Scalars['String']>;
  answerKey?: Maybe<Scalars['JSON']>;
  key: Scalars['String'];
  legend?: Maybe<Array<Scalars['String']>>;
  multiText?: Maybe<Array<Scalars['String']>>;
  score?: Maybe<Scalars['Float']>;
  text?: Maybe<Scalars['String']>;
  textForProviders?: Maybe<Scalars['String']>;
  value: Scalars['JSON'];
}

export interface AnswerSubmission {
  answerKey?: InputMaybe<Scalars['JSON']>;
  key: Scalars['String'];
  multiText?: InputMaybe<Array<Scalars['String']>>;
  score?: InputMaybe<Scalars['Float']>;
  text?: InputMaybe<Scalars['String']>;
}

export interface AppointmentCharge {
  amountInCents: Scalars['Float'];
  capturedAt: Scalars['DateTime'];
  chargeType: AppointmentChargeType;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
}

export const AppointmentChargeType = {
  Copay: 'Copay',
  LateCancellationFee: 'LateCancellationFee',
  NoShowFee: 'NoShowFee',
  PatientResponsibility: 'PatientResponsibility',
  SelfPay: 'SelfPay',
} as const;

export type AppointmentChargeType =
  typeof AppointmentChargeType[keyof typeof AppointmentChargeType];
export interface AppointmentFeedbackInput {
  audioQualityDown?: InputMaybe<CallQuality>;
  audioQualityUp?: InputMaybe<CallQuality>;
  comments?: InputMaybe<Scalars['String']>;
  issues?: InputMaybe<Array<Scalars['String']>>;
  overallRating?: InputMaybe<Scalars['Float']>;
  userDevice?: InputMaybe<Scalars['JSON']>;
  videoQualityDown?: InputMaybe<CallQuality>;
  videoQualityUp?: InputMaybe<CallQuality>;
  videoRating?: InputMaybe<Scalars['Float']>;
}

export interface AppointmentTemplate {
  appointmentType: Scalars['String'];
  careType: CareType;
  description: Scalars['String'];
  duration: Scalars['Int'];
  id: Scalars['Float'];
  organization: Organization;
}

export interface AppointmentTemplateUpdate {
  appointmentType: Scalars['String'];
  careType: Scalars['String'];
  duration: Scalars['Int'];
}

export const AppointmentType = {
  Adhoc: 'adhoc',
  Checkin: 'checkin',
  Intake: 'intake',
} as const;

export type AppointmentType = typeof AppointmentType[keyof typeof AppointmentType];
export const AppView = {
  Mcp: 'mcp',
  Oz: 'oz',
  Referral: 'referral',
} as const;

export type AppView = typeof AppView[keyof typeof AppView];
export interface Assessment {
  id: Scalars['Float'];
  key: AssessmentKey;
  questionnaires: Array<Questionnaire>;
}

export const AssessmentKey = {
  AdjustedIntake: 'AdjustedIntake',
  Bipolar1Assessment: 'Bipolar1Assessment',
  Bipolar1Screener: 'Bipolar1Screener',
  CoachingExecutiveFunctioningKickoff: 'CoachingExecutiveFunctioningKickoff',
  CoachingExecutiveFunctioningWellnessAssessment: 'CoachingExecutiveFunctioningWellnessAssessment',
  CoachingKickOff: 'CoachingKickOff',
  CoachingWellnessAssessment: 'CoachingWellnessAssessment',
  FindCare: 'FindCare',
  Intake: 'Intake',
  MedicalHistory: 'MedicalHistory',
  Scales: 'Scales',
  SelfRefer1Screener: 'SelfRefer1Screener',
} as const;

export type AssessmentKey = typeof AssessmentKey[keyof typeof AssessmentKey];
export interface AssessmentModel {
  asrsv11: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  gad7: Scalars['Float'];
  id: Scalars['Float'];
  phq9: Scalars['Float'];
  result: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
}

export interface AssessmentRequestModel {
  complete: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  key: AssessmentKey;
}

export interface AssessmentResponse {
  createdAt: Scalars['DateTime'];
  followUpRequest?: Maybe<AssessmentRequestModel>;
  id: Scalars['Float'];
  provider?: Maybe<Provider>;
  questionnaires: Array<QuestionnaireResult>;
  specialConditions?: Maybe<SpecialConditions>;
}

export interface AssignableSkill {
  moduleId: Scalars['String'];
  skillId: Scalars['String'];
  title: Scalars['String'];
}

export interface AssignmentPagination {
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
}

export interface AssignSkillToUserResponse {
  isDuplicate: Scalars['Boolean'];
  wasAssigned: Scalars['Boolean'];
}

export interface AsynchronousJob {
  id: Scalars['Float'];
  status: Scalars['String'];
  url?: Maybe<Scalars['String']>;
}

export interface AuthInstructions {
  authMethod: AuthMethod;
  hasPreviousSSOLogin?: Maybe<Scalars['Boolean']>;
  organizationAbbreviation?: Maybe<Scalars['String']>;
  samlEntryPoint?: Maybe<Scalars['String']>;
}

export const AuthMethod = {
  Password: 'Password',
  Saml: 'SAML',
} as const;

export type AuthMethod = typeof AuthMethod[keyof typeof AuthMethod];
export interface Availability {
  allocationId: Scalars['Float'];
  blockers: Array<AvailabilityBlockedReason>;
  careType: CareType;
  end: Scalars['String'];
  isFeeForServiceTime?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['Float']>;
  provider: Provider;
  providerId: Scalars['Float'];
  start: Scalars['String'];
}

export const AvailabilityBlockedReason = {
  BackToBackIntake: 'BackToBackIntake',
  ContractSessions: 'ContractSessions',
  DedicatedHoursMaxedForWeek: 'DedicatedHoursMaxedForWeek',
  FfsAllocationBlockedForSameDay: 'FFSAllocationBlockedForSameDay',
  IntakesMaxedForDay: 'IntakesMaxedForDay',
  NoDgmHoursLeftForState: 'NoDGMHoursLeftForState',
  NoDgmIntakesLeftForState: 'NoDGMIntakesLeftForState',
  SessionsModelWeeklyIntakes: 'SessionsModelWeeklyIntakes',
  SessionsModelWeeklySessions: 'SessionsModelWeeklySessions',
  StudentCapacity: 'StudentCapacity',
} as const;

export type AvailabilityBlockedReason =
  typeof AvailabilityBlockedReason[keyof typeof AvailabilityBlockedReason];
export interface AvailabilityResult {
  data: Array<Availability>;
  globalBlockers: Array<SimpleGlobalAvailabilityBlocker>;
  interval: Scalars['Float'];
}

export interface AvailabilityV4 {
  availability: Array<Availability>;
  globalBlockers: Array<SimpleGlobalAvailabilityBlocker>;
}

/** Calendar events show events related to a patient. */
export interface CalendarEvent {
  adminCrisisProtocolEngagedAt?: Maybe<Scalars['DateTime']>;
  adminCrisisTeamNotifiedAt?: Maybe<Scalars['DateTime']>;
  adminHostCrisisId?: Maybe<Scalars['String']>;
  appointmentType: Scalars['String'];
  cancelledOrRescheduleWithin: Scalars['Boolean'];
  careType: CareType;
  changedOutOfPolicy: Scalars['Boolean'];
  charges: Array<AppointmentCharge>;
  checkedInAt?: Maybe<Scalars['DateTime']>;
  complete: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  endTime: Scalars['DateTime'];
  finalizedInsuranceClaim?: Maybe<FinalizedInsuranceClaim>;
  id: Scalars['Float'];
  interpretationServices?: Maybe<Scalars['String']>;
  /** Is the appointment blocked by patient tasks */
  isBlocked: Scalars['Boolean'];
  medicalNote?: Maybe<MedicalNote>;
  provider: Provider;
  providerId: Scalars['Float'];
  providerIncident?: Maybe<ProviderIncident>;
  requiresProviderFeedback: Scalars['Boolean'];
  startTime: Scalars['DateTime'];
  status: Scalars['String'];
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusChanges?: Maybe<Array<CalendarStatusChange>>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['Float'];
  usesDedicatedGroupModelHours?: Maybe<Scalars['Boolean']>;
  videoSessionId: Scalars['String'];
  videoToken: Scalars['String'];
}

/** Calendar events show events related to a patient. */
export type CalendarEventCancelledOrRescheduleWithinArgs = {
  diffHours: Scalars['Float'];
};

/** Calendar events show events related to a patient. */
export type CalendarEventRequiresProviderFeedbackArgs = {
  includeCurrentAppointment?: InputMaybe<Scalars['Boolean']>;
};

/** Calendar events show events related to a patient. */
export type CalendarEventStatusChangedAtArgs = {
  toStatuses: Array<Scalars['String']>;
};

export interface CalendarStatusChange {
  actingProvider?: Maybe<Provider>;
  createdAt: Scalars['DateTime'];
  fromStatus: Scalars['String'];
  id: Scalars['Float'];
  toStatus: Scalars['String'];
}

export interface CallQuality {
  bitrate: Scalars['Float'];
  packetLossRatio: Scalars['Float'];
}

export interface CampusOrgMappingInput {
  campus: Scalars['String'];
  organizationId: Scalars['Int'];
}

export interface CampusOrgMappingsInput {
  campus: Scalars['String'];
  organizationId: Scalars['Int'];
}

export interface CampusTeamMemberInput {
  providerId: Scalars['Int'];
  relationshipType: UniversityRelationship;
}

export interface CampusTeamRelationship {
  provider: Provider;
  relationshipType: UniversityRelationship;
  user: User;
}

export interface CampusTeamRelationshipEvents {
  events: Array<Event>;
  relationship: CampusTeamRelationship;
}

export interface CapacityUsage {
  endTime: Scalars['DateTime'];
  entries: Array<CareTypeUsage>;
  startTime: Scalars['DateTime'];
}

export interface CareEligibility {
  isUserEligible?: Maybe<Scalars['Boolean']>;
  isUserUnderage: Scalars['Boolean'];
  minimumAge: Scalars['Float'];
  minorConsentNeeded: Scalars['Boolean'];
  permittedCareTypes: Array<CareType>;
}

export interface CareRecommendation {
  recommendation?: Maybe<Scalars['String']>;
}

export interface CareRecommendationResponse {
  careRecommendation?: Maybe<RecommendationOptions>;
  connectNowEnabled: Scalars['Boolean'];
  isUserDeactivated?: Maybe<Scalars['Boolean']>;
  onDemandEnabled: Scalars['Boolean'];
  togetherAllEnabled: Scalars['Boolean'];
}

export interface CareSessionInfo {
  careType: CareType;
  completedAfter?: Maybe<Scalars['DateTime']>;
  completedAppts: Scalars['Float'];
  flowLimit?: Maybe<Scalars['Float']>;
  remainingAppts?: Maybe<Scalars['Float']>;
  unlimitedAppts: Scalars['Boolean'];
  user: User;
}

export const CareStatus = {
  Active: 'Active',
  Cancelled: 'Cancelled',
  Deleted: 'Deleted',
  Discharged: 'Discharged',
  New: 'New',
  OnHold: 'OnHold',
  ScreenedOut: 'ScreenedOut',
} as const;

export type CareStatus = typeof CareStatus[keyof typeof CareStatus];
export interface CareTeamChannel {
  channel: Channel;
  id: Scalars['Float'];
  user: User;
}

export interface CareTeamPreference {
  key: Scalars['String'];
  value: Scalars['JSON'];
}

export interface CareTeamReferral {
  activePatients: Scalars['Float'];
  activeReferrals: Scalars['Float'];
  provider: Provider;
  referralCompletionRate: Scalars['Float'];
  referralsMade: Scalars['Float'];
}

export const CareType = {
  Psychiatry: 'Psychiatry',
  Therapy: 'Therapy',
} as const;

export type CareType = typeof CareType[keyof typeof CareType];
export interface CareTypeUsage {
  careType: CareType;
  minutesAvailable: Scalars['Int'];
  minutesUsed: Scalars['Int'];
}

export interface Channel {
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  lastMessage?: Maybe<MessageV2>;
  messages: Array<MessageV2>;
  /** all providers who have sent messages in the channel */
  uniqProviders: Array<Provider>;
  unreadMessageCount: Scalars['Float'];
  unreadMessages: Array<MessageV2>;
}

export const ClaimAction = {
  Note: 'Note',
  StatusChange: 'StatusChange',
} as const;

export type ClaimAction = typeof ClaimAction[keyof typeof ClaimAction];
export interface CoachChatMessage {
  author: CoachChatMessageAuthor;
  content: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['String'];
}

export const CoachChatMessageAuthor = {
  Coach: 'coach',
  Patient: 'patient',
} as const;

export type CoachChatMessageAuthor =
  typeof CoachChatMessageAuthor[keyof typeof CoachChatMessageAuthor];
export interface CoachChatUpdate {
  action: Scalars['String'];
  message?: Maybe<CoachChatMessage>;
  messageId: Scalars['String'];
}

export interface CoachingAssessmentProgress {
  completed: Scalars['Boolean'];
  key: CoachingAssessments;
}

export const CoachingAssessments = {
  Goals: 'Goals',
  GoalsSuccess: 'GoalsSuccess',
  Wellness: 'Wellness',
  WellnessSuccess: 'WellnessSuccess',
} as const;

export type CoachingAssessments = typeof CoachingAssessments[keyof typeof CoachingAssessments];
export interface CoachingIntakeProgress {
  setGoalsCompleted: Scalars['Boolean'];
  wellnessAssessmentCompleted: Scalars['Boolean'];
}

export interface CoachingIntakeProgressV2 {
  coachingAssessments: Array<CoachingAssessmentProgress>;
}

export const CoachingProgramType = {
  EmotionalWellness: 'EmotionalWellness',
  ExecutiveFunctioning: 'ExecutiveFunctioning',
} as const;

export type CoachingProgramType = typeof CoachingProgramType[keyof typeof CoachingProgramType];
export const CoachingStatus = {
  Active: 'active',
  Inactive: 'inactive',
  Onboarding: 'onboarding',
} as const;

export type CoachingStatus = typeof CoachingStatus[keyof typeof CoachingStatus];
export interface CoachOverview {
  coachAvatarUrl?: Maybe<Scalars['String']>;
  coachingEndDate?: Maybe<Scalars['String']>;
  coachingStartDate?: Maybe<Scalars['String']>;
  coachName: Scalars['String'];
  hasUnreadMessages: Scalars['Boolean'];
}

export interface ConfigurationOption {
  description: Scalars['String'];
  key: Scalars['String'];
  subText?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  value: Scalars['JSON'];
}

/** The different kinds of alternative care */
export const ConnectNowAlternativeCare = {
  CampusCrisisLine: 'campusCrisisLine',
  CollegeCounselor: 'collegeCounselor',
  EmergencyRoom: 'emergencyRoom',
  MantraTherapy: 'mantraTherapy',
  NoSupport: 'noSupport',
  OffCampusTherapy: 'offCampusTherapy',
  Other: 'other',
  OtherOr998: 'otherOr998',
  Unsure: 'unsure',
} as const;

export type ConnectNowAlternativeCare =
  typeof ConnectNowAlternativeCare[keyof typeof ConnectNowAlternativeCare];
/** The main concerns of a user */
export const ConnectNowMainConcerns = {
  Academics: 'Academics',
  AnxietyStress: 'AnxietyStress',
  CareerPlanning: 'CareerPlanning',
  ConcernForSomeoneElse: 'ConcernForSomeoneElse',
  Depression: 'Depression',
  Family: 'Family',
  FeelingDown: 'FeelingDown',
  Finances: 'Finances',
  FriendsRoommates: 'FriendsRoommates',
  Grief: 'Grief',
  HealthConcerns: 'HealthConcerns',
  Lgbtqia: 'LGBTQIA',
  LifeChanges: 'LifeChanges',
  Loneliness: 'Loneliness',
  Other: 'Other',
  Relationship: 'Relationship',
  SelfInjury: 'SelfInjury',
  SubstanceAbuse: 'SubstanceAbuse',
  SuicidalThoughts: 'SuicidalThoughts',
  Work: 'Work',
} as const;

export type ConnectNowMainConcerns =
  typeof ConnectNowMainConcerns[keyof typeof ConnectNowMainConcerns];
export interface ConnectNowPreEncounterInput {
  alternativeCare: ConnectNowAlternativeCare;
  currentLocation: Scalars['String'];
  distressLevel: Scalars['Int'];
  mainConcern: ConnectNowMainConcerns;
  otherAlternativeCare?: InputMaybe<Scalars['String']>;
  otherMainConcern?: InputMaybe<Scalars['String']>;
  preferredPhoneNumber: Scalars['String'];
  suicidalThoughts: ConnectNowSuicidalThoughts;
}

export interface ConnectNowReportsInput {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
}

export interface ConnectNowSession {
  callerFirstName: Scalars['String'];
  callerLastName: Scalars['String'];
  callerPreferredName?: Maybe<Scalars['String']>;
  callStartTime: Scalars['DateTime'];
  callType: Scalars['String'];
  customerId?: Maybe<Scalars['String']>;
  healthieNoteId: Scalars['String'];
  id: Scalars['Float'];
  organization?: Maybe<ConnectNowSessionOrganization>;
  userId?: Maybe<Scalars['Float']>;
}

export interface ConnectNowSessionDocument {
  expiringUrl: Scalars['String'];
}

export interface ConnectNowSessionDocumentOutput {
  document?: Maybe<ConnectNowSessionDocument>;
}

export interface ConnectNowSessionOrganization {
  id: Scalars['Float'];
  name: Scalars['String'];
}

/** The different kinds of suicidal thoughts */
export const ConnectNowSuicidalThoughts = {
  DuringMyLifeTime: 'DuringMyLifeTime',
  Never: 'Never',
  WithinThePastMonth: 'WithinThePastMonth',
  WithinThePastYear: 'WithinThePastYear',
} as const;

export type ConnectNowSuicidalThoughts =
  typeof ConnectNowSuicidalThoughts[keyof typeof ConnectNowSuicidalThoughts];
export interface Contact {
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneValidated?: Maybe<Scalars['Boolean']>;
  primary?: Maybe<Scalars['Boolean']>;
  relationship?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  userId: Scalars['Int'];
}

export interface ContactUpsertInput {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  primary: Scalars['Boolean'];
  relationship?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
}

export interface ContinuityOfCareEligibilityCarePeriodValues {
  currentPeriod: OrganizationCarePeriodModel;
  firstDayOfBreak?: Maybe<Scalars['String']>;
  isCarePeriodEnding?: Maybe<Scalars['Boolean']>;
  isEndingDueToBreak?: Maybe<Scalars['Boolean']>;
  lastDayOfBreak?: Maybe<Scalars['String']>;
  lastDayOfTerm?: Maybe<Scalars['String']>;
  nextPeriod?: Maybe<OrganizationCarePeriodModel>;
}

export interface ContinuityOfCareEligibilityInput {
  additionalNotes?: InputMaybe<Scalars['String']>;
  careType: CareType;
  enrollment: Scalars['Boolean'];
  interestedInContinuingCare: Scalars['Boolean'];
  paymentType: Scalars['String'];
  providerId?: InputMaybe<Scalars['Float']>;
  remainingSessions?: InputMaybe<Scalars['Float']>;
  residentialLocationDuringBreak: Scalars['String'];
  userId: Scalars['Float'];
}

export interface ContinuityOfCareEligibilityModel {
  additionalNotes?: Maybe<Scalars['String']>;
  careType: CareType;
  completedBy: Provider;
  completedById: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  enrollment: Scalars['Boolean'];
  id: Scalars['Int'];
  interestedInContinuingCare: Scalars['Boolean'];
  paymentType: Scalars['String'];
  provider?: Maybe<Provider>;
  providerId: Scalars['Float'];
  remainingSessions?: Maybe<Scalars['Float']>;
  residentialLocationDuringBreak: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['Float'];
}

export interface ContinuityOfCareEligibilityResult {
  canContinueCare: Scalars['Boolean'];
  enrollment: Scalars['Boolean'];
  interestedInContinuingCare: Scalars['Boolean'];
  isEligible: Scalars['Boolean'];
  needsReferral: Scalars['Boolean'];
  paymentType: PaymentType;
  providerInState: Scalars['Boolean'];
}

export interface ContinuityOfCareEligibilityValidation {
  careType: CareType;
  paymentType: PaymentType;
}

export interface ContinuityOfCareModel {
  careType: CareType;
  completedOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  nextSteps: NextSteps;
  pendingUser?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  requestedCareOutOfState?: Maybe<Scalars['Boolean']>;
  requiresReferral?: Maybe<Scalars['Boolean']>;
  secondaryState?: Maybe<StateCodes>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
}

export interface Contract {
  id: Scalars['Int'];
  psychiatryWeeks: ContractWeeks;
  scope: ContractScope;
  therapyWeeks: ContractWeeks;
  utilization: ContractUtilization;
}

export interface ContractCareTypeUtilization {
  sessionsRemaining: Scalars['Float'];
  sessionsScheduled: Scalars['Float'];
  sessionsUtilized: Scalars['Float'];
  studentCapacityRemaining: Scalars['Float'];
  studentCapacityUtilized: Scalars['Float'];
}

export interface ContractScope {
  endDate: Scalars['LocalDate'];
  psychiatryPatients: Scalars['Int'];
  psychiatrySessions: Scalars['Int'];
  startDate: Scalars['LocalDate'];
  therapyPatients: Scalars['Int'];
  therapySessions: Scalars['Int'];
}

export interface ContractUtilization {
  psychiatryUsage: ContractCareTypeUtilization;
  therapyUsage: ContractCareTypeUtilization;
}

export interface ContractWeek {
  endDate: Scalars['LocalDate'];
  id: Scalars['Int'];
  intakesMax: Scalars['Int'];
  intakesScheduled: Scalars['Int'];
  intakesUtilized: Scalars['Int'];
  sessionsMax: Scalars['Int'];
  sessionsScheduled: Scalars['Int'];
  sessionsUtilized: Scalars['Int'];
  startDate: Scalars['LocalDate'];
}

export interface ContractWeeks {
  current?: Maybe<ContractWeek>;
  past: Array<ContractWeek>;
  upcoming: Array<ContractWeek>;
}

export interface CreateAllocationInput {
  appointmentTypes?: InputMaybe<Array<Scalars['String']>>;
  childOrganizationIds?: InputMaybe<Array<Scalars['Float']>>;
  endTime: Scalars['DateTime'];
  existingId?: InputMaybe<Scalars['Float']>;
  isFeeForServiceTime?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  purpose: TimeAllocationPurpose;
  repeatsUntil?: InputMaybe<Scalars['DateTime']>;
  startTime: Scalars['DateTime'];
}

export interface CreateCareTransition {
  careType: CareType;
  nextSteps: NextSteps;
  reason?: InputMaybe<Scalars['String']>;
  requiresReferral?: InputMaybe<Scalars['Boolean']>;
  secondaryState?: InputMaybe<StateCodes>;
  userId: Scalars['Int'];
}

export interface CreateContractError {
  errorCode: CreateContractErrorCode;
  message: Scalars['String'];
}

export const CreateContractErrorCode = {
  InvalidDateRange: 'InvalidDateRange',
  MainOrgOnly: 'MainOrgOnly',
} as const;

export type CreateContractErrorCode =
  typeof CreateContractErrorCode[keyof typeof CreateContractErrorCode];
export interface CreateContractInput {
  endDate: Scalars['LooseLocalDate'];
  organizationId: Scalars['Float'];
  psychiatryPatients?: InputMaybe<Scalars['Int']>;
  psychiatrySessions?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['LooseLocalDate'];
  therapyPatients?: InputMaybe<Scalars['Int']>;
  therapySessions?: InputMaybe<Scalars['Int']>;
}

export type CreateContractResult = CreateContractError | CreateContractSuccess;

export interface CreateContractSuccess {
  id: Scalars['Int'];
}

export interface CreateFileUpload {
  globallyShared: Scalars['Boolean'];
  key: Scalars['String'];
  name: Scalars['String'];
  type: UploadType;
  userId?: InputMaybe<Scalars['Float']>;
}

export interface CreateIntake {
  endTime: Scalars['DateTime'];
  isFeeForServiceTime?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  providerId: Scalars['Float'];
  startTime: Scalars['DateTime'];
}

export interface CreateOrganization {
  abbreviation: Scalars['String'];
  careTypes: Array<CareType>;
  departmentAddress: Scalars['String'];
  departmentHours: Scalars['String'];
  departmentName: Scalars['String'];
  emailDomains: Array<Scalars['String']>;
  entitlements: Array<OrganizationEntitlementInput>;
  name: Scalars['String'];
  type: OrganizationType;
}

export interface CreateOrganizationAnnouncementInput {
  details?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['Float'];
  title: Scalars['String'];
}

export interface CreateProviderStateAllocationsModelInput {
  endDate: Scalars['String'];
  maxHours: Scalars['Float'];
  maxIntakeHours: Scalars['Float'];
  providerStateId: Scalars['Int'];
  startDate: Scalars['String'];
}

export interface CreateStaff {
  classification: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  npi?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['Float'];
  phone: Scalars['String'];
  postNominalTitles: Scalars['String'];
  role: CreateStaffRole;
}

export const CreateStaffRole = {
  UniversityAdmin: 'universityAdmin',
  UniversityReferrer: 'universityReferrer',
  UniversityStaff: 'universityStaff',
  UniversitySupportingClinician: 'universitySupportingClinician',
} as const;

export type CreateStaffRole = typeof CreateStaffRole[keyof typeof CreateStaffRole];
export interface CreateTestUser {
  careTypes: Array<CareType>;
  completeCheckout?: InputMaybe<Scalars['Boolean']>;
  completeProfile?: InputMaybe<Scalars['Boolean']>;
  defaultPaymentSource?: InputMaybe<PaymentSource>;
  includeAppointmentFeedback?: InputMaybe<Scalars['Boolean']>;
  medicalHistory?: InputMaybe<Scalars['Boolean']>;
  mockMessages?: InputMaybe<Scalars['Boolean']>;
  numAppointments?: InputMaybe<Scalars['Float']>;
  numAssessments?: InputMaybe<Scalars['Float']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  psychiatristId?: InputMaybe<Scalars['Float']>;
  referralSource: ReferralSource;
  therapistId?: InputMaybe<Scalars['Float']>;
  withPharmacy?: InputMaybe<Scalars['Boolean']>;
}

export interface CreateTicketInput {
  assigneeId?: InputMaybe<Scalars['Float']>;
  category: TicketCategory;
  description?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<TicketPriority>;
  resolveIn24Hrs?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
  userId?: InputMaybe<Scalars['Float']>;
}

export interface CreateUser {
  birthDate: Scalars['UserBirthdate'];
  campusTeam?: InputMaybe<Array<CampusTeamMemberInput>>;
  careTypes: Array<CareType>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  geoState: StateCodes;
  lastName: Scalars['String'];
  organizationId: Scalars['Float'];
  phone: Scalars['String'];
  preferredName?: InputMaybe<Scalars['String']>;
  samlUniqueId?: InputMaybe<Scalars['String']>;
  suggestedProviders?: InputMaybe<Array<Scalars['Float']>>;
}

export interface CreateUserDemographicsInput {
  academicProgram: Scalars['String'];
  ethnicity: Scalars['String'];
  genderIdentity: Scalars['String'];
  isInternationalStudent: Scalars['Boolean'];
  sexualOrientation: Scalars['String'];
  yearInProgram: Scalars['String'];
}

export interface CrisisTeamHours {
  dayOfWeek: DayOfWeek;
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
}

export interface CrisisTeamHoursInput {
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
}

export interface CrisisTeamRelationship {
  crisisPhone: Scalars['String'];
  organization: Organization;
  provider: Provider;
}

export interface CustomClinicalRecConfig {
  /** The url for the call to action button on the custom clinical care recommendation card. */
  customClinicalRecButtonLink?: Maybe<Scalars['String']>;
  /** The text for the call to action button on the custom clinical care recommendation card. */
  customClinicalRecButtonText?: Maybe<Scalars['String']>;
  customClinicalRecDescription: Scalars['String'];
  customClinicalRecTitle: Scalars['String'];
}

export interface DataSharingConsent {
  endDate: Scalars['DateTime'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  revokedAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
}

export interface DataSharingRequest {
  canProviderSendReminder: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  lastReminderSentDate?: Maybe<Scalars['DateTime']>;
  pending: Scalars['Boolean'];
  provider: Provider;
}

export const DayOfWeek = {
  Friday: 'Friday',
  Monday: 'Monday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  Thursday: 'Thursday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
} as const;

export type DayOfWeek = typeof DayOfWeek[keyof typeof DayOfWeek];
export interface DdhFileReport {
  callId: Scalars['String'];
  callStart: Scalars['DateTime'];
  ddhFileSuccessId: Scalars['Float'];
  id: Scalars['Float'];
}

export interface DdhFileSuccess {
  ddhFileEntityId: Scalars['Float'];
  id: Scalars['Float'];
  organizationId: Scalars['Float'];
  reportDate: Scalars['String'];
  reports?: Maybe<Array<DdhFileReport>>;
  zipLink: Scalars['String'];
}

export interface DebugUserCareFlow {
  careStatus: CareStatus;
  careType: CareType;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  paymentSource: PaymentSource;
  taskStatus: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  validEnd?: Maybe<Scalars['DateTime']>;
  validStart: Scalars['DateTime'];
}

export interface Dependency {
  foreignKey?: Maybe<Scalars['String']>;
  optionText?: Maybe<Scalars['String']>;
}

export interface DependentQuestions {
  /** The question is required if <target> question is blank. */
  requiredIfEmpty?: Maybe<Scalars['Boolean']>;
  requiredIfValues?: Maybe<Array<Scalars['String']>>;
  targetKey: Scalars['String'];
}

export const DiscountType = {
  PercentDiscount: 'PercentDiscount',
  ReplacePrice: 'ReplacePrice',
} as const;

export type DiscountType = typeof DiscountType[keyof typeof DiscountType];
export const EditableUserFields = {
  Address: 'Address',
  CareFlows: 'CareFlows',
  CareTeam: 'CareTeam',
  DateOfBirth: 'DateOfBirth',
  Email: 'Email',
  LegalName: 'LegalName',
  Organization: 'Organization',
  OrgCareTeam: 'OrgCareTeam',
  Phone: 'Phone',
  PreferredName: 'PreferredName',
  Providers: 'Providers',
  SecondaryState: 'SecondaryState',
  UniqueId: 'UniqueId',
} as const;

export type EditableUserFields = typeof EditableUserFields[keyof typeof EditableUserFields];
export interface EditContractScopeError {
  errorCode: EditContractScopeErrorCode;
  message: Scalars['String'];
}

export const EditContractScopeErrorCode = {
  ContractRangeTooLong: 'ContractRangeTooLong',
  InvalidDateRange: 'InvalidDateRange',
  OverlappingContracts: 'OverlappingContracts',
} as const;

export type EditContractScopeErrorCode =
  typeof EditContractScopeErrorCode[keyof typeof EditContractScopeErrorCode];
export interface EditContractScopeInput {
  endDate?: InputMaybe<Scalars['LooseLocalDate']>;
  id: Scalars['Int'];
  psychiatryPatients?: InputMaybe<Scalars['Int']>;
  psychiatrySessions?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['LooseLocalDate']>;
  therapyPatients?: InputMaybe<Scalars['Int']>;
  therapySessions?: InputMaybe<Scalars['Int']>;
}

export type EditContractScopeResult = EditContractScopeError | EditContractScopeSuccess;

export interface EditContractScopeSuccess {
  id: Scalars['Int'];
}

export const EditContractWeekErrorCode = {
  IntakeGreaterThanSessions: 'IntakeGreaterThanSessions',
  WeekNotFound: 'WeekNotFound',
} as const;

export type EditContractWeekErrorCode =
  typeof EditContractWeekErrorCode[keyof typeof EditContractWeekErrorCode];
export interface EditContractWeekIntakesInput {
  id: Scalars['Int'];
  intakes: Scalars['Int'];
}

export interface EditContractWeeksAggregateError {
  aggregateErrors: Array<EditContractWeeksError>;
}

export interface EditContractWeeksError {
  errorCode: EditContractWeekErrorCode;
  weekId: Scalars['Int'];
}

export interface EditContractWeekSessionsInput {
  id: Scalars['Int'];
  sessions: Scalars['Int'];
}

export interface EditContractWeeksInput {
  psychiatryWeekIntakes?: InputMaybe<Array<EditContractWeekIntakesInput>>;
  psychiatryWeekSessions?: InputMaybe<Array<EditContractWeekSessionsInput>>;
  therapyWeekIntakes?: InputMaybe<Array<EditContractWeekIntakesInput>>;
  therapyWeekSessions?: InputMaybe<Array<EditContractWeekSessionsInput>>;
}

export type EditContractWeeksResult = EditContractWeeksAggregateError | EditContractWeeksSuccess;

export interface EditContractWeeksSuccess {
  successMessage: Scalars['String'];
}

export interface EditOrganizationAnnouncementInput {
  details?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
}

export interface EditOrganizationCarePeriod {
  endDate: Scalars['String'];
  id: Scalars['Int'];
  periodType: PeriodType;
  startDate: Scalars['String'];
}

export interface EditOrganizationHandbookInput {
  campusCrisisTeamInProduct?: InputMaybe<Scalars['String']>;
  campusSafetyPoliceNumber?: InputMaybe<Scalars['String']>;
  coPayChargedByPartner?: InputMaybe<Scalars['String']>;
  defaultTherapyCadence?: InputMaybe<Scalars['String']>;
  ekgsNearCampus?: InputMaybe<Scalars['String']>;
  fullADHDEvaluation?: InputMaybe<Scalars['String']>;
  highLevelProtocolManagingEmergency?: InputMaybe<Scalars['String']>;
  hospitalization?: InputMaybe<Scalars['String']>;
  labsNearCampus?: InputMaybe<Scalars['String']>;
  mainPointOfContactCrisis?: InputMaybe<Scalars['String']>;
  noShowsCountForSessionLimits?: InputMaybe<Scalars['String']>;
  protocolForMedicalLeaveDoc?: InputMaybe<Scalars['String']>;
  requestingAcademicAccomodations?: InputMaybe<Scalars['String']>;
  sessionLimitsRenew?: InputMaybe<Scalars['String']>;
  summerBreakEligibility?: InputMaybe<Scalars['String']>;
  supportingClinician?: InputMaybe<Scalars['String']>;
  vitalsNearCampus?: InputMaybe<Scalars['String']>;
  winterBreakEligibility?: InputMaybe<Scalars['String']>;
}

export interface EditTicketInput {
  assigneeId?: InputMaybe<Scalars['Float']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<TicketCategory>;
  description?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<TicketPriority>;
  resolveIn24Hrs?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<TicketStatus>;
  title?: InputMaybe<Scalars['String']>;
}

export interface EditUser {
  authToken?: InputMaybe<Scalars['String']>;
  billingAddress?: InputMaybe<AddressInput>;
  birthDate?: InputMaybe<Scalars['String']>;
  campusTeam?: InputMaybe<Array<CampusTeamMemberInput>>;
  careFlows?: InputMaybe<Array<UserCareFlowInput>>;
  dtc?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: InputMaybe<Scalars['String']>;
  mantraAdminCareTeam?: InputMaybe<Array<Scalars['Float']>>;
  organizationId?: InputMaybe<Scalars['Float']>;
  phone?: InputMaybe<Scalars['String']>;
  preferredName?: InputMaybe<Scalars['String']>;
  primaryAddress?: InputMaybe<AddressInput>;
  providerId?: InputMaybe<Scalars['Int']>;
  samlUniqueId?: InputMaybe<Scalars['String']>;
  secondaryState?: InputMaybe<StateCodes>;
  therapistId?: InputMaybe<Scalars['Int']>;
}

export interface EmbedCoach {
  coachId: Scalars['String'];
  isCurrentCoach: Scalars['Boolean'];
  name: Scalars['String'];
}

export interface EmbedOrganization {
  name: Scalars['String'];
}

export interface EmbedPatient {
  name: Scalars['String'];
  organization?: Maybe<EmbedOrganization>;
}

export const EngagementTag = {
  Disengaged: 'Disengaged',
  IncompleteTasks: 'IncompleteTasks',
  LimitedSessions: 'LimitedSessions',
  NeedsInsuranceDetails: 'NeedsInsuranceDetails',
  NoShow: 'NoShow',
  Unverified: 'Unverified',
} as const;

export type EngagementTag = typeof EngagementTag[keyof typeof EngagementTag];
export const Entitlement = {
  AllowCharlieHealthIopReferralsViaMcp: 'AllowCharlieHealthIOPReferralsViaMCP',
  AllowMultipleReferralTypes: 'AllowMultipleReferralTypes',
  AllowTogetherAllReferralsViaMcp: 'AllowTogetherAllReferralsViaMCP',
  CampusResources: 'CampusResources',
  CanReactivatePatients: 'CanReactivatePatients',
  Coaching: 'Coaching',
  CollateEmail: 'CollateEmail',
  ConnectNow: 'ConnectNow',
  CrisisTeam: 'CrisisTeam',
  CustomAvailabilityInterval: 'CustomAvailabilityInterval',
  CustomHubDashboardModule: 'CustomHubDashboardModule',
  CustomMinAge: 'CustomMinAge',
  DdhSync: 'DDHSync',
  DedicatedGroupModel: 'DedicatedGroupModel',
  DisableCampusResources: 'DisableCampusResources',
  DisablePatientPortal: 'DisablePatientPortal',
  ExecutiveFunctioning: 'ExecutiveFunctioning',
  HideReportingDashboard: 'HideReportingDashboard',
  Hub: 'Hub',
  HubTogetherallEnable: 'HubTogetherallEnable',
  InterpretationService: 'InterpretationService',
  LevelOfCareAssessment: 'LevelOfCareAssessment',
  ManualSponsoredCareReactivation: 'ManualSponsoredCareReactivation',
  OnDemand: 'OnDemand',
  OrgIdentification: 'OrgIdentification',
  ReferrerRole: 'ReferrerRole',
  RequireConsentForMinors: 'RequireConsentForMinors',
  RequireSelfReferEligibilityCheck: 'RequireSelfReferEligibilityCheck',
  RestrictedPatientProfiles: 'RestrictedPatientProfiles',
  ScreenSelfReferStudents: 'ScreenSelfReferStudents',
  SelfReferral: 'SelfReferral',
  SelfReferralCollaborativeModelScreen: 'SelfReferralCollaborativeModelScreen',
  SessionsModel: 'SessionsModel',
  ShowUtilizationTabToMcpAdmin: 'ShowUtilizationTabToMcpAdmin',
  Sso: 'SSO',
  SupportingClinicianRole: 'SupportingClinicianRole',
  UnlimitedNoShows: 'UnlimitedNoShows',
  WellnessLevelOfCareAssessment: 'WellnessLevelOfCareAssessment',
  WholeCampusCare: 'WholeCampusCare',
} as const;

export type Entitlement = typeof Entitlement[keyof typeof Entitlement];
export interface EntitlementDefinition {
  config: Array<EntitlementDefinitionConfig>;
  description: Scalars['String'];
  key: Entitlement;
  name: Scalars['String'];
}

export interface EntitlementDefinitionConfig {
  key: Scalars['String'];
  maxLength?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  placeholder?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  size?: Maybe<EntitlementDefinitionConfigSize>;
  smallText?: Maybe<Scalars['String']>;
  type: Scalars['String'];
}

export const EntitlementDefinitionConfigSize = {
  Large: 'large',
  Small: 'small',
} as const;

export type EntitlementDefinitionConfigSize =
  typeof EntitlementDefinitionConfigSize[keyof typeof EntitlementDefinitionConfigSize];
export interface Event {
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['Float'];
  provider?: Maybe<Provider>;
  tag: Scalars['String'];
  user?: Maybe<User>;
}

export type FacultyHubReferralResult = FacultyHubReferralSuccess | ReferralError;

export interface FacultyHubReferralSuccess {
  successMessage: Scalars['String'];
}

export interface FaQuestion {
  answer: Scalars['String'];
  examples?: Maybe<Array<Scalars['String']>>;
  question: Scalars['String'];
}

export interface FeedbackAnswer {
  key: Scalars['String'];
  /** JSON stringified value */
  value: Scalars['String'];
}

export const FeedbackType = {
  Followup: 'Followup',
  Initial: 'Initial',
} as const;

export type FeedbackType = typeof FeedbackType[keyof typeof FeedbackType];
export interface FinalizedInsuranceClaim {
  insurancePaidInCents: Scalars['Float'];
  patientResponsibilityInCents: Scalars['Float'];
}

export interface FindCareStartOutput {
  connectNowEnabled: Scalars['Boolean'];
  onDemandEnabled: Scalars['Boolean'];
  questionnaire: Questionnaire;
}

export interface FinishSkillReflectionResponse {
  deleted?: Maybe<Scalars['Boolean']>;
  saved?: Maybe<Scalars['Boolean']>;
}

export interface FirstFactorResult {
  /** Token to be included with the second factor mutation when using 2FA */
  firstFactorToken: Scalars['String'];
  /** Last 4 digits of phone number used in two factor auth */
  phoneLastFour: Scalars['String'];
}

export interface FormSection {
  /** Copy after the main question */
  afterCopy?: Maybe<Array<SectionCopy>>;
  background?: Maybe<SectionColor>;
  /** Copy before the main question */
  beforeCopy?: Maybe<Array<SectionCopy>>;
  border?: Maybe<SectionColor>;
  /** Allows for sub-sections */
  children?: Maybe<Array<FormSection>>;
  closeable?: Maybe<Scalars['Boolean']>;
  dependsOn?: Maybe<SectionDependency>;
  format: SectionFormat;
  hooks?: Maybe<Array<SectionHook>>;
  isolatedOption?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  maxSelections?: Maybe<Scalars['Float']>;
  /** Options if selectable */
  options?: Maybe<Array<Scalars['String']>>;
  placeholder?: Maybe<Scalars['String']>;
  /** Prevent submission with outstanding required questions */
  required?: Maybe<Scalars['Boolean']>;
  /** Rules to determine if a field is required or not */
  requiredIf?: Maybe<RequiredIf>;
  sectionTitle?: Maybe<Scalars['String']>;
  selectDependentValues?: Maybe<Array<SelectDependentValue>>;
  startClosed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  /** Hide number when rendering */
  uncounted?: Maybe<Scalars['Boolean']>;
  /** JSON stringified value */
  value?: Maybe<Scalars['String']>;
  warning?: Maybe<TriggeredCopy>;
}

export interface GenericCollegeModalModel {
  description: Scalars['String'];
  id: Scalars['Float'];
  linkText: Scalars['String'];
  organizationId: Scalars['Float'];
  pictureType?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
}

export interface GetContractError {
  errorCode: GetContractErrorCode;
  message: Scalars['String'];
}

export const GetContractErrorCode = {
  ContractNotFound: 'ContractNotFound',
} as const;

export type GetContractErrorCode = typeof GetContractErrorCode[keyof typeof GetContractErrorCode];
export type GetContractResult = Contract | GetContractError;

export interface GetUpNextSkillForModuleResult {
  moduleIsComplete: Scalars['Boolean'];
  upNextSkill?: Maybe<Scalars['JSON']>;
  upNextSkillIsComplete: Scalars['Boolean'];
}

export interface GetUpNextSkillResult {
  moduleWillBeComplete: Scalars['Boolean'];
  upNextSkill?: Maybe<Scalars['JSON']>;
  upNextSkillIsComplete: Scalars['Boolean'];
}

export const GlobalAvailabilityBlockerType = {
  ContractSessions: 'ContractSessions',
  NoContract: 'NoContract',
  ProviderStateBookableHours: 'ProviderStateBookableHours',
  ProviderStateWeeklyIntakes: 'ProviderStateWeeklyIntakes',
  StudentCapacity: 'StudentCapacity',
  WeeklyIntakes: 'WeeklyIntakes',
  WeeklySessions: 'WeeklySessions',
} as const;

export type GlobalAvailabilityBlockerType =
  typeof GlobalAvailabilityBlockerType[keyof typeof GlobalAvailabilityBlockerType];
export const HookType = {
  ClearOnChange: 'clearOnChange',
} as const;

export type HookType = typeof HookType[keyof typeof HookType];
export interface HubAnswerSubmission {
  answerKeys?: InputMaybe<Array<Scalars['String']>>;
  manualInput?: InputMaybe<Scalars['String']>;
  questionKey: Scalars['String'];
}

export interface HubAppointment {
  appointmentTypeId: Scalars['String'];
  appointmentTypeName: Scalars['String'];
  id: Scalars['String'];
  length: Scalars['Float'];
  rescheduleLink?: Maybe<Scalars['String']>;
  /** The ISO datetime for when the appointment starts. */
  start: Scalars['DateTime'];
  zoomJoinUrl: Scalars['String'];
}

export interface HubAssessmentSubmission {
  answers: Array<HubAnswerSubmission>;
  questionnaireKey: Scalars['String'];
}

export interface HubConnectNowData {
  crisisCallPhoneNumber: Scalars['String'];
  crisisSMS?: Maybe<Scalars['String']>;
  crisisWebChat?: Maybe<Scalars['String']>;
}

export interface HubConnectNowInterpretationServicesData {
  crisisInterpretationSMS?: Maybe<Scalars['String']>;
}

export interface HubConnectNowOutput {
  connectNowData?: Maybe<HubConnectNowData>;
  connectNowEnabled: Scalars['Boolean'];
  connectNowInterpretationServicesData?: Maybe<HubConnectNowInterpretationServicesData>;
}

export interface HubCustomModuleConfig {
  description?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
}

export interface HubCustomModuleData {
  data?: Maybe<HubCustomModuleConfig>;
  isEnabled: Scalars['Boolean'];
}

/** An appointment external to the hub. */
export interface HubExternalAppointment {
  /** The ISO datetime for when the appointment ends. */
  end: Scalars['DateTime'];
  /** The id of the appointment. */
  id: Scalars['Float'];
  /** The mantra resolution name for the appointment. */
  mrn: Scalars['String'];
  /** The ISO datetime for when the appointment starts. */
  start: Scalars['DateTime'];
}

export interface HubOnDemandData {
  crisisCallPhoneNumber: Scalars['String'];
  crisisSMS?: Maybe<Scalars['String']>;
  crisisWebChat?: Maybe<Scalars['String']>;
}

export interface HubOnDemandOutput {
  onDemandData?: Maybe<HubOnDemandData>;
  onDemandEnabled: Scalars['Boolean'];
}

export interface HubOrganizationResource {
  contact?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  showAsCrisisResource: Scalars['Boolean'];
  website?: Maybe<Scalars['String']>;
}

export interface HubResendActivationError {
  errorCode: HubResendActivationErrorCode;
  message: Scalars['String'];
}

export const HubResendActivationErrorCode = {
  InvalidEmail: 'InvalidEmail',
} as const;

export type HubResendActivationErrorCode =
  typeof HubResendActivationErrorCode[keyof typeof HubResendActivationErrorCode];
export type HubResendActivationResult = HubResendActivationError | HubResendActivationSuccess;

export interface HubResendActivationSuccess {
  success: Scalars['Boolean'];
}

export interface HubStatusForUser {
  hasHubOnboarded: Scalars['Boolean'];
  isHubEnabled: Scalars['Boolean'];
}

export interface HubUser {
  birthDate?: Maybe<Scalars['Date']>;
  canRedirect: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  hasHubOnboarded: Scalars['Boolean'];
  impersonatingId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  organization?: Maybe<HubUserOrganization>;
  organizationCanAccessCampusResources: Scalars['Boolean'];
  organizationCanAccessCare: Scalars['Boolean'];
  organizationCanAccessTherapy: Scalars['Boolean'];
  organizationCanAccessTogetherAll: Scalars['Boolean'];
  organizationCounselingCenter?: Maybe<HubUserOrgCounselingCenter>;
  organizationHasCoaching: Scalars['Boolean'];
  organizationHasSelfReferrals: Scalars['Boolean'];
  organizationLogo?: Maybe<HubUserOrganizationLogo>;
  organizationMinimumHubAge: Scalars['Int'];
  phone?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  primaryAddress?: Maybe<Address>;
  pronouns?: Maybe<Scalars['String']>;
  uid: Scalars['String'];
  upcomingAppointment?: Maybe<HubUserHealthieAppointmentData>;
}

export interface HubUserHealthieAppointmentData {
  appointmentTypeId: Scalars['String'];
  appointmentTypeName: Scalars['String'];
  id: Scalars['String'];
  length: Scalars['Float'];
  start: Scalars['String'];
  zoomJoinUrl: Scalars['String'];
}

export interface HubUserOnboardError {
  errorCode: HubUserOnboardErrorCode;
  message: Scalars['String'];
}

export const HubUserOnboardErrorCode = {
  AgeBlocked: 'AgeBlocked',
  AgeConsentIncomplete: 'AgeConsentIncomplete',
  AgeConsentRequired: 'AgeConsentRequired',
} as const;

export type HubUserOnboardErrorCode =
  typeof HubUserOnboardErrorCode[keyof typeof HubUserOnboardErrorCode];
export type HubUserOnboardResult = HubUserOnboardError | HubUserOnboardSuccess;

export interface HubUserOnboardSuccess {
  successMessage: Scalars['String'];
}

export interface HubUserOrganization {
  id: Scalars['Int'];
  name: Scalars['String'];
}

export interface HubUserOrganizationLogo {
  contentType: Scalars['String'];
  orgName: Scalars['String'];
  url: Scalars['String'];
}

export interface HubUserOrgCounselingCenter {
  departmentAddress: Scalars['String'];
  departmentHours: Scalars['String'];
  departmentName: Scalars['String'];
}

export interface HubUserUpdateInput {
  birthDate?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  preferredName?: InputMaybe<Scalars['String']>;
  primaryAddress?: InputMaybe<AddressInput>;
  pronouns?: InputMaybe<Scalars['String']>;
}

export interface Hyperlink {
  displayText: Scalars['String'];
  url: Scalars['String'];
}

export interface InAppNotification {
  createdAt: Scalars['DateTime'];
  data: Scalars['JSON'];
  id: Scalars['Float'];
  isDismissible: Scalars['Boolean'];
  isRequired?: Maybe<Scalars['Boolean']>;
  key: UserNotificationKey;
}

export interface InsuranceClaim {
  balanceCents: Scalars['Float'];
  events: Array<InsuranceClaimEvent>;
  externalStatus: Scalars['String'];
  geoState: Scalars['String'];
  id: Scalars['Float'];
  internalStatus: InternalStatus;
  payer: Payer;
  provider: Provider;
  sentDate: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user: User;
  visitDate: Scalars['DateTime'];
}

export interface InsuranceClaimEvent {
  action: ClaimAction;
  actor?: Maybe<Provider>;
  codes: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  details: Scalars['String'];
  id: Scalars['Float'];
  status: InternalStatus;
  updatedAt: Scalars['DateTime'];
}

export interface InsuranceDetails {
  deductibleRemainingCents?: Maybe<Scalars['Float']>;
  eligibilityVerified: Scalars['Boolean'];
  groupNumber?: Maybe<Scalars['String']>;
  inNetwork: Scalars['Boolean'];
  lastChecked?: Maybe<Scalars['DateTime']>;
  memberId?: Maybe<Scalars['String']>;
  noMentalHealthBenefits?: Maybe<Scalars['Boolean']>;
  patientRelationshipToSubscriber: PatientRelationshipToSubscriber;
  payer?: Maybe<Payer>;
  state: Scalars['String'];
  subscriberDateOfBirth?: Maybe<Scalars['String']>;
  subscriberFirstName?: Maybe<Scalars['String']>;
  subscriberGender?: Maybe<Scalars['String']>;
  subscriberLastName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
}

export interface InsuranceDetailsInput {
  birthDate?: InputMaybe<Scalars['String']>;
  enteredByProviderId?: InputMaybe<Scalars['Float']>;
  firstName?: InputMaybe<Scalars['String']>;
  groupNumber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['String']>;
  patientRelationshipToSubscriber: PatientRelationshipToSubscriber;
  payerId?: InputMaybe<Scalars['Float']>;
  state: Scalars['String'];
  subscriberDateOfBirth?: InputMaybe<Scalars['String']>;
  subscriberFirstName?: InputMaybe<Scalars['String']>;
  subscriberGender?: InputMaybe<Scalars['String']>;
  subscriberLastName?: InputMaybe<Scalars['String']>;
}

export interface InsuranceEligibility {
  inNetwork: Scalars['Boolean'];
  noMentalHealthBenefits?: Maybe<Scalars['Boolean']>;
  valid: Scalars['Boolean'];
}

export interface IntakeAvailabilityResult {
  data: Array<Availability>;
  duration: Scalars['Float'];
  globalBlockers: Array<SimpleGlobalAvailabilityBlocker>;
  interval: Scalars['Float'];
}

export const InternalStatus = {
  Accepted: 'Accepted',
  Cancelled: 'Cancelled',
  Closed: 'Closed',
  Created: 'Created',
  Denied: 'Denied',
  FollowUp: 'FollowUp',
  Rejected: 'Rejected',
  Sent: 'Sent',
} as const;

export type InternalStatus = typeof InternalStatus[keyof typeof InternalStatus];
export interface InterpretationLanguagesResponse {
  languages: Array<LanguageEntry>;
}

export interface Invoice {
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  paid: Scalars['Boolean'];
}

export interface IopReferralModel {
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['Float'];
  lastName: Scalars['String'];
  organizationId?: Maybe<Scalars['Float']>;
  organizationName: Scalars['String'];
  preferredName?: Maybe<Scalars['String']>;
  referralDateTime: Scalars['DateTime'];
  status: Scalars['String'];
  userId?: Maybe<Scalars['Float']>;
}

export interface JumpBackInSkillData {
  module?: Maybe<Scalars['JSON']>;
  skill?: Maybe<Scalars['JSON']>;
}

export interface LanguageEntry {
  id: Scalars['Float'];
  isCommon: Scalars['Boolean'];
  name: Scalars['String'];
}

export interface ListContractsItemModel {
  endDate: Scalars['LocalDate'];
  id: Scalars['Int'];
  startDate: Scalars['LocalDate'];
}

export interface ListContractsModel {
  contracts: Array<ListContractsItemModel>;
}

export interface ListCopy {
  bullets: Array<Scalars['String']>;
  showInView?: Maybe<Scalars['Boolean']>;
  style?: Maybe<TextStyle>;
}

export const LoginHubError = {
  InvalidToken: 'invalid_token',
  InvalidTokenId: 'invalid_token_id',
  InvalidUser: 'invalid_user',
} as const;

export type LoginHubError = typeof LoginHubError[keyof typeof LoginHubError];
export interface LoginHubResult {
  error?: Maybe<LoginHubError>;
  hasHubOnboarded?: Maybe<Scalars['Boolean']>;
  success?: Maybe<LoginHubSuccess>;
}

export interface LoginHubSuccess {
  impersonated: Scalars['Boolean'];
  jwt?: Maybe<Scalars['String']>;
  userId: Scalars['Int'];
}

export interface LoginToHubError {
  errorCode: LoginToHubErrorCode;
  message: Scalars['String'];
}

export const LoginToHubErrorCode = {
  InvalidToken: 'InvalidToken',
  InvalidTokenId: 'InvalidTokenId',
  InvalidUser: 'InvalidUser',
} as const;

export type LoginToHubErrorCode = typeof LoginToHubErrorCode[keyof typeof LoginToHubErrorCode];
export type LoginToHubResult = LoginToHubError | LoginToHubSuccess;

export interface LoginToHubSuccess {
  hasHubOnboarded?: Maybe<Scalars['Boolean']>;
  impersonated: Scalars['Boolean'];
  jwt: Scalars['String'];
  userId: Scalars['Int'];
}

/** The main concerns of a user */
export const MainConcerns = {
  Academics: 'ACADEMICS',
  AnxietyStress: 'ANXIETY_STRESS',
  CareerPlanning: 'CAREER_PLANNING',
  ConcernForSomeoneElse: 'CONCERN_FOR_SOMEONE_ELSE',
  Depression: 'DEPRESSION',
  Family: 'FAMILY',
  FeelingDown: 'FEELING_DOWN',
  Finances: 'FINANCES',
  FriendsRoommates: 'FRIENDS_ROOMMATES',
  Grief: 'GRIEF',
  HealthConcerns: 'HEALTH_CONCERNS',
  Lgbtqia: 'LGBTQIA',
  LifeChanges: 'LIFE_CHANGES',
  Loneliness: 'LONELINESS',
  Other: 'OTHER',
  Relationship: 'RELATIONSHIP',
  SelfInjury: 'SELF_INJURY',
  SubstanceAbuse: 'SUBSTANCE_ABUSE',
  SuicidalThoughts: 'SUICIDAL_THOUGHTS',
  Work: 'WORK',
} as const;

export type MainConcerns = typeof MainConcerns[keyof typeof MainConcerns];
export interface ManualInput {
  label?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
}

export interface MaybeBlockedCalendarEvents {
  blockedReason?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  data?: Maybe<CalendarEvent>;
}

export interface MaybeBlockedProviders {
  blockedReason?: Maybe<ProviderServiceBlockedReason>;
  data?: Maybe<Array<Provider>>;
}

export interface McpCurrentContractCareTypeUtilization {
  sessionsRemaining: Scalars['Float'];
  sessionsScheduled: Scalars['Float'];
  sessionsUtilized: Scalars['Float'];
  studentCapacityRemaining: Scalars['Float'];
  studentCapacityUtilized: Scalars['Float'];
}

export interface McpCurrentContractScope {
  endDate: Scalars['LocalDate'];
  psychiatryPatients: Scalars['Int'];
  psychiatrySessions: Scalars['Int'];
  startDate: Scalars['LocalDate'];
  therapyPatients: Scalars['Int'];
  therapySessions: Scalars['Int'];
}

export interface McpCurrentContractUtilization {
  psychiatryUsage: McpCurrentContractCareTypeUtilization;
  scope: McpCurrentContractScope;
  therapyUsage: McpCurrentContractCareTypeUtilization;
}

export interface McpCurrentContractUtilizationResult {
  utilization?: Maybe<McpCurrentContractUtilization>;
}

export interface MedicalHistory {
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  questionnaires: Array<MedicalHistoryQuestionnaire>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['Float'];
  uuid: Scalars['String'];
}

export interface MedicalHistoryQuestion {
  caption?: Maybe<Scalars['String']>;
  dependency?: Maybe<Dependency>;
  disclaimer?: Maybe<Scalars['String']>;
  examples?: Maybe<Array<Scalars['String']>>;
  isOptional?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  legend?: Maybe<Array<Scalars['String']>>;
  manualInput?: Maybe<ManualInput>;
  options?: Maybe<Array<Option>>;
  overrideDescription?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  preText?: Maybe<Scalars['String']>;
  subText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
  visible?: Maybe<Scalars['Boolean']>;
}

export interface MedicalHistoryQuestionnaire {
  description?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  preScreen?: Maybe<QuestionnairePreScreen>;
  questions: Array<MedicalHistoryQuestion>;
  title: Scalars['String'];
}

export interface MedicalNote {
  appointment?: Maybe<CalendarEvent>;
  claimSubmitted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  key: Scalars['String'];
  provider: Provider;
  submittedAt?: Maybe<Scalars['DateTime']>;
  template: MedicalNoteTemplate;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  valueForKey?: Maybe<Scalars['String']>;
}

export type MedicalNoteValueForKeyArgs = {
  key: Scalars['String'];
};

export interface MedicalNoteSectionInput {
  key: Scalars['String'];
  /** JSON stringified value */
  value?: InputMaybe<Scalars['String']>;
}

export interface MedicalNoteTemplate {
  careTypes?: Maybe<Array<CareType>>;
  key: Scalars['String'];
  patientDetails?: Maybe<Array<PatientDetail>>;
  questions: Array<FormSection>;
  title: Scalars['String'];
  titleTag?: Maybe<TextTag>;
  uuid: Scalars['String'];
}

export interface MedicationInfo {
  brandNames: Array<Scalars['String']>;
  faq: Array<FaQuestion>;
  fdaApprovedTreatmentOf: Array<Scalars['String']>;
  medicationGuidelines: Array<Hyperlink>;
  name: Scalars['String'];
  safetyInformation: SafetyInfo;
  whatToExpect: Scalars['String'];
}

export const MedicationStatus = {
  Active: 'Active',
  CancelDenied: 'CancelDenied',
  Cancelled: 'Cancelled',
  CancelPending: 'CancelPending',
  CancelRequested: 'CancelRequested',
  Changed: 'Changed',
  Completed: 'Completed',
  Deleted: 'Deleted',
  Discontinued: 'Discontinued',
  FullFill: 'FullFill',
  Inactive: 'Inactive',
  NoFill: 'NoFill',
  PartialFill: 'PartialFill',
  Unknown: 'Unknown',
} as const;

export type MedicationStatus = typeof MedicationStatus[keyof typeof MedicationStatus];
export interface MessageV2 {
  attachments: Array<Upload>;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  /** Do not use this with queries that return arrays of messages */
  provider?: Maybe<Provider>;
  providerId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
}

export const MinorConsentStatus = {
  Approved: 'Approved',
  Incomplete: 'Incomplete',
} as const;

export type MinorConsentStatus = typeof MinorConsentStatus[keyof typeof MinorConsentStatus];
export interface MinorConsentStatusModel {
  status?: Maybe<MinorConsentStatus>;
}

export interface Mutation {
  activateCrisisMode: Scalars['String'];
  activateSafetyPlan: SafetyPlan;
  addProviderToCrisisTeams: Scalars['Boolean'];
  addSelfReferralEmails: Scalars['Boolean'];
  addUserCareEligibilitySubmission: ContinuityOfCareEligibilityResult;
  adminAcceptTermsOfService: Scalars['Boolean'];
  adminAuthenticate: PasswordAuthResult;
  adminBeginUpload: SignedS3Upload;
  adminCancelAppointment: CalendarEvent;
  adminCompleteOrganizationPhotoUpload: Scalars['Boolean'];
  adminCompleteProviderPhotoUpload: Provider;
  adminCompleteUpload: Upload;
  adminContinuityOfCareExport: AsynchronousJob;
  adminCreateCareTransition: ContinuityOfCareModel;
  adminCreateNoteExport: AsynchronousJob;
  adminCreateStaff: Provider;
  adminCreateTestUser: User;
  adminCreateUser: User;
  adminDeleteUpload: Scalars['Boolean'];
  adminDisableTwoFactor: Scalars['Boolean'];
  adminEditSafetyPlan: SafetyPlan;
  adminEditUser: AdminEditUserResult;
  adminEndCall: Scalars['Boolean'];
  adminEnterSecondFactor: TwoFactorAuthResult;
  adminMarkNoShow: Scalars['Boolean'];
  adminMarkTaskDone: Scalars['Boolean'];
  adminMarkUpcoming: Scalars['Boolean'];
  adminModifyAppointment: CalendarEvent;
  adminPasswordlessLogin: Scalars['String'];
  adminRescheduleAppointment: CalendarEvent;
  adminResetProviderPassword: Scalars['Boolean'];
  adminScheduleAppointment: CalendarEvent;
  /** Send a two factor code for the authenticated provider (use for verification) */
  adminSendTwoFactorCode: Scalars['Boolean'];
  adminSetPharmacyOnPatient: Scalars['Boolean'];
  adminSetTwoFactorConfig: Scalars['Boolean'];
  adminSubmitAssessment: AssessmentResponse;
  adminTransitionForCarePeriod: Scalars['Boolean'];
  adminTriggerProviderPasswordlessFlow: Scalars['Boolean'];
  adminUndoTaskDone: Scalars['Boolean'];
  adminUpdateAllocationsForDate: Scalars['Boolean'];
  adminUpdateProvider: Provider;
  adminUpdateScreenUserResult: Scalars['Boolean'];
  adminUpdateStateAllocationsForDate: Scalars['Boolean'];
  adminUpdateUpload: Scalars['Boolean'];
  adminVerifyTwoFactorPhone: Scalars['String'];
  answerSkillReflectionQuestion: Scalars['Boolean'];
  applyCoupon?: Maybe<ServiceCoupon>;
  assignCoachToEmbedPatient: Scalars['Boolean'];
  assignSkillToUser: AssignSkillToUserResponse;
  autoApplyCoupons: Array<ServiceCoupon>;
  beginEmailVerification: Scalars['Boolean'];
  beginSponsoredCareReenrollment: Scalars['Boolean'];
  beginUpload: SignedS3Upload;
  cancelAppointment: Scalars['Boolean'];
  cancelHealthieAppointment: Scalars['Boolean'];
  cancelSponsoredCareReenrollment: Scalars['Boolean'];
  changeProviderPassword: Scalars['Boolean'];
  changeStatusOfClaim: InsuranceClaimEvent;
  checkIsBlurry: Scalars['Boolean'];
  clearProviderLockout: Scalars['Boolean'];
  completeAppointmentCheckin: CalendarEvent;
  completePendingTransition: Scalars['Boolean'];
  completeUpload: Upload;
  consumeCoachingRecommendation: Scalars['Boolean'];
  consumeTherapyRecommendation: Scalars['Boolean'];
  convertPatientCare: User;
  createConnectNowPreEncounter: Scalars['Boolean'];
  createContract: CreateContractResult;
  createDataSharingRequest: DataSharingRequest;
  createHubFeedback: Scalars['Boolean'];
  createNoteForClaim: InsuranceClaimEvent;
  createOnDemandEncounter: Scalars['Boolean'];
  createOrganization: Organization;
  createOrganizationAnnouncement: OrganizationAnnouncement;
  createOrUpdateOrganizationResource: OrganizationResource;
  createOrUpdatePayer: Payer;
  createPartnerReferral: Scalars['Boolean'];
  createProvider: Provider;
  createTestVideoSession: TestVideoSession;
  createTicket: Ticket;
  createUserFirstStep: User;
  createZendeskTicket: Scalars['Boolean'];
  deactivateOrganization: Scalars['Boolean'];
  debugImpersonateAsUser: Scalars['String'];
  debugLoginAsUser: Scalars['String'];
  /** Internal debug tooling */
  debugModifyDataSharingConsent: DataSharingConsent;
  /** Internal debug tooling */
  debugModifyDataSharingRequest: DataSharingRequest;
  decoupleOrgEntitlementsFromParent: Scalars['Boolean'];
  deleteEmergencyContact: Contact;
  deleteInsuranceCredential: Scalars['Boolean'];
  deleteMedicalNote: Scalars['Boolean'];
  deleteOrganizationResource: Scalars['Boolean'];
  deleteOutreachAttempt: Scalars['Boolean'];
  deleteUserCareEligibilitySubmission: Scalars['Boolean'];
  deleteUserContact: Scalars['Boolean'];
  deleteZendeskUpload: Scalars['Boolean'];
  disableDevice: Scalars['Boolean'];
  dismissNotification: Scalars['Boolean'];
  dropInsurance: Scalars['Boolean'];
  editContractScope: EditContractScopeResult;
  editContractWeeks: EditContractWeeksResult;
  editOrganizationAnnouncement: Scalars['Boolean'];
  editOrganizationHandbook: Scalars['Boolean'];
  editSafetyPlan: SafetyPlan;
  /** null if current provider gives up permission to view ticket */
  editTicket?: Maybe<Ticket>;
  emailUnsubscribe: Scalars['Boolean'];
  facultyHubReferral: FacultyHubReferralResult;
  findCareFinish: Scalars['Boolean'];
  finishProviderPasswordReset: Scalars['Boolean'];
  /** @deprecated Legacy reset password flow. */
  finishResetPassword: Scalars['Boolean'];
  finishSkillReflection: FinishSkillReflectionResponse;
  /** @deprecated use pushAssessment instead */
  forceNextScalesToNow: Scalars['Boolean'];
  forgotProviderPassword: Scalars['Boolean'];
  /** returns unsubmitted note of key for user or creates a new one */
  getOrCreateNote: MedicalNote;
  hubUserAcceptTerms: Scalars['Boolean'];
  hubUserOnboard: HubUserOnboardResult;
  impersonate: Scalars['String'];
  initializeStudent: User;
  /** @deprecated Replaced with loginToHub mutation. */
  loginHub: LoginHubResult;
  loginToHub: LoginToHubResult;
  loginUserWithPassword: PasswordAuthResult;
  logoutHub: Scalars['Boolean'];
  logoutProvider: Scalars['Boolean'];
  logoutUser: Scalars['Boolean'];
  markCareTeamChannelsAsRead: Scalars['Boolean'];
  markEmbedPatientCoachingInactive: Scalars['Boolean'];
  markHealthieAppointmentForCancellation: Scalars['Boolean'];
  /** @deprecated Use hubUserOnboard resolver instead. */
  markHubUserOnboarded: Scalars['Boolean'];
  markReferralComplete: Scalars['Boolean'];
  markSkillAssignmentSeenByAssigner: Scalars['Boolean'];
  markSkillComplete: Scalars['Boolean'];
  markSkillStarted: Scalars['Boolean'];
  minorConsentApproveUser: Scalars['Boolean'];
  minorConsentOverrideUser: Scalars['Boolean'];
  networkSearchAccommodationRequest: Scalars['Boolean'];
  notifyOrganizationCrisisTeam: Scalars['Boolean'];
  passwordlessLogin: PasswordlessLoginResult;
  patientMarkAsRead: Scalars['Boolean'];
  patientMessageProvider: MessageV2;
  preLoginOrPasswordLogin: PreLoginOrPasswordLoginResult;
  preSignUp: PreSignUpResult;
  providerMarkPrivateChannelsAsRead: Scalars['Boolean'];
  providerMessagePatient: MessageV2;
  pushAssessment: Scalars['Boolean'];
  reactivateSelfForSponsoredCare: Scalars['Boolean'];
  reactivateUserCareFlow: UserCareFlow;
  reactivateUserForSponsoredCare: Scalars['Boolean'];
  recheckInsuranceEligibility: Scalars['Boolean'];
  recoupleOrgEntitlementsToParent: Scalars['Boolean'];
  refreshProviderLogin: Scalars['String'];
  regenerateProviderPassword: Scalars['String'];
  registerDevice: Scalars['Boolean'];
  removeProviderFromCrisisTeams: Scalars['Boolean'];
  resendActivationEmail: HubResendActivationResult;
  /** Send a two factor code when attempting to authenticate */
  resendTwoFactorCode: Scalars['Boolean'];
  resetBadgeCount: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  resetSessionsForFlow: OrganizationCareFlow;
  resetSessionStartForUser: Scalars['Boolean'];
  resetUserPassword: Scalars['String'];
  resubmitClaim: Scalars['Boolean'];
  /** should be called from the onBlur callback for each question */
  saveNoteSections: Scalars['Boolean'];
  saveUserDemographics: Scalars['Boolean'];
  selectCareFlow: User;
  sendCareTeamMessage: MessageV2;
  sendCoachChatMessage: SendCoachChatMessageResult;
  sendDataSharingRequestReminder: Scalars['Boolean'];
  sendPasswordResetEmail: Scalars['Boolean'];
  setDataSharingConsent: Scalars['Boolean'];
  setFlaggedForOutreach: Scalars['Boolean'];
  setHubUserPreferredName: Scalars['Boolean'];
  setInsurance: InsuranceEligibility;
  setPasswordWithToken: SetPasswordWithTokenResult;
  setPharmacyOnPatient: Scalars['Boolean'];
  setProviderSubscribed: Scalars['Boolean'];
  setSafeOperatingHandbookUrl: Scalars['Boolean'];
  setSuggestedPharmacyId: Scalars['Boolean'];
  setUserInsurance: InsuranceEligibility;
  setUserSubscribed: Scalars['Boolean'];
  setUserVerified: Scalars['Boolean'];
  signUp: SignUpResult;
  skillPathwaySubmit: Scalars['Boolean'];
  skillReflectionDelete: Scalars['Boolean'];
  skillReflectionEditAnswer: Scalars['Boolean'];
  skipPharmacySelection: Scalars['Boolean'];
  softDeleteUser: Scalars['Boolean'];
  studentHubReferral: StudentHubReferralResult;
  submitAppointmentFeedback: Scalars['Boolean'];
  submitAppointmentIncident: Scalars['Boolean'];
  submitCoachingAssessment: Scalars['Boolean'];
  submitCoachingAssessmentV2: Scalars['Boolean'];
  submitIntakeAssessment: AssessmentResponse;
  submitMedicalHistory: Scalars['Boolean'];
  submitNote: MedicalNote;
  submitProviderFeedback: Scalars['Boolean'];
  submitRequestedAssessment: Scalars['Boolean'];
  submitSelfRefer1Screener: AssessmentResponse;
  swapPrimaryEmergencyContact: Contact;
  syncHealthieProvider: Scalars['Boolean'];
  toggleOrganizationOnHold: Organization;
  /** Track events from the client */
  track: Scalars['Boolean'];
  trackPushOpen: Scalars['Boolean'];
  triggerPasswordlessAuthFlow: Scalars['Boolean'];
  unassignSkillFromUser: Scalars['Boolean'];
  updateAppointmentTemplates: Scalars['Boolean'];
  updateCareFlowForUser: Scalars['Boolean'];
  updateCertifications: Array<ProviderCertification>;
  updateCoupon: ServiceCoupon;
  updateCrisisTeamHours: Scalars['Boolean'];
  updateEducation: Array<ProviderEducation>;
  updateEligibilityCriteria: Scalars['Boolean'];
  updateEntitlements: Scalars['Boolean'];
  /** @deprecated Does not return state errors - use updateHubUser2() instead. */
  updateHubUser: Scalars['Boolean'];
  updateHubUser2: UpdateHubUserResult;
  updateNotificationOutcome: Scalars['Boolean'];
  updateOrganization: Organization;
  updateOrganizationCareFlows: Array<OrganizationCareFlow>;
  updateOrganizationCarePeriods: Array<OrganizationCarePeriodModel>;
  updateOrganizationDedicatedGroupModelAllocations: Scalars['Boolean'];
  updateOrganizationDefaults: Scalars['Boolean'];
  updateOrganizations: Provider;
  updateOutreachAttempt: Scalars['Boolean'];
  updatePaymentMethod: PaymentMethod;
  updatePreferences: Scalars['Boolean'];
  updateProviderTraits: Scalars['Boolean'];
  updateQualifications: Array<ProviderQualification>;
  updateSamlConfiguration: SamlConfiguration;
  updateScreenUserResult: Scalars['Boolean'];
  updateSponsoredCareReenrollment: Scalars['Boolean'];
  updateUser2: UpdateUser2Result;
  upsertEmergencyContact: Contact;
  upsertInsuranceCredential: ProviderInsuranceCredential;
  upsertSuggestedProviders: Scalars['Boolean'];
  upsertUserContact: Contact;
  upsertUserSkillFeedback: Scalars['Boolean'];
  userCreateCareTransition: Scalars['Boolean'];
  userScheduleAppointment: CalendarEvent;
}

export type MutationActivateCrisisModeArgs = {
  appointmentId: Scalars['Float'];
};

export type MutationActivateSafetyPlanArgs = {
  userId: Scalars['Float'];
};

export type MutationAddProviderToCrisisTeamsArgs = {
  organizationIds: Array<Scalars['Float']>;
  phone: Scalars['String'];
  providerId: Scalars['Float'];
};

export type MutationAddSelfReferralEmailsArgs = {
  emails: Array<Scalars['String']>;
  organizationId: Scalars['Float'];
};

export type MutationAddUserCareEligibilitySubmissionArgs = {
  submission: ContinuityOfCareEligibilityInput;
};

export type MutationAdminAuthenticateArgs = {
  deviceToken?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationAdminBeginUploadArgs = {
  contentType: Scalars['String'];
  userId?: InputMaybe<Scalars['Float']>;
};

export type MutationAdminCancelAppointmentArgs = {
  id: Scalars['Float'];
  outOfPolicy?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdminCompleteOrganizationPhotoUploadArgs = {
  key: Scalars['String'];
  organizationId: Scalars['Float'];
};

export type MutationAdminCompleteProviderPhotoUploadArgs = {
  key?: InputMaybe<Scalars['String']>;
  providerId: Scalars['Float'];
};

export type MutationAdminCompleteUploadArgs = {
  input: CreateFileUpload;
};

export type MutationAdminContinuityOfCareExportArgs = {
  userId: Scalars['Float'];
};

export type MutationAdminCreateCareTransitionArgs = {
  createTransition: CreateCareTransition;
};

export type MutationAdminCreateNoteExportArgs = {
  noteId: Scalars['Float'];
};

export type MutationAdminCreateStaffArgs = {
  input: CreateStaff;
};

export type MutationAdminCreateTestUserArgs = {
  args: CreateTestUser;
};

export type MutationAdminCreateUserArgs = {
  input: CreateUser;
  intakeAppointment?: InputMaybe<CreateIntake>;
};

export type MutationAdminDeleteUploadArgs = {
  uploadId: Scalars['Float'];
};

export type MutationAdminEditSafetyPlanArgs = {
  plan: SafetyPlanInput;
  userId: Scalars['Float'];
};

export type MutationAdminEditUserArgs = {
  editUser: EditUser;
};

export type MutationAdminEndCallArgs = {
  appointmentId: Scalars['Float'];
  userId: Scalars['Float'];
};

export type MutationAdminEnterSecondFactorArgs = {
  code: Scalars['String'];
  firstFactorToken: Scalars['String'];
};

export type MutationAdminMarkNoShowArgs = {
  appointmentId: Scalars['Float'];
  userId: Scalars['Float'];
};

export type MutationAdminMarkTaskDoneArgs = {
  taskId: Scalars['Float'];
};

export type MutationAdminMarkUpcomingArgs = {
  id: Scalars['Float'];
};

export type MutationAdminModifyAppointmentArgs = {
  end: Scalars['DateTime'];
  id: Scalars['Float'];
  start: Scalars['DateTime'];
};

export type MutationAdminPasswordlessLoginArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};

export type MutationAdminRescheduleAppointmentArgs = {
  end: Scalars['DateTime'];
  id: Scalars['Float'];
  outOfPolicy?: InputMaybe<Scalars['Boolean']>;
  start: Scalars['DateTime'];
};

export type MutationAdminResetProviderPasswordArgs = {
  id: Scalars['Float'];
};

export type MutationAdminScheduleAppointmentArgs = {
  appointmentType: Scalars['String'];
  end: Scalars['DateTime'];
  isFeeForServiceTime?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  providerId: Scalars['Float'];
  start: Scalars['DateTime'];
  userId: Scalars['Float'];
};

export type MutationAdminSetPharmacyOnPatientArgs = {
  pharmacyId?: InputMaybe<Scalars['Float']>;
  userId: Scalars['Float'];
};

export type MutationAdminSetTwoFactorConfigArgs = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  phone: Scalars['String'];
  providerId?: InputMaybe<Scalars['Float']>;
};

export type MutationAdminSubmitAssessmentArgs = {
  assessment: AssessmentKey;
  questionnaires: Array<QuestionnaireSubmission>;
  userId: Scalars['Float'];
};

export type MutationAdminTransitionForCarePeriodArgs = {
  carePeriodId: Scalars['Float'];
};

export type MutationAdminTriggerProviderPasswordlessFlowArgs = {
  email: Scalars['String'];
};

export type MutationAdminUndoTaskDoneArgs = {
  taskId: Scalars['Float'];
};

export type MutationAdminUpdateAllocationsForDateArgs = {
  allocations: Array<CreateAllocationInput>;
  date: Scalars['String'];
  providerId: Scalars['Float'];
  timezone: Scalars['String'];
  weekly: Scalars['Boolean'];
};

export type MutationAdminUpdateProviderArgs = {
  input: UpdateProvider;
};

export type MutationAdminUpdateScreenUserResultArgs = {
  input: ScreenUserResultInput;
  userId: Scalars['Float'];
};

export type MutationAdminUpdateStateAllocationsForDateArgs = {
  allocationsToCreate: Array<CreateProviderStateAllocationsModelInput>;
  allocationsToUpdate: Array<UpdateProviderStateAllocationsModelInput>;
};

export type MutationAdminUpdateUploadArgs = {
  input: UpdateFileUpload;
};

export type MutationAdminVerifyTwoFactorPhoneArgs = {
  code: Scalars['String'];
  phone: Scalars['String'];
};

export type MutationAnswerSkillReflectionQuestionArgs = {
  answer: Scalars['String'];
  question: Scalars['String'];
  questionKey: Scalars['String'];
  skillId: Scalars['String'];
};

export type MutationApplyCouponArgs = {
  code: Scalars['String'];
};

export type MutationAssignCoachToEmbedPatientArgs = {
  coachId: Scalars['String'];
  userId: Scalars['Int'];
};

export type MutationAssignSkillToUserArgs = {
  moduleId: Scalars['String'];
  skillId: Scalars['String'];
  userId: Scalars['Float'];
};

export type MutationAutoApplyCouponsArgs = {
  careType: CareType;
};

export type MutationBeginSponsoredCareReenrollmentArgs = {
  userId: Scalars['Float'];
};

export type MutationBeginUploadArgs = {
  contentType: Scalars['String'];
};

export type MutationCancelAppointmentArgs = {
  appointmentId: Scalars['Int'];
  reason?: InputMaybe<Scalars['String']>;
  reasonDetails?: InputMaybe<Scalars['String']>;
};

export type MutationCancelHealthieAppointmentArgs = {
  appointmentId: Scalars['String'];
};

export type MutationCancelSponsoredCareReenrollmentArgs = {
  userId: Scalars['Float'];
};

export type MutationChangeProviderPasswordArgs = {
  password: Scalars['String'];
};

export type MutationChangeStatusOfClaimArgs = {
  claimId: Scalars['Float'];
  details: Scalars['String'];
  status: InternalStatus;
};

export type MutationCheckIsBlurryArgs = {
  key: Scalars['String'];
  purpose: Scalars['String'];
};

export type MutationClearProviderLockoutArgs = {
  providerId: Scalars['Int'];
};

export type MutationCompleteAppointmentCheckinArgs = {
  appointmentId: Scalars['Int'];
};

export type MutationCompletePendingTransitionArgs = {
  careType: CareType;
};

export type MutationCompleteUploadArgs = {
  key: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  purpose: Scalars['String'];
};

export type MutationConsumeCoachingRecommendationArgs = {
  coachingProgramType?: InputMaybe<CoachingProgramType>;
};

export type MutationConvertPatientCareArgs = {
  careFlows: Array<UserCareFlowInput>;
  userId: Scalars['Float'];
};

export type MutationCreateConnectNowPreEncounterArgs = {
  data: ConnectNowPreEncounterInput;
};

export type MutationCreateContractArgs = {
  input: CreateContractInput;
};

export type MutationCreateDataSharingRequestArgs = {
  userId: Scalars['Float'];
};

export type MutationCreateHubFeedbackArgs = {
  feedback: Scalars['String'];
  url: Scalars['String'];
};

export type MutationCreateNoteForClaimArgs = {
  claimId: Scalars['Float'];
  note: Scalars['String'];
};

export type MutationCreateOnDemandEncounterArgs = {
  data: OnDemandEncounterInput;
};

export type MutationCreateOrganizationArgs = {
  input: CreateOrganization;
};

export type MutationCreateOrganizationAnnouncementArgs = {
  input: CreateOrganizationAnnouncementInput;
};

export type MutationCreateOrUpdateOrganizationResourceArgs = {
  organizationId: Scalars['Int'];
  resource: OrganizationResourceInput;
};

export type MutationCreateOrUpdatePayerArgs = {
  params: UpdatePayerParams;
};

export type MutationCreatePartnerReferralArgs = {
  data: PartnerReferralInput;
};

export type MutationCreateProviderArgs = {
  careTypes: Array<CareType>;
  classification: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  funFact?: InputMaybe<Scalars['String']>;
  geoStates?: InputMaybe<Array<Scalars['String']>>;
  lastName: Scalars['String'];
  npi: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  postNominalTitles?: InputMaybe<Scalars['String']>;
  withTwoFactor?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateTicketArgs = {
  ticket: CreateTicketInput;
};

export type MutationCreateUserFirstStepArgs = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  newsletter?: InputMaybe<Scalars['Boolean']>;
  password: Scalars['String'];
  preferredName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type MutationCreateZendeskTicketArgs = {
  attachmentTokens?: InputMaybe<Array<Scalars['String']>>;
  body: Scalars['String'];
  subject: Scalars['String'];
  tags: Array<Scalars['String']>;
};

export type MutationDeactivateOrganizationArgs = {
  id: Scalars['Int'];
};

export type MutationDebugImpersonateAsUserArgs = {
  reason: Scalars['String'];
  userId: Scalars['Float'];
};

export type MutationDebugLoginAsUserArgs = {
  userId: Scalars['Float'];
};

export type MutationDebugModifyDataSharingConsentArgs = {
  endDate: Scalars['DateTime'];
  id: Scalars['Float'];
  revokedAt?: InputMaybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
};

export type MutationDebugModifyDataSharingRequestArgs = {
  date: Scalars['DateTime'];
  id: Scalars['Float'];
  pending: Scalars['Boolean'];
};

export type MutationDecoupleOrgEntitlementsFromParentArgs = {
  id: Scalars['Float'];
};

export type MutationDeleteEmergencyContactArgs = {
  id: Scalars['Float'];
};

export type MutationDeleteInsuranceCredentialArgs = {
  id: Scalars['Float'];
};

export type MutationDeleteMedicalNoteArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteOrganizationResourceArgs = {
  resourceId: Scalars['Int'];
};

export type MutationDeleteOutreachAttemptArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteUserCareEligibilitySubmissionArgs = {
  id: Scalars['Float'];
  userId: Scalars['Float'];
};

export type MutationDeleteUserContactArgs = {
  id: Scalars['Float'];
};

export type MutationDeleteZendeskUploadArgs = {
  token: Scalars['String'];
};

export type MutationDisableDeviceArgs = {
  firebaseToken: Scalars['String'];
};

export type MutationDismissNotificationArgs = {
  id: Scalars['Float'];
};

export type MutationEditContractScopeArgs = {
  input: EditContractScopeInput;
};

export type MutationEditContractWeeksArgs = {
  input: EditContractWeeksInput;
};

export type MutationEditOrganizationAnnouncementArgs = {
  id: Scalars['Float'];
  input: EditOrganizationAnnouncementInput;
};

export type MutationEditOrganizationHandbookArgs = {
  input: EditOrganizationHandbookInput;
  organizationId: Scalars['Float'];
};

export type MutationEditSafetyPlanArgs = {
  plan: SafetyPlanInput;
};

export type MutationEditTicketArgs = {
  id: Scalars['Float'];
  ticket: EditTicketInput;
};

export type MutationEmailUnsubscribeArgs = {
  token: Scalars['String'];
};

export type MutationFacultyHubReferralArgs = {
  email: Scalars['String'];
};

export type MutationFindCareFinishArgs = {
  submissions: Array<HubAssessmentSubmission>;
};

export type MutationFinishProviderPasswordResetArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationFinishResetPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationFinishSkillReflectionArgs = {
  discardReflection: Scalars['Boolean'];
  skillId: Scalars['String'];
};

export type MutationForceNextScalesToNowArgs = {
  userId: Scalars['Float'];
};

export type MutationForgotProviderPasswordArgs = {
  email: Scalars['String'];
};

export type MutationGetOrCreateNoteArgs = {
  key: Scalars['String'];
  userId: Scalars['Float'];
};

export type MutationHubUserOnboardArgs = {
  birthDate: Scalars['String'];
  preferredName?: InputMaybe<Scalars['String']>;
};

export type MutationImpersonateArgs = {
  providerToImpersonateId: Scalars['Float'];
  reason: Scalars['String'];
};

export type MutationInitializeStudentArgs = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  state: StateCodes;
  zip: Scalars['String'];
};

export type MutationLoginHubArgs = {
  token: Scalars['String'];
  tokenId: Scalars['String'];
};

export type MutationLoginToHubArgs = {
  token: Scalars['String'];
  tokenId: Scalars['String'];
};

export type MutationLoginUserWithPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationMarkCareTeamChannelsAsReadArgs = {
  channels: Array<Scalars['Float']>;
};

export type MutationMarkEmbedPatientCoachingInactiveArgs = {
  userId: Scalars['Int'];
};

export type MutationMarkHealthieAppointmentForCancellationArgs = {
  appointmentId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
};

export type MutationMarkReferralCompleteArgs = {
  userId: Scalars['Float'];
};

export type MutationMarkSkillAssignmentSeenByAssignerArgs = {
  skillAssignmentId: Scalars['Float'];
};

export type MutationMarkSkillCompleteArgs = {
  input: SkillCompletionInputModel;
};

export type MutationMarkSkillStartedArgs = {
  input: SkillCompletionInputModel;
};

export type MutationMinorConsentApproveUserArgs = {
  userId: Scalars['Int'];
};

export type MutationMinorConsentOverrideUserArgs = {
  birthDate: Scalars['UserBirthdate'];
  userId: Scalars['Int'];
};

export type MutationNetworkSearchAccommodationRequestArgs = {
  accommodationRequestDetails: Scalars['String'];
};

export type MutationNotifyOrganizationCrisisTeamArgs = {
  crisisId: Scalars['String'];
};

export type MutationPasswordlessLoginArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};

export type MutationPatientMarkAsReadArgs = {
  providerId: Scalars['Float'];
};

export type MutationPatientMessageProviderArgs = {
  attachmentIds?: InputMaybe<Array<Scalars['Float']>>;
  content: Scalars['String'];
  providerId: Scalars['Float'];
};

export type MutationPreLoginOrPasswordLoginArgs = {
  app: SamlUserApp;
  email: Scalars['String'];
  mrn?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type MutationPreSignUpArgs = {
  app: SamlUserApp;
  email: Scalars['String'];
};

export type MutationProviderMarkPrivateChannelsAsReadArgs = {
  channels: Array<Scalars['Float']>;
};

export type MutationProviderMessagePatientArgs = {
  attachmentIds?: InputMaybe<Array<Scalars['Float']>>;
  content: Scalars['String'];
  userId: Scalars['Float'];
};

export type MutationPushAssessmentArgs = {
  assessment: AssessmentKey;
  userId: Scalars['Float'];
};

export type MutationReactivateSelfForSponsoredCareArgs = {
  careType: CareType;
};

export type MutationReactivateUserCareFlowArgs = {
  careType: CareType;
  paymentSource?: InputMaybe<PaymentSource>;
  userId: Scalars['Float'];
};

export type MutationReactivateUserForSponsoredCareArgs = {
  careType: CareType;
  userId: Scalars['Float'];
};

export type MutationRecheckInsuranceEligibilityArgs = {
  userId: Scalars['Int'];
};

export type MutationRecoupleOrgEntitlementsToParentArgs = {
  id: Scalars['Float'];
};

export type MutationRegenerateProviderPasswordArgs = {
  providerId: Scalars['Int'];
};

export type MutationRegisterDeviceArgs = {
  applicationName: Scalars['String'];
  firebaseToken: Scalars['String'];
  platform: Scalars['String'];
};

export type MutationRemoveProviderFromCrisisTeamsArgs = {
  providerId: Scalars['Float'];
};

export type MutationResendActivationEmailArgs = {
  email: Scalars['String'];
};

export type MutationResendTwoFactorCodeArgs = {
  firstFactorToken: Scalars['String'];
};

export type MutationResetBadgeCountArgs = {
  counterName: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};

export type MutationResetSessionsForFlowArgs = {
  appointmentsAfter: Scalars['String'];
  careFlowId: Scalars['Int'];
};

export type MutationResetSessionStartForUserArgs = {
  appointmentsAfter?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['Float'];
};

export type MutationResetUserPasswordArgs = {
  userId: Scalars['Float'];
};

export type MutationResubmitClaimArgs = {
  noteId: Scalars['Int'];
};

export type MutationSaveNoteSectionsArgs = {
  sections: Array<MedicalNoteSectionInput>;
  uuid: Scalars['String'];
};

export type MutationSaveUserDemographicsArgs = {
  input: CreateUserDemographicsInput;
};

export type MutationSelectCareFlowArgs = {
  careFlows: Array<UserCareFlowInput>;
};

export type MutationSendCareTeamMessageArgs = {
  content: Scalars['String'];
  userId: Scalars['Float'];
};

export type MutationSendCoachChatMessageArgs = {
  coachingProgramId?: InputMaybe<Scalars['Float']>;
  content: Scalars['String'];
};

export type MutationSendDataSharingRequestReminderArgs = {
  userId: Scalars['Float'];
};

export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['String'];
};

export type MutationSetDataSharingConsentArgs = {
  consent: Scalars['Boolean'];
  initials?: InputMaybe<Scalars['String']>;
};

export type MutationSetFlaggedForOutreachArgs = {
  userId: Scalars['Int'];
  value: Scalars['Boolean'];
};

export type MutationSetHubUserPreferredNameArgs = {
  preferredName: Scalars['String'];
};

export type MutationSetInsuranceArgs = {
  insurance: InsuranceDetailsInput;
};

export type MutationSetPasswordWithTokenArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
  tokenId: Scalars['Float'];
};

export type MutationSetPharmacyOnPatientArgs = {
  pharmacyId?: InputMaybe<Scalars['Float']>;
};

export type MutationSetProviderSubscribedArgs = {
  providerId: Scalars['Int'];
  subscribed: Scalars['Boolean'];
};

export type MutationSetSafeOperatingHandbookUrlArgs = {
  organizationId: Scalars['Int'];
  url?: InputMaybe<Scalars['String']>;
};

export type MutationSetSuggestedPharmacyIdArgs = {
  organizationId: Scalars['Int'];
  pharmacyId?: InputMaybe<Scalars['Int']>;
};

export type MutationSetUserInsuranceArgs = {
  insurance: InsuranceDetailsInput;
  userId: Scalars['Int'];
};

export type MutationSetUserSubscribedArgs = {
  subscribed: Scalars['Boolean'];
  userId: Scalars['Int'];
};

export type MutationSetUserVerifiedArgs = {
  id: Scalars['Float'];
  verify: Scalars['Boolean'];
};

export type MutationSignUpArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
  tokenId: Scalars['String'];
};

export type MutationSkillPathwaySubmitArgs = {
  answers: Array<SkillPathwayAnswer>;
};

export type MutationSkillReflectionDeleteArgs = {
  reflectionId: Scalars['Float'];
};

export type MutationSkillReflectionEditAnswerArgs = {
  answer: Scalars['String'];
  questionKey: Scalars['String'];
  reflectionId: Scalars['Float'];
};

export type MutationSoftDeleteUserArgs = {
  userId: Scalars['Float'];
};

export type MutationStudentHubReferralArgs = {
  email: Scalars['String'];
};

export type MutationSubmitAppointmentFeedbackArgs = {
  appointmentId: Scalars['Float'];
  feedback: AppointmentFeedbackInput;
};

export type MutationSubmitAppointmentIncidentArgs = {
  appointmentId: Scalars['Float'];
  incident: Scalars['String'];
};

export type MutationSubmitCoachingAssessmentArgs = {
  assessmentType: Scalars['String'];
  submissions: Array<HubAssessmentSubmission>;
};

export type MutationSubmitCoachingAssessmentV2Args = {
  assessmentType: CoachingAssessments;
  submissions: Array<HubAssessmentSubmission>;
};

export type MutationSubmitIntakeAssessmentArgs = {
  questionnaires: Array<QuestionnaireSubmission>;
};

export type MutationSubmitMedicalHistoryArgs = {
  questionnaires: Array<QuestionnaireSubmission>;
};

export type MutationSubmitNoteArgs = {
  sections: Array<MedicalNoteSectionInput>;
  uuid: Scalars['String'];
};

export type MutationSubmitProviderFeedbackArgs = {
  answers?: InputMaybe<Array<FeedbackAnswer>>;
  careType: CareType;
  feedbackType: FeedbackType;
  optedOut?: InputMaybe<Scalars['Boolean']>;
  providerId: Scalars['Int'];
};

export type MutationSubmitRequestedAssessmentArgs = {
  questionnaires: Array<QuestionnaireSubmission>;
  requestId: Scalars['Float'];
};

export type MutationSubmitSelfRefer1ScreenerArgs = {
  questionnaires: Array<QuestionnaireSubmission>;
};

export type MutationSwapPrimaryEmergencyContactArgs = {
  id: Scalars['Float'];
  userId: Scalars['Float'];
};

export type MutationSyncHealthieProviderArgs = {
  id: Scalars['Float'];
};

export type MutationToggleOrganizationOnHoldArgs = {
  isOnHold: Scalars['Boolean'];
  organizationId: Scalars['Float'];
};

export type MutationTrackArgs = {
  data?: InputMaybe<Scalars['JSON']>;
  tag: Scalars['String'];
};

export type MutationTrackPushOpenArgs = {
  createdAt?: InputMaybe<Scalars['Float']>;
  messageId: Scalars['String'];
  templateId?: InputMaybe<Scalars['Float']>;
};

export type MutationTriggerPasswordlessAuthFlowArgs = {
  email: Scalars['String'];
};

export type MutationUnassignSkillFromUserArgs = {
  skillAssignmentId: Scalars['Float'];
  userId: Scalars['Float'];
};

export type MutationUpdateAppointmentTemplatesArgs = {
  organizationId: Scalars['Int'];
  templates: Array<AppointmentTemplateUpdate>;
};

export type MutationUpdateCareFlowForUserArgs = {
  careFlow: UserCareFlowInput;
  userId: Scalars['Float'];
};

export type MutationUpdateCertificationsArgs = {
  certifications: Array<ProviderCertificationInput>;
  providerId: Scalars['Float'];
};

export type MutationUpdateCouponArgs = {
  updateCoupon: UpdateCoupon;
};

export type MutationUpdateCrisisTeamHoursArgs = {
  crisisTeamHours: Array<CrisisTeamHoursInput>;
  organizationId: Scalars['Float'];
};

export type MutationUpdateEducationArgs = {
  education: Array<ProviderEducationInput>;
  providerId: Scalars['Float'];
};

export type MutationUpdateEligibilityCriteriaArgs = {
  criteria: Array<OrganizationEligibilityCriteriaInput>;
  organizationId: Scalars['Int'];
};

export type MutationUpdateEntitlementsArgs = {
  entitlements: Array<UpdateOrganizationEntitlementInput>;
  organizationId: Scalars['Float'];
};

export type MutationUpdateHubUserArgs = {
  updates: HubUserUpdateInput;
};

export type MutationUpdateHubUser2Args = {
  updates: HubUserUpdateInput;
};

export type MutationUpdateNotificationOutcomeArgs = {
  emailId?: InputMaybe<Scalars['Int']>;
  outcome: Scalars['String'];
  smsId?: InputMaybe<Scalars['Int']>;
};

export type MutationUpdateOrganizationArgs = {
  update: UpdateOrganization;
};

export type MutationUpdateOrganizationCareFlowsArgs = {
  careFlows: Array<OrganizationCareFlowInput>;
  organizationId: Scalars['Int'];
};

export type MutationUpdateOrganizationCarePeriodsArgs = {
  editedPeriods: Array<EditOrganizationCarePeriod>;
  newPeriods: Array<NewOrganizationCarePeriod>;
  organizationId: Scalars['Int'];
};

export type MutationUpdateOrganizationDedicatedGroupModelAllocationsArgs = {
  editedOrganizationDedicatedGroupModelAllocations: Array<OrganizationDedicatedGroupModelAllocationInput>;
  organizationId: Scalars['Int'];
};

export type MutationUpdateOrganizationDefaultsArgs = {
  overrides: Array<OrganizationOverrides>;
};

export type MutationUpdateOrganizationsArgs = {
  organizationIds: Array<Scalars['Float']>;
  providerId: Scalars['Float'];
};

export type MutationUpdateOutreachAttemptArgs = {
  attempt: OutreachAttemptInput;
};

export type MutationUpdatePaymentMethodArgs = {
  pmToken: Scalars['String'];
  pmType: Scalars['String'];
};

export type MutationUpdatePreferencesArgs = {
  preferences: Array<CareTeamPreference>;
};

export type MutationUpdateProviderTraitsArgs = {
  providerId: Scalars['Int'];
  traits: Array<ProviderTraitInput>;
};

export type MutationUpdateQualificationsArgs = {
  providerId: Scalars['Float'];
  qualifications: Array<ProviderQualificationInput>;
};

export type MutationUpdateSamlConfigurationArgs = {
  organizationId: Scalars['Int'];
  type: SamlConfigurationType;
  update: UpdateSamlConfiguration;
};

export type MutationUpdateScreenUserResultArgs = {
  input: ScreenUserResultInput;
};

export type MutationUpdateSponsoredCareReenrollmentArgs = {
  status: ReenrollmentStatus;
};

export type MutationUpdateUser2Args = {
  updates: UpdateUserInput;
};

export type MutationUpsertEmergencyContactArgs = {
  id?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  primary?: InputMaybe<Scalars['Boolean']>;
  relationship: Scalars['String'];
  userId: Scalars['Float'];
};

export type MutationUpsertInsuranceCredentialArgs = {
  credential: UpsertProviderInsuranceCredential;
};

export type MutationUpsertSuggestedProvidersArgs = {
  providerIds: Array<Scalars['Float']>;
  userId: Scalars['Float'];
};

export type MutationUpsertUserContactArgs = {
  input: ContactUpsertInput;
};

export type MutationUpsertUserSkillFeedbackArgs = {
  additionalFeedback: Scalars['String'];
  questions: Array<SkillFeedbackQuestionModelInput>;
  rating: Scalars['Float'];
  skillId: Scalars['String'];
};

export type MutationUserCreateCareTransitionArgs = {
  careType: CareType;
  nextSteps: NextSteps;
};

export type MutationUserScheduleAppointmentArgs = {
  checkin: Scalars['Boolean'];
  end: Scalars['DateTime'];
  isFeeForServiceTime?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  providerId: Scalars['Float'];
  reason?: InputMaybe<Scalars['String']>;
  reasonDetails?: InputMaybe<Scalars['String']>;
  rescheduleId?: InputMaybe<Scalars['Float']>;
  start: Scalars['DateTime'];
};

export interface NavigateFromHubToPatientPortalError {
  errorCode: NavigateFromHubToPatientPortalErrorCode;
  message: Scalars['String'];
}

export const NavigateFromHubToPatientPortalErrorCode = {
  VerifyEligibility: 'VerifyEligibility',
} as const;

export type NavigateFromHubToPatientPortalErrorCode =
  typeof NavigateFromHubToPatientPortalErrorCode[keyof typeof NavigateFromHubToPatientPortalErrorCode];
export interface NavigateFromHubToPatientPortalForward {
  externalRedirect: Scalars['String'];
}

export interface NavigateFromHubToPatientPortalReauth {
  mrn: Scalars['String'];
}

export type NavigateFromHubToPatientPortalResult =
  | NavigateFromHubToPatientPortalError
  | NavigateFromHubToPatientPortalSuccess;

export type NavigateFromHubToPatientPortalResult3 =
  | NavigateFromHubToPatientPortalError
  | NavigateFromHubToPatientPortalForward
  | NavigateFromHubToPatientPortalReauth;

export interface NavigateFromHubToPatientPortalSuccess {
  loginUrl: Scalars['String'];
}

export interface NewOrganizationCarePeriod {
  endDate: Scalars['String'];
  periodType: PeriodType;
  startDate: Scalars['String'];
}

export const NextSteps = {
  ActiveSelfPay: 'activeSelfPay',
  ActiveSponsored: 'activeSponsored',
  Cancel: 'cancel',
  OnHold: 'onHold',
} as const;

export type NextSteps = typeof NextSteps[keyof typeof NextSteps];
export const NextStepsFilter = {
  ActiveSponsored: 'ActiveSponsored',
  Cancelled: 'Cancelled',
  CompletedSelfPay: 'CompletedSelfPay',
  Na: 'NA',
  OnHold: 'OnHold',
  PendingSelfPay: 'PendingSelfPay',
} as const;

export type NextStepsFilter = typeof NextStepsFilter[keyof typeof NextStepsFilter];
export interface NumericKeyValue {
  key: Scalars['ID'];
  value: Scalars['Float'];
}

export interface OnDemandEncounterInput {
  alternativeCare: AlternativeCare;
  currentLocation: Scalars['String'];
  distressLevel: Scalars['Int'];
  mainConcern: MainConcerns;
  otherAlternativeCare?: InputMaybe<Scalars['String']>;
  otherMainConcern?: InputMaybe<Scalars['String']>;
  preferredPhoneNumber: Scalars['String'];
  suicidalThoughts: SuicidalThoughts;
}

export interface OnDemandReportsInput {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
}

export interface OnDemandSession {
  callStartTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Float'];
  user: OnDemandSessionUser;
}

export interface OnDemandSessionOrganization {
  id: Scalars['Float'];
  name: Scalars['String'];
}

export interface OnDemandSessionUser {
  customerId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Float'];
  lastName: Scalars['String'];
  organization: OnDemandSessionOrganization;
}

export interface Option {
  key?: Maybe<Scalars['String']>;
  requiresManualInput?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  text: Scalars['String'];
}

export interface Organization {
  abbreviation: Scalars['String'];
  activityStatus: Scalars['String'];
  appointmentTemplate: AppointmentTemplate;
  appointmentTemplates: Array<AppointmentTemplate>;
  careFlows: Array<OrganizationCareFlow>;
  carePeriods: Array<OrganizationCarePeriodModel>;
  children: Array<Organization>;
  childrenCount: Scalars['Int'];
  crisisHours: Array<CrisisTeamHours>;
  crisisHoursActive: Scalars['Boolean'];
  crisisTeamProviders: Array<Provider>;
  currentCarePeriodUtilization?: Maybe<UtilizationVirtualModel>;
  currentCrisisHours?: Maybe<CrisisTeamHours>;
  departmentAddress: Scalars['String'];
  departmentHours: Scalars['String'];
  departmentName: Scalars['String'];
  director?: Maybe<Provider>;
  eligibilityCriteria: Array<OrganizationEligibilityCriteriaModel>;
  emailDomains: Array<Scalars['String']>;
  emergencyResources: Array<OrganizationResource>;
  entitlements: Array<OrganizationEntitlement>;
  entitlementsCoupledToParent: Scalars['Boolean'];
  highRiskUsers: Array<User>;
  id: Scalars['Int'];
  latestAssessments: Array<AssessmentModel>;
  latestCarePeriod?: Maybe<OrganizationCarePeriodModel>;
  latestNotes: Array<MedicalNote>;
  logo?: Maybe<Upload>;
  missedAppointments: Array<CalendarEvent>;
  name: Scalars['String'];
  nextAvailableTimeSlots: Array<Availability>;
  organizationUtilization?: Maybe<OrganizationUtilizationVirtualModel>;
  parent?: Maybe<Organization>;
  providers: Array<Provider>;
  providersWithParent: Array<Provider>;
  referralCredits?: Maybe<OrganizationReferralCreditOverview>;
  reporting: OrganizationReport;
  resources: Array<OrganizationResource>;
  resourcesUpdatedAt?: Maybe<Scalars['DateTime']>;
  resourcesUpdatedBy?: Maybe<Provider>;
  safeOperatingHandbook: OrganizationHandbook;
  safeOperatingHandbookUrl?: Maybe<Scalars['String']>;
  safetyPlans: Array<SafetyPlan>;
  samlConfiguration?: Maybe<SamlConfiguration>;
  suggestedPharmacy?: Maybe<Pharmacy>;
  suicidalRiskUsers: Array<User>;
  type: Scalars['String'];
  usersWithOnlyIncompleteReferrals: Array<User>;
  usersWithoutReferrals: Array<User>;
  usesDedicatedGroupModel: Scalars['Boolean'];
}

export type OrganizationAppointmentTemplateArgs = {
  careType: CareType;
};

export type OrganizationLatestAssessmentsArgs = {
  limit: Scalars['Float'];
};

export type OrganizationLatestNotesArgs = {
  key?: InputMaybe<Scalars['String']>;
  limit: Scalars['Float'];
};

export type OrganizationNextAvailableTimeSlotsArgs = {
  appointmentType: Scalars['String'];
  endTime?: InputMaybe<Scalars['String']>;
};

export type OrganizationProvidersArgs = {
  roles?: InputMaybe<Array<Scalars['String']>>;
};

export type OrganizationProvidersWithParentArgs = {
  roles?: InputMaybe<Array<Scalars['String']>>;
};

export type OrganizationReportingArgs = {
  carePeriodId?: InputMaybe<Scalars['Float']>;
  referralSource?: InputMaybe<ReferralSource>;
};

export type OrganizationSafetyPlansArgs = {
  complete?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationSamlConfigurationArgs = {
  type: SamlConfigurationType;
};

export interface OrganizationAnnouncement {
  createdAt: Scalars['DateTime'];
  createdByProviderName: Scalars['String'];
  details?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  inCurrentCarePeriod: Scalars['Boolean'];
  title: Scalars['String'];
}

export interface OrganizationCareFlow {
  canMcpUsersRefer: Scalars['Boolean'];
  canReferrerUsersRefer: Scalars['Boolean'];
  canSelfRefer: Scalars['Boolean'];
  careType: CareType;
  defaultPaymentSource: PaymentSource;
  hideSessionCount: Scalars['Boolean'];
  id: Scalars['Int'];
  referralRestrictedStates: Array<StateCodes>;
  sponsoredSessions?: Maybe<Scalars['Float']>;
  sponsoredSessionsLimitType: SponsoredSessionsLimitType;
  statesAvailable: Array<StateCodes>;
}

export interface OrganizationCareFlowInput {
  canMcpUsersRefer: Scalars['Boolean'];
  canReferrerUsersRefer: Scalars['Boolean'];
  canSelfRefer: Scalars['Boolean'];
  careType: CareType;
  defaultPaymentSource: PaymentSource;
  hideSessionCount: Scalars['Boolean'];
  referralRestrictedStates: Array<StateCodes>;
  sponsoredSessions?: InputMaybe<Scalars['Float']>;
  sponsoredSessionsLimitType: SponsoredSessionsLimitType;
}

export interface OrganizationCarePeriodModel {
  endDate: Scalars['String'];
  id: Scalars['Int'];
  organizationId: Scalars['Int'];
  periodType: PeriodType;
  startDate: Scalars['String'];
}

export interface OrganizationDedicatedGroupModelAllocationInput {
  id: Scalars['Int'];
  psychiatryMaxHours?: InputMaybe<Scalars['Float']>;
  psychiatryMaxIntakeHours?: InputMaybe<Scalars['Int']>;
  therapyMaxHours?: InputMaybe<Scalars['Float']>;
  therapyMaxIntakeHours?: InputMaybe<Scalars['Int']>;
}

export interface OrganizationEligibilityCriteriaInput {
  minimumAge?: InputMaybe<Scalars['Float']>;
  permittedCareTypes?: InputMaybe<Array<CareType>>;
  states?: InputMaybe<Array<Scalars['String']>>;
}

export interface OrganizationEligibilityCriteriaModel {
  id: Scalars['Int'];
  minimumAge?: Maybe<Scalars['Float']>;
  permittedCareTypes?: Maybe<Array<CareType>>;
  states?: Maybe<Array<Scalars['String']>>;
}

export interface OrganizationEntitlement {
  config: Scalars['JSON'];
  id: Scalars['Int'];
  key: Entitlement;
}

export interface OrganizationEntitlementInput {
  config?: InputMaybe<Scalars['JSON']>;
  key: Entitlement;
}

export interface OrganizationHandbook {
  campusCrisisTeamInProduct: Scalars['String'];
  campusSafetyPoliceNumber: Scalars['String'];
  coPayChargedByPartner: Scalars['String'];
  defaultTherapyCadence: Scalars['String'];
  ekgsNearCampus: Scalars['String'];
  fullADHDEvaluation: Scalars['String'];
  hasManualReactivationEntitlement: Scalars['Boolean'];
  highLevelProtocolManagingEmergency: Scalars['String'];
  hospitalization: Scalars['String'];
  labsNearCampus: Scalars['String'];
  mainPointOfContactCrisis: Scalars['String'];
  noShowsCountForSessionLimits: Scalars['String'];
  organizationId: Scalars['Int'];
  protocolForMedicalLeaveDoc: Scalars['String'];
  requestingAcademicAccomodations: Scalars['String'];
  sessionLimitsRenew: Scalars['String'];
  summerBreakEligibility: Scalars['String'];
  supportingClinician: Scalars['String'];
  vitalsNearCampus: Scalars['String'];
  winterBreakEligibility: Scalars['String'];
}

export interface OrganizationOption {
  id: Scalars['Int'];
  name: Scalars['String'];
}

export interface OrganizationOverrides {
  key: Scalars['String'];
  organizationId: Scalars['Float'];
  value: Scalars['JSON'];
}

export interface OrganizationReferralCreditOverview {
  availableCredits: Scalars['Int'];
  psychiatryCreditWeight: Scalars['Int'];
  referralPeriodEnd?: Maybe<Scalars['DateTime']>;
  therapyCreditWeight: Scalars['Int'];
}

export interface OrganizationReport {
  activeStudentsCount: Scalars['Float'];
  /** Average Time from Referral to First Visit (Seconds) */
  averageReferralToVisitTime?: Maybe<Scalars['Float']>;
  careTeamReferrals: Array<CareTeamReferral>;
  diagnosesBreakdown: Array<UserDiagnosis>;
  patientMonitoring: Array<User>;
  referralCompletionRate?: Maybe<Scalars['Float']>;
  scope: ReportScope;
  visitAttendance: VisitAttendance;
  visitSatisfactionRatings: SatisfactionRating;
}

export interface OrganizationResource {
  contact?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  position: Scalars['Float'];
  resourceSubtype?: Maybe<Scalars['String']>;
  resourceType: ResourceType;
  showAsCrisisResource: Scalars['Boolean'];
  tags: Array<ResourceTag>;
  website?: Maybe<Scalars['String']>;
}

export interface OrganizationResourceInput {
  contact?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  hours?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  resourceSubtype?: InputMaybe<Scalars['String']>;
  resourceType: ResourceType;
  showAsCrisisResource?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Array<ResourceTag>>;
  website?: InputMaybe<Scalars['String']>;
}

/** All possible organization types */
export const OrganizationType = {
  University: 'University',
} as const;

export type OrganizationType = typeof OrganizationType[keyof typeof OrganizationType];
export interface OrganizationUtilizationVirtualModel {
  carePeriodUtilizations: Array<UtilizationVirtualModel>;
}

export interface OrganizationUtilizationWeek {
  endDate: Scalars['String'];
  id: Scalars['Int'];
  organizationId: Scalars['Int'];
  psychiatryHours: UtilizationHours;
  startDate: Scalars['String'];
  therapyHours: UtilizationHours;
}

export interface OutreachAttemptInput {
  attemptNumber?: InputMaybe<Scalars['Int']>;
  createdByCustom?: InputMaybe<Scalars['String']>;
  date: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  methods: Array<Scalars['String']>;
  outcome: Scalars['String'];
  subject: Scalars['String'];
  userId: Scalars['Int'];
  zendeskTicketId?: InputMaybe<Scalars['Int']>;
}

export interface OutreachAttemptModel {
  attemptNumber?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Provider>;
  createdByCustom?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  methods: Array<Scalars['String']>;
  outcome: Scalars['String'];
  subject: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  zendeskTicketId?: Maybe<Scalars['Int']>;
}

export interface PaginatedConnectNowSession {
  count?: Maybe<Scalars['Float']>;
  cursor: Scalars['String'];
  hasMore: Scalars['Boolean'];
  items: Array<ConnectNowSession>;
  limitCountExceeded?: Maybe<Scalars['Boolean']>;
}

export interface PaginatedIopReferralInput {
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  partner: ReferralPartners;
  search?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']>;
}

export interface PaginatedIopReferrals {
  count: Scalars['Float'];
  cursor: Scalars['String'];
  hasMore: Scalars['Boolean'];
  items: Array<IopReferralModel>;
  limitCountExceeded?: Maybe<Scalars['Boolean']>;
}

export interface PaginatedOnDemandSession {
  count?: Maybe<Scalars['Float']>;
  cursor: Scalars['String'];
  hasMore: Scalars['Boolean'];
  items: Array<OnDemandSession>;
  limitCountExceeded?: Maybe<Scalars['Boolean']>;
}

export interface PaginatedUsers {
  count: Scalars['Float'];
  cursor: Scalars['String'];
  hasMore: Scalars['Boolean'];
  items: Array<User>;
  limitCountExceeded?: Maybe<Scalars['Boolean']>;
}

export interface PartnerReferralInput {
  birthDate: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organizationId?: InputMaybe<Scalars['Float']>;
  partner: ReferralPartners;
  preferredName?: InputMaybe<Scalars['String']>;
  providerId: Scalars['Float'];
  referralEmail?: InputMaybe<Scalars['String']>;
  referralSource: ReferralSource;
  userId?: InputMaybe<Scalars['Float']>;
}

export interface PasswordAuthResult {
  authToken?: Maybe<Scalars['String']>;
  blockedReason?: Maybe<Scalars['String']>;
  firstFactorResult?: Maybe<FirstFactorResult>;
  passwordCorrect: Scalars['Boolean'];
  twoFactorNeeded: Scalars['Boolean'];
  useOtherApp?: Maybe<UseOtherApp>;
}

export interface PasswordlessLoginResult {
  activationLinkExpired?: Maybe<Scalars['Boolean']>;
  authToken?: Maybe<Scalars['String']>;
  canLogin?: Maybe<Scalars['Boolean']>;
}

export interface PatientChannel {
  channel: Channel;
  id: Scalars['Float'];
  user: User;
}

export interface PatientDetail {
  breakLine?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
}

export const PatientRelationshipToSubscriber = {
  CadaverDonor: 'CadaverDonor',
  Child: 'Child',
  ChildWhereInsuredHasNoFinancialResponsibility: 'ChildWhereInsuredHasNoFinancialResponsibility',
  EmancipatedMinor: 'EmancipatedMinor',
  Employee: 'Employee',
  Father: 'Father',
  FosterChild: 'FosterChild',
  Grandchild: 'Grandchild',
  Grandparent: 'Grandparent',
  HandicappedDependent: 'HandicappedDependent',
  InjuredPlaintiff: 'InjuredPlaintiff',
  LifePartner: 'LifePartner',
  Mother: 'Mother',
  NieceOrNephew: 'NieceOrNephew',
  OrganDonor: 'OrganDonor',
  Other: 'Other',
  Self: 'Self',
  SignificantOther: 'SignificantOther',
  SponsoredDependent: 'SponsoredDependent',
  Spouse: 'Spouse',
  StepsonOrStepdaughter: 'StepsonOrStepdaughter',
  Unknown: 'Unknown',
  WardOfTheCourt: 'WardOfTheCourt',
} as const;

export type PatientRelationshipToSubscriber =
  typeof PatientRelationshipToSubscriber[keyof typeof PatientRelationshipToSubscriber];
export interface Payer {
  billToProviderName: Scalars['Boolean'];
  id: Scalars['Float'];
  inNetworkStates: Array<Scalars['String']>;
  name: Scalars['String'];
  standardPayerId: Scalars['String'];
  stateAddressOverrides: Array<PayerStateAddressOverride>;
  tradingPartnerId: Scalars['String'];
  useAddressForAllStates?: Maybe<Scalars['String']>;
  visible: Scalars['Boolean'];
}

export interface PayerStateAddressOverride {
  id: Scalars['Int'];
  overrideForState: StateCodes;
  stateToUseInstead: StateCodes;
}

export interface PayerStateAddressOverrideInput {
  overrideForState: StateCodes;
  stateToUseInstead: StateCodes;
}

export interface PaymentMethod {
  id: Scalars['String'];
}

export interface PaymentProfile {
  cardType: Scalars['String'];
  expirationDate: Scalars['String'];
  id: Scalars['String'];
  lastFour: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
}

/** Supported Payment Sources */
export const PaymentSource = {
  Insurance: 'Insurance',
  OonInsurance: 'OONInsurance',
  Organization: 'Organization',
  SelfPay: 'SelfPay',
} as const;

export type PaymentSource = typeof PaymentSource[keyof typeof PaymentSource];
export interface PaymentSubscription {
  id: Scalars['String'];
  nextInvoice: Scalars['Float'];
}

export const PaymentType = {
  SelfPay: 'selfPay',
  Sponsored: 'sponsored',
} as const;

export type PaymentType = typeof PaymentType[keyof typeof PaymentType];
export const PeriodType = {
  NonSponsoredBreak: 'nonSponsoredBreak',
  NonSponsoredTerm: 'nonSponsoredTerm',
  SponsoredBreak: 'sponsoredBreak',
  SponsoredTerm: 'sponsoredTerm',
} as const;

export type PeriodType = typeof PeriodType[keyof typeof PeriodType];
export const Permission = {
  AppointmentCancel: 'appointmentCancel',
  AppointmentCancelAll: 'appointmentCancelAll',
  AppointmentEdit: 'appointmentEdit',
  AppointmentView: 'appointmentView',
  AssessmentPush: 'assessmentPush',
  CampusTeamEdit: 'campusTeamEdit',
  CareDashboards: 'careDashboards',
  ContractCreate: 'contractCreate',
  ContractEdit: 'contractEdit',
  ContractView: 'contractView',
  CrisisTeamEdit: 'crisisTeamEdit',
  CrisisTeamEngage: 'crisisTeamEngage',
  DedicatedGroupModelAllocationEdit: 'dedicatedGroupModelAllocationEdit',
  Dev: 'dev',
  GeneratePassword: 'generatePassword',
  Impersonate: 'impersonate',
  MantraAdmin: 'mantraAdmin',
  NoteCreate: 'noteCreate',
  NoteCreateReferral: 'noteCreateReferral',
  NoteCreateText: 'noteCreateText',
  NoteExport: 'noteExport',
  OrderLabs: 'orderLabs',
  OrganizationCampusResourcesEdit: 'organizationCampusResourcesEdit',
  OrganizationDeactivate: 'organizationDeactivate',
  OrganizationHandbookEdit: 'organizationHandbookEdit',
  OrganizationParentCoupleDecoupleEdit: 'organizationParentCoupleDecoupleEdit',
  OrganizationViewAll: 'organizationViewAll',
  PatientCreate: 'patientCreate',
  PatientEdit: 'patientEdit',
  PatientEditCareFlows: 'patientEditCareFlows',
  PatientEditProviders: 'patientEditProviders',
  PatientList: 'patientList',
  PatientMessage: 'patientMessage',
  PatientVideo: 'patientVideo',
  Phi: 'phi',
  Pii: 'pii',
  Prescribe: 'prescribe',
  ProviderAvailabilityEdit: 'providerAvailabilityEdit',
  ProviderCreate: 'providerCreate',
  ProviderDelete: 'providerDelete',
  ProviderEdit: 'providerEdit',
  ProviderList: 'providerList',
  ProviderMessage: 'providerMessage',
  ProviderMessageViewAll: 'providerMessageViewAll',
  ProviderPasswordReset: 'providerPasswordReset',
  ProviderView: 'providerView',
  ProviderViewEmail: 'providerViewEmail',
  ProviderViewPhone: 'providerViewPhone',
  ReferralsShowOrg: 'referralsShowOrg',
  ReferralsView: 'referralsView',
  Reporting: 'reporting',
  SafetyPlanActivate: 'safetyPlanActivate',
  SafetyPlanEdit: 'safetyPlanEdit',
  SponsoredCareReenrollment: 'sponsoredCareReenrollment',
  TicketCreate: 'ticketCreate',
  TicketView: 'ticketView',
  TicketViewAll: 'ticketViewAll',
  UtilizationView: 'utilizationView',
} as const;

export type Permission = typeof Permission[keyof typeof Permission];
/** Pharmacy data model from Dosespot. */
export interface Pharmacy {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  default?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  pharmacyId: Scalars['Float'];
  primary?: Maybe<Scalars['Boolean']>;
  primaryFax?: Maybe<Scalars['String']>;
  primaryPhone?: Maybe<Scalars['String']>;
  serviceLevel?: Maybe<Scalars['Float']>;
  state: Scalars['String'];
  storeName: Scalars['String'];
  zipCode: Scalars['String'];
}

export const PortalKey = {
  Coaching: 'coaching',
  ConnectNow: 'connectNow',
  ExecutiveFunctioning: 'executiveFunctioning',
  Hub: 'hub',
  Loca: 'loca',
  LocaWellness: 'locaWellness',
  NonLocaTherapyGateway: 'nonLocaTherapyGateway',
  Odes: 'odes',
  Psychiatry: 'psychiatry',
  Skills: 'skills',
  Therapy: 'therapy',
  Togetherall: 'togetherall',
} as const;

export type PortalKey = typeof PortalKey[keyof typeof PortalKey];
export interface PreLoginOrPasswordLoginError {
  errorCode: PreLoginOrPasswordLoginErrorCode;
  message: Scalars['String'];
}

export const PreLoginOrPasswordLoginErrorCode = {
  CouldNotLogin: 'CouldNotLogin',
  Deactivated: 'Deactivated',
  PasswordRequired: 'PasswordRequired',
} as const;

export type PreLoginOrPasswordLoginErrorCode =
  typeof PreLoginOrPasswordLoginErrorCode[keyof typeof PreLoginOrPasswordLoginErrorCode];
export interface PreLoginOrPasswordLoginPassword {
  passwordRequired: Scalars['Boolean'];
}

export interface PreLoginOrPasswordLoginRedirect {
  externalRedirect: Scalars['String'];
}

export type PreLoginOrPasswordLoginResult =
  | PreLoginOrPasswordLoginError
  | PreLoginOrPasswordLoginPassword
  | PreLoginOrPasswordLoginRedirect
  | PreLoginOrPasswordLoginSaml
  | PreLoginOrPasswordLoginSuccess;

export interface PreLoginOrPasswordLoginSaml {
  samlEntryPoint: Scalars['String'];
}

export interface PreLoginOrPasswordLoginSuccess {
  /** Mobile only */
  hasHubOnboarded?: Maybe<Scalars['Boolean']>;
  /** Mobile only */
  jwt?: Maybe<Scalars['String']>;
  loginSuccess: Scalars['Boolean'];
}

export interface Prescription {
  daysSupply?: Maybe<Scalars['Float']>;
  directions: Scalars['String'];
  dispenseUnitDescription: Scalars['String'];
  displayName: Scalars['String'];
  doseForm: Scalars['String'];
  genericProductName: Scalars['String'];
  info?: Maybe<MedicationInfo>;
  medicationStatus: MedicationStatus;
  prescriptionId: Scalars['Float'];
  quantity: Scalars['String'];
  rawName?: Maybe<Scalars['String']>;
  status: Scalars['Float'];
  strength: Scalars['String'];
  writtenDate: Scalars['String'];
}

export interface PreSignUpError {
  errorCode: PreSignUpErrorCode;
  message: Scalars['String'];
}

export const PreSignUpErrorCode = {
  CampusReferralRequired: 'CampusReferralRequired',
  EligibilityError: 'EligibilityError',
  UnknownPartner: 'UnknownPartner',
} as const;

export type PreSignUpErrorCode = typeof PreSignUpErrorCode[keyof typeof PreSignUpErrorCode];
export type PreSignUpResult = PreSignUpError | PreSignUpSsoRedirect | PreSignUpSuccess;

export interface PreSignUpSsoRedirect {
  samlEntryPoint: Scalars['String'];
}

export interface PreSignUpSuccess {
  successMessage: Scalars['String'];
}

export interface Prices {
  lateCancellationFee: Scalars['Int'];
  noShowFee: Scalars['Int'];
  psychiatryFollowUp: Scalars['Int'];
  psychiatryIntake: Scalars['Int'];
  therapyFollowUp: Scalars['Int'];
  therapyIntake: Scalars['Int'];
}

export interface PricesInCents {
  lateCancellationFeeInCents: Scalars['Int'];
  noShowFeeInCents: Scalars['Int'];
  psychiatryFollowUpPriceInCents: Scalars['Int'];
  psychiatryIntakePriceInCents: Scalars['Int'];
  therapyFollowUpPriceInCents: Scalars['Int'];
  therapyIntakePriceInCents: Scalars['Int'];
}

export interface Provider {
  /** don't use this in lists, it doesn't use a dataloader yet */
  acceptingNewPatients: Scalars['Boolean'];
  accountSecurityStatus: Scalars['String'];
  activityStatus?: Maybe<Scalars['String']>;
  /** oz, mcp, or referral */
  appView: AppView;
  careTypes: Array<CareType>;
  certifications: Array<ProviderCertification>;
  classification: Scalars['String'];
  degrees: Array<ProviderEducation>;
  dosespotId?: Maybe<Scalars['Float']>;
  eligibleForSchedulingPromotion: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  funFact: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  geoStates: Array<StateCodes>;
  guaranteedPay: Scalars['Boolean'];
  hasDosespot: Scalars['Boolean'];
  hasSso?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  insuranceCredentials: Array<ProviderInsuranceCredential>;
  lastName?: Maybe<Scalars['String']>;
  /** virtualized firstName + lastName */
  name: Scalars['String'];
  npi?: Maybe<Scalars['String']>;
  organizationCrisisTeams: Array<CrisisTeamRelationship>;
  organizations: Array<Organization>;
  patients: Array<User>;
  permissions: Array<Permission>;
  personalTitle?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  portrait?: Maybe<Upload>;
  postNominalTitles?: Maybe<Scalars['String']>;
  privateChannel: Channel;
  pronouns?: Maybe<Scalars['String']>;
  providerStates: Array<ProviderStateRelationShipModel>;
  providerType?: Maybe<Scalars['String']>;
  qualifications: Array<Scalars['String']>;
  qualifications2: Array<ProviderQualification>;
  role: Role;
  traits: Array<ProviderTrait>;
  twoFactorConfig?: Maybe<TwoFactorConfig>;
  unreadProviderMessagesCount: Scalars['Float'];
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  upcomingAvailability: Array<Availability>;
  upcomingAvailabilityV4: AvailabilityV4;
}

export type ProviderAcceptingNewPatientsArgs = {
  organizationId?: InputMaybe<Scalars['Float']>;
};

export type ProviderInsuranceCredentialsArgs = {
  forState?: InputMaybe<Scalars['String']>;
};

export type ProviderUpcomingAvailabilityArgs = {
  apptType: Scalars['String'];
  careType?: InputMaybe<CareType>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  patientState?: InputMaybe<StateCodes>;
};

export type ProviderUpcomingAvailabilityV4Args = {
  apptType: Scalars['String'];
  careType?: InputMaybe<CareType>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  patientState?: InputMaybe<StateCodes>;
};

export interface ProviderCertification {
  abbreviation: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
}

export interface ProviderCertificationInput {
  abbreviation: Scalars['String'];
  name: Scalars['String'];
}

export interface ProviderEducation {
  abbreviation: Scalars['String'];
  degree: Scalars['String'];
  id: Scalars['Float'];
  university: Scalars['String'];
}

export interface ProviderEducationInput {
  abbreviation: Scalars['String'];
  degree: Scalars['String'];
  university: Scalars['String'];
}

export interface ProviderFeedback {
  careType: CareType;
  createdAt: Scalars['DateTime'];
  optedOut?: Maybe<Scalars['Boolean']>;
  providerId: Scalars['Int'];
  questions?: Maybe<Array<FormSection>>;
  userId: Scalars['Int'];
}

export const ProviderGenderSearch = {
  Man: 'Man',
  NonBinary: 'NonBinary',
  TransgenderMan: 'TransgenderMan',
  TransgenderWoman: 'TransgenderWoman',
  Woman: 'Woman',
} as const;

export type ProviderGenderSearch = typeof ProviderGenderSearch[keyof typeof ProviderGenderSearch];
export interface ProviderIncident {
  appointment: CalendarEvent;
  createdAt: Scalars['DateTime'];
  incident: Scalars['String'];
}

export interface ProviderInOfficeAvailability {
  isOutOfOffice: Scalars['Boolean'];
  nextAvailableDate?: Maybe<Scalars['LocalDate']>;
}

export interface ProviderInsuranceCredential {
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  payer: Payer;
  state: Scalars['String'];
  updatedAt: Scalars['DateTime'];
}

export interface ProviderIsLoggedIn {
  authToken?: Maybe<Scalars['String']>;
  isLoggedIn: Scalars['Boolean'];
}

export interface ProviderNetworkResult {
  /** Number of providers out of payer network */
  outOfNetworkCount: Scalars['Float'];
  providers: Array<Provider>;
  /** The state code if the state is not allowed for the organization and care type. */
  stateNotAllowed?: Maybe<StateCodes>;
}

export interface ProviderQualification {
  abbreviation: Scalars['String'];
  id: Scalars['Float'];
  licences: Array<Scalars['String']>;
  name: Scalars['String'];
  states: Array<Scalars['String']>;
}

export interface ProviderQualificationInput {
  abbreviation: Scalars['String'];
  licences: Array<Scalars['String']>;
  name: Scalars['String'];
  states: Array<Scalars['String']>;
}

export const ProviderServiceBlockedReason = {
  PatientMissingGeoState: 'PatientMissingGeoState',
} as const;

export type ProviderServiceBlockedReason =
  typeof ProviderServiceBlockedReason[keyof typeof ProviderServiceBlockedReason];
export interface ProviderStateAllocationsModel {
  endDate: Scalars['String'];
  id: Scalars['Int'];
  maxHours: Scalars['Float'];
  maxIntakeHours: Scalars['Float'];
  providerState: ProviderStateRelationShipModel;
  providerStateId: Scalars['Int'];
  startDate: Scalars['String'];
}

export interface ProviderStateRelationShipModel {
  id: Scalars['Int'];
  provider: Provider;
  providerId: Scalars['Int'];
  state: StateCodes;
}

export interface ProviderTrait {
  category: TraitCategory;
  values: Array<Scalars['String']>;
}

export interface ProviderTraitInput {
  category: TraitCategory;
  values: Array<Scalars['String']>;
}

export interface Query {
  activeSubscriptions: Array<PaymentSubscription>;
  adminAppointment: CalendarEvent;
  adminAppointmentByCrisisId: MaybeBlockedCalendarEvents;
  adminAppointmentByUser: CalendarEvent;
  adminAppointments: Array<CalendarEvent>;
  adminAppointmentsToday: Array<CalendarEvent>;
  adminAssessment: Assessment;
  adminAssessmentResults: Array<AssessmentResponse>;
  adminAvailability: AvailabilityResult;
  adminDosespotLink?: Maybe<Scalars['String']>;
  adminExistingCalendarEventsInRange: Array<CalendarEvent>;
  /** Find appointments at risk when introducing a new provider availability */
  adminFindApptsAtRisk: Array<CalendarEvent>;
  adminGetAllocations: Array<Allocation>;
  adminGetJob: AsynchronousJob;
  adminGetStateAllocations: Array<ProviderStateAllocationsModel>;
  adminNeedsPatientOutreach: Array<User>;
  adminOrgIntakeAvailability: IntakeAvailabilityResult;
  adminOverviewReport: OrganizationReport;
  adminPatientMonitoring: Array<User>;
  adminProvider: Provider;
  adminScreenUserResult?: Maybe<ScreenUserResult>;
  adminSearchPharmacy: Array<Pharmacy>;
  adminTimeAllocationsToday: Array<Allocation>;
  adminUrgentActionUsers: Array<User>;
  adminUser: User;
  adminUserDemographics?: Maybe<UserDemographics>;
  adminUserNotifications: Array<InAppNotification>;
  adminUserPharmacies: Array<Pharmacy>;
  adminUsersNeedingBooking: Array<User>;
  allEvents: Array<Event>;
  allPayers: Array<Payer>;
  anonymousNavigateFromPatientPortal: AnonymousNavigateFromPatientPortalResult;
  appointment?: Maybe<CalendarEvent>;
  appointments: Array<CalendarEvent>;
  appointmentTemplate: AppointmentTemplate;
  assessmentByKey: Assessment;
  assessmentForRequest: Assessment;
  assessments: Array<AssessmentResponse>;
  authInstructionsForEmail: AuthInstructions;
  availability: AvailabilityResult;
  availableCoachesForEmbedPatient: Array<EmbedCoach>;
  careEligibility: CareEligibility;
  careNavigators: Array<Provider>;
  careRecommendationGetForUser: CareRecommendationResponse;
  careTeamChannels: Array<CareTeamChannel>;
  coachingExecutiveFunctioningGoalsAssessment: Assessment;
  coachingExecutiveFunctioningWellnessAssessment: Assessment;
  coachingGoalsAssessment: Assessment;
  coachingIntakeProgress: CoachingIntakeProgress;
  coachingIntakeProgressV2: CoachingIntakeProgressV2;
  coachingKickoffLink: Scalars['String'];
  coachingWellnessAssessment: Assessment;
  coachOverview: CoachOverview;
  configs: Array<ConfigurationOption>;
  connectNowOrgSessions?: Maybe<PaginatedConnectNowSession>;
  connectNowSessionReport?: Maybe<ConnectNowSessionDocumentOutput>;
  coupons: Array<ServiceCoupon>;
  crisisReportsForOrg: Array<DdhFileSuccess>;
  crisisReportsZip: DdhFileSuccess;
  /** @deprecated Use onboardingHubUser or onboardedHubUser instead. */
  currentHubUser: HubUser;
  currentProvider: Provider;
  currentUser: User;
  customClinicalRecConfigGetForUser: CustomClinicalRecConfig;
  /** Internal debug tooling */
  debugCareFlowsForUser: Array<DebugUserCareFlow>;
  /** Internal debug tooling */
  debugDataSharingConsentsForUser: Array<DataSharingConsent>;
  /** Internal debug tooling */
  debugDataSharingRequestsForUser: Array<DataSharingRequest>;
  doesProviderNeedToReactivateUserCareType?: Maybe<ReactivationType>;
  doesUserNeedToReactivateCareType?: Maybe<UserSponsoredReactivation>;
  /** states in which psychiatry is available for dtc */
  dtcPsychiatryStates: Array<Scalars['String']>;
  /** states in which therapy is available for dtc */
  dtcTherapyStates: Array<Scalars['String']>;
  embedPatient?: Maybe<EmbedPatient>;
  embedPatientCoachingStatus?: Maybe<CoachingStatus>;
  embedPatientIdFromHealthieId?: Maybe<Scalars['Float']>;
  entitlementDefinitions: Array<EntitlementDefinition>;
  events: Array<Event>;
  /** environment specific configuration */
  featureFlags: Array<Scalars['String']>;
  fetchProviderFeedbackTemplate: ProviderFeedback;
  findCareStart: FindCareStartOutput;
  findCareUserHasCompleted: Scalars['Boolean'];
  genericCollegeModal: Array<GenericCollegeModalModel>;
  getAllCompletedSkillsForModule: Array<Scalars['String']>;
  getAllNotesAndFormSubmissionsForUser: Array<MedicalNote>;
  getAllNotesForUser: Array<MedicalNote>;
  getAssessmentLink: Scalars['String'];
  getAssignableSkills: Array<AssignableSkill>;
  getBadgeCount: Scalars['Float'];
  getCareRecommendationStatusForUser: RecommendationStatusForUserResponse;
  getClaim: InsuranceClaim;
  getClaims: Array<InsuranceClaim>;
  getCoachChatMessages: Array<CoachChatMessage>;
  getContract: GetContractResult;
  getEligibilityCarePeriodValues: ContinuityOfCareEligibilityCarePeriodValues;
  getEligibilityResult: ContinuityOfCareEligibilityResult;
  getHubStatusForUser: HubStatusForUser;
  getJumpBackInSkills: Array<JumpBackInSkillData>;
  getMySkillsAssignments: UserSkillAssignments;
  getNoteByUuid: MedicalNote;
  getPortals: Array<PortalKey>;
  getPossibleAssignees: Array<Provider>;
  getSkillAssignmentCountsForUser: SkillAssignmentCountsResponse;
  getSkillsAssignedToUser: UserSkillAssignments;
  getUpNextSkill: GetUpNextSkillResult;
  getUpNextSkillForModule: GetUpNextSkillForModuleResult;
  hasCompletedAnySkill: Scalars['Boolean'];
  hasSkillBeenStarted: Scalars['Boolean'];
  healthieProviderSynced: Scalars['Boolean'];
  hubAppointmentById: HubAppointment;
  hubGetAssessmentLink: Scalars['String'];
  hubOrganizationResources: Array<HubOrganizationResource>;
  /** @deprecated Deprecated use "appointment" with the appt id */
  intakeCall: CalendarEvent;
  interpretationLanguages: InterpretationLanguagesResponse;
  interpretationServices: UserInterpretationServices;
  isConnectNowServiceAvailable: Scalars['Boolean'];
  isLoggedInHub: Scalars['Boolean'];
  isOnDemandServiceAvailable: Scalars['Boolean'];
  isSkillComplete: Scalars['Boolean'];
  latestAssessment: AssessmentResponse;
  listContracts: ListContractsModel;
  loadUnfinishedSkillReflection?: Maybe<UnfinishedSkillReflection>;
  mantraAdmins: Array<Provider>;
  mcpCurrentContractUtilization: McpCurrentContractUtilizationResult;
  medicationsForUser: Array<Prescription>;
  minorConsentStatusForUser: MinorConsentStatusModel;
  navigateFromHubMobileToWeb: Scalars['String'];
  /** @deprecated Replaced by navigateFromHubToPatientPortal2 */
  navigateFromHubToPatientPortal: Scalars['String'];
  navigateFromHubToPatientPortal2: NavigateFromHubToPatientPortalResult;
  navigateFromHubToPatientPortal3: NavigateFromHubToPatientPortalResult3;
  navigateFromPatientPortalToHub: Scalars['String'];
  newClaimsCount: Scalars['Float'];
  nextAppointment?: Maybe<CalendarEvent>;
  nextAssessment?: Maybe<Scalars['DateTime']>;
  nextAvailableAppointment?: Maybe<Scalars['DateTime']>;
  nextHubExternalAppointment?: Maybe<HubExternalAppointment>;
  notifications: Array<InAppNotification>;
  onboardedHubUser: HubUser;
  onboardingHubUser: HubUser;
  onDemandOrgSessions?: Maybe<PaginatedOnDemandSession>;
  organization: Organization;
  organizationAnnouncementsByDays: Array<OrganizationAnnouncement>;
  organizationAnnouncementsByOrgId: Array<OrganizationAnnouncement>;
  organizationCampusTeam?: Maybe<Array<CampusTeamRelationship>>;
  organizationConnectNow?: Maybe<HubConnectNowOutput>;
  organizationCustomHubModule: HubCustomModuleData;
  organizationHandbook: OrganizationHandbook;
  organizationOnDemand?: Maybe<HubOnDemandOutput>;
  organizations: Array<Organization>;
  paginatedIopReferrals: PaginatedIopReferrals;
  paginatedUsers: PaginatedUsers;
  patients: Array<User>;
  patientsMonitoredCount?: Maybe<Scalars['Float']>;
  payers: Array<Payer>;
  permissibleUploadTypes: Array<UploadType>;
  pharmacies: Array<Pharmacy>;
  portalCareRecommendationForUser: CareRecommendation;
  prescriptions: Array<Prescription>;
  /** @deprecated Deprecated use "pricesInCents" resolver */
  prices: Prices;
  pricesInCents: PricesInCents;
  privateChannels: Array<PatientChannel>;
  promotionWindowInDays: Scalars['Float'];
  providerById: Provider;
  /** Current provider's campus team events */
  providerCampusTeamEvents?: Maybe<Array<CampusTeamRelationshipEvents>>;
  providerEmails: Array<SentEmail>;
  providerIsLoggedIn: ProviderIsLoggedIn;
  providerNetworkSearch: ProviderNetworkResult;
  providerOfficeAvailability: ProviderInOfficeAvailability;
  providers: Array<Provider>;
  providerSafetyPlans: Array<SafetyPlan>;
  providersById: Array<Provider>;
  providersForUser: MaybeBlockedProviders;
  referrals: Array<User>;
  remainingHoursForRange: Array<RemainingHours>;
  safetyPlans: Array<SafetyPlan>;
  searchPharmacy: Array<Pharmacy>;
  searchPharmacyByAddress: Array<Pharmacy>;
  searchUsers: Array<User>;
  skillPathwayGetForUser?: Maybe<Array<SkillPathwayStep>>;
  skillPathwayNextSkill: SkillPathwayNextSkill;
  skillReflectionGetAllForUser: UserSkillReflections;
  skillReflectionGetUserAnswers?: Maybe<SkillReflectionAnswers>;
  ticket: Ticket;
  tickets: Array<Ticket>;
  traitCategories: Array<ProviderTrait>;
  unreadCareTeamMessages: Scalars['Float'];
  unreadHubExternalMessages?: Maybe<Array<UnreadHubExternalMessage>>;
  unreadPatientMessages: Scalars['Float'];
  unsubmittedMedicalNotes: Array<MedicalNote>;
  /** number of users who need ID verification */
  unverifiedUserCount: Scalars['Float'];
  upcomingHubAppointment?: Maybe<HubAppointment>;
  userCoachingHealthieStatus: UserCoachingHealthieStatus;
  userCoachingStatus: UserCoachingStatus;
  userDemographics?: Maybe<UserDemographics>;
  userEmails: Array<SentEmail>;
  userHasSso: Scalars['Boolean'];
  userHealthieStatus: UserHealthieStatus;
  userIsLoggedIn: UserIsLoggedIn;
  userOutreachAttempts: Array<OutreachAttemptModel>;
  userReferralStatus: User;
  users: Array<User>;
  userSkillFeedback?: Maybe<SkillFeedbackModel>;
  userSmses: Array<SentSmsModel>;
  usersOrganizations: Array<OrganizationOption>;
  weeklyCapacityUsage: Array<CapacityUsage>;
  zendeskLink: Scalars['String'];
}

export type QueryAdminAppointmentArgs = {
  id: Scalars['Float'];
};

export type QueryAdminAppointmentByCrisisIdArgs = {
  crisisId: Scalars['String'];
};

export type QueryAdminAppointmentByUserArgs = {
  appointmentId?: InputMaybe<Scalars['Float']>;
  userId: Scalars['Float'];
};

export type QueryAdminAppointmentsArgs = {
  allProviders?: InputMaybe<Scalars['Boolean']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  providerId?: InputMaybe<Scalars['Float']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type QueryAdminAssessmentArgs = {
  assessment: AssessmentKey;
  userId: Scalars['Float'];
};

export type QueryAdminAssessmentResultsArgs = {
  userId: Scalars['Float'];
};

export type QueryAdminAvailabilityArgs = {
  appointmentType: Scalars['String'];
  careType: CareType;
  duration: Scalars['Float'];
  end?: InputMaybe<Scalars['String']>;
  patientState?: InputMaybe<StateCodes>;
  providerIds: Array<Scalars['Float']>;
  rescheduleId?: InputMaybe<Scalars['Float']>;
  start: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  userId: Scalars['Float'];
};

export type QueryAdminDosespotLinkArgs = {
  userId: Scalars['Float'];
};

export type QueryAdminExistingCalendarEventsInRangeArgs = {
  apptTypes?: InputMaybe<Array<Scalars['String']>>;
  careTypes?: InputMaybe<Array<CareType>>;
  end?: InputMaybe<Scalars['String']>;
  providerId: Scalars['Float'];
  start: Scalars['String'];
};

export type QueryAdminFindApptsAtRiskArgs = {
  allocations: Array<CreateAllocationInput>;
  date: Scalars['String'];
  providerId: Scalars['Float'];
  timezone: Scalars['String'];
  weekly: Scalars['Boolean'];
};

export type QueryAdminGetAllocationsArgs = {
  providerId: Scalars['Float'];
};

export type QueryAdminGetJobArgs = {
  jobId: Scalars['Float'];
};

export type QueryAdminGetStateAllocationsArgs = {
  providerId: Scalars['Float'];
};

export type QueryAdminOrgIntakeAvailabilityArgs = {
  careType: CareType;
  end?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  patientState?: InputMaybe<StateCodes>;
  providerIds: Array<Scalars['Float']>;
  start: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
};

export type QueryAdminOverviewReportArgs = {
  scope: ReportScope;
};

export type QueryAdminProviderArgs = {
  providerId: Scalars['Float'];
};

export type QueryAdminScreenUserResultArgs = {
  userId: Scalars['Float'];
};

export type QueryAdminSearchPharmacyArgs = {
  query: Scalars['String'];
};

export type QueryAdminUserArgs = {
  id: Scalars['Float'];
};

export type QueryAdminUserDemographicsArgs = {
  id: Scalars['Float'];
};

export type QueryAdminUserNotificationsArgs = {
  userId: Scalars['Float'];
};

export type QueryAdminUserPharmaciesArgs = {
  userId: Scalars['Float'];
};

export type QueryAdminUsersNeedingBookingArgs = {
  careType: CareType;
};

export type QueryAllEventsArgs = {
  before?: InputMaybe<Scalars['DateTime']>;
};

export type QueryAnonymousNavigateFromPatientPortalArgs = {
  input: AnonymousNavigateFromPatientPortalInput;
};

export type QueryAppointmentArgs = {
  id: Scalars['Float'];
};

export type QueryAppointmentTemplateArgs = {
  appointmentType: Scalars['String'];
  careType: Scalars['String'];
};

export type QueryAssessmentByKeyArgs = {
  assessmentKey: AssessmentKey;
};

export type QueryAssessmentForRequestArgs = {
  requestId: Scalars['Float'];
};

export type QueryAuthInstructionsForEmailArgs = {
  app?: InputMaybe<SamlUserApp>;
  email: Scalars['String'];
  mrn?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SamlConfigurationType>;
};

export type QueryAvailabilityArgs = {
  careType?: InputMaybe<Scalars['String']>;
  checkin?: InputMaybe<Scalars['Boolean']>;
  end?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['String']>;
};

export type QueryAvailableCoachesForEmbedPatientArgs = {
  userId: Scalars['Int'];
};

export type QueryCareTeamChannelsArgs = {
  providerId?: InputMaybe<Scalars['Float']>;
};

export type QueryConnectNowOrgSessionsArgs = {
  params: ConnectNowReportsInput;
};

export type QueryConnectNowSessionReportArgs = {
  id: Scalars['String'];
};

export type QueryCrisisReportsZipArgs = {
  ddhFileSuccessId: Scalars['Int'];
};

export type QueryDebugCareFlowsForUserArgs = {
  userId: Scalars['Float'];
};

export type QueryDebugDataSharingConsentsForUserArgs = {
  userId: Scalars['Float'];
};

export type QueryDebugDataSharingRequestsForUserArgs = {
  userId: Scalars['Float'];
};

export type QueryDoesProviderNeedToReactivateUserCareTypeArgs = {
  careType: CareType;
  userId: Scalars['Float'];
};

export type QueryEmbedPatientArgs = {
  patientId: Scalars['Float'];
};

export type QueryEmbedPatientCoachingStatusArgs = {
  userId: Scalars['Int'];
};

export type QueryEmbedPatientIdFromHealthieIdArgs = {
  healthieId: Scalars['String'];
};

export type QueryEventsArgs = {
  userId: Scalars['Float'];
};

export type QueryFetchProviderFeedbackTemplateArgs = {
  careType: Scalars['String'];
  feedbackType: FeedbackType;
  providerId: Scalars['Int'];
};

export type QueryFindCareStartArgs = {
  questionnaireKey: Scalars['String'];
};

export type QueryGenericCollegeModalArgs = {
  orgId: Scalars['Float'];
};

export type QueryGetAllCompletedSkillsForModuleArgs = {
  moduleId: Scalars['String'];
};

export type QueryGetAllNotesAndFormSubmissionsForUserArgs = {
  userId: Scalars['Float'];
};

export type QueryGetAllNotesForUserArgs = {
  userId: Scalars['Float'];
};

export type QueryGetAssignableSkillsArgs = {
  userId: Scalars['Float'];
};

export type QueryGetClaimArgs = {
  id: Scalars['Float'];
};

export type QueryGetClaimsArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type QueryGetCoachChatMessagesArgs = {
  coachingProgramId?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};

export type QueryGetContractArgs = {
  id: Scalars['Float'];
};

export type QueryGetEligibilityCarePeriodValuesArgs = {
  submissionId: Scalars['Float'];
};

export type QueryGetEligibilityResultArgs = {
  submissionId: Scalars['Float'];
};

export type QueryGetMySkillsAssignmentsArgs = {
  page: Scalars['Float'];
  perPage: Scalars['Float'];
};

export type QueryGetNoteByUuidArgs = {
  uuid: Scalars['String'];
};

export type QueryGetPossibleAssigneesArgs = {
  ticketId?: InputMaybe<Scalars['Float']>;
  userId: Scalars['Float'];
};

export type QueryGetSkillsAssignedToUserArgs = {
  page: Scalars['Float'];
  perPage: Scalars['Float'];
  userId: Scalars['Float'];
};

export type QueryGetUpNextSkillArgs = {
  isPathway: Scalars['Boolean'];
  moduleId: Scalars['String'];
  skillId: Scalars['String'];
};

export type QueryGetUpNextSkillForModuleArgs = {
  moduleId: Scalars['String'];
};

export type QueryHasSkillBeenStartedArgs = {
  input: SkillCompletionInputModel;
};

export type QueryHealthieProviderSyncedArgs = {
  id: Scalars['Float'];
};

export type QueryHubAppointmentByIdArgs = {
  appointmentId: Scalars['String'];
};

export type QueryIsSkillCompleteArgs = {
  input: SkillCompletionInputModel;
};

export type QueryLatestAssessmentArgs = {
  key: AssessmentKey;
};

export type QueryListContractsArgs = {
  organizationId: Scalars['Float'];
};

export type QueryLoadUnfinishedSkillReflectionArgs = {
  skillId: Scalars['String'];
};

export type QueryMedicationsForUserArgs = {
  userId: Scalars['Float'];
};

export type QueryMinorConsentStatusForUserArgs = {
  userId: Scalars['Int'];
};

export type QueryNavigateFromHubMobileToWebArgs = {
  source?: InputMaybe<Scalars['String']>;
};

export type QueryNavigateFromHubToPatientPortalArgs = {
  mrn?: InputMaybe<Scalars['String']>;
};

export type QueryNavigateFromHubToPatientPortal2Args = {
  mrn?: InputMaybe<Scalars['String']>;
};

export type QueryNavigateFromHubToPatientPortal3Args = {
  mrn?: InputMaybe<Scalars['String']>;
};

export type QueryNextAvailableAppointmentArgs = {
  appointmentType: Scalars['String'];
  careType: CareType;
  organizationId: Scalars['Float'];
  startDate: Scalars['String'];
  userId?: InputMaybe<Scalars['Float']>;
};

export type QueryOnDemandOrgSessionsArgs = {
  params: OnDemandReportsInput;
};

export type QueryOrganizationArgs = {
  id: Scalars['Float'];
};

export type QueryOrganizationAnnouncementsByDaysArgs = {
  days?: InputMaybe<Scalars['Float']>;
  organizationId: Scalars['Float'];
};

export type QueryOrganizationAnnouncementsByOrgIdArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  organizationId: Scalars['Float'];
};

export type QueryOrganizationCampusTeamArgs = {
  organizationId: Scalars['Float'];
};

export type QueryOrganizationHandbookArgs = {
  organizationId: Scalars['Float'];
};

export type QueryPaginatedIopReferralsArgs = {
  params: PaginatedIopReferralInput;
};

export type QueryPaginatedUsersArgs = {
  params: UserListParams;
};

export type QueryPatientsArgs = {
  providerId: Scalars['Float'];
};

export type QueryPrivateChannelsArgs = {
  providerId?: InputMaybe<Scalars['Float']>;
};

export type QueryProviderByIdArgs = {
  id: Scalars['Float'];
};

export type QueryProviderEmailsArgs = {
  providerId: Scalars['Float'];
};

export type QueryProviderNetworkSearchArgs = {
  careType: CareType;
  doesOrgUseDGMOrSessions?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<Array<ProviderGenderSearch>>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  payerId?: InputMaybe<Scalars['Float']>;
  state?: InputMaybe<StateCodes>;
  traits?: InputMaybe<Array<ProviderTraitInput>>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type QueryProviderOfficeAvailabilityArgs = {
  providerId: Scalars['Int'];
};

export type QueryProvidersArgs = {
  roles?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryProviderSafetyPlansArgs = {
  incomplete?: InputMaybe<Scalars['Boolean']>;
};

export type QueryProvidersByIdArgs = {
  ids: Array<Scalars['Int']>;
};

export type QueryProvidersForUserArgs = {
  userId?: InputMaybe<Scalars['Float']>;
};

export type QueryRemainingHoursForRangeArgs = {
  appointmentType: Scalars['String'];
  careType: CareType;
  end: Scalars['String'];
  organizationId: Scalars['Float'];
  start: Scalars['String'];
};

export type QuerySafetyPlansArgs = {
  complete?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySearchPharmacyArgs = {
  query: Scalars['String'];
};

export type QuerySearchPharmacyByAddressArgs = {
  address: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
};

export type QuerySearchUsersArgs = {
  search: SearchUserInput;
};

export type QuerySkillReflectionGetAllForUserArgs = {
  page: Scalars['Float'];
  perPage: Scalars['Float'];
};

export type QuerySkillReflectionGetUserAnswersArgs = {
  reflectionId: Scalars['Float'];
};

export type QueryTicketArgs = {
  id: Scalars['Float'];
};

export type QueryTicketsArgs = {
  userId?: InputMaybe<Scalars['Float']>;
  view: TicketView;
};

export type QueryUpcomingHubAppointmentArgs = {
  delay?: InputMaybe<Scalars['Float']>;
};

export type QueryUserCoachingHealthieStatusArgs = {
  userId: Scalars['Float'];
};

export type QueryUserEmailsArgs = {
  userId: Scalars['Int'];
};

export type QueryUserHealthieStatusArgs = {
  userId: Scalars['Float'];
};

export type QueryUserOutreachAttemptsArgs = {
  userId: Scalars['Int'];
};

export type QueryUserReferralStatusArgs = {
  userId: Scalars['Float'];
};

export type QueryUsersArgs = {
  includeNewPatients?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Float']>;
};

export type QueryUserSkillFeedbackArgs = {
  skillId: Scalars['String'];
};

export type QueryUserSmsesArgs = {
  userId: Scalars['Int'];
};

export type QueryUsersOrganizationsArgs = {
  params: UserListParams;
};

export type QueryWeeklyCapacityUsageArgs = {
  organizationId?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['Int']>;
  startTime: Scalars['DateTime'];
  weeks: Scalars['Int'];
};

export type QueryZendeskLinkArgs = {
  userId: Scalars['Float'];
};

export interface Question {
  caption?: Maybe<Scalars['String']>;
  dependency?: Maybe<Dependency>;
  disclaimer?: Maybe<Scalars['String']>;
  examples?: Maybe<Array<Scalars['String']>>;
  isOptional?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  legend?: Maybe<Array<Scalars['String']>>;
  manualInput?: Maybe<ManualInput>;
  options?: Maybe<Array<Option>>;
  overrideDescription?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  preText?: Maybe<Scalars['String']>;
  subText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
}

export interface Questionnaire {
  description?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  preScreen?: Maybe<QuestionnairePreScreen>;
  questions: Array<Question>;
  title: Scalars['String'];
}

export interface QuestionnairePreScreen {
  asset?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<SectionCopy>;
  title?: Maybe<Scalars['String']>;
}

export interface QuestionnaireResult {
  answers: Array<Answer>;
  assessment: AssessmentResponse;
  description: Scalars['String'];
  key: Scalars['String'];
  maximumScore?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  symptoms?: Maybe<Scalars['String']>;
}

export interface QuestionnaireSubmission {
  answers: Array<AnswerSubmission>;
  key: Scalars['String'];
}

export const ReactivationType = {
  Confirm: 'confirm',
  Reactivate: 'reactivate',
} as const;

export type ReactivationType = typeof ReactivationType[keyof typeof ReactivationType];
export const RecommendationOptions = {
  AssessmentVisit: 'assessmentVisit',
  Coaching: 'coaching',
  CustomClinicalRec: 'customClinicalRec',
  Therapy: 'therapy',
} as const;

export type RecommendationOptions =
  typeof RecommendationOptions[keyof typeof RecommendationOptions];
export interface RecommendationStatusForUserResponse {
  activeRecommendation: Scalars['Boolean'];
  expiration?: Maybe<Scalars['DateTime']>;
  isActiveOrOnboardingInCare: Scalars['Boolean'];
  isActiveOrOnboardingInTherapy: Scalars['Boolean'];
}

export const ReenrollmentStatus = {
  Cancelled: 'Cancelled',
  Confirmed: 'Confirmed',
  Declined: 'Declined',
  Pending: 'Pending',
} as const;

export type ReenrollmentStatus = typeof ReenrollmentStatus[keyof typeof ReenrollmentStatus];
export interface ReferralError {
  errorCode: ReferralErrorCode;
  message: Scalars['String'];
}

export const ReferralErrorCode = {
  InvalidEmail: 'InvalidEmail',
  InvalidOrgEmail: 'InvalidOrgEmail',
} as const;

export type ReferralErrorCode = typeof ReferralErrorCode[keyof typeof ReferralErrorCode];
export const ReferralPartners = {
  CharlieHealth: 'charlie_health',
  Togetherall: 'togetherall',
} as const;

export type ReferralPartners = typeof ReferralPartners[keyof typeof ReferralPartners];
/** App where the user originated */
export const ReferralSource = {
  Mcp: 'MCP',
  Oz: 'OZ',
  Portal: 'PORTAL',
} as const;

export type ReferralSource = typeof ReferralSource[keyof typeof ReferralSource];
export interface ReflectionPagination {
  page: Scalars['Float'];
  totalPages: Scalars['Float'];
}

export interface RemainingHours {
  date: Scalars['String'];
  dgmAllocationId?: Maybe<Scalars['Int']>;
  remainingHours: Scalars['Float'];
}

/** Scope of report generated */
export const ReportScope = {
  All: 'ALL',
  Dtc: 'DTC',
  Org: 'ORG',
} as const;

export type ReportScope = typeof ReportScope[keyof typeof ReportScope];
export interface RequiredIf {
  dependentQuestions: Array<DependentQuestions>;
}

export const ResourceTag = {
  ByAppointment: 'ByAppointment',
  InPerson: 'InPerson',
  NonEmergencies: 'NonEmergencies',
  Open247: 'Open247',
  Telehealth: 'Telehealth',
} as const;

export type ResourceTag = typeof ResourceTag[keyof typeof ResourceTag];
export const ResourceType = {
  AcademicAndAccessibility: 'AcademicAndAccessibility',
  Additional: 'Additional',
  DisabilityAccessibilityCenter: 'DisabilityAccessibilityCenter',
  Diversity: 'Diversity',
  Emergency: 'Emergency',
  Health: 'Health',
  HealthServices: 'HealthServices',
  LgbtqiaCenter: 'LgbtqiaCenter',
  LocalHospitalCommunityProvider: 'LocalHospitalCommunityProvider',
  Other: 'Other',
  PreferredCampusPharmacy: 'PreferredCampusPharmacy',
  PublicSafetyCampusPolice: 'PublicSafetyCampusPolice',
  ResidenceLife: 'ResidenceLife',
  Safety: 'Safety',
  UrgentCareCenter: 'UrgentCareCenter',
} as const;

export type ResourceType = typeof ResourceType[keyof typeof ResourceType];
export const Role = {
  Admin: 'admin',
  Patient: 'patient',
  ProductAdmin: 'productAdmin',
  Provider: 'provider',
  Supreme: 'supreme',
  UniversityAdmin: 'universityAdmin',
  UniversityReferrer: 'universityReferrer',
  UniversityStaff: 'universityStaff',
  UniversitySupportingClinician: 'universitySupportingClinician',
} as const;

export type Role = typeof Role[keyof typeof Role];
export interface SafetyInfo {
  commonSideEffects: Array<SideEffect>;
  faq: Array<FaQuestion>;
  potentiallySeriousSideEffects: Array<SideEffect>;
  warning: Scalars['String'];
}

export interface SafetyPlan {
  activatingProvider: Provider;
  complete: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  data: SafetyPlanData;
  editHistory: Array<Event>;
  id: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  user: User;
}

export interface SafetyPlanContact {
  address?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  name: Scalars['String'];
}

export interface SafetyPlanData {
  copingStrategies: Array<Scalars['String']>;
  crisisContacts: Array<SafetyPlanContact>;
  crisisFacilities: Array<SafetyPlanContact>;
  crisisResources: Array<SafetyPlanContact>;
  dangerousMeans?: Maybe<Scalars['Boolean']>;
  dangerousMeansList?: Maybe<Scalars['String']>;
  dangerousMeansPlan?: Maybe<Scalars['String']>;
  distractions: Array<SafetyPlanContact>;
  firearmAccess?: Maybe<Scalars['Boolean']>;
  firearmAccessPlan?: Maybe<Scalars['String']>;
  professionalContacts: Array<SafetyPlanContact>;
  reasonToLive?: Maybe<Scalars['String']>;
  warningSigns: Array<Scalars['String']>;
}

export interface SafetyPlanInput {
  copingStrategies: Array<Scalars['String']>;
  crisisContacts: Array<SafetyPlanQuestionInput>;
  crisisFacilities: Array<SafetyPlanQuestionInput>;
  crisisResources: Array<SafetyPlanQuestionInput>;
  dangerousMeans?: InputMaybe<Scalars['Boolean']>;
  dangerousMeansList?: InputMaybe<Scalars['String']>;
  dangerousMeansPlan?: InputMaybe<Scalars['String']>;
  distractions: Array<SafetyPlanQuestionInput>;
  firearmAccess?: InputMaybe<Scalars['Boolean']>;
  firearmAccessPlan?: InputMaybe<Scalars['String']>;
  professionalContacts: Array<SafetyPlanQuestionInput>;
  reasonToLive?: InputMaybe<Scalars['String']>;
  warningSigns: Array<Scalars['String']>;
}

export interface SafetyPlanQuestionInput {
  address?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
}

export interface SamlConfiguration {
  authnRequestBinding?: Maybe<Scalars['String']>;
  automaticallyUpdateEmail: Scalars['Boolean'];
  campusOrgMappings?: Maybe<Array<CampusOrgMappingsInput>>;
  certificates: Array<Scalars['String']>;
  disableRequestedAuthnContext: Scalars['Boolean'];
  enableManualUniqueIdEdits: Scalars['Boolean'];
  entryPoint: Scalars['String'];
  id: Scalars['Int'];
  issuer?: Maybe<Scalars['String']>;
  metadata: Scalars['String'];
  profileCampusField?: Maybe<Scalars['String']>;
  profileEmailField: Scalars['String'];
  profileMatchingField: Scalars['String'];
  profileUniqueIdField?: Maybe<Scalars['String']>;
}

export const SamlConfigurationType = {
  Provider: 'Provider',
  User: 'User',
} as const;

export type SamlConfigurationType =
  typeof SamlConfigurationType[keyof typeof SamlConfigurationType];
export const SamlUserApp = {
  Hub: 'Hub',
  HubAndroid: 'HubAndroid',
  HubiOs: 'HubiOS',
} as const;

export type SamlUserApp = typeof SamlUserApp[keyof typeof SamlUserApp];
export interface SatisfactionRating {
  averageRating?: Maybe<Scalars['Float']>;
  numberOfRatings: Scalars['Float'];
}

export interface ScreenUserResult {
  screenedOut: Scalars['Boolean'];
  screenedOutFromHub: Scalars['Boolean'];
}

export interface ScreenUserResultInput {
  screenedOut: Scalars['Boolean'];
  screenedOutFromHub?: InputMaybe<Scalars['Boolean']>;
}

export interface SearchUserInput {
  careStatus?: InputMaybe<CareStatus>;
  createdAfter?: InputMaybe<Scalars['DateTime']>;
  createdBefore?: InputMaybe<Scalars['DateTime']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  value?: InputMaybe<Scalars['String']>;
}

export const SectionColor = {
  BrandAltBg: 'brandAltBg',
  DangerBg: 'dangerBg',
  GreyBg: 'greyBg',
} as const;

export type SectionColor = typeof SectionColor[keyof typeof SectionColor];
export type SectionCopy = ListCopy | TextCopy;

/** Change behavior based on another section */
export interface SectionDependency {
  key: Scalars['String'];
  /** only show if question has values */
  showValues?: Maybe<Array<Scalars['String']>>;
}

export const SectionFormat = {
  Appointment: 'appointment',
  Checkbox: 'checkbox',
  Date: 'date',
  Diagnosis: 'diagnosis',
  Formtexttitle: 'formtexttitle',
  Multicheckbox: 'multicheckbox',
  Multidiagnosis: 'multidiagnosis',
  Multiselect: 'multiselect',
  Multitext: 'multitext',
  Radio: 'radio',
  Rx: 'rx',
  Scale: 'scale',
  Select: 'select',
  Sign: 'sign',
  Singlelineinput: 'singlelineinput',
  Textarea: 'textarea',
  Title: 'title',
} as const;

export type SectionFormat = typeof SectionFormat[keyof typeof SectionFormat];
export interface SectionHook {
  targetKey: Scalars['String'];
  type: HookType;
}

/** Auto-select a value based on another value */
export interface SelectDependentValue {
  /** trigger selection if one of these values */
  dependsValues?: Maybe<Array<Scalars['String']>>;
  targetKey: Scalars['String'];
  /** the value to be selected */
  targetSelectValue?: Maybe<Scalars['String']>;
}

export interface SendCoachChatMessageResult {
  id?: Maybe<Scalars['String']>;
}

export interface SentEmail {
  bounced: Scalars['Boolean'];
  clicked: Scalars['Boolean'];
  delivered: Scalars['Boolean'];
  id: Scalars['Float'];
  label: Scalars['String'];
  opened: Scalars['Boolean'];
  outcome?: Maybe<Scalars['String']>;
  sent: Scalars['Boolean'];
  sentAt: Scalars['DateTime'];
  subject: Scalars['String'];
  template: Scalars['String'];
  unsubscribed: Scalars['Boolean'];
}

export interface SentSmsModel {
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['Int'];
  template?: Maybe<Scalars['String']>;
}

export interface ServiceCoupon {
  active: Scalars['Boolean'];
  autoApplyOnConversion: Scalars['Boolean'];
  careTypes: Array<CareType>;
  code: Scalars['String'];
  discountType: DiscountType;
  id: Scalars['Float'];
  maxUses: Scalars['Float'];
  organization?: Maybe<Organization>;
  percentDiscount?: Maybe<Scalars['Float']>;
  replacePriceCents?: Maybe<Scalars['Int']>;
}

export interface SetPasswordWithTokenError {
  errorCode: SetPasswordWithTokenErrorCode;
  message: Scalars['String'];
}

export const SetPasswordWithTokenErrorCode = {
  InvalidPasswordFormat: 'InvalidPasswordFormat',
  InvalidToken: 'InvalidToken',
  TokenExpired: 'TokenExpired',
} as const;

export type SetPasswordWithTokenErrorCode =
  typeof SetPasswordWithTokenErrorCode[keyof typeof SetPasswordWithTokenErrorCode];
export type SetPasswordWithTokenResult = SetPasswordWithTokenError | SetPasswordWithTokenSuccess;

export interface SetPasswordWithTokenSuccess {
  success: Scalars['Boolean'];
}

export interface SideEffect {
  explanation: Scalars['String'];
  title: Scalars['String'];
}

export interface SignedS3Upload {
  fields: Array<Array<Scalars['String']>>;
  key: Scalars['String'];
  url: Scalars['String'];
}

export interface SignUpError {
  errorCode: SignUpErrorCode;
  message: Scalars['String'];
}

export const SignUpErrorCode = {
  CampusReferralRequired: 'CampusReferralRequired',
  EligibilityError: 'EligibilityError',
  InvalidPassword: 'InvalidPassword',
  InvalidToken: 'InvalidToken',
  TokenExpired: 'TokenExpired',
  UnknownPartner: 'UnknownPartner',
} as const;

export type SignUpErrorCode = typeof SignUpErrorCode[keyof typeof SignUpErrorCode];
export type SignUpResult = SignUpError | SignUpSuccess;

export interface SignUpSuccess {
  successMessage: Scalars['String'];
}

export interface SimpleGlobalAvailabilityBlocker {
  date: Scalars['LocalDate'];
  providerId: Scalars['Float'];
  type: GlobalAvailabilityBlockerType;
}

export interface SkillAssignmentCountsResponse {
  notCompleted: Scalars['Int'];
  notSeen: Scalars['Int'];
}

export interface SkillAssignmentModel {
  assignedById: Scalars['Int'];
  assignerHasSeen: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  moduleId: Scalars['String'];
  skillId: Scalars['String'];
  skillReflection?: Maybe<SkillReflectionAnswers>;
  skillTitle: Scalars['String'];
  startedAt?: Maybe<Scalars['DateTime']>;
  userHasSeen: Scalars['Boolean'];
  userId: Scalars['Int'];
}

export interface SkillCompletionInputModel {
  moduleId: Scalars['String'];
  sectionId?: InputMaybe<Scalars['String']>;
  skillId: Scalars['String'];
}

export interface SkillFeedbackModel {
  additionalFeedback?: Maybe<Scalars['String']>;
  rating: Scalars['Float'];
}

export interface SkillFeedbackQuestionModelInput {
  questionSlug: Scalars['String'];
  response: Scalars['Float'];
}

export interface SkillPathwayAnswer {
  moduleId: Scalars['String'];
  nudgeIds: Array<Scalars['String']>;
  score: Scalars['Float'];
  sectionId: Scalars['String'];
}

export interface SkillPathwayNextSkill {
  hasPathway: Scalars['Boolean'];
  moduleId?: Maybe<Scalars['String']>;
  nextSkillId?: Maybe<Scalars['String']>;
  sectionId?: Maybe<Scalars['String']>;
}

export interface SkillPathwayNudge {
  id: Scalars['String'];
  name: Scalars['String'];
}

export interface SkillPathwayStep {
  moduleId: Scalars['String'];
  nudges: Array<SkillPathwayNudge>;
  score: Scalars['Float'];
  sectionId: Scalars['String'];
}

export interface SkillReflection {
  id: Scalars['Float'];
  isComplete: Scalars['Boolean'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
}

export interface SkillReflectionAnswers {
  id: Scalars['Float'];
  questions: Array<SkillReflectionQuestion>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
}

export interface SkillReflectionQuestion {
  answer: Scalars['String'];
  question: Scalars['String'];
  questionKey: Scalars['String'];
}

export const SortDirection = {
  Ascending: 'Ascending',
  Descending: 'Descending',
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];
export interface SpecialConditions {
  bipolarSchizophrenia: Scalars['Boolean'];
  suicidal: Scalars['Boolean'];
}

export const SponsoredSessionsLimitType = {
  ShortTermCapped: 'shortTermCapped',
  ShortTermUncapped: 'shortTermUncapped',
  StudentAssistanceProgram: 'studentAssistanceProgram',
  Unrestricted: 'unrestricted',
} as const;

export type SponsoredSessionsLimitType =
  typeof SponsoredSessionsLimitType[keyof typeof SponsoredSessionsLimitType];
export const StateCodes = {
  Ak: 'AK',
  Al: 'AL',
  Ar: 'AR',
  As: 'AS',
  Az: 'AZ',
  Ca: 'CA',
  Co: 'CO',
  Ct: 'CT',
  Dc: 'DC',
  De: 'DE',
  Fl: 'FL',
  Fm: 'FM',
  Ga: 'GA',
  Gu: 'GU',
  Hi: 'HI',
  Ia: 'IA',
  Id: 'ID',
  Il: 'IL',
  In: 'IN',
  Ks: 'KS',
  Ky: 'KY',
  La: 'LA',
  Ma: 'MA',
  Md: 'MD',
  Me: 'ME',
  Mh: 'MH',
  Mi: 'MI',
  Mn: 'MN',
  Mo: 'MO',
  Mp: 'MP',
  Ms: 'MS',
  Mt: 'MT',
  Nc: 'NC',
  Nd: 'ND',
  Ne: 'NE',
  Nh: 'NH',
  Nj: 'NJ',
  Nm: 'NM',
  Nv: 'NV',
  Ny: 'NY',
  Oh: 'OH',
  Ok: 'OK',
  Or: 'OR',
  Pa: 'PA',
  Pr: 'PR',
  Pw: 'PW',
  Ri: 'RI',
  Sc: 'SC',
  Sd: 'SD',
  Tn: 'TN',
  Tx: 'TX',
  Ut: 'UT',
  Va: 'VA',
  Vi: 'VI',
  Vt: 'VT',
  Wa: 'WA',
  Wi: 'WI',
  Wv: 'WV',
  Wy: 'WY',
} as const;

export type StateCodes = typeof StateCodes[keyof typeof StateCodes];
export type StudentHubReferralResult = ReferralError | StudentHubReferralSuccess;

export interface StudentHubReferralSuccess {
  successMessage: Scalars['String'];
}

export interface Subscription {
  coachChatUpdates: CoachChatUpdate;
  portalUpdates: Array<PortalKey>;
}

export type SubscriptionCoachChatUpdatesArgs = {
  coachingProgramId?: InputMaybe<Scalars['Float']>;
};

/** The different kinds of suicidal thoughts */
export const SuicidalThoughts = {
  DuringMyLifetime: 'DURING_MY_LIFETIME',
  Never: 'NEVER',
  WithinThePastMonth: 'WITHIN_THE_PAST_MONTH',
  WithinThePastYear: 'WITHIN_THE_PAST_YEAR',
} as const;

export type SuicidalThoughts = typeof SuicidalThoughts[keyof typeof SuicidalThoughts];
export interface Task {
  blueprintId: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  done: Scalars['Boolean'];
  id: Scalars['Int'];
  ref: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['Float'];
}

export const TaskStatusFilter = {
  Cancelled: 'Cancelled',
  Discharged: 'Discharged',
  Intake: 'Intake',
  OngoingCare: 'OngoingCare',
  OnHold: 'OnHold',
  ScreenedOut: 'ScreenedOut',
} as const;

export type TaskStatusFilter = typeof TaskStatusFilter[keyof typeof TaskStatusFilter];
export interface TestVideoSession {
  videoSessionId: Scalars['String'];
  videoToken: Scalars['String'];
}

export interface TextCopy {
  showInView?: Maybe<Scalars['Boolean']>;
  style?: Maybe<TextStyle>;
  text: Scalars['String'];
}

export const TextKind = {
  Danger: 'danger',
} as const;

export type TextKind = typeof TextKind[keyof typeof TextKind];
export interface TextStyle {
  italic?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<TextKind>;
  tag?: Maybe<TextTag>;
}

export const TextTag = {
  Body: 'body',
  BodyBold: 'bodyBold',
  BodyGrey: 'bodyGrey',
  Bodysmall: 'bodysmall',
  BodySmallBold: 'bodySmallBold',
  Caption: 'caption',
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  Label: 'label',
} as const;

export type TextTag = typeof TextTag[keyof typeof TextTag];
export interface Ticket {
  assignee?: Maybe<Provider>;
  assignOptions: TicketAssignOption;
  blockedReason?: Maybe<Scalars['String']>;
  canEdit: Scalars['Boolean'];
  canResolve: Scalars['Boolean'];
  category: TicketCategory;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  events: Array<Event>;
  id: Scalars['Int'];
  priority?: Maybe<TicketPriority>;
  requester?: Maybe<Provider>;
  resolveIn24Hrs?: Maybe<Scalars['Boolean']>;
  status: TicketStatus;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
}

export const TicketAssignOption = {
  Any: 'Any',
  None: 'None',
  Self: 'Self',
} as const;

export type TicketAssignOption = typeof TicketAssignOption[keyof typeof TicketAssignOption];
export const TicketCategory = {
  CloseCareCancellation: 'closeCareCancellation',
  CloseCareDischarge: 'closeCareDischarge',
  Coc: 'coc',
  DatasharingCoc: 'datasharingCoc',
  DatasharingRoi: 'datasharingRoi',
  InformationalContinuityOfCare: 'informationalContinuityOfCare',
  InformationalGeneral: 'informationalGeneral',
  Insurance: 'insurance',
  Labs: 'labs',
  OnHold: 'onHold',
  Other: 'other',
  Outreach: 'outreach',
  OutreachGeneral: 'outreachGeneral',
  OutreachStudentDisengagement: 'outreachStudentDisengagement',
  OutreachStudentNoShow: 'outreachStudentNoShow',
  PriorAuth: 'priorAuth',
  ProviderDefaultScheduleChange: 'providerDefaultScheduleChange',
  ProviderGeneralTimeOff: 'providerGeneralTimeOff',
  ProviderScheduleAdjustment: 'providerScheduleAdjustment',
  ReferralAdhdEvaluation: 'referralADHDEvaluation',
  ReferralExternalPsychiatry: 'referralExternalPsychiatry',
  ReferralExternalTherapy: 'referralExternalTherapy',
  ReferralInternalCoaching: 'referralInternalCoaching',
  ReferralInternalPsychiatry: 'referralInternalPsychiatry',
  ReferralInternalTherapy: 'referralInternalTherapy',
  ReferralIop: 'referralIOP',
  ReferralOther: 'referralOther',
  ReferralPsychiatry: 'referralPsychiatry',
  ReferralTherapy: 'referralTherapy',
  ReleaseOfInformationAccommodationLetter: 'releaseOfInformationAccommodationLetter',
  ReleaseOfInformationStudent: 'releaseOfInformationStudent',
  ReleaseOfInformationSummaryOfTreatmentLetter: 'releaseOfInformationSummaryOfTreatmentLetter',
  ReleaseOfInformationThirdParty: 'releaseOfInformationThirdParty',
  RxFax: 'rxFax',
  RxPriorAuth: 'rxPriorAuth',
  RxQuestion: 'rxQuestion',
  RxRefill: 'rxRefill',
  RxSideEffect: 'rxSideEffect',
  Scheduling: 'scheduling',
  SchedulingProviderDefaultScheduleChangeInPolicy:
    'schedulingProviderDefaultScheduleChangeInPolicy',
  SchedulingProviderDefaultScheduleChangeOutOfPolicy:
    'schedulingProviderDefaultScheduleChangeOutOfPolicy',
  SchedulingProviderEmergencyTimeOff: 'schedulingProviderEmergencyTimeOff',
  SchedulingProviderGeneralTimeOffInPolicy: 'schedulingProviderGeneralTimeOffInPolicy',
  SchedulingProviderGeneralTimeOffOutOfPolicy: 'schedulingProviderGeneralTimeOffOutOfPolicy',
  SchedulingProviderScheduleAdjustmentInPolicy: 'schedulingProviderScheduleAdjustmentInPolicy',
  SchedulingProviderScheduleAdjustmentOutOfPolicy:
    'schedulingProviderScheduleAdjustmentOutOfPolicy',
  SchedulingStudentAppointment: 'schedulingStudentAppointment',
  SelfPayBilling: 'selfPayBilling',
  SelfPayPsychiatry: 'selfPayPsychiatry',
  SelfPayTherapy: 'selfPayTherapy',
  TechSupport: 'techSupport',
  TechSupportAudioVideoIssue: 'techSupportAudioVideoIssue',
  TechSupportDocumentationIssue: 'techSupportDocumentationIssue',
  TechSupportEmailPasswordReset: 'techSupportEmailPasswordReset',
  TechSupportGeneral: 'techSupportGeneral',
  TechSupportOzPasswordReset: 'techSupportOzPasswordReset',
  TechSupportSlackPasswordReset: 'techSupportSlackPasswordReset',
  TechSupportStudentAssistance: 'techSupportStudentAssistance',
  TransferOfCareCoaching: 'transferOfCareCoaching',
  TransferOfCarePsychiatry: 'transferOfCarePsychiatry',
  TransferOfCareTherapy: 'transferOfCareTherapy',
} as const;

export type TicketCategory = typeof TicketCategory[keyof typeof TicketCategory];
export const TicketPriority = {
  High: 'High',
  Low: 'Low',
  Medium: 'Medium',
} as const;

export type TicketPriority = typeof TicketPriority[keyof typeof TicketPriority];
export const TicketStatus = {
  Active: 'Active',
  Blocked: 'Blocked',
  Complete: 'Complete',
  Deleted: 'Deleted',
} as const;

export type TicketStatus = typeof TicketStatus[keyof typeof TicketStatus];
export const TicketView = {
  Active: 'Active',
  AssignedToMe: 'AssignedToMe',
  Blocked: 'Blocked',
  New: 'New',
  Resolved: 'Resolved',
  Unresolved: 'Unresolved',
} as const;

export type TicketView = typeof TicketView[keyof typeof TicketView];
export const TimeAllocationPurpose = {
  Admin: 'Admin',
  Availability: 'Availability',
  TimeOff: 'TimeOff',
} as const;

export type TimeAllocationPurpose =
  typeof TimeAllocationPurpose[keyof typeof TimeAllocationPurpose];
export const TraitCategory = {
  Ethnicity: 'Ethnicity',
  Faith: 'Faith',
  Languages: 'Languages',
  Sexuality: 'Sexuality',
  Specialties: 'Specialties',
  TherapyTypes: 'TherapyTypes',
} as const;

export type TraitCategory = typeof TraitCategory[keyof typeof TraitCategory];
export interface TriageInfo {
  answers: Array<Answer>;
  assessmentId: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
}

/** Copy triggered on specific answers */
export interface TriggeredCopy {
  background?: Maybe<SectionColor>;
  border?: Maybe<SectionColor>;
  copy: Array<SectionCopy>;
  /** If depends on another key */
  dependsKey?: Maybe<Scalars['String']>;
  triggers: Array<Scalars['String']>;
}

export interface TwoFactorAuthResult {
  authToken?: Maybe<Scalars['String']>;
  codeValid: Scalars['Boolean'];
  deviceToken?: Maybe<Scalars['String']>;
}

export interface TwoFactorConfig {
  enabled: Scalars['Boolean'];
  id: Scalars['Float'];
  phone: Scalars['String'];
  phoneVerified: Scalars['Boolean'];
}

export interface UnfinishedSkillReflection {
  isComplete: Scalars['Boolean'];
  questions: Array<SkillReflectionQuestion>;
}

export const UniversityRelationship = {
  UniversityCollaborator: 'universityCollaborator',
  UniversityMonitor: 'universityMonitor',
} as const;

export type UniversityRelationship =
  typeof UniversityRelationship[keyof typeof UniversityRelationship];
export interface UnreadHubExternalMessage {
  mrn: Scalars['String'];
  totalCount: Scalars['Float'];
}

export interface UpdateCoupon {
  active: Scalars['Boolean'];
  autoApplyOnConversion: Scalars['Boolean'];
  careTypes: Array<CareType>;
  code: Scalars['String'];
  discountType: DiscountType;
  id?: InputMaybe<Scalars['Int']>;
  maxUses: Scalars['Float'];
  organizationId?: InputMaybe<Scalars['Float']>;
  percentDiscount?: InputMaybe<Scalars['Float']>;
  replacePriceCents?: InputMaybe<Scalars['Int']>;
}

export interface UpdateFileUpload {
  globallyShared: Scalars['Boolean'];
  name: Scalars['String'];
  type: UploadType;
  uploadId: Scalars['Float'];
}

export interface UpdateHubUserError {
  errorCode: UpdateHubUserErrorCode;
  message: Scalars['String'];
}

export const UpdateHubUserErrorCode = {
  DuplicatePhone: 'DuplicatePhone',
  InvalidEmail: 'InvalidEmail',
  InvalidPhone: 'InvalidPhone',
  InvalidState: 'InvalidState',
  InvalidZip: 'InvalidZip',
} as const;

export type UpdateHubUserErrorCode =
  typeof UpdateHubUserErrorCode[keyof typeof UpdateHubUserErrorCode];
export type UpdateHubUserResult = UpdateHubUserError | UpdateHubUserSuccess;

export interface UpdateHubUserSuccess {
  successMessage: Scalars['String'];
}

export interface UpdateOrganization {
  abbreviation?: InputMaybe<Scalars['String']>;
  departmentAddress?: InputMaybe<Scalars['String']>;
  departmentHours?: InputMaybe<Scalars['String']>;
  departmentName?: InputMaybe<Scalars['String']>;
  directorId?: InputMaybe<Scalars['Int']>;
  emailDomains?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
}

export interface UpdateOrganizationEntitlementInput {
  config?: InputMaybe<Scalars['JSON']>;
  enabled: Scalars['Boolean'];
  key: Entitlement;
}

export interface UpdatePayerParams {
  billToProviderName: Scalars['Boolean'];
  id?: InputMaybe<Scalars['Float']>;
  inNetworkStates: Array<Scalars['String']>;
  name: Scalars['String'];
  standardPayerId: Scalars['String'];
  stateAddressOverrides: Array<PayerStateAddressOverrideInput>;
  tradingPartnerId: Scalars['String'];
  useAddressForAllStates?: InputMaybe<Scalars['String']>;
  visible: Scalars['Boolean'];
}

export interface UpdateProvider {
  activityStatus?: InputMaybe<Scalars['String']>;
  classification?: InputMaybe<Scalars['String']>;
  dosespotId?: InputMaybe<Scalars['Float']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  funFact?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  geoStates?: InputMaybe<Array<Scalars['String']>>;
  guaranteedPay?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Float'];
  lastName?: InputMaybe<Scalars['String']>;
  npi?: InputMaybe<Scalars['String']>;
  personalTitle?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postNominalTitles?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
  providerType?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UpdateStaffRole>;
}

export interface UpdateProviderStateAllocationsModelInput {
  id: Scalars['Int'];
  maxHours: Scalars['Float'];
  maxIntakeHours: Scalars['Float'];
}

export interface UpdateSamlConfiguration {
  authnRequestBinding?: InputMaybe<Scalars['String']>;
  automaticallyUpdateEmail: Scalars['Boolean'];
  campusOrgMappings?: InputMaybe<Array<CampusOrgMappingInput>>;
  certificates: Array<Scalars['String']>;
  disableRequestedAuthnContext: Scalars['Boolean'];
  enableManualUniqueIdEdits: Scalars['Boolean'];
  entryPoint: Scalars['String'];
  issuer?: InputMaybe<Scalars['String']>;
  profileCampusField?: InputMaybe<Scalars['String']>;
  profileEmailField: Scalars['String'];
  profileMatchingField?: InputMaybe<Scalars['String']>;
  profileUniqueIdField: Scalars['String'];
}

export const UpdateStaffRole = {
  UniversityAdmin: 'universityAdmin',
  UniversityReferrer: 'universityReferrer',
  UniversityStaff: 'universityStaff',
  UniversitySupportingClinician: 'universitySupportingClinician',
} as const;

export type UpdateStaffRole = typeof UpdateStaffRole[keyof typeof UpdateStaffRole];
export interface UpdateUser2Result {
  id: Scalars['Int'];
}

export interface UpdateUserInput {
  authToken?: InputMaybe<Scalars['String']>;
  billingAddress?: InputMaybe<AddressInput>;
  birthDate?: InputMaybe<Scalars['String']>;
  cardToken?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  interpretationLanguageId?: InputMaybe<Scalars['Float']>;
  lastName?: InputMaybe<Scalars['String']>;
  organizationIdentification?: InputMaybe<Scalars['String']>;
  pharmacyId?: InputMaybe<Scalars['Float']>;
  phone?: InputMaybe<Scalars['String']>;
  preferredName?: InputMaybe<Scalars['String']>;
  primaryAddress?: InputMaybe<AddressInput>;
  pronouns?: InputMaybe<Scalars['String']>;
  requestsInterpretationServices?: InputMaybe<Scalars['Boolean']>;
  secondaryState?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
}

export interface Upload {
  canEdit?: Maybe<Scalars['Boolean']>;
  contentType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdByProvider?: Maybe<Provider>;
  globallyShared?: Maybe<Scalars['Boolean']>;
  id: Scalars['Float'];
  name: Scalars['String'];
  type: UploadType;
  url: Scalars['String'];
}

export const UploadType = {
  InsuranceDetails: 'InsuranceDetails',
  LabResults: 'LabResults',
  Other: 'Other',
  PatientResource: 'PatientResource',
  ReferralNote: 'ReferralNote',
} as const;

export type UploadType = typeof UploadType[keyof typeof UploadType];
export interface UpsertProviderInsuranceCredential {
  active: Scalars['Boolean'];
  payerId: Scalars['Float'];
  providerId: Scalars['Float'];
  state: Scalars['String'];
}

export interface UseOtherApp {
  name: Scalars['String'];
  roleDescription: Scalars['String'];
  url: Scalars['String'];
}

export interface User {
  adminAlerts: Array<AdminAlert>;
  appointmentTemplates: Array<AppointmentTemplate>;
  assessmentInfo: UserAssessmentInfo;
  authToken?: Maybe<Scalars['String']>;
  awaitingMinorConsent: Scalars['Boolean'];
  /** get first of each questionnaire taken by the user */
  baselineQuestionnaires: Array<QuestionnaireResult>;
  billingAddress?: Maybe<Address>;
  birthDate?: Maybe<Scalars['Date']>;
  campusTeam?: Maybe<Array<CampusTeamRelationship>>;
  careFlows: Array<UserCareFlow>;
  careStatus: CareStatus;
  careTeam: Array<Provider>;
  careTeamChannel: Channel;
  careTransitions: Array<ContinuityOfCareModel>;
  careTypes: Array<CareType>;
  cocEligibility: Array<ContinuityOfCareEligibilityValidation>;
  /** Care eligibility form responses for continuity of care */
  continuityOfCareEligibility: Array<ContinuityOfCareEligibilityModel>;
  continuityOfCareTransitions: Array<ContinuityOfCareModel>;
  counselingCenter?: Maybe<UserOrgCounselingCenter>;
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  dataSharingConsent?: Maybe<DataSharingConsent>;
  dataSharingRequest?: Maybe<DataSharingRequest>;
  editableFields: Array<EditableUserFields>;
  email: Scalars['String'];
  /** Returns email if the requester has permission to see it, and null otherwise. Helpful for user lists. */
  emailIfVisible?: Maybe<Scalars['String']>;
  emailVerified: Scalars['Boolean'];
  emergencyContacts: Array<Contact>;
  engagementTags: Array<EngagementTag>;
  firstName: Scalars['String'];
  flaggedForOutreachAt?: Maybe<Scalars['DateTime']>;
  hasADHD: Scalars['Boolean'];
  hasAnxiety: Scalars['Boolean'];
  hasDepression: Scalars['Boolean'];
  hasDosespot: Scalars['Boolean'];
  /** Get insurance eligibility without restricted check */
  hasEligibleInsurance: Scalars['Boolean'];
  hasOutstandingPrescription: Scalars['Boolean'];
  hasPaymentMethod: Scalars['Boolean'];
  hasProvider: Scalars['Boolean'];
  hasSafetyPlan: Scalars['Boolean'];
  hasSso?: Maybe<Scalars['Boolean']>;
  hasSubscription?: Maybe<Scalars['Boolean']>;
  hasTherapist: Scalars['Boolean'];
  hubOnboardingStatus?: Maybe<HubStatusForUser>;
  id: Scalars['Int'];
  identificationCard?: Maybe<Upload>;
  identityVerified: Scalars['Boolean'];
  insuranceDetails?: Maybe<InsuranceDetails>;
  insuranceDetailsIfBookingForSelfPayPatient?: Maybe<InsuranceDetails>;
  insuranceDetailsIfVisible?: Maybe<InsuranceDetails>;
  intakeComplete: Scalars['Boolean'];
  /** makes stripe request */
  invoices: Array<Invoice>;
  isPaymentMethodValid: Scalars['Boolean'];
  isTherapyOnboarding: Scalars['Boolean'];
  lastAppointment?: Maybe<CalendarEvent>;
  lastName: Scalars['String'];
  /** Last manual outreach made for this user */
  lastOutreachAttempt?: Maybe<Scalars['DateTime']>;
  lastPrescriptionWrittenDate?: Maybe<Scalars['DateTime']>;
  latestMedicalHistoryResult?: Maybe<MedicalHistory>;
  /** get latest of each questionnaire taken by the user */
  latestQuestionnaires: Array<QuestionnaireResult>;
  mantraAdminCareTeam: Array<Provider>;
  needsScales: Scalars['Boolean'];
  nextAppointment?: Maybe<CalendarEvent>;
  nextAppointmentTime?: Maybe<Scalars['DateTime']>;
  nextScales?: Maybe<Scalars['DateTime']>;
  notes: Array<MedicalNote>;
  onHoldOrActiveCareTypes: Array<CareType>;
  organization?: Maybe<Organization>;
  organizationIdentification?: Maybe<Scalars['String']>;
  origin: UserOrigin;
  /** makes stripe request */
  paymentInfo?: Maybe<PaymentProfile>;
  pendingSponsoredCareReenrollment: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  primaryAddress?: Maybe<Address>;
  /** state from primaryAddress without restricted check */
  primaryAddressState?: Maybe<Scalars['String']>;
  primaryEmergencyContact?: Maybe<Contact>;
  privateChannel: Channel;
  privateChannels?: Maybe<Array<Channel>>;
  privateChannelWith?: Maybe<Channel>;
  pronouns?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  providerForCareType?: Maybe<Provider>;
  providerIfVisible?: Maybe<Provider>;
  recordViewStatus: UserRecordViewStatus;
  referralComplete?: Maybe<Scalars['Boolean']>;
  referralSource: ReferralSource;
  referredByUniversityReferrer: Scalars['Boolean'];
  referringProvider?: Maybe<Provider>;
  safetyPlan?: Maybe<SafetyPlan>;
  samlUniqueId?: Maybe<Scalars['String']>;
  screenedOut?: Maybe<Scalars['Boolean']>;
  secondaryState?: Maybe<StateCodes>;
  selfie?: Maybe<Upload>;
  suggestedProviders: Array<Provider>;
  tasks?: Maybe<Array<Task>>;
  taskStatus: Scalars['String'];
  taskStatusIfVisible?: Maybe<Scalars['String']>;
  therapist?: Maybe<Provider>;
  therapistIfVisible?: Maybe<Provider>;
  timeZone?: Maybe<Scalars['String']>;
  triageInfo?: Maybe<Array<TriageInfo>>;
  uid: Scalars['String'];
  unansweredMessagesCount: Scalars['Int'];
  universityCareTeam: Array<Provider>;
  universityCareTeamPrimary?: Maybe<Provider>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  uploads: Array<Upload>;
  verificationStatus: Scalars['String'];
}

export type UserLastAppointmentArgs = {
  appointmentType?: InputMaybe<AppointmentType>;
  careType: CareType;
  status?: InputMaybe<Scalars['String']>;
};

export type UserNextAppointmentArgs = {
  appointmentType?: InputMaybe<AppointmentType>;
  careType?: InputMaybe<CareType>;
  status?: InputMaybe<Scalars['String']>;
};

export type UserPrivateChannelArgs = {
  providerId?: InputMaybe<Scalars['Float']>;
};

export type UserPrivateChannelWithArgs = {
  kind: Scalars['String'];
};

export type UserProviderForCareTypeArgs = {
  careType: CareType;
};

export interface UserAssessmentInfo {
  additionalDiagnoses?: Maybe<Array<Scalars['String']>>;
  additionalProviderContact?: Maybe<Scalars['String']>;
  additionalProviderName?: Maybe<Scalars['String']>;
  allergies?: Maybe<Array<Scalars['String']>>;
  diagnoses?: Maybe<Array<Scalars['String']>>;
  medication?: Maybe<Array<Prescription>>;
  otherMeds?: Maybe<Array<Scalars['String']>>;
  primaryDiagnosis?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  psychiatricHistory?: Maybe<Array<Scalars['String']>>;
  riskLevel?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  suicidal?: Maybe<Array<Scalars['String']>>;
  wellnessGoals?: Maybe<Array<Scalars['String']>>;
}

export interface UserCareFlow {
  /** Broad activity check without restricted permission */
  canBookAppointment: Scalars['Boolean'];
  careSessionInfo?: Maybe<CareSessionInfo>;
  careStatus: CareStatus;
  careType: CareType;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  organizationCareFlow?: Maybe<OrganizationCareFlow>;
  paymentSource: PaymentSource;
  pendingTransition?: Maybe<ContinuityOfCareModel>;
  providerStatesAvailable: Array<Scalars['String']>;
  taskStatus: Scalars['String'];
  updatedAt: Scalars['DateTime'];
}

export interface UserCareFlowInput {
  careStatus?: InputMaybe<CareStatus>;
  careType: CareType;
  paymentSource: PaymentSource;
  sessionLimitOverride?: InputMaybe<Scalars['Int']>;
}

export interface UserCoachingHealthieStatus {
  userCoachingHealthieStatus?: Maybe<CoachingStatus>;
}

export interface UserCoachingStatus {
  userCoachingStatus?: Maybe<CoachingStatus>;
}

export interface UserDemographics {
  academicProgram: Scalars['String'];
  createdAt: Scalars['DateTime'];
  ethnicity: Scalars['String'];
  genderIdentity: Scalars['String'];
  id: Scalars['Float'];
  isInternationalStudent: Scalars['Boolean'];
  sexualOrientation: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  yearInProgram: Scalars['String'];
}

export interface UserDiagnosis {
  category: Scalars['String'];
  code: Scalars['String'];
  key: Scalars['String'];
  subCategory?: Maybe<Scalars['String']>;
  userId: Scalars['Float'];
}

export interface UserHealthieStatus {
  userIsInitialized: Scalars['Boolean'];
}

export interface UserInterpretationServices {
  interpretationLanguageId?: Maybe<Scalars['Int']>;
  requestsInterpretationServices?: Maybe<Scalars['Boolean']>;
}

export interface UserIsLoggedIn {
  authToken?: Maybe<Scalars['String']>;
  isLoggedIn: Scalars['Boolean'];
}

export interface UserListParams {
  careStatus?: InputMaybe<CareStatus>;
  careTeamMemberId?: InputMaybe<Scalars['Float']>;
  cursor?: InputMaybe<Scalars['String']>;
  dtc?: InputMaybe<Scalars['Boolean']>;
  excludeMyPatients?: InputMaybe<Scalars['Boolean']>;
  geoState?: InputMaybe<Scalars['String']>;
  includeAllPatients?: InputMaybe<Scalars['Boolean']>;
  includeNewPatients?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Float']>;
  nextSteps?: InputMaybe<NextStepsFilter>;
  organizationId?: InputMaybe<Scalars['Float']>;
  providerId?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']>;
  taskStatus?: InputMaybe<TaskStatusFilter>;
}

export const UserNotificationKey = {
  BipolarScreening: 'bipolarScreening',
  CompleteSafetyPlan: 'completeSafetyPlan',
  DataSharingRequest: 'dataSharingRequest',
  InvalidEmergencyContact: 'invalidEmergencyContact',
  InvalidPaymentMethod: 'invalidPaymentMethod',
  MonthlyScales: 'monthlyScales',
  PendingCareTransition: 'pendingCareTransition',
  PrescriptionSubmitted: 'prescriptionSubmitted',
  ProviderFeedback: 'providerFeedback',
  ReactivateSponsoredPsychiatry: 'reactivateSponsoredPsychiatry',
  ReactivateSponsoredTherapy: 'reactivateSponsoredTherapy',
  ScheduleAppointmentPsychiatry: 'scheduleAppointmentPsychiatry',
  ScheduleAppointmentTherapy: 'scheduleAppointmentTherapy',
  UnreadMessages: 'unreadMessages',
} as const;

export type UserNotificationKey = typeof UserNotificationKey[keyof typeof UserNotificationKey];
export interface UserOrgCounselingCenter {
  departmentAddress: Scalars['String'];
  departmentHours: Scalars['String'];
  departmentName: Scalars['String'];
}

export const UserOrigin = {
  Dtc: 'Dtc',
  Student: 'Student',
} as const;

export type UserOrigin = typeof UserOrigin[keyof typeof UserOrigin];
export const UserRecordViewStatus = {
  Full: 'Full',
  Limited: 'Limited',
  Restricted: 'Restricted',
} as const;

export type UserRecordViewStatus = typeof UserRecordViewStatus[keyof typeof UserRecordViewStatus];
export interface UserSkillAssignments {
  pagination: AssignmentPagination;
  skillAssignments: Array<SkillAssignmentModel>;
}

export interface UserSkillReflections {
  pagination: ReflectionPagination;
  reflections: Array<SkillReflection>;
}

export interface UserSponsoredReactivation {
  careTypes: Array<CareType>;
  isSelfReactivationAllowed: Scalars['Boolean'];
  reactivationCopy?: Maybe<Scalars['String']>;
  userHasRemainingAppts: Scalars['Boolean'];
}

export interface UtilizationHours {
  hoursScheduled?: Maybe<Scalars['Float']>;
  hoursUtilized?: Maybe<Scalars['Float']>;
  intakesScheduled?: Maybe<Scalars['Float']>;
  intakesUtilized?: Maybe<Scalars['Float']>;
  maxHours?: Maybe<Scalars['Float']>;
  maxIntakeHours?: Maybe<Scalars['Int']>;
}

export interface UtilizationVirtualModel {
  carePeriod: OrganizationCarePeriodModel;
  weeklyUtilizationRecords: Array<OrganizationUtilizationWeek>;
}

export interface VisitAttendance {
  attendance: Array<NumericKeyValue>;
  carePeriod?: Maybe<OrganizationCarePeriodModel>;
}

export type ForceNextScalesToNowMutationVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type ForceNextScalesToNowMutation = { forceNextScalesToNow: boolean };

export type PushAssessmentMutationVariables = Exact<{
  assessment: AssessmentKey;
  userId: Scalars['Float'];
}>;

export type PushAssessmentMutation = { pushAssessment: boolean };

export type AdminAssessmentQueryVariables = Exact<{
  assessment: AssessmentKey;
  userId: Scalars['Float'];
}>;

export type AdminAssessmentQuery = {
  assessment: {
    questionnaires: Array<{
      title: string;
      key: string;
      description?: string | null;
      questions: Array<{
        text?: string | null;
        key: string;
        label?: string | null;
        type?: string | null;
        overrideDescription?: string | null;
        dependency?: { foreignKey?: string | null; optionText?: string | null } | null;
        options?: Array<{ text: string; score?: number | null }> | null;
      }>;
    }>;
  };
};

export type AdminSubmitAssessmentMutationVariables = Exact<{
  questionnaires: Array<QuestionnaireSubmission> | QuestionnaireSubmission;
  userId: Scalars['Float'];
  assessment: AssessmentKey;
}>;

export type AdminSubmitAssessmentMutation = {
  result: {
    id: number;
    questionnaires: Array<{ key: string; score?: number | null; symptoms?: string | null }>;
  };
};

export type ProviderCampusTeamEventsQueryVariables = Exact<{ [key: string]: never }>;

export type ProviderCampusTeamEventsQuery = {
  providerCampusTeamEvents?: Array<{
    relationship: {
      relationshipType: UniversityRelationship;
      user: {
        id: number;
        customerId?: string | null;
        createdAt: Date;
        firstName: string;
        lastName: string;
        preferredName?: string | null;
        organization?: {
          id: number;
          name: string;
          abbreviation: string;
          emailDomains: Array<string>;
        } | null;
        selfie?: {
          id: number;
          createdAt: Date;
          contentType: string;
          name: string;
          url: string;
          type: UploadType;
          globallyShared?: boolean | null;
          canEdit?: boolean | null;
          createdByProvider?: { id: number; name: string } | null;
        } | null;
      };
    };
    events: Array<{
      id: number;
      createdAt: Date;
      tag: string;
      data?: { [key: string]: any } | null;
    }>;
  }> | null;
};

export type CampusTeamOptionFragment = {
  id: number;
  name: string;
  portrait?: { url: string } | null;
};

export type CampusTeamOptionsQueryVariables = Exact<{
  organizationId: Scalars['Float'];
}>;

export type CampusTeamOptionsQuery = {
  organization: {
    campusTeamOptions: Array<{ id: number; name: string; portrait?: { url: string } | null }>;
  };
};

export type CampusTeamOptionsForUserQueryVariables = Exact<{
  organizationId: Scalars['Float'];
  userId: Scalars['Float'];
}>;

export type CampusTeamOptionsForUserQuery = {
  organization: {
    campusTeamOptions: Array<{ id: number; name: string; portrait?: { url: string } | null }>;
  };
  adminUser: {
    campusTeam?: Array<{
      relationshipType: UniversityRelationship;
      provider: { id: number; name: string; portrait?: { url: string } | null };
    }> | null;
  };
};

export type ProviderNetworkSearchQueryVariables = Exact<{
  gender?: InputMaybe<Array<ProviderGenderSearch> | ProviderGenderSearch>;
  state?: InputMaybe<StateCodes>;
  careType: CareType;
  traits?: InputMaybe<Array<ProviderTraitInput> | ProviderTraitInput>;
  organizationId?: InputMaybe<Scalars['Float']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  payerId?: InputMaybe<Scalars['Float']>;
  userId?: InputMaybe<Scalars['Float']>;
  apptType: Scalars['String'];
}>;

export type ProviderNetworkSearchQuery = {
  promotionWindowInDays: number;
  providerNetworkSearch: {
    outOfNetworkCount: number;
    stateNotAllowed?: StateCodes | null;
    providers: Array<{
      careTypes: Array<CareType>;
      classification: string;
      funFact: string;
      eligibleForSchedulingPromotion: boolean;
      id: number;
      name: string;
      role: Role;
      geoStates: Array<StateCodes>;
      portrait?: {
        id: number;
        createdAt: Date;
        contentType: string;
        name: string;
        url: string;
        type: UploadType;
        globallyShared?: boolean | null;
        canEdit?: boolean | null;
        createdByProvider?: { id: number; name: string } | null;
      } | null;
      traits: Array<{ category: TraitCategory; values: Array<string> }>;
      upcomingAvailabilityV4: {
        availability: Array<{
          start: string;
          end: string;
          providerId: number;
          organizationId?: number | null;
        }>;
        globalBlockers: Array<{
          providerId: number;
          date: string;
          type: GlobalAvailabilityBlockerType;
        }>;
      };
    }>;
  };
};

export type ProviderNetworkProfileQueryVariables = Exact<{
  providerId: Scalars['Float'];
  organizationId?: InputMaybe<Scalars['Float']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
}>;

export type ProviderNetworkProfileQuery = {
  provider: {
    id: number;
    firstName?: string | null;
    lastName?: string | null;
    pronouns?: string | null;
    gender?: string | null;
    careTypes: Array<CareType>;
    acceptingNewPatients: boolean;
    classification: string;
    geoStates: Array<StateCodes>;
    funFact: string;
    portrait?: { url: string } | null;
    organizations: Array<{ id: number; name: string }>;
    traits: Array<{ category: TraitCategory; values: Array<string> }>;
    insuranceCredentials: Array<{ active: boolean; state: string; payer: { name: string } }>;
    qualifications2: Array<{
      id: number;
      name: string;
      abbreviation: string;
      states: Array<string>;
      licences: Array<string>;
    }>;
    degrees: Array<{ id: number; university: string; degree: string; abbreviation: string }>;
    upcomingIntakeAvailabiility: Array<{ start: string; end: string; providerId: number }>;
  };
};

export type UpsertSuggestedProvidersMutationVariables = Exact<{
  userId: Scalars['Float'];
  providerIds: Array<Scalars['Float']> | Scalars['Float'];
}>;

export type UpsertSuggestedProvidersMutation = { upsertSuggestedProviders: boolean };

export type EnrollModalQueryVariables = Exact<{
  organizationId: Scalars['Float'];
  providerIds: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type EnrollModalQuery = {
  organization: {
    providers: Array<{ id: number; name: string; role: Role }>;
    children: Array<{
      id: number;
      name: string;
      providers: Array<{ id: number; name: string; role: Role }>;
    }>;
    parent?: {
      id: number;
      name: string;
      providers: Array<{ id: number; name: string; role: Role }>;
    } | null;
    careFlows: Array<{ id: number; careType: CareType; statesAvailable: Array<StateCodes> }>;
    referralCredits?: {
      availableCredits: number;
      therapyCreditWeight: number;
      psychiatryCreditWeight: number;
      referralPeriodEnd?: Date | null;
    } | null;
  };
  providersById: Array<{
    careTypes: Array<CareType>;
    classification: string;
    funFact: string;
    id: number;
    name: string;
    role: Role;
    geoStates: Array<StateCodes>;
    portrait?: {
      id: number;
      createdAt: Date;
      contentType: string;
      name: string;
      url: string;
      type: UploadType;
      globallyShared?: boolean | null;
      canEdit?: boolean | null;
      createdByProvider?: { id: number; name: string } | null;
    } | null;
  }>;
};

export type AdminCreateUserWithAppointmentMutationVariables = Exact<{
  input: CreateUser;
  intakeAppointment: CreateIntake;
}>;

export type AdminCreateUserWithAppointmentMutation = {
  adminCreateUser: { id: number; firstName: string; lastName: string };
};

export type RemainingHoursForRangeQueryVariables = Exact<{
  careType: CareType;
  organizationId: Scalars['Float'];
  appointmentType: Scalars['String'];
  start: Scalars['String'];
  end: Scalars['String'];
  userId?: InputMaybe<Scalars['Float']>;
}>;

export type RemainingHoursForRangeQuery = {
  nextAvailableAppointment?: Date | null;
  remainingHoursForRange: Array<{
    date: string;
    remainingHours: number;
    dgmAllocationId?: number | null;
  }>;
};

export type ProviderNetworkOrganizationQueryVariables = Exact<{
  organizationId: Scalars['Float'];
}>;

export type ProviderNetworkOrganizationQuery = {
  organization: {
    name: string;
    entitlements: Array<{ key: Entitlement; config: { [key: string]: any } }>;
  };
};

export type ProviderPopoverQueryVariables = Exact<{
  providerId: Scalars['Float'];
}>;

export type ProviderPopoverQuery = { adminProvider: { id: number; name: string } };

export type NetworkReferralUserFragment = {
  id: number;
  firstName: string;
  lastName: string;
  birthDate?: Date | null;
  careFlows: Array<{
    careType: CareType;
    paymentSource: PaymentSource;
    organizationCareFlow?: { defaultPaymentSource: PaymentSource } | null;
  }>;
  insuranceDetailsIfVisible?: {
    memberId?: string | null;
    state: string;
    inNetwork: boolean;
    eligibilityVerified: boolean;
    payer?: { id: number; name: string } | null;
  } | null;
  insuranceDetailsIfBookingForSelfPayPatient?: {
    memberId?: string | null;
    state: string;
    inNetwork: boolean;
    eligibilityVerified: boolean;
    payer?: { id: number; name: string } | null;
  } | null;
  appointmentTemplates: Array<{
    id: number;
    description: string;
    duration: number;
    appointmentType: string;
    careType: CareType;
  }>;
  suggestedProviders: Array<{ id: number }>;
};

export type NetworkReferralUserQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type NetworkReferralUserQuery = {
  adminUser: {
    id: number;
    firstName: string;
    lastName: string;
    birthDate?: Date | null;
    careFlows: Array<{
      careType: CareType;
      paymentSource: PaymentSource;
      organizationCareFlow?: { defaultPaymentSource: PaymentSource } | null;
    }>;
    insuranceDetailsIfVisible?: {
      memberId?: string | null;
      state: string;
      inNetwork: boolean;
      eligibilityVerified: boolean;
      payer?: { id: number; name: string } | null;
    } | null;
    insuranceDetailsIfBookingForSelfPayPatient?: {
      memberId?: string | null;
      state: string;
      inNetwork: boolean;
      eligibilityVerified: boolean;
      payer?: { id: number; name: string } | null;
    } | null;
    appointmentTemplates: Array<{
      id: number;
      description: string;
      duration: number;
      appointmentType: string;
      careType: CareType;
    }>;
    suggestedProviders: Array<{ id: number }>;
  };
};

export type AppointmentUserV2Fragment = {
  id: number;
  firstName: string;
  preferredName?: string | null;
  lastName: string;
  customerId?: string | null;
  primaryAddressState?: string | null;
  hasEligibleInsurance: boolean;
  hasTherapist: boolean;
  hasProvider: boolean;
  isPaymentMethodValid: boolean;
  hasPaymentMethod: boolean;
  careTypes: Array<CareType>;
  therapyUpcoming?: { id: number } | null;
  therapyComplete?: { id: number } | null;
  psychUpcoming?: { id: number } | null;
  psychComplete?: { id: number } | null;
  organization?: {
    id: number;
    name: string;
    abbreviation: string;
    emailDomains: Array<string>;
    parent?: { id: number; name: string; abbreviation: string; emailDomains: Array<string> } | null;
    entitlements: Array<{ key: Entitlement; config: { [key: string]: any } }>;
  } | null;
  careFlows: Array<{
    careType: CareType;
    canBookAppointment: boolean;
    paymentSource: PaymentSource;
  }>;
  appointmentTemplates: Array<{
    id: number;
    description: string;
    duration: number;
    appointmentType: string;
    careType: CareType;
  }>;
};

export type BookingProviderFragment = {
  id: number;
  name: string;
  role: Role;
  geoStates: Array<StateCodes>;
  careTypes: Array<CareType>;
  organizations: Array<{ id: number }>;
};

export type ReschedulingAppointmentFragment = {
  id: number;
  status: string;
  startTime: Date;
  endTime: Date;
  appointmentType: string;
  careType: CareType;
  user: {
    id: number;
    firstName: string;
    preferredName?: string | null;
    lastName: string;
    customerId?: string | null;
    primaryAddressState?: string | null;
    hasEligibleInsurance: boolean;
    hasTherapist: boolean;
    hasProvider: boolean;
    isPaymentMethodValid: boolean;
    hasPaymentMethod: boolean;
    careTypes: Array<CareType>;
    therapyUpcoming?: { id: number } | null;
    therapyComplete?: { id: number } | null;
    psychUpcoming?: { id: number } | null;
    psychComplete?: { id: number } | null;
    organization?: {
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
      parent?: {
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      } | null;
      entitlements: Array<{ key: Entitlement; config: { [key: string]: any } }>;
    } | null;
    careFlows: Array<{
      careType: CareType;
      canBookAppointment: boolean;
      paymentSource: PaymentSource;
    }>;
    appointmentTemplates: Array<{
      id: number;
      description: string;
      duration: number;
      appointmentType: string;
      careType: CareType;
    }>;
  };
  provider: {
    id: number;
    name: string;
    role: Role;
    geoStates: Array<StateCodes>;
    careTypes: Array<CareType>;
    organizations: Array<{ id: number }>;
  };
};

export type UserBookingQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type UserBookingQuery = {
  adminUser: {
    id: number;
    firstName: string;
    preferredName?: string | null;
    lastName: string;
    customerId?: string | null;
    primaryAddressState?: string | null;
    hasEligibleInsurance: boolean;
    hasTherapist: boolean;
    hasProvider: boolean;
    isPaymentMethodValid: boolean;
    hasPaymentMethod: boolean;
    careTypes: Array<CareType>;
    therapyUpcoming?: { id: number } | null;
    therapyComplete?: { id: number } | null;
    psychUpcoming?: { id: number } | null;
    psychComplete?: { id: number } | null;
    organization?: {
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
      parent?: {
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      } | null;
      entitlements: Array<{ key: Entitlement; config: { [key: string]: any } }>;
    } | null;
    careFlows: Array<{
      careType: CareType;
      canBookAppointment: boolean;
      paymentSource: PaymentSource;
    }>;
    appointmentTemplates: Array<{
      id: number;
      description: string;
      duration: number;
      appointmentType: string;
      careType: CareType;
    }>;
  };
};

export type ReferralUserBookingQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type ReferralUserBookingQuery = {
  adminUser: {
    id: number;
    firstName: string;
    preferredName?: string | null;
    lastName: string;
    customerId?: string | null;
    primaryAddressState?: string | null;
    hasEligibleInsurance: boolean;
    hasTherapist: boolean;
    hasProvider: boolean;
    isPaymentMethodValid: boolean;
    hasPaymentMethod: boolean;
    careTypes: Array<CareType>;
    therapyUpcoming?: { id: number } | null;
    therapyComplete?: { id: number } | null;
    psychUpcoming?: { id: number } | null;
    psychComplete?: { id: number } | null;
    organization?: {
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
      parent?: {
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      } | null;
      entitlements: Array<{ key: Entitlement; config: { [key: string]: any } }>;
    } | null;
    careFlows: Array<{
      careType: CareType;
      canBookAppointment: boolean;
      paymentSource: PaymentSource;
    }>;
    appointmentTemplates: Array<{
      id: number;
      description: string;
      duration: number;
      appointmentType: string;
      careType: CareType;
    }>;
  };
  providers: Array<{
    id: number;
    name: string;
    role: Role;
    geoStates: Array<StateCodes>;
    careTypes: Array<CareType>;
    organizations: Array<{ id: number }>;
  }>;
};

export type ReschedulingAppointmentQueryVariables = Exact<{
  rescheduleId: Scalars['Float'];
}>;

export type ReschedulingAppointmentQuery = {
  adminAppointment: {
    id: number;
    status: string;
    startTime: Date;
    endTime: Date;
    appointmentType: string;
    careType: CareType;
    user: {
      id: number;
      firstName: string;
      preferredName?: string | null;
      lastName: string;
      customerId?: string | null;
      primaryAddressState?: string | null;
      hasEligibleInsurance: boolean;
      hasTherapist: boolean;
      hasProvider: boolean;
      isPaymentMethodValid: boolean;
      hasPaymentMethod: boolean;
      careTypes: Array<CareType>;
      therapyUpcoming?: { id: number } | null;
      therapyComplete?: { id: number } | null;
      psychUpcoming?: { id: number } | null;
      psychComplete?: { id: number } | null;
      organization?: {
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
        parent?: {
          id: number;
          name: string;
          abbreviation: string;
          emailDomains: Array<string>;
        } | null;
        entitlements: Array<{ key: Entitlement; config: { [key: string]: any } }>;
      } | null;
      careFlows: Array<{
        careType: CareType;
        canBookAppointment: boolean;
        paymentSource: PaymentSource;
      }>;
      appointmentTemplates: Array<{
        id: number;
        description: string;
        duration: number;
        appointmentType: string;
        careType: CareType;
      }>;
    };
    provider: {
      id: number;
      name: string;
      role: Role;
      geoStates: Array<StateCodes>;
      careTypes: Array<CareType>;
      organizations: Array<{ id: number }>;
    };
  };
};

export type ProviderForCareTypeQueryVariables = Exact<{
  userId: Scalars['Float'];
  careType: CareType;
  apptType: Scalars['String'];
  organizationId?: InputMaybe<Scalars['Float']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  patientState?: InputMaybe<StateCodes>;
}>;

export type ProviderForCareTypeQuery = {
  adminUser: {
    providerForCareType?: {
      id: number;
      name: string;
      role: Role;
      geoStates: Array<StateCodes>;
      careTypes: Array<CareType>;
      upcomingAvailabilityV4: { availability: Array<{ start: string }> };
      organizations: Array<{ id: number }>;
    } | null;
  };
};

export type AdminFindApptsAtRiskQueryVariables = Exact<{
  date: Scalars['String'];
  providerId: Scalars['Float'];
  timezone: Scalars['String'];
  weekly: Scalars['Boolean'];
  allocations: Array<CreateAllocationInput> | CreateAllocationInput;
}>;

export type AdminFindApptsAtRiskQuery = {
  adminFindApptsAtRisk: Array<{
    startTime: Date;
    endTime: Date;
    user: {
      id: number;
      firstName: string;
      preferredName?: string | null;
      lastName: string;
      customerId?: string | null;
    };
  }>;
};

export type AllocationsTabQueryVariables = Exact<{
  providerId: Scalars['Float'];
}>;

export type AllocationsTabQuery = {
  adminGetAllocations: Array<{
    id: number;
    startTime: Date;
    endTime: Date;
    repeatsUntil?: Date | null;
    weekly: boolean;
    isFeeForServiceTime?: boolean | null;
    purpose: TimeAllocationPurpose;
    timezone: string;
    appointmentTypes?: Array<string> | null;
    provider: { id: number; name: string; careTypes: Array<CareType> };
    organization?: {
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
      childrenCount: number;
    } | null;
    childOrganizations?: Array<{
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
    }> | null;
  }>;
  adminGetStateAllocations: Array<{
    id: number;
    startDate: string;
    endDate: string;
    maxHours: number;
    maxIntakeHours: number;
    providerState: { state: StateCodes };
  }>;
  adminProvider: {
    id: number;
    providerStates: Array<{ id: number; state: StateCodes }>;
    organizations: Array<{
      id: number;
      name: string;
      children: Array<{ id: number; name: string }>;
    }>;
  };
};

export type AdminUpdateAllocationsForDateMutationVariables = Exact<{
  date: Scalars['String'];
  providerId: Scalars['Float'];
  timezone: Scalars['String'];
  weekly: Scalars['Boolean'];
  allocations: Array<CreateAllocationInput> | CreateAllocationInput;
}>;

export type AdminUpdateAllocationsForDateMutation = { adminUpdateAllocationsForDate: boolean };

export type AdminUpdateStateAllocationsForDateMutationVariables = Exact<{
  allocationsToCreate:
    | Array<CreateProviderStateAllocationsModelInput>
    | CreateProviderStateAllocationsModelInput;
  allocationsToUpdate:
    | Array<UpdateProviderStateAllocationsModelInput>
    | UpdateProviderStateAllocationsModelInput;
}>;

export type AdminUpdateStateAllocationsForDateMutation = {
  adminUpdateStateAllocationsForDate: boolean;
};

export type ReferralAppointmentDataFragment = {
  therapyUpcoming?: { id: number; startTime: Date } | null;
  therapyComplete?: { id: number; startTime: Date } | null;
  psychUpcoming?: { id: number; startTime: Date } | null;
  psychComplete?: { id: number; startTime: Date } | null;
};

export type ReferralUserDataFragment = {
  id: number;
  customerId?: string | null;
  createdAt: Date;
  onHoldOrActiveCareTypes: Array<CareType>;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  email?: string | null;
  therapyUpcoming?: { id: number; startTime: Date } | null;
  therapyComplete?: { id: number; startTime: Date } | null;
  psychUpcoming?: { id: number; startTime: Date } | null;
  psychComplete?: { id: number; startTime: Date } | null;
};

export type ReferralListQueryVariables = Exact<{
  fetchReferrer: Scalars['Boolean'];
  fetchOrganization: Scalars['Boolean'];
}>;

export type ReferralListQuery = {
  referrals: Array<{
    id: number;
    customerId?: string | null;
    createdAt: Date;
    onHoldOrActiveCareTypes: Array<CareType>;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
    email?: string | null;
    referringProvider?: { id: number; name: string } | null;
    organization?: { id: number; name: string } | null;
    therapyUpcoming?: { id: number; startTime: Date } | null;
    therapyComplete?: { id: number; startTime: Date } | null;
    psychUpcoming?: { id: number; startTime: Date } | null;
    psychComplete?: { id: number; startTime: Date } | null;
  }>;
};

export type UserReferralStatusQueryVariables = Exact<{
  patientId: Scalars['Float'];
}>;

export type UserReferralStatusQuery = {
  userReferralStatus: {
    therapyUpcoming?: { id: number; startTime: Date } | null;
    therapyComplete?: { id: number; startTime: Date } | null;
    psychUpcoming?: { id: number; startTime: Date } | null;
    psychComplete?: { id: number; startTime: Date } | null;
  };
};

export type StudentsQueryVariables = Exact<{
  params: UserListParams;
}>;

export type StudentsQuery = {
  paginatedUsers: {
    hasMore: boolean;
    cursor: string;
    count: number;
    items: Array<{
      careStatus: CareStatus;
      careTypes: Array<CareType>;
      organizationIdentification?: string | null;
      id: number;
      customerId?: string | null;
      createdAt: Date;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      email?: string | null;
      taskStatus?: string | null;
      provider?: { id: number; name: string } | null;
      therapist?: {
        id: number;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        classification: string;
        role: Role;
        email?: string | null;
        geoStates: Array<StateCodes>;
        postNominalTitles?: string | null;
        personalTitle?: string | null;
        careTypes: Array<CareType>;
        portrait?: { url: string } | null;
        organizations: Array<{
          id: number;
          name: string;
          abbreviation: string;
          emailDomains: Array<string>;
        }>;
      } | null;
      organization?: {
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      } | null;
      campusTeam?: Array<{
        relationshipType: UniversityRelationship;
        provider: { name: string };
      }> | null;
    }>;
  };
};

export type StudentListOptionsQueryVariables = Exact<{
  organizationId: Scalars['Float'];
}>;

export type StudentListOptionsQuery = {
  providers: Array<{ id: number; name: string; role: Role; geoStates: Array<StateCodes> }>;
  organizationCampusTeam?: Array<{ provider: { id: number; name: string } }> | null;
  organization: {
    entitlements: Array<{ key: Entitlement }>;
    children: Array<{
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
    }>;
  };
};

export type TicketFragment = {
  id: number;
  title: string;
  priority?: TicketPriority | null;
  description?: string | null;
  blockedReason?: string | null;
  status: TicketStatus;
  updatedAt: Date;
  createdAt: Date;
  resolveIn24Hrs?: boolean | null;
  category: TicketCategory;
};

export type TicketUserFragment = {
  id: number;
  customerId?: string | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  organization?: { id: number; name: string } | null;
};

export type TicketsQueryVariables = Exact<{
  view: TicketView;
  userId?: InputMaybe<Scalars['Float']>;
}>;

export type TicketsQuery = {
  newTickets: Array<{ id: number }>;
  tickets: Array<{
    id: number;
    title: string;
    priority?: TicketPriority | null;
    description?: string | null;
    blockedReason?: string | null;
    status: TicketStatus;
    updatedAt: Date;
    createdAt: Date;
    resolveIn24Hrs?: boolean | null;
    category: TicketCategory;
    user?: {
      id: number;
      customerId?: string | null;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      organization?: { id: number; name: string } | null;
    } | null;
    requester?: { id: number; name: string } | null;
    assignee?: { id: number; name: string } | null;
  }>;
};

export type TicketQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type TicketQuery = {
  ticket: {
    assignOptions: TicketAssignOption;
    canEdit: boolean;
    canResolve: boolean;
    id: number;
    title: string;
    priority?: TicketPriority | null;
    description?: string | null;
    blockedReason?: string | null;
    status: TicketStatus;
    updatedAt: Date;
    createdAt: Date;
    resolveIn24Hrs?: boolean | null;
    category: TicketCategory;
    user?: {
      id: number;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
    } | null;
    requester?: {
      id: number;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      classification: string;
      role: Role;
      email?: string | null;
      geoStates: Array<StateCodes>;
      postNominalTitles?: string | null;
      personalTitle?: string | null;
      careTypes: Array<CareType>;
      portrait?: { url: string } | null;
      organizations: Array<{
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      }>;
    } | null;
    assignee?: {
      id: number;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      classification: string;
      role: Role;
      email?: string | null;
      geoStates: Array<StateCodes>;
      postNominalTitles?: string | null;
      personalTitle?: string | null;
      careTypes: Array<CareType>;
      portrait?: { url: string } | null;
      organizations: Array<{
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      }>;
    } | null;
    events: Array<{
      id: number;
      tag: string;
      data?: { [key: string]: any } | null;
      createdAt: Date;
    }>;
  };
};

export type PossibleAssigneesQueryVariables = Exact<{
  userId: Scalars['Float'];
  ticketId?: InputMaybe<Scalars['Float']>;
}>;

export type PossibleAssigneesQuery = {
  providers: Array<{ id: number; name: string; role: Role; classification: string }>;
};

export type CreateTicketMutationVariables = Exact<{
  input: CreateTicketInput;
}>;

export type CreateTicketMutation = { ticket: { id: number } };

export type EditTicketMutationVariables = Exact<{
  id: Scalars['Float'];
  input: EditTicketInput;
}>;

export type EditTicketMutation = { ticket?: { id: number } | null };

export type SearchUsersQueryVariables = Exact<{
  search: SearchUserInput;
}>;

export type SearchUsersQuery = {
  searchUsers: Array<{
    id: number;
    email: string;
    customerId?: string | null;
    createdAt: Date;
    careStatus: CareStatus;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
    organization?: {
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
    } | null;
  }>;
};

export type UserListItemFragment = {
  email: string;
  createdAt: Date;
  careStatus: CareStatus;
  taskStatus: string;
  id: number;
  customerId?: string | null;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  provider?: { id: number; name: string } | null;
  therapist?: {
    id: number;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    classification: string;
    role: Role;
    email?: string | null;
    geoStates: Array<StateCodes>;
    postNominalTitles?: string | null;
    personalTitle?: string | null;
    careTypes: Array<CareType>;
    portrait?: { url: string } | null;
    organizations: Array<{
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
    }>;
  } | null;
  organization?: {
    id: number;
    name: string;
    abbreviation: string;
    emailDomains: Array<string>;
  } | null;
  continuityOfCareTransitions: Array<{
    careType: CareType;
    nextSteps: NextSteps;
    pendingUser?: boolean | null;
    user: {
      id: number;
      organization?: {
        id: number;
        latestCarePeriod?: { id: number; endDate: string } | null;
      } | null;
    };
  }>;
  careFlows: Array<{
    id: number;
    careType: CareType;
    careStatus: CareStatus;
    paymentSource: PaymentSource;
    careSessionInfo?: {
      unlimitedAppts: boolean;
      remainingAppts?: number | null;
      flowLimit?: number | null;
      completedAppts: number;
    } | null;
  }>;
};

export type PaginatedUsersQueryVariables = Exact<{
  params: UserListParams;
}>;

export type PaginatedUsersQuery = {
  paginatedUsers: {
    count: number;
    cursor: string;
    hasMore: boolean;
    items: Array<{
      email: string;
      createdAt: Date;
      careStatus: CareStatus;
      taskStatus: string;
      id: number;
      customerId?: string | null;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      provider?: { id: number; name: string } | null;
      therapist?: {
        id: number;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        classification: string;
        role: Role;
        email?: string | null;
        geoStates: Array<StateCodes>;
        postNominalTitles?: string | null;
        personalTitle?: string | null;
        careTypes: Array<CareType>;
        portrait?: { url: string } | null;
        organizations: Array<{
          id: number;
          name: string;
          abbreviation: string;
          emailDomains: Array<string>;
        }>;
      } | null;
      organization?: {
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      } | null;
      continuityOfCareTransitions: Array<{
        careType: CareType;
        nextSteps: NextSteps;
        pendingUser?: boolean | null;
        user: {
          id: number;
          organization?: {
            id: number;
            latestCarePeriod?: { id: number; endDate: string } | null;
          } | null;
        };
      }>;
      careFlows: Array<{
        id: number;
        careType: CareType;
        careStatus: CareStatus;
        paymentSource: PaymentSource;
        careSessionInfo?: {
          unlimitedAppts: boolean;
          remainingAppts?: number | null;
          flowLimit?: number | null;
          completedAppts: number;
        } | null;
      }>;
    }>;
  };
};

export type UsersOrganizationsQueryVariables = Exact<{
  params: UserListParams;
}>;

export type UsersOrganizationsQuery = { usersOrganizations: Array<{ id: number; name: string }> };

export type GetConsentHistoryQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type GetConsentHistoryQuery = {
  adminUser: {
    organization?: {
      abbreviation: string;
      carePeriods: Array<{ id: number; startDate: string; endDate: string }>;
    } | null;
  };
  requests: Array<{ id: number; createdAt: Date; pending: boolean }>;
  consents: Array<{ id: number; startDate: Date; endDate: Date; revokedAt?: Date | null }>;
  careFlows: Array<{
    id: number;
    careType: CareType;
    careStatus: CareStatus;
    validStart: Date;
    validEnd?: Date | null;
    paymentSource: PaymentSource;
  }>;
  appointments: Array<{
    id: number;
    startTime: Date;
    endTime: Date;
    description?: string | null;
    appointmentType: string;
    status: string;
  }>;
};

export type ModifyExistingRequestMutationVariables = Exact<{
  id: Scalars['Float'];
  date: Scalars['DateTime'];
  pending: Scalars['Boolean'];
}>;

export type ModifyExistingRequestMutation = {
  request: { id: number; createdAt: Date; pending: boolean };
};

export type ModifyExistingConsentMutationVariables = Exact<{
  id: Scalars['Float'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  revokedAt?: InputMaybe<Scalars['DateTime']>;
}>;

export type ModifyExistingConsentMutation = {
  consent: { id: number; startDate: Date; endDate: Date; revokedAt?: Date | null };
};

export type AdminUserQueryVariables = Exact<{
  id: Scalars['Float'];
  assessmentInfo: Scalars['Boolean'];
  restricted: Scalars['Boolean'];
  full: Scalars['Boolean'];
  phi: Scalars['Boolean'];
}>;

export type AdminUserQuery = {
  permissibleUploadTypes: Array<UploadType>;
  adminUser: {
    samlUniqueId?: string | null;
    id: number;
    customerId?: string | null;
    createdAt: Date;
    birthDate?: Date | null;
    email?: string;
    phone?: string | null;
    emailVerified?: boolean;
    hasDosespot: boolean;
    pronouns?: string | null;
    careTypes: Array<CareType>;
    secondaryState?: StateCodes | null;
    organizationIdentification?: string | null;
    referralComplete?: boolean | null;
    origin: UserOrigin;
    unsubscribedAt?: Date | null;
    pendingSponsoredCareReenrollment: boolean;
    editableFields: Array<EditableUserFields>;
    primaryAddressState?: string | null;
    lastPrescriptionWrittenDate?: Date | null;
    taskStatus?: string;
    nextScales?: Date | null;
    needsScales?: boolean;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
    latestMedicalHistoryResult?: {
      uuid: string;
      createdAt: Date;
      updatedAt: Date;
      questionnaires: Array<{
        questions: Array<{
          key: string;
          text?: string | null;
          type?: string | null;
          value?: { [key: string]: any } | null;
        }>;
      }>;
    } | null;
    cocEligibility: Array<{ careType: CareType; paymentType: PaymentType }>;
    careFlows: Array<{
      id: number;
      careType: CareType;
      careStatus?: CareStatus;
      paymentSource?: PaymentSource;
      careSessionInfo?: {
        unlimitedAppts: boolean;
        remainingAppts?: number | null;
        flowLimit?: number | null;
        completedAppts: number;
      } | null;
      pendingTransition?: { nextSteps: NextSteps } | null;
    }>;
    primaryAddress?: {
      name?: string | null;
      line1: string;
      line2?: string | null;
      city: string;
      state?: StateCodes | null;
      zip: string;
    } | null;
    billingAddress?: {
      name?: string | null;
      line1: string;
      line2?: string | null;
      city: string;
      state?: StateCodes | null;
      zip: string;
    } | null;
    therapist?: {
      id: number;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      classification: string;
      role: Role;
      email?: string | null;
      geoStates: Array<StateCodes>;
      postNominalTitles?: string | null;
      personalTitle?: string | null;
      careTypes: Array<CareType>;
      portrait?: { url: string } | null;
      organizations: Array<{
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      }>;
    } | null;
    suggestedProviders: Array<{
      id: number;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      classification: string;
      role: Role;
      email?: string | null;
      geoStates: Array<StateCodes>;
      postNominalTitles?: string | null;
      personalTitle?: string | null;
      careTypes: Array<CareType>;
      portrait?: { url: string } | null;
      organizations: Array<{
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      }>;
    }>;
    insuranceDetails?: {
      memberId?: string | null;
      groupNumber?: string | null;
      updatedAt: Date;
      state: string;
      subscriberFirstName?: string | null;
      subscriberLastName?: string | null;
      subscriberDateOfBirth?: string | null;
      subscriberGender?: string | null;
      patientRelationshipToSubscriber: PatientRelationshipToSubscriber;
      lastChecked?: Date | null;
      eligibilityVerified: boolean;
      deductibleRemainingCents?: number | null;
      noMentalHealthBenefits?: boolean | null;
      payer?: { id: number; name: string; inNetworkStates: Array<string> } | null;
    } | null;
    emergencyContacts?: Array<{
      id: number;
      name?: string | null;
      relationship?: string | null;
      phoneNumber?: string | null;
      primary?: boolean | null;
    }>;
    selfie?: {
      id: number;
      createdAt: Date;
      contentType: string;
      name: string;
      url: string;
      type: UploadType;
      globallyShared?: boolean | null;
      canEdit?: boolean | null;
      createdByProvider?: { id: number; name: string } | null;
    } | null;
    assessmentInfo?: {
      diagnoses?: Array<string> | null;
      primaryDiagnosis?: string | null;
      additionalDiagnoses?: Array<string> | null;
      riskLevel?: string | null;
      allergies?: Array<string> | null;
      wellnessGoals?: Array<string> | null;
      otherMeds?: Array<string> | null;
      additionalProviderName?: string | null;
      additionalProviderContact?: string | null;
      psychiatricHistory?: Array<string> | null;
    };
    latestQuestionnaires?: Array<{
      key: string;
      score?: number | null;
      symptoms?: string | null;
      description: string;
      assessment: {
        createdAt: Date;
        provider?: { id: number; name: string; role: Role; geoStates: Array<StateCodes> } | null;
      };
    }>;
    baselineQuestionnaires?: Array<{
      key: string;
      score?: number | null;
      symptoms?: string | null;
      description: string;
    }>;
    continuityOfCareEligibility?: Array<{
      residentialLocationDuringBreak: string;
      interestedInContinuingCare: boolean;
      enrollment: boolean;
      additionalNotes?: string | null;
      careType: CareType;
      createdAt: Date;
      updatedAt: Date;
      provider?: { id: number; name: string } | null;
      completedBy: { id: number; name: string };
    }>;
    tasks?: Array<{
      id: number;
      ref: string;
      title: string;
      createdAt: Date;
      done: boolean;
      userId: number;
      updatedAt: Date;
    }> | null;
    provider?: {
      id: number;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      classification: string;
      role: Role;
      email?: string | null;
      geoStates: Array<StateCodes>;
      postNominalTitles?: string | null;
      personalTitle?: string | null;
      careTypes: Array<CareType>;
      portrait?: { url: string } | null;
      organizations: Array<{
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      }>;
    } | null;
    mantraAdminCareTeam: Array<{
      id: number;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      classification: string;
      role: Role;
      email?: string | null;
      geoStates: Array<StateCodes>;
      postNominalTitles?: string | null;
      personalTitle?: string | null;
      careTypes: Array<CareType>;
      portrait?: { url: string } | null;
      organizations: Array<{
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      }>;
    }>;
    organization?: {
      usesDedicatedGroupModel: boolean;
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
      entitlements: Array<{ key: Entitlement }>;
      parent?: { id: number; name: string; abbreviation: string } | null;
      latestCarePeriod?: { endDate: string } | null;
      careFlows: Array<{
        careType: CareType;
        defaultPaymentSource: PaymentSource;
        sponsoredSessionsLimitType: SponsoredSessionsLimitType;
      }>;
    } | null;
    safetyPlan?: {
      id: number;
      createdAt: Date;
      complete: boolean;
      activatingProvider: {
        firstName?: string | null;
        lastName?: string | null;
        postNominalTitles?: string | null;
      };
      editHistory: Array<{
        createdAt: Date;
        provider?: {
          id: number;
          firstName?: string | null;
          lastName?: string | null;
          postNominalTitles?: string | null;
        } | null;
      }>;
      data: {
        reasonToLive?: string | null;
        firearmAccess?: boolean | null;
        firearmAccessPlan?: string | null;
        dangerousMeans?: boolean | null;
        dangerousMeansList?: string | null;
        dangerousMeansPlan?: string | null;
        warningSigns: Array<string>;
        copingStrategies: Array<string>;
        distractions: Array<{ name: string; contact?: string | null; address?: string | null }>;
        crisisContacts: Array<{ name: string; contact?: string | null; address?: string | null }>;
        professionalContacts: Array<{
          name: string;
          contact?: string | null;
          address?: string | null;
        }>;
        crisisResources: Array<{ name: string; contact?: string | null; address?: string | null }>;
        crisisFacilities: Array<{ name: string; contact?: string | null; address?: string | null }>;
      };
    } | null;
    nextAppointment?: { id: number; startTime: Date } | null;
    continuityOfCareTransitions?: Array<{
      careType: CareType;
      nextSteps: NextSteps;
      pendingUser?: boolean | null;
      reason?: string | null;
      requiresReferral?: boolean | null;
      completedOn?: Date | null;
      updatedAt?: Date | null;
      user: {
        id: number;
        organization?: {
          id: number;
          latestCarePeriod?: { id: number; endDate: string } | null;
        } | null;
      };
    }>;
    dataSharingRequest?: {
      canProviderSendReminder: boolean;
      lastReminderSentDate?: Date | null;
      createdAt: Date;
      provider: { name: string };
    } | null;
    dataSharingConsent?: { isActive: boolean; startDate: Date; endDate: Date } | null;
    campusTeam?: Array<{
      relationshipType: UniversityRelationship;
      provider: {
        id: number;
        firstName?: string | null;
        lastName?: string | null;
        name: string;
        role: Role;
        classification: string;
        portrait?: { url: string } | null;
        organizations: Array<{ id: number; name: string }>;
      };
    }> | null;
    hubOnboardingStatus?: { isHubEnabled: boolean; hasHubOnboarded: boolean } | null;
  };
  events: Array<{ id: number; tag: string; createdAt: Date; data?: { [key: string]: any } | null }>;
  careNavigators: Array<{ id: number }>;
};

export type UserHealthieStatusQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type UserHealthieStatusQuery = { userHealthieStatus: { userIsInitialized: boolean } };

export type UserCoachingHealthieStatusQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type UserCoachingHealthieStatusQuery = {
  userCoachingHealthieStatus: { userCoachingHealthieStatus?: CoachingStatus | null };
};

export type AdminUserViewQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type AdminUserViewQuery = {
  adminUser: { id: number; recordViewStatus: UserRecordViewStatus };
};

export type ZendeskLinkQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type ZendeskLinkQuery = { zendeskLink: string };

export type AssessmentResultsQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type AssessmentResultsQuery = {
  results: Array<{
    id: number;
    createdAt: Date;
    provider?: { id: number; name: string; role: Role; geoStates: Array<StateCodes> } | null;
    questionnaires: Array<{
      key: string;
      score?: number | null;
      symptoms?: string | null;
      description: string;
      answers: Array<{
        text?: string | null;
        textForProviders?: string | null;
        score?: number | null;
        key: string;
        answer?: string | null;
        multiText?: Array<string> | null;
      }>;
    }>;
  }>;
};

export type OutreachQueryVariables = Exact<{
  userId: Scalars['Int'];
  userIdFloat: Scalars['Float'];
}>;

export type OutreachQuery = {
  adminUser: { unsubscribedAt?: Date | null };
  userEmails: Array<{
    id: number;
    subject: string;
    sent: boolean;
    delivered: boolean;
    opened: boolean;
    bounced: boolean;
    sentAt: Date;
    outcome?: string | null;
  }>;
  userSmses: Array<{ id: number; description: string; createdAt: Date }>;
  userOutreachAttempts: Array<{
    id: number;
    createdAt: Date;
    updatedAt: Date;
    date: string;
    subject: string;
    methods: Array<string>;
    outcome: string;
    attemptNumber?: number | null;
    description?: string | null;
    createdByCustom?: string | null;
    createdBy?: { id: number; name: string } | null;
  }>;
  admins: Array<{ id: number; name: string }>;
};

export type UserNotificationsQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type UserNotificationsQuery = {
  adminUserNotifications: Array<{
    id: number;
    createdAt: Date;
    key: UserNotificationKey;
    data: { [key: string]: any };
    isDismissible: boolean;
    isRequired?: boolean | null;
  }>;
};

export type BeginSponsoredCareReenrollmentMutationVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type BeginSponsoredCareReenrollmentMutation = { beginSponsoredCareReenrollment: boolean };

export type CancelSponsoredCareReenrollmentMutationVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type CancelSponsoredCareReenrollmentMutation = { cancelSponsoredCareReenrollment: boolean };

export type SuggestedProviderUserQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type SuggestedProviderUserQuery = {
  adminUser: {
    id: number;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
    birthDate?: Date | null;
    suggestedProviders: Array<{ id: number }>;
    organization?: { id: number } | null;
  };
};

export type VideoCallDataFragment = {
  id: number;
  status: string;
  startTime: Date;
  endTime: Date;
  videoSessionId: string;
  videoToken: string;
  appointmentType: string;
  description?: string | null;
  adminHostCrisisId?: string | null;
  adminCrisisTeamNotifiedAt?: Date | null;
  adminCrisisProtocolEngagedAt?: Date | null;
  user: {
    id: number;
    customerId?: string | null;
    createdAt: Date;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
    provider?: { id: number; name: string } | null;
    organization?: {
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
      currentCrisisHours?: { startTime: Date; endTime: Date } | null;
      crisisTeamProviders: Array<{ role: Role }>;
      entitlements: Array<{ key: Entitlement; config: { [key: string]: any } }>;
    } | null;
    selfie?: { id: number; url: string } | null;
  };
  provider: { id: number; name: string };
};

export type CrisisCalendarEventQueryVariables = Exact<{
  crisisId: Scalars['String'];
}>;

export type CrisisCalendarEventQuery = {
  maybeEvent: {
    blockedReason?: string | null;
    body?: string | null;
    data?: {
      id: number;
      status: string;
      startTime: Date;
      endTime: Date;
      videoSessionId: string;
      videoToken: string;
      appointmentType: string;
      description?: string | null;
      adminHostCrisisId?: string | null;
      adminCrisisTeamNotifiedAt?: Date | null;
      adminCrisisProtocolEngagedAt?: Date | null;
      user: {
        id: number;
        customerId?: string | null;
        createdAt: Date;
        firstName: string;
        lastName: string;
        preferredName?: string | null;
        provider?: { id: number; name: string } | null;
        organization?: {
          id: number;
          name: string;
          abbreviation: string;
          emailDomains: Array<string>;
          currentCrisisHours?: { startTime: Date; endTime: Date } | null;
          crisisTeamProviders: Array<{ role: Role }>;
          entitlements: Array<{ key: Entitlement; config: { [key: string]: any } }>;
        } | null;
        selfie?: { id: number; url: string } | null;
      };
      provider: { id: number; name: string };
    } | null;
  };
};

export type VideoCallSidebarQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type VideoCallSidebarQuery = {
  user: {
    birthDate?: Date | null;
    pronouns?: string | null;
    phone?: string | null;
    id: number;
    customerId?: string | null;
    createdAt: Date;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
    organization?: {
      safeOperatingHandbookUrl?: string | null;
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
      safeOperatingHandbook: {
        sessionLimitsRenew: string;
        noShowsCountForSessionLimits: string;
        defaultTherapyCadence: string;
        coPayChargedByPartner: string;
        labsNearCampus: string;
        ekgsNearCampus: string;
        vitalsNearCampus: string;
        highLevelProtocolManagingEmergency: string;
        campusSafetyPoliceNumber: string;
        supportingClinician: string;
        hospitalization: string;
        mainPointOfContactCrisis: string;
        campusCrisisTeamInProduct: string;
        requestingAcademicAccomodations: string;
        fullADHDEvaluation: string;
        protocolForMedicalLeaveDoc: string;
        winterBreakEligibility: string;
        summerBreakEligibility: string;
        hasManualReactivationEntitlement: boolean;
      };
    } | null;
    primaryAddress?: {
      name?: string | null;
      line1: string;
      line2?: string | null;
      city: string;
      state?: StateCodes | null;
      zip: string;
    } | null;
    emergencyContacts: Array<{
      id: number;
      name?: string | null;
      relationship?: string | null;
      phoneNumber?: string | null;
      primary?: boolean | null;
    }>;
  };
};

export type ProviderIsLoggedInQueryVariables = Exact<{ [key: string]: never }>;

export type ProviderIsLoggedInQuery = {
  providerIsLoggedIn: { isLoggedIn: boolean; authToken?: string | null };
};

export type CampusResourcesQueryVariables = Exact<{
  organizationId: Scalars['Float'];
}>;

export type CampusResourcesQuery = {
  organization: {
    resourcesUpdatedAt?: Date | null;
    resources: Array<{
      id: number;
      name: string;
      description?: string | null;
      position: number;
      resourceType: ResourceType;
      resourceSubtype?: string | null;
      showAsCrisisResource: boolean;
      contact?: string | null;
      location?: string | null;
      phone?: string | null;
      email?: string | null;
      hours?: string | null;
      website?: string | null;
      tags: Array<ResourceTag>;
    }>;
    resourcesUpdatedBy?: { id: number; name: string } | null;
  };
};

export type CreateOrUpdateOrganizationResourceMutationVariables = Exact<{
  organizationId: Scalars['Int'];
  resource: OrganizationResourceInput;
}>;

export type CreateOrUpdateOrganizationResourceMutation = {
  createOrUpdateOrganizationResource: { id: number };
};

export type DeleteOrganizationResourceMutationVariables = Exact<{
  resourceId: Scalars['Int'];
}>;

export type DeleteOrganizationResourceMutation = { deleteOrganizationResource: boolean };

export type DebugLoginAsUserMutationVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type DebugLoginAsUserMutation = { debugLoginAsUser: string };

export type DebugImpersonateAsUserMutationVariables = Exact<{
  userId: Scalars['Float'];
  reason: Scalars['String'];
}>;

export type DebugImpersonateAsUserMutation = { debugImpersonateAsUser: string };

export type TaskFragment = {
  id: number;
  ref: string;
  title: string;
  createdAt: Date;
  done: boolean;
  userId: number;
  updatedAt: Date;
};

export type UploadFragment = {
  id: number;
  createdAt: Date;
  contentType: string;
  name: string;
  url: string;
  type: UploadType;
  globallyShared?: boolean | null;
  canEdit?: boolean | null;
  createdByProvider?: { id: number; name: string } | null;
};

export type SafetyPlanContactFragment = {
  name: string;
  contact?: string | null;
  address?: string | null;
};

export type SafetyPlanDataFragment = {
  reasonToLive?: string | null;
  firearmAccess?: boolean | null;
  firearmAccessPlan?: string | null;
  dangerousMeans?: boolean | null;
  dangerousMeansList?: string | null;
  dangerousMeansPlan?: string | null;
  warningSigns: Array<string>;
  copingStrategies: Array<string>;
  distractions: Array<{ name: string; contact?: string | null; address?: string | null }>;
  crisisContacts: Array<{ name: string; contact?: string | null; address?: string | null }>;
  professionalContacts: Array<{ name: string; contact?: string | null; address?: string | null }>;
  crisisResources: Array<{ name: string; contact?: string | null; address?: string | null }>;
  crisisFacilities: Array<{ name: string; contact?: string | null; address?: string | null }>;
};

export type OrganizationFragment = {
  id: number;
  name: string;
  abbreviation: string;
  emailDomains: Array<string>;
};

export type OrganizationUtilizationFragment = {
  organizationUtilization?: {
    carePeriodUtilizations: Array<{
      carePeriod: { startDate: string; endDate: string; periodType: PeriodType };
      weeklyUtilizationRecords: Array<{
        id: number;
        organizationId: number;
        startDate: string;
        endDate: string;
        therapyHours: {
          maxHours?: number | null;
          maxIntakeHours?: number | null;
          hoursUtilized?: number | null;
          hoursScheduled?: number | null;
          intakesUtilized?: number | null;
          intakesScheduled?: number | null;
        };
        psychiatryHours: {
          maxHours?: number | null;
          maxIntakeHours?: number | null;
          hoursUtilized?: number | null;
          hoursScheduled?: number | null;
          intakesUtilized?: number | null;
          intakesScheduled?: number | null;
        };
      }>;
    }>;
  } | null;
};

export type ProviderFragment = {
  id: number;
  name: string;
  firstName?: string | null;
  lastName?: string | null;
  classification: string;
  role: Role;
  email?: string | null;
  geoStates: Array<StateCodes>;
  postNominalTitles?: string | null;
  personalTitle?: string | null;
  careTypes: Array<CareType>;
  portrait?: { url: string } | null;
  organizations: Array<{
    id: number;
    name: string;
    abbreviation: string;
    emailDomains: Array<string>;
  }>;
};

export type BaseProviderFragment = {
  id: number;
  name: string;
  role: Role;
  geoStates: Array<StateCodes>;
};

export type AppointmentAttendeeFragment = {
  id: number;
  firstName: string;
  preferredName?: string | null;
  lastName: string;
  customerId?: string | null;
  provider?: { id: number; name: string; role: Role; careTypes: Array<CareType> } | null;
  therapist?: { id: number; name: string; role: Role } | null;
  organization?: {
    id: number;
    name: string;
    abbreviation: string;
    emailDomains: Array<string>;
  } | null;
};

export type BasicAppointmentUserFragment = {
  id: number;
  firstName: string;
  preferredName?: string | null;
  lastName: string;
  customerId?: string | null;
  careTypes: Array<CareType>;
  primaryAddressState?: string | null;
  therapyUpcoming?: { id: number } | null;
  therapyComplete?: { id: number } | null;
  psychUpcoming?: { id: number } | null;
  psychComplete?: { id: number } | null;
};

export type AddressFragment = {
  name?: string | null;
  line1: string;
  line2?: string | null;
  city: string;
  state?: StateCodes | null;
  zip: string;
};

export type PharmacyFragment = {
  pharmacyId: number;
  storeName: string;
  address1: string;
  address2?: string | null;
  city: string;
  state: string;
  zipCode: string;
  primaryPhone?: string | null;
  primaryFax?: string | null;
  serviceLevel?: number | null;
  latitude?: number | null;
  longitude?: number | null;
  primary?: boolean | null;
};

export type InsuranceClaimEventFragment = {
  id: number;
  updatedAt: Date;
  action: ClaimAction;
  status: InternalStatus;
  details: string;
  actor?: { name: string } | null;
};

export type UserNamesFragment = {
  firstName: string;
  lastName: string;
  preferredName?: string | null;
};

export type UserBasicFragment = {
  id: number;
  customerId?: string | null;
  createdAt: Date;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
};

export type UserDetailFragment = {
  id: number;
  customerId?: string | null;
  createdAt: Date;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  organization?: {
    id: number;
    name: string;
    abbreviation: string;
    emailDomains: Array<string>;
  } | null;
  selfie?: {
    id: number;
    createdAt: Date;
    contentType: string;
    name: string;
    url: string;
    type: UploadType;
    globallyShared?: boolean | null;
    canEdit?: boolean | null;
    createdByProvider?: { id: number; name: string } | null;
  } | null;
};

export type MonitorUserFragment = {
  id: number;
  customerId?: string | null;
  createdAt: Date;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  assessmentInfo: { riskLevel?: string | null; suicidal?: Array<string> | null };
  safetyPlan?: { complete: boolean } | null;
};

export type SentEmailFragment = {
  id: number;
  sentAt: Date;
  template: string;
  subject: string;
  delivered: boolean;
  bounced: boolean;
  opened: boolean;
};

export type AppointmentTemplateFragment = {
  id: number;
  description: string;
  duration: number;
  appointmentType: string;
  careType: CareType;
};

export type CareFlowFragment = {
  id: number;
  careType: CareType;
  careStatus: CareStatus;
  paymentSource: PaymentSource;
};

export type OrganizationCareFlowFragment = {
  careType: CareType;
  canReferrerUsersRefer: boolean;
  canMcpUsersRefer: boolean;
  defaultPaymentSource: PaymentSource;
};

export type CareFlowAndSessionsFragment = {
  id: number;
  careType: CareType;
  careStatus: CareStatus;
  paymentSource: PaymentSource;
  careSessionInfo?: {
    unlimitedAppts: boolean;
    remainingAppts?: number | null;
    flowLimit?: number | null;
    completedAppts: number;
  } | null;
};

export type CareSessionInfoFragment = {
  unlimitedAppts: boolean;
  remainingAppts?: number | null;
  flowLimit?: number | null;
  completedAppts: number;
};

export type CouponFragment = {
  id: number;
  code: string;
  discountType: DiscountType;
  percentDiscount?: number | null;
  replacePriceCents?: number | null;
  maxUses: number;
  active: boolean;
  autoApplyOnConversion: boolean;
  careTypes: Array<CareType>;
  organization?: { id: number; name: string } | null;
};

export type PayerFragment = {
  id: number;
  name: string;
  inNetworkStates: Array<string>;
  visible: boolean;
  tradingPartnerId: string;
  standardPayerId: string;
  billToProviderName: boolean;
  useAddressForAllStates?: string | null;
  stateAddressOverrides: Array<{ overrideForState: StateCodes; stateToUseInstead: StateCodes }>;
};

export type BaseReportFragment = {
  activeStudentsCount: number;
  referralCompletionRate?: number | null;
  averageReferralToVisitTime?: number | null;
  patientMonitoring: Array<{
    id: number;
    customerId?: string | null;
    createdAt: Date;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
    assessmentInfo: { riskLevel?: string | null; suicidal?: Array<string> | null };
    safetyPlan?: { complete: boolean } | null;
  }>;
  careTeamReferrals: Array<{
    referralsMade: number;
    activePatients: number;
    activeReferrals: number;
    provider: { id: number; name: string; role: Role; portrait?: { url: string } | null };
  }>;
  visitAttendance: {
    carePeriod?: { startDate: string } | null;
    attendance: Array<{ key: string; value: number }>;
  };
  visitSatisfactionRatings: { averageRating?: number | null; numberOfRatings: number };
};

export type DiagnosisReportFragment = {
  diagnosesBreakdown: Array<{
    userId: number;
    code: string;
    key: string;
    category: string;
    subCategory?: string | null;
  }>;
};

export type UserNotificationFragment = {
  id: number;
  createdAt: Date;
  key: UserNotificationKey;
  data: { [key: string]: any };
  isDismissible: boolean;
  isRequired?: boolean | null;
};

export type OrganizationEntitlementFragment = { key: Entitlement; config: { [key: string]: any } };

export type OrganizationHandbookFragment = {
  sessionLimitsRenew: string;
  noShowsCountForSessionLimits: string;
  defaultTherapyCadence: string;
  coPayChargedByPartner: string;
  labsNearCampus: string;
  ekgsNearCampus: string;
  vitalsNearCampus: string;
  highLevelProtocolManagingEmergency: string;
  campusSafetyPoliceNumber: string;
  supportingClinician: string;
  hospitalization: string;
  mainPointOfContactCrisis: string;
  campusCrisisTeamInProduct: string;
  requestingAcademicAccomodations: string;
  fullADHDEvaluation: string;
  protocolForMedicalLeaveDoc: string;
  winterBreakEligibility: string;
  summerBreakEligibility: string;
  hasManualReactivationEntitlement: boolean;
};

export type MessageFragment = {
  id: string;
  content: string;
  providerId?: number | null;
  userId?: number | null;
  createdAt: Date;
};

export type ListChannelFragment = {
  id: number;
  unreadMessageCount: number;
  lastMessage?: {
    id: string;
    content: string;
    providerId?: number | null;
    userId?: number | null;
    createdAt: Date;
    provider?: {
      id: number;
      name: string;
      portrait?: {
        id: number;
        createdAt: Date;
        contentType: string;
        name: string;
        url: string;
        type: UploadType;
        globallyShared?: boolean | null;
        canEdit?: boolean | null;
        createdByProvider?: { id: number; name: string } | null;
      } | null;
    } | null;
  } | null;
};

export type UserMessagesQueryVariables = Exact<{
  id: Scalars['Float'];
  providerId?: InputMaybe<Scalars['Float']>;
}>;

export type UserMessagesQuery = {
  user: {
    id: number;
    channel: {
      id: number;
      unreadMessageCount: number;
      messages: Array<{
        id: string;
        content: string;
        providerId?: number | null;
        userId?: number | null;
        createdAt: Date;
        attachments: Array<{
          id: number;
          createdAt: Date;
          contentType: string;
          name: string;
          url: string;
          type: UploadType;
          globallyShared?: boolean | null;
          canEdit?: boolean | null;
          createdByProvider?: { id: number; name: string } | null;
        }>;
      }>;
    };
  };
};

export type UserCareTeamChannelQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type UserCareTeamChannelQuery = {
  user: {
    channel: {
      id: number;
      unreadMessageCount: number;
      uniqProviders: Array<{
        id: number;
        name: string;
        firstName?: string | null;
        lastName?: string | null;
        classification: string;
        role: Role;
        email?: string | null;
        geoStates: Array<StateCodes>;
        postNominalTitles?: string | null;
        personalTitle?: string | null;
        careTypes: Array<CareType>;
        portrait?: { url: string } | null;
        organizations: Array<{
          id: number;
          name: string;
          abbreviation: string;
          emailDomains: Array<string>;
        }>;
      }>;
      messages: Array<{
        id: string;
        content: string;
        providerId?: number | null;
        userId?: number | null;
        createdAt: Date;
      }>;
    };
  };
};

export type MessageUsersQueryVariables = Exact<{
  providerId?: InputMaybe<Scalars['Float']>;
  privateChannels: Scalars['Boolean'];
  careTeamChannels: Scalars['Boolean'];
}>;

export type MessageUsersQuery = {
  careTeamChannels?: Array<{
    id: number;
    user: {
      id: number;
      customerId?: string | null;
      createdAt: Date;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      organization?: {
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      } | null;
      selfie?: {
        id: number;
        createdAt: Date;
        contentType: string;
        name: string;
        url: string;
        type: UploadType;
        globallyShared?: boolean | null;
        canEdit?: boolean | null;
        createdByProvider?: { id: number; name: string } | null;
      } | null;
    };
    channel: {
      id: number;
      unreadMessageCount: number;
      lastMessage?: {
        id: string;
        content: string;
        providerId?: number | null;
        userId?: number | null;
        createdAt: Date;
        provider?: {
          id: number;
          name: string;
          portrait?: {
            id: number;
            createdAt: Date;
            contentType: string;
            name: string;
            url: string;
            type: UploadType;
            globallyShared?: boolean | null;
            canEdit?: boolean | null;
            createdByProvider?: { id: number; name: string } | null;
          } | null;
        } | null;
      } | null;
    };
  }>;
  privateChannels?: Array<{
    id: number;
    user: {
      id: number;
      customerId?: string | null;
      createdAt: Date;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      organization?: {
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      } | null;
      selfie?: {
        id: number;
        createdAt: Date;
        contentType: string;
        name: string;
        url: string;
        type: UploadType;
        globallyShared?: boolean | null;
        canEdit?: boolean | null;
        createdByProvider?: { id: number; name: string } | null;
      } | null;
    };
    channel: {
      id: number;
      unreadMessageCount: number;
      lastMessage?: {
        id: string;
        content: string;
        providerId?: number | null;
        userId?: number | null;
        createdAt: Date;
        provider?: {
          id: number;
          name: string;
          portrait?: {
            id: number;
            createdAt: Date;
            contentType: string;
            name: string;
            url: string;
            type: UploadType;
            globallyShared?: boolean | null;
            canEdit?: boolean | null;
            createdByProvider?: { id: number; name: string } | null;
          } | null;
        } | null;
      } | null;
    };
  }>;
};

export type SendCareTeamMessageMutationVariables = Exact<{
  userId: Scalars['Float'];
  content: Scalars['String'];
}>;

export type SendCareTeamMessageMutation = {
  sendCareTeamMessage: {
    id: string;
    content: string;
    providerId?: number | null;
    userId?: number | null;
    createdAt: Date;
  };
};

export type SendMessageToMutationVariables = Exact<{
  userId: Scalars['Float'];
  content: Scalars['String'];
  attachmentIds?: InputMaybe<Array<Scalars['Float']> | Scalars['Float']>;
}>;

export type SendMessageToMutation = {
  providerMessagePatient: {
    id: string;
    content: string;
    providerId?: number | null;
    userId?: number | null;
    createdAt: Date;
    attachments: Array<{
      id: number;
      createdAt: Date;
      contentType: string;
      name: string;
      url: string;
      type: UploadType;
      globallyShared?: boolean | null;
      canEdit?: boolean | null;
      createdByProvider?: { id: number; name: string } | null;
    }>;
  };
};

export type MarkPrivateChannelsMutationVariables = Exact<{
  channels: Array<Scalars['Float']> | Scalars['Float'];
}>;

export type MarkPrivateChannelsMutation = { providerMarkPrivateChannelsAsRead: boolean };

export type MarkCareTeamChannelsMutationVariables = Exact<{
  channels: Array<Scalars['Float']> | Scalars['Float'];
}>;

export type MarkCareTeamChannelsMutation = { markCareTeamChannelsAsRead: boolean };

export type MinorConsentStatusForUserQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type MinorConsentStatusForUserQuery = {
  minorConsentStatusForUser: { status?: MinorConsentStatus | null };
};

export type MinorConsentApproveUserMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type MinorConsentApproveUserMutation = { minorConsentApproveUser: boolean };

export type MinorConsentOverrideUserMutationVariables = Exact<{
  userId: Scalars['Int'];
  birthDate: Scalars['UserBirthdate'];
}>;

export type MinorConsentOverrideUserMutation = { minorConsentOverrideUser: boolean };

export type AdminAuthenticateMutationVariables = Exact<{
  password: Scalars['String'];
  email: Scalars['String'];
  deviceToken?: InputMaybe<Scalars['String']>;
}>;

export type AdminAuthenticateMutation = {
  adminAuthenticate: {
    passwordCorrect: boolean;
    authToken?: string | null;
    twoFactorNeeded: boolean;
    firstFactorResult?: { firstFactorToken: string; phoneLastFour: string } | null;
  };
};

export type ResendTwoFactorCodeMutationVariables = Exact<{
  firstFactorToken: Scalars['String'];
}>;

export type ResendTwoFactorCodeMutation = { resendTwoFactorCode: boolean };

export type AdminEnterSecondFactorMutationVariables = Exact<{
  firstFactorToken: Scalars['String'];
  code: Scalars['String'];
}>;

export type AdminEnterSecondFactorMutation = {
  adminEnterSecondFactor: {
    authToken?: string | null;
    codeValid: boolean;
    deviceToken?: string | null;
  };
};

export type ForgotProviderPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ForgotProviderPasswordMutation = { forgotProviderPassword: boolean };

export type ChangeProviderPasswordMutationVariables = Exact<{
  password: Scalars['String'];
}>;

export type ChangeProviderPasswordMutation = { changeProviderPassword: boolean };

export type AdminBeginUploadMutationVariables = Exact<{
  contentType: Scalars['String'];
  userId?: InputMaybe<Scalars['Float']>;
}>;

export type AdminBeginUploadMutation = {
  adminBeginUpload: { url: string; key: string; fields: Array<Array<string>> };
};

export type AdminCompleteProviderPhotoUploadMutationVariables = Exact<{
  key?: InputMaybe<Scalars['String']>;
  providerId: Scalars['Float'];
}>;

export type AdminCompleteProviderPhotoUploadMutation = {
  adminCompleteProviderPhotoUpload: { id: number };
};

export type AdminCancelAppointmentMutationVariables = Exact<{
  id: Scalars['Float'];
  outOfPolicy?: InputMaybe<Scalars['Boolean']>;
}>;

export type AdminCancelAppointmentMutation = {
  adminCancelAppointment: { id: number; status: string };
};

export type AdminMarkUpcomingMutationVariables = Exact<{
  id: Scalars['Float'];
}>;

export type AdminMarkUpcomingMutation = { adminMarkUpcoming: boolean };

export type AdminRescheduleAppointmentMutationVariables = Exact<{
  id: Scalars['Float'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  outOfPolicy?: InputMaybe<Scalars['Boolean']>;
}>;

export type AdminRescheduleAppointmentMutation = {
  adminRescheduleAppointment: { id: number; status: string };
};

export type AdminCreateUserMutationVariables = Exact<{
  input: CreateUser;
}>;

export type AdminCreateUserMutation = { adminCreateUser: { id: number } };

export type UpsertUserEmergencyContactMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Float']>;
  userId: Scalars['Float'];
  name: Scalars['String'];
  relationship: Scalars['String'];
  phoneNumber: Scalars['String'];
  primary?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpsertUserEmergencyContactMutation = { upsertEmergencyContact: { id: number } };

export type DeleteEmergencyContactMutationVariables = Exact<{
  id: Scalars['Float'];
}>;

export type DeleteEmergencyContactMutation = { deleteEmergencyContact: { id: number } };

export type SwapPrimaryEmergencyContactMutationVariables = Exact<{
  id: Scalars['Float'];
  userId: Scalars['Float'];
}>;

export type SwapPrimaryEmergencyContactMutation = { swapPrimaryEmergencyContact: { id: number } };

export type AdminCreateStaffMutationVariables = Exact<{
  input: CreateStaff;
}>;

export type AdminCreateStaffMutation = { adminCreateStaff: { id: number } };

export type AdminUpdateProviderMutationVariables = Exact<{
  input: UpdateProvider;
}>;

export type AdminUpdateProviderMutation = { adminUpdateProvider: { id: number } };

export type EditSafetyPlanMutationVariables = Exact<{
  userId: Scalars['Float'];
  plan: SafetyPlanInput;
}>;

export type EditSafetyPlanMutation = {
  adminEditSafetyPlan: {
    data: {
      reasonToLive?: string | null;
      firearmAccess?: boolean | null;
      firearmAccessPlan?: string | null;
      dangerousMeans?: boolean | null;
      dangerousMeansList?: string | null;
      dangerousMeansPlan?: string | null;
      warningSigns: Array<string>;
      copingStrategies: Array<string>;
      distractions: Array<{ name: string; contact?: string | null; address?: string | null }>;
      crisisContacts: Array<{ name: string; contact?: string | null; address?: string | null }>;
      professionalContacts: Array<{
        name: string;
        contact?: string | null;
        address?: string | null;
      }>;
      crisisResources: Array<{ name: string; contact?: string | null; address?: string | null }>;
      crisisFacilities: Array<{ name: string; contact?: string | null; address?: string | null }>;
    };
  };
};

export type ActivateSafetyPlanMutationVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type ActivateSafetyPlanMutation = {
  activateSafetyPlan: {
    id: number;
    data: {
      reasonToLive?: string | null;
      firearmAccess?: boolean | null;
      firearmAccessPlan?: string | null;
      dangerousMeans?: boolean | null;
      dangerousMeansList?: string | null;
      dangerousMeansPlan?: string | null;
      warningSigns: Array<string>;
      copingStrategies: Array<string>;
      distractions: Array<{ name: string; contact?: string | null; address?: string | null }>;
      crisisContacts: Array<{ name: string; contact?: string | null; address?: string | null }>;
      professionalContacts: Array<{
        name: string;
        contact?: string | null;
        address?: string | null;
      }>;
      crisisResources: Array<{ name: string; contact?: string | null; address?: string | null }>;
      crisisFacilities: Array<{ name: string; contact?: string | null; address?: string | null }>;
    };
  };
};

export type AdminScheduleAppointmentMutationVariables = Exact<{
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  userId: Scalars['Float'];
  providerId: Scalars['Float'];
  appointmentType: Scalars['String'];
  organizationId?: InputMaybe<Scalars['Float']>;
  isFeeForServiceTime?: InputMaybe<Scalars['Boolean']>;
}>;

export type AdminScheduleAppointmentMutation = {
  adminScheduleAppointment: {
    id: number;
    description?: string | null;
    startTime: Date;
    endTime: Date;
  };
};

export type ChangePasswordAndAcceptTosMutationVariables = Exact<{
  password: Scalars['String'];
}>;

export type ChangePasswordAndAcceptTosMutation = {
  changeProviderPassword: boolean;
  adminAcceptTermsOfService: boolean;
};

export type AcceptTosMutationVariables = Exact<{ [key: string]: never }>;

export type AcceptTosMutation = { adminAcceptTermsOfService: boolean };

export type VerifyUserMutationVariables = Exact<{
  id: Scalars['Float'];
  verify: Scalars['Boolean'];
}>;

export type VerifyUserMutation = { setUserVerified: boolean };

export type MarkTaskDoneMutationVariables = Exact<{
  taskId: Scalars['Float'];
}>;

export type MarkTaskDoneMutation = { adminMarkTaskDone: boolean };

export type UndoTaskDoneMutationVariables = Exact<{
  taskId: Scalars['Float'];
}>;

export type UndoTaskDoneMutation = { adminUndoTaskDone: boolean };

export type AdminEndCallMutationVariables = Exact<{
  userId: Scalars['Float'];
  appointmentId: Scalars['Float'];
}>;

export type AdminEndCallMutation = { adminEndCall: boolean };

export type AdminMarkNoShowMutationVariables = Exact<{
  userId: Scalars['Float'];
  appointmentId: Scalars['Float'];
}>;

export type AdminMarkNoShowMutation = { adminMarkNoShow: boolean };

export type RefreshProviderLoginMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshProviderLoginMutation = { refreshProviderLogin: string };

export type AdminEditUserMutationVariables = Exact<{
  editUser: EditUser;
}>;

export type AdminEditUserMutation = { adminEditUser: { id: number; email: string } };

export type SetPharmacyMutationVariables = Exact<{
  pharmacyId?: InputMaybe<Scalars['Float']>;
  userId: Scalars['Float'];
}>;

export type SetPharmacyMutation = { adminSetPharmacyOnPatient: boolean };

export type AdminContinuityOfCareExportMutationVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type AdminContinuityOfCareExportMutation = {
  adminContinuityOfCareExport: { id: number; status: string; url?: string | null };
};

export type FinishPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
}>;

export type FinishPasswordResetMutation = { finishProviderPasswordReset: boolean };

export type CreateNoteForClaimMutationVariables = Exact<{
  note: Scalars['String'];
  id: Scalars['Float'];
}>;

export type CreateNoteForClaimMutation = { createNoteForClaim: { id: number } };

export type ChangeStatusOfClaimMutationVariables = Exact<{
  status: InternalStatus;
  details: Scalars['String'];
  claimId: Scalars['Float'];
}>;

export type ChangeStatusOfClaimMutation = { changeStatusOfClaim: { id: number } };

export type AdminCreateTestUserMutationVariables = Exact<{
  args: CreateTestUser;
}>;

export type AdminCreateTestUserMutation = {
  adminCreateTestUser: {
    id: number;
    customerId?: string | null;
    createdAt: Date;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
  };
};

export type UpdateCareteamPreferencesMutationVariables = Exact<{
  preferences: Array<CareTeamPreference> | CareTeamPreference;
}>;

export type UpdateCareteamPreferencesMutation = { updatePreferences: boolean };

export type AdminPasswordlessLoginMutationVariables = Exact<{
  email: Scalars['String'];
  token: Scalars['String'];
}>;

export type AdminPasswordlessLoginMutation = { adminPasswordlessLogin: string };

export type AdminResetProviderPasswordMutationVariables = Exact<{
  id: Scalars['Float'];
}>;

export type AdminResetProviderPasswordMutation = { adminResetProviderPassword: boolean };

export type SyncHealthieProviderMutationVariables = Exact<{
  id: Scalars['Float'];
}>;

export type SyncHealthieProviderMutation = { syncHealthieProvider: boolean };

export type NetworkSearchAccommodationRequestMutationVariables = Exact<{
  accommodationRequestDetails: Scalars['String'];
}>;

export type NetworkSearchAccommodationRequestMutation = {
  networkSearchAccommodationRequest: boolean;
};

export type MarkReferralCompleteMutationVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type MarkReferralCompleteMutation = { markReferralComplete: boolean };

export type EmailUnsubscribeMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type EmailUnsubscribeMutation = { emailUnsubscribe: boolean };

export type AdminCompleteOrganizationPhotoUploadMutationVariables = Exact<{
  key: Scalars['String'];
  organizationId: Scalars['Float'];
}>;

export type AdminCompleteOrganizationPhotoUploadMutation = {
  adminCompleteOrganizationPhotoUpload: boolean;
};

export type AdminCreateOrganizationMutationVariables = Exact<{
  input: CreateOrganization;
}>;

export type AdminCreateOrganizationMutation = {
  createOrganization: {
    id: number;
    name: string;
    abbreviation: string;
    emailDomains: Array<string>;
  };
};

export type AdminCompleteUploadMutationVariables = Exact<{
  input: CreateFileUpload;
}>;

export type AdminCompleteUploadMutation = { adminCompleteUpload: { id: number; name: string } };

export type AdminUpdateUploadMutationVariables = Exact<{
  input: UpdateFileUpload;
}>;

export type AdminUpdateUploadMutation = { adminUpdateUpload: boolean };

export type AdminDeleteUploadMutationVariables = Exact<{
  uploadId: Scalars['Float'];
}>;

export type AdminDeleteUploadMutation = { adminDeleteUpload: boolean };

export type UpdateCouponMutationVariables = Exact<{
  updateCoupon: UpdateCoupon;
}>;

export type UpdateCouponMutation = { updateCoupon: { id: number } };

export type UpdateBioMutationVariables = Exact<{
  providerId: Scalars['Float'];
  qualifications: Array<ProviderQualificationInput> | ProviderQualificationInput;
  education: Array<ProviderEducationInput> | ProviderEducationInput;
  certifications: Array<ProviderCertificationInput> | ProviderCertificationInput;
}>;

export type UpdateBioMutation = {
  updateQualifications: Array<{ id: number }>;
  updateEducation: Array<{ id: number }>;
  updateCertifications: Array<{ id: number }>;
};

export type UpdateProviderOrganizationsMutationVariables = Exact<{
  providerId: Scalars['Float'];
  organizationIds: Array<Scalars['Float']> | Scalars['Float'];
}>;

export type UpdateProviderOrganizationsMutation = { updateOrganizations: { id: number } };

export type CreateProviderMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  classification: Scalars['String'];
  postNominalTitles?: InputMaybe<Scalars['String']>;
  careTypes: Array<CareType> | CareType;
  funFact?: InputMaybe<Scalars['String']>;
  geoStates: Array<Scalars['String']> | Scalars['String'];
  npi: Scalars['String'];
  phone: Scalars['String'];
  withTwoFactor?: InputMaybe<Scalars['Boolean']>;
}>;

export type CreateProviderMutation = { createProvider: { id: number } };

export type TriggerPasswordlessAuthFlowMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type TriggerPasswordlessAuthFlowMutation = { triggerPasswordlessAuthFlow: boolean };

export type UpdateEntitlementsMutationVariables = Exact<{
  organizationId: Scalars['Float'];
  entitlements: Array<UpdateOrganizationEntitlementInput> | UpdateOrganizationEntitlementInput;
}>;

export type UpdateEntitlementsMutation = { updateEntitlements: boolean };

export type CreateCareTransitionMutationVariables = Exact<{
  transition: CreateCareTransition;
}>;

export type CreateCareTransitionMutation = { adminCreateCareTransition: { id: number } };

export type UpdateOrganizationCarePeriodsMutationVariables = Exact<{
  organizationId: Scalars['Int'];
  newPeriods: Array<NewOrganizationCarePeriod> | NewOrganizationCarePeriod;
  editedPeriods: Array<EditOrganizationCarePeriod> | EditOrganizationCarePeriod;
}>;

export type UpdateOrganizationCarePeriodsMutation = {
  updateOrganizationCarePeriods: Array<{ id: number }>;
};

export type UpdateOrganizationDedicatedGroupModelAllocationsMutationVariables = Exact<{
  organizationId: Scalars['Int'];
  editedOrganizationDedicatedGroupModelAllocations:
    | Array<OrganizationDedicatedGroupModelAllocationInput>
    | OrganizationDedicatedGroupModelAllocationInput;
}>;

export type UpdateOrganizationDedicatedGroupModelAllocationsMutation = {
  updateOrganizationDedicatedGroupModelAllocations: boolean;
};

export type UpdateContractWeeksMutationVariables = Exact<{
  input: EditContractWeeksInput;
}>;

export type UpdateContractWeeksMutation = {
  __typename: 'Mutation';
  editContractWeeks:
    | { aggregateErrors: Array<{ weekId: number; errorCode: EditContractWeekErrorCode }> }
    | { successMessage: string };
};

export type UpdateOrganizationCareFlowsMutationVariables = Exact<{
  organizationId: Scalars['Int'];
  careFlows: Array<OrganizationCareFlowInput> | OrganizationCareFlowInput;
}>;

export type UpdateOrganizationCareFlowsMutation = {
  updateOrganizationCareFlows: Array<{ id: number }>;
};

export type UpsertInsuranceCredentialMutationVariables = Exact<{
  credential: UpsertProviderInsuranceCredential;
}>;

export type UpsertInsuranceCredentialMutation = { upsertInsuranceCredential: { id: number } };

export type DeleteInsuranceCredentialMutationVariables = Exact<{
  id: Scalars['Float'];
}>;

export type DeleteInsuranceCredentialMutation = { deleteInsuranceCredential: boolean };

export type ReactivateUserMutationVariables = Exact<{
  userId: Scalars['Float'];
  careType: CareType;
  paymentSource?: InputMaybe<PaymentSource>;
}>;

export type ReactivateUserMutation = {
  reactivateUserCareFlow: { id: number; careStatus: CareStatus };
};

export type SetUserInsuranceMutationVariables = Exact<{
  userId: Scalars['Int'];
  insurance: InsuranceDetailsInput;
}>;

export type SetUserInsuranceMutation = { setUserInsurance: { valid: boolean; inNetwork: boolean } };

export type SetProviderSubscribedMutationVariables = Exact<{
  providerId: Scalars['Int'];
  subscribed: Scalars['Boolean'];
}>;

export type SetProviderSubscribedMutation = { setProviderSubscribed: boolean };

export type SetUserSubscribedMutationVariables = Exact<{
  userId: Scalars['Int'];
  subscribed: Scalars['Boolean'];
}>;

export type SetUserSubscribedMutation = { setUserSubscribed: boolean };

export type AdminTransitionForCarePeriodMutationVariables = Exact<{
  carePeriodId: Scalars['Float'];
}>;

export type AdminTransitionForCarePeriodMutation = { adminTransitionForCarePeriod: boolean };

export type ResubmitClaimMutationVariables = Exact<{
  noteId: Scalars['Int'];
}>;

export type ResubmitClaimMutation = { resubmitClaim: boolean };

export type CreateOrUpdatePayerMutationVariables = Exact<{
  params: UpdatePayerParams;
}>;

export type CreateOrUpdatePayerMutation = { createOrUpdatePayer: { id: number } };

export type UpdateOrganizationMutationVariables = Exact<{
  update: UpdateOrganization;
}>;

export type UpdateOrganizationMutation = { updateOrganization: { id: number } };

export type UpdateAppointmentTemplatesMutationVariables = Exact<{
  organizationId: Scalars['Int'];
  templates: Array<AppointmentTemplateUpdate> | AppointmentTemplateUpdate;
}>;

export type UpdateAppointmentTemplatesMutation = { updateAppointmentTemplates: boolean };

export type UpdateSamlConfigurationMutationVariables = Exact<{
  organizationId: Scalars['Int'];
  type: SamlConfigurationType;
  update: UpdateSamlConfiguration;
}>;

export type UpdateSamlConfigurationMutation = { updateSamlConfiguration: { entryPoint: string } };

export type AdminSendTwoFactorCodeMutationVariables = Exact<{ [key: string]: never }>;

export type AdminSendTwoFactorCodeMutation = { adminSendTwoFactorCode: boolean };

export type AdminVerifyTwoFactorPhoneMutationVariables = Exact<{
  phone: Scalars['String'];
  code: Scalars['String'];
}>;

export type AdminVerifyTwoFactorPhoneMutation = { adminVerifyTwoFactorPhone: string };

export type AdminSetTwoFactorConfigMutationVariables = Exact<{
  phone: Scalars['String'];
  enabled?: InputMaybe<Scalars['Boolean']>;
  providerId?: InputMaybe<Scalars['Float']>;
}>;

export type AdminSetTwoFactorConfigMutation = { adminSetTwoFactorConfig: boolean };

export type RecheckInsuranceEligibilityMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type RecheckInsuranceEligibilityMutation = { recheckInsuranceEligibility: boolean };

export type TrackMutationVariables = Exact<{
  tag: Scalars['String'];
  data?: InputMaybe<Scalars['JSON']>;
}>;

export type TrackMutation = { track: boolean };

export type UpdateProviderAndTraitsMutationVariables = Exact<{
  updateProvider: UpdateProvider;
  providerId: Scalars['Int'];
  traits: Array<ProviderTraitInput> | ProviderTraitInput;
}>;

export type UpdateProviderAndTraitsMutation = {
  updateProviderTraits: boolean;
  adminUpdateProvider: { id: number };
};

export type ClearProviderLockoutMutationVariables = Exact<{
  providerId: Scalars['Int'];
}>;

export type ClearProviderLockoutMutation = { clearProviderLockout: boolean };

export type UpdateEligibilityCriteriaMutationVariables = Exact<{
  organizationId: Scalars['Int'];
  criteria: Array<OrganizationEligibilityCriteriaInput> | OrganizationEligibilityCriteriaInput;
}>;

export type UpdateEligibilityCriteriaMutation = { updateEligibilityCriteria: boolean };

export type CreateDataSharingRequestMutationVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type CreateDataSharingRequestMutation = { createDataSharingRequest: { id: number } };

export type SendDataSharingRequestReminderMutationVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type SendDataSharingRequestReminderMutation = { sendDataSharingRequestReminder: boolean };

export type ModifyAppointmentMutationVariables = Exact<{
  id: Scalars['Float'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
}>;

export type ModifyAppointmentMutation = { adminModifyAppointment: { id: number } };

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { logoutProvider: boolean };

export type UpdateOutreachAttemptMutationVariables = Exact<{
  attempt: OutreachAttemptInput;
}>;

export type UpdateOutreachAttemptMutation = { updateOutreachAttempt: boolean };

export type DeleteOutreachAttemptMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteOutreachAttemptMutation = { deleteOutreachAttempt: boolean };

export type SetSuggestedPharmacyIdMutationVariables = Exact<{
  organizationId: Scalars['Int'];
  pharmacyId?: InputMaybe<Scalars['Int']>;
}>;

export type SetSuggestedPharmacyIdMutation = { setSuggestedPharmacyId: boolean };

export type UpdateCareFlowForUserMutationVariables = Exact<{
  userId: Scalars['Float'];
  careFlow: UserCareFlowInput;
}>;

export type UpdateCareFlowForUserMutation = { updateCareFlowForUser: boolean };

export type AddUserCareEligibilitySubmissionMutationVariables = Exact<{
  submission: ContinuityOfCareEligibilityInput;
}>;

export type AddUserCareEligibilitySubmissionMutation = {
  addUserCareEligibilitySubmission: {
    providerInState: boolean;
    needsReferral: boolean;
    canContinueCare: boolean;
    paymentType: PaymentType;
    interestedInContinuingCare: boolean;
    enrollment: boolean;
    isEligible: boolean;
  };
};

export type DeleteUserCareEligibilitySubmissionMutationVariables = Exact<{
  id: Scalars['Float'];
  userId: Scalars['Float'];
}>;

export type DeleteUserCareEligibilitySubmissionMutation = {
  deleteUserCareEligibilitySubmission: boolean;
};

export type RegenerateProviderPasswordMutationVariables = Exact<{
  providerId: Scalars['Int'];
}>;

export type RegenerateProviderPasswordMutation = { regenerateProviderPassword: string };

export type AddProviderToCrisisTeamsMutationVariables = Exact<{
  organizationIds: Array<Scalars['Float']> | Scalars['Float'];
  providerId: Scalars['Float'];
  phone: Scalars['String'];
}>;

export type AddProviderToCrisisTeamsMutation = { addProviderToCrisisTeams: boolean };

export type RemoveProviderFromCrisisTeamsMutationVariables = Exact<{
  providerId: Scalars['Float'];
}>;

export type RemoveProviderFromCrisisTeamsMutation = { removeProviderFromCrisisTeams: boolean };

export type UpdateCrisisTeamHoursMutationVariables = Exact<{
  organizationId: Scalars['Float'];
  crisisTeamHours: Array<CrisisTeamHoursInput> | CrisisTeamHoursInput;
}>;

export type UpdateCrisisTeamHoursMutation = { updateCrisisTeamHours: boolean };

export type ActivateCrisisModeMutationVariables = Exact<{
  appointmentId: Scalars['Float'];
}>;

export type ActivateCrisisModeMutation = { activateCrisisMode: string };

export type NotifyOrganizationCrisisTeamMutationVariables = Exact<{
  crisisId: Scalars['String'];
}>;

export type NotifyOrganizationCrisisTeamMutation = { notifyOrganizationCrisisTeam: boolean };

export type SetSafeOperatingHandbookUrlMutationVariables = Exact<{
  organizationId: Scalars['Int'];
  url?: InputMaybe<Scalars['String']>;
}>;

export type SetSafeOperatingHandbookUrlMutation = { setSafeOperatingHandbookUrl: boolean };

export type ImpersonateMutationVariables = Exact<{
  providerToImpersonateId: Scalars['Float'];
  reason: Scalars['String'];
}>;

export type ImpersonateMutation = { impersonate: string };

export type ReactivateUserForSponsoredCareMutationVariables = Exact<{
  userId: Scalars['Float'];
  careType: CareType;
}>;

export type ReactivateUserForSponsoredCareMutation = { reactivateUserForSponsoredCare: boolean };

export type EditOrganizationHandbookMutationVariables = Exact<{
  organizationId: Scalars['Float'];
  input: EditOrganizationHandbookInput;
}>;

export type EditOrganizationHandbookMutation = { editOrganizationHandbook: boolean };

export type CreateOrganizationAnnouncementMutationVariables = Exact<{
  input: CreateOrganizationAnnouncementInput;
}>;

export type CreateOrganizationAnnouncementMutation = {
  createOrganizationAnnouncement: { id: number };
};

export type EditOrganizationAnnouncementMutationVariables = Exact<{
  id: Scalars['Float'];
  input: EditOrganizationAnnouncementInput;
}>;

export type EditOrganizationAnnouncementMutation = { editOrganizationAnnouncement: boolean };

export type AdminUpdateScreenUserResultMutationVariables = Exact<{
  userId: Scalars['Float'];
  input: ScreenUserResultInput;
}>;

export type AdminUpdateScreenUserResultMutation = { adminUpdateScreenUserResult: boolean };

export type AdminResetUserPasswordMutationVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type AdminResetUserPasswordMutation = { resetUserPassword: string };

export type SoftDeleteUserMutationVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type SoftDeleteUserMutation = { softDeleteUser: boolean };

export type CreatePartnerReferralMutationVariables = Exact<{
  data: PartnerReferralInput;
}>;

export type CreatePartnerReferralMutation = { createPartnerReferral: boolean };

export type CreateContractMutationVariables = Exact<{
  input: CreateContractInput;
}>;

export type CreateContractMutation = {
  createContract: { errorCode: CreateContractErrorCode } | { id: number };
};

export type EditContractScopeMutationVariables = Exact<{
  input: EditContractScopeInput;
}>;

export type EditContractScopeMutation = {
  editContractScope: { errorCode: EditContractScopeErrorCode } | { id: number };
};

export type DecoupleOrgEntitlementsFromParentMutationVariables = Exact<{
  organizationId: Scalars['Float'];
}>;

export type DecoupleOrgEntitlementsFromParentMutation = {
  decoupleOrgEntitlementsFromParent: boolean;
};

export type RecoupleOrgEntitlementsToParentMutationVariables = Exact<{
  organizationId: Scalars['Float'];
}>;

export type RecoupleOrgEntitlementsToParentMutation = { recoupleOrgEntitlementsToParent: boolean };

export type NoteTextStyleFragment = {
  tag?: TextTag | null;
  kind?: TextKind | null;
  italic?: boolean | null;
};

type SectionCopy_ListCopy_Fragment = {
  showInView?: boolean | null;
  bullets: Array<string>;
  style?: { tag?: TextTag | null; kind?: TextKind | null; italic?: boolean | null } | null;
};

type SectionCopy_TextCopy_Fragment = {
  showInView?: boolean | null;
  text: string;
  style?: { tag?: TextTag | null; kind?: TextKind | null; italic?: boolean | null } | null;
};

export type SectionCopyFragment = SectionCopy_ListCopy_Fragment | SectionCopy_TextCopy_Fragment;

export type MedNoteSectionBaseFragment = {
  key: string;
  format: SectionFormat;
  title?: string | null;
  sectionTitle?: string | null;
  required?: boolean | null;
  uncounted?: boolean | null;
  value?: string | null;
  beforeCopy?: Array<
    | {
        showInView?: boolean | null;
        bullets: Array<string>;
        style?: { tag?: TextTag | null; kind?: TextKind | null; italic?: boolean | null } | null;
      }
    | {
        showInView?: boolean | null;
        text: string;
        style?: { tag?: TextTag | null; kind?: TextKind | null; italic?: boolean | null } | null;
      }
  > | null;
  afterCopy?: Array<
    | {
        showInView?: boolean | null;
        bullets: Array<string>;
        style?: { tag?: TextTag | null; kind?: TextKind | null; italic?: boolean | null } | null;
      }
    | {
        showInView?: boolean | null;
        text: string;
        style?: { tag?: TextTag | null; kind?: TextKind | null; italic?: boolean | null } | null;
      }
  > | null;
};

export type MedNoteSectionDetailFragment = {
  options?: Array<string> | null;
  placeholder?: string | null;
  isolatedOption?: string | null;
  maxSelections?: number | null;
  border?: SectionColor | null;
  background?: SectionColor | null;
  closeable?: boolean | null;
  startClosed?: boolean | null;
  key: string;
  format: SectionFormat;
  title?: string | null;
  sectionTitle?: string | null;
  required?: boolean | null;
  uncounted?: boolean | null;
  value?: string | null;
  warning?: {
    triggers: Array<string>;
    dependsKey?: string | null;
    background?: SectionColor | null;
    border?: SectionColor | null;
    copy: Array<
      | {
          showInView?: boolean | null;
          bullets: Array<string>;
          style?: { tag?: TextTag | null; kind?: TextKind | null; italic?: boolean | null } | null;
        }
      | {
          showInView?: boolean | null;
          text: string;
          style?: { tag?: TextTag | null; kind?: TextKind | null; italic?: boolean | null } | null;
        }
    >;
  } | null;
  hooks?: Array<{ type: HookType; targetKey: string }> | null;
  dependsOn?: { key: string; showValues?: Array<string> | null } | null;
  selectDependentValues?: Array<{
    targetKey: string;
    dependsValues?: Array<string> | null;
    targetSelectValue?: string | null;
  }> | null;
  requiredIf?: {
    dependentQuestions: Array<{
      targetKey: string;
      requiredIfValues?: Array<string> | null;
      requiredIfEmpty?: boolean | null;
    }>;
  } | null;
  beforeCopy?: Array<
    | {
        showInView?: boolean | null;
        bullets: Array<string>;
        style?: { tag?: TextTag | null; kind?: TextKind | null; italic?: boolean | null } | null;
      }
    | {
        showInView?: boolean | null;
        text: string;
        style?: { tag?: TextTag | null; kind?: TextKind | null; italic?: boolean | null } | null;
      }
  > | null;
  afterCopy?: Array<
    | {
        showInView?: boolean | null;
        bullets: Array<string>;
        style?: { tag?: TextTag | null; kind?: TextKind | null; italic?: boolean | null } | null;
      }
    | {
        showInView?: boolean | null;
        text: string;
        style?: { tag?: TextTag | null; kind?: TextKind | null; italic?: boolean | null } | null;
      }
  > | null;
};

export type MedicalNoteTemplateFragment = {
  key: string;
  title: string;
  titleTag?: TextTag | null;
  uuid: string;
  patientDetails?: Array<{ key: string; value?: string | null; breakLine?: boolean | null }> | null;
  questions: Array<{
    options?: Array<string> | null;
    placeholder?: string | null;
    isolatedOption?: string | null;
    maxSelections?: number | null;
    border?: SectionColor | null;
    background?: SectionColor | null;
    closeable?: boolean | null;
    startClosed?: boolean | null;
    key: string;
    format: SectionFormat;
    title?: string | null;
    sectionTitle?: string | null;
    required?: boolean | null;
    uncounted?: boolean | null;
    value?: string | null;
    children?: Array<{
      options?: Array<string> | null;
      placeholder?: string | null;
      isolatedOption?: string | null;
      maxSelections?: number | null;
      border?: SectionColor | null;
      background?: SectionColor | null;
      closeable?: boolean | null;
      startClosed?: boolean | null;
      key: string;
      format: SectionFormat;
      title?: string | null;
      sectionTitle?: string | null;
      required?: boolean | null;
      uncounted?: boolean | null;
      value?: string | null;
      warning?: {
        triggers: Array<string>;
        dependsKey?: string | null;
        background?: SectionColor | null;
        border?: SectionColor | null;
        copy: Array<
          | {
              showInView?: boolean | null;
              bullets: Array<string>;
              style?: {
                tag?: TextTag | null;
                kind?: TextKind | null;
                italic?: boolean | null;
              } | null;
            }
          | {
              showInView?: boolean | null;
              text: string;
              style?: {
                tag?: TextTag | null;
                kind?: TextKind | null;
                italic?: boolean | null;
              } | null;
            }
        >;
      } | null;
      hooks?: Array<{ type: HookType; targetKey: string }> | null;
      dependsOn?: { key: string; showValues?: Array<string> | null } | null;
      selectDependentValues?: Array<{
        targetKey: string;
        dependsValues?: Array<string> | null;
        targetSelectValue?: string | null;
      }> | null;
      requiredIf?: {
        dependentQuestions: Array<{
          targetKey: string;
          requiredIfValues?: Array<string> | null;
          requiredIfEmpty?: boolean | null;
        }>;
      } | null;
      beforeCopy?: Array<
        | {
            showInView?: boolean | null;
            bullets: Array<string>;
            style?: {
              tag?: TextTag | null;
              kind?: TextKind | null;
              italic?: boolean | null;
            } | null;
          }
        | {
            showInView?: boolean | null;
            text: string;
            style?: {
              tag?: TextTag | null;
              kind?: TextKind | null;
              italic?: boolean | null;
            } | null;
          }
      > | null;
      afterCopy?: Array<
        | {
            showInView?: boolean | null;
            bullets: Array<string>;
            style?: {
              tag?: TextTag | null;
              kind?: TextKind | null;
              italic?: boolean | null;
            } | null;
          }
        | {
            showInView?: boolean | null;
            text: string;
            style?: {
              tag?: TextTag | null;
              kind?: TextKind | null;
              italic?: boolean | null;
            } | null;
          }
      > | null;
    }> | null;
    warning?: {
      triggers: Array<string>;
      dependsKey?: string | null;
      background?: SectionColor | null;
      border?: SectionColor | null;
      copy: Array<
        | {
            showInView?: boolean | null;
            bullets: Array<string>;
            style?: {
              tag?: TextTag | null;
              kind?: TextKind | null;
              italic?: boolean | null;
            } | null;
          }
        | {
            showInView?: boolean | null;
            text: string;
            style?: {
              tag?: TextTag | null;
              kind?: TextKind | null;
              italic?: boolean | null;
            } | null;
          }
      >;
    } | null;
    hooks?: Array<{ type: HookType; targetKey: string }> | null;
    dependsOn?: { key: string; showValues?: Array<string> | null } | null;
    selectDependentValues?: Array<{
      targetKey: string;
      dependsValues?: Array<string> | null;
      targetSelectValue?: string | null;
    }> | null;
    requiredIf?: {
      dependentQuestions: Array<{
        targetKey: string;
        requiredIfValues?: Array<string> | null;
        requiredIfEmpty?: boolean | null;
      }>;
    } | null;
    beforeCopy?: Array<
      | {
          showInView?: boolean | null;
          bullets: Array<string>;
          style?: { tag?: TextTag | null; kind?: TextKind | null; italic?: boolean | null } | null;
        }
      | {
          showInView?: boolean | null;
          text: string;
          style?: { tag?: TextTag | null; kind?: TextKind | null; italic?: boolean | null } | null;
        }
    > | null;
    afterCopy?: Array<
      | {
          showInView?: boolean | null;
          bullets: Array<string>;
          style?: { tag?: TextTag | null; kind?: TextKind | null; italic?: boolean | null } | null;
        }
      | {
          showInView?: boolean | null;
          text: string;
          style?: { tag?: TextTag | null; kind?: TextKind | null; italic?: boolean | null } | null;
        }
    > | null;
  }>;
};

export type GetNoteByUuidQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type GetNoteByUuidQuery = {
  getNoteByUuid: {
    id: number;
    key: string;
    submittedAt?: Date | null;
    createdAt: Date;
    updatedAt: Date;
    template: {
      key: string;
      title: string;
      titleTag?: TextTag | null;
      uuid: string;
      patientDetails?: Array<{
        key: string;
        value?: string | null;
        breakLine?: boolean | null;
      }> | null;
      questions: Array<{
        options?: Array<string> | null;
        placeholder?: string | null;
        isolatedOption?: string | null;
        maxSelections?: number | null;
        border?: SectionColor | null;
        background?: SectionColor | null;
        closeable?: boolean | null;
        startClosed?: boolean | null;
        key: string;
        format: SectionFormat;
        title?: string | null;
        sectionTitle?: string | null;
        required?: boolean | null;
        uncounted?: boolean | null;
        value?: string | null;
        children?: Array<{
          options?: Array<string> | null;
          placeholder?: string | null;
          isolatedOption?: string | null;
          maxSelections?: number | null;
          border?: SectionColor | null;
          background?: SectionColor | null;
          closeable?: boolean | null;
          startClosed?: boolean | null;
          key: string;
          format: SectionFormat;
          title?: string | null;
          sectionTitle?: string | null;
          required?: boolean | null;
          uncounted?: boolean | null;
          value?: string | null;
          warning?: {
            triggers: Array<string>;
            dependsKey?: string | null;
            background?: SectionColor | null;
            border?: SectionColor | null;
            copy: Array<
              | {
                  showInView?: boolean | null;
                  bullets: Array<string>;
                  style?: {
                    tag?: TextTag | null;
                    kind?: TextKind | null;
                    italic?: boolean | null;
                  } | null;
                }
              | {
                  showInView?: boolean | null;
                  text: string;
                  style?: {
                    tag?: TextTag | null;
                    kind?: TextKind | null;
                    italic?: boolean | null;
                  } | null;
                }
            >;
          } | null;
          hooks?: Array<{ type: HookType; targetKey: string }> | null;
          dependsOn?: { key: string; showValues?: Array<string> | null } | null;
          selectDependentValues?: Array<{
            targetKey: string;
            dependsValues?: Array<string> | null;
            targetSelectValue?: string | null;
          }> | null;
          requiredIf?: {
            dependentQuestions: Array<{
              targetKey: string;
              requiredIfValues?: Array<string> | null;
              requiredIfEmpty?: boolean | null;
            }>;
          } | null;
          beforeCopy?: Array<
            | {
                showInView?: boolean | null;
                bullets: Array<string>;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
            | {
                showInView?: boolean | null;
                text: string;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
          > | null;
          afterCopy?: Array<
            | {
                showInView?: boolean | null;
                bullets: Array<string>;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
            | {
                showInView?: boolean | null;
                text: string;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
          > | null;
        }> | null;
        warning?: {
          triggers: Array<string>;
          dependsKey?: string | null;
          background?: SectionColor | null;
          border?: SectionColor | null;
          copy: Array<
            | {
                showInView?: boolean | null;
                bullets: Array<string>;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
            | {
                showInView?: boolean | null;
                text: string;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
          >;
        } | null;
        hooks?: Array<{ type: HookType; targetKey: string }> | null;
        dependsOn?: { key: string; showValues?: Array<string> | null } | null;
        selectDependentValues?: Array<{
          targetKey: string;
          dependsValues?: Array<string> | null;
          targetSelectValue?: string | null;
        }> | null;
        requiredIf?: {
          dependentQuestions: Array<{
            targetKey: string;
            requiredIfValues?: Array<string> | null;
            requiredIfEmpty?: boolean | null;
          }>;
        } | null;
        beforeCopy?: Array<
          | {
              showInView?: boolean | null;
              bullets: Array<string>;
              style?: {
                tag?: TextTag | null;
                kind?: TextKind | null;
                italic?: boolean | null;
              } | null;
            }
          | {
              showInView?: boolean | null;
              text: string;
              style?: {
                tag?: TextTag | null;
                kind?: TextKind | null;
                italic?: boolean | null;
              } | null;
            }
        > | null;
        afterCopy?: Array<
          | {
              showInView?: boolean | null;
              bullets: Array<string>;
              style?: {
                tag?: TextTag | null;
                kind?: TextKind | null;
                italic?: boolean | null;
              } | null;
            }
          | {
              showInView?: boolean | null;
              text: string;
              style?: {
                tag?: TextTag | null;
                kind?: TextKind | null;
                italic?: boolean | null;
              } | null;
            }
        > | null;
      }>;
    };
    appointment?: { id: number } | null;
  };
};

export type ExportNoteMutationVariables = Exact<{
  noteId: Scalars['Float'];
}>;

export type ExportNoteMutation = {
  adminCreateNoteExport: { id: number; status: string; url?: string | null };
};

export type DeleteMedicalNoteMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type DeleteMedicalNoteMutation = { deleteMedicalNote: boolean };

export type GetOrCreateNoteMutationVariables = Exact<{
  key: Scalars['String'];
  userId: Scalars['Float'];
}>;

export type GetOrCreateNoteMutation = {
  note: {
    id: number;
    key: string;
    appointment?: { id: number } | null;
    template: {
      key: string;
      title: string;
      titleTag?: TextTag | null;
      uuid: string;
      patientDetails?: Array<{
        key: string;
        value?: string | null;
        breakLine?: boolean | null;
      }> | null;
      questions: Array<{
        options?: Array<string> | null;
        placeholder?: string | null;
        isolatedOption?: string | null;
        maxSelections?: number | null;
        border?: SectionColor | null;
        background?: SectionColor | null;
        closeable?: boolean | null;
        startClosed?: boolean | null;
        key: string;
        format: SectionFormat;
        title?: string | null;
        sectionTitle?: string | null;
        required?: boolean | null;
        uncounted?: boolean | null;
        value?: string | null;
        children?: Array<{
          options?: Array<string> | null;
          placeholder?: string | null;
          isolatedOption?: string | null;
          maxSelections?: number | null;
          border?: SectionColor | null;
          background?: SectionColor | null;
          closeable?: boolean | null;
          startClosed?: boolean | null;
          key: string;
          format: SectionFormat;
          title?: string | null;
          sectionTitle?: string | null;
          required?: boolean | null;
          uncounted?: boolean | null;
          value?: string | null;
          warning?: {
            triggers: Array<string>;
            dependsKey?: string | null;
            background?: SectionColor | null;
            border?: SectionColor | null;
            copy: Array<
              | {
                  showInView?: boolean | null;
                  bullets: Array<string>;
                  style?: {
                    tag?: TextTag | null;
                    kind?: TextKind | null;
                    italic?: boolean | null;
                  } | null;
                }
              | {
                  showInView?: boolean | null;
                  text: string;
                  style?: {
                    tag?: TextTag | null;
                    kind?: TextKind | null;
                    italic?: boolean | null;
                  } | null;
                }
            >;
          } | null;
          hooks?: Array<{ type: HookType; targetKey: string }> | null;
          dependsOn?: { key: string; showValues?: Array<string> | null } | null;
          selectDependentValues?: Array<{
            targetKey: string;
            dependsValues?: Array<string> | null;
            targetSelectValue?: string | null;
          }> | null;
          requiredIf?: {
            dependentQuestions: Array<{
              targetKey: string;
              requiredIfValues?: Array<string> | null;
              requiredIfEmpty?: boolean | null;
            }>;
          } | null;
          beforeCopy?: Array<
            | {
                showInView?: boolean | null;
                bullets: Array<string>;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
            | {
                showInView?: boolean | null;
                text: string;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
          > | null;
          afterCopy?: Array<
            | {
                showInView?: boolean | null;
                bullets: Array<string>;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
            | {
                showInView?: boolean | null;
                text: string;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
          > | null;
        }> | null;
        warning?: {
          triggers: Array<string>;
          dependsKey?: string | null;
          background?: SectionColor | null;
          border?: SectionColor | null;
          copy: Array<
            | {
                showInView?: boolean | null;
                bullets: Array<string>;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
            | {
                showInView?: boolean | null;
                text: string;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
          >;
        } | null;
        hooks?: Array<{ type: HookType; targetKey: string }> | null;
        dependsOn?: { key: string; showValues?: Array<string> | null } | null;
        selectDependentValues?: Array<{
          targetKey: string;
          dependsValues?: Array<string> | null;
          targetSelectValue?: string | null;
        }> | null;
        requiredIf?: {
          dependentQuestions: Array<{
            targetKey: string;
            requiredIfValues?: Array<string> | null;
            requiredIfEmpty?: boolean | null;
          }>;
        } | null;
        beforeCopy?: Array<
          | {
              showInView?: boolean | null;
              bullets: Array<string>;
              style?: {
                tag?: TextTag | null;
                kind?: TextKind | null;
                italic?: boolean | null;
              } | null;
            }
          | {
              showInView?: boolean | null;
              text: string;
              style?: {
                tag?: TextTag | null;
                kind?: TextKind | null;
                italic?: boolean | null;
              } | null;
            }
        > | null;
        afterCopy?: Array<
          | {
              showInView?: boolean | null;
              bullets: Array<string>;
              style?: {
                tag?: TextTag | null;
                kind?: TextKind | null;
                italic?: boolean | null;
              } | null;
            }
          | {
              showInView?: boolean | null;
              text: string;
              style?: {
                tag?: TextTag | null;
                kind?: TextKind | null;
                italic?: boolean | null;
              } | null;
            }
        > | null;
      }>;
    };
  };
};

export type SaveNoteSectionsMutationVariables = Exact<{
  uuid: Scalars['String'];
  sections: Array<MedicalNoteSectionInput> | MedicalNoteSectionInput;
}>;

export type SaveNoteSectionsMutation = { saveNoteSections: boolean };

export type SubmitNoteMutationVariables = Exact<{
  uuid: Scalars['String'];
  sections: Array<MedicalNoteSectionInput> | MedicalNoteSectionInput;
}>;

export type SubmitNoteMutation = { submitNote: { id: number; submittedAt?: Date | null } };

export type OrganizationUsersQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars['Float']>;
}>;

export type OrganizationUsersQuery = {
  users: Array<{
    email: string;
    createdAt: Date;
    careStatus: CareStatus;
    taskStatus: string;
    id: number;
    customerId?: string | null;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
    provider?: { id: number; name: string } | null;
    therapist?: {
      id: number;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      classification: string;
      role: Role;
      email?: string | null;
      geoStates: Array<StateCodes>;
      postNominalTitles?: string | null;
      personalTitle?: string | null;
      careTypes: Array<CareType>;
      portrait?: { url: string } | null;
      organizations: Array<{
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      }>;
    } | null;
    organization?: {
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
    } | null;
    continuityOfCareTransitions: Array<{
      careType: CareType;
      nextSteps: NextSteps;
      pendingUser?: boolean | null;
      user: {
        id: number;
        organization?: {
          id: number;
          latestCarePeriod?: { id: number; endDate: string } | null;
        } | null;
      };
    }>;
    careFlows: Array<{
      id: number;
      careType: CareType;
      careStatus: CareStatus;
      paymentSource: PaymentSource;
      careSessionInfo?: {
        unlimitedAppts: boolean;
        remainingAppts?: number | null;
        flowLimit?: number | null;
        completedAppts: number;
      } | null;
    }>;
  }>;
  providers: Array<{ id: number; name: string; role: Role }>;
};

export type UsersVerificationListQueryVariables = Exact<{ [key: string]: never }>;

export type UsersVerificationListQuery = {
  users: Array<{
    email: string;
    birthDate?: Date | null;
    verificationStatus: string;
    id: number;
    customerId?: string | null;
    createdAt: Date;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
    identificationCard?: {
      id: number;
      createdAt: Date;
      contentType: string;
      name: string;
      url: string;
      type: UploadType;
      globallyShared?: boolean | null;
      canEdit?: boolean | null;
      createdByProvider?: { id: number; name: string } | null;
    } | null;
    selfie?: {
      id: number;
      createdAt: Date;
      contentType: string;
      name: string;
      url: string;
      type: UploadType;
      globallyShared?: boolean | null;
      canEdit?: boolean | null;
      createdByProvider?: { id: number; name: string } | null;
    } | null;
  }>;
};

export type AdminUserMessagesQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type AdminUserMessagesQuery = {
  adminUser: {
    id: number;
    privateChannel: {
      id: number;
      unreadMessageCount: number;
      lastMessage?: {
        id: string;
        content: string;
        providerId?: number | null;
        userId?: number | null;
        createdAt: Date;
        provider?: {
          id: number;
          name: string;
          portrait?: {
            id: number;
            createdAt: Date;
            contentType: string;
            name: string;
            url: string;
            type: UploadType;
            globallyShared?: boolean | null;
            canEdit?: boolean | null;
            createdByProvider?: { id: number; name: string } | null;
          } | null;
        } | null;
      } | null;
    };
  };
};

export type MedicationsForUserQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type MedicationsForUserQuery = {
  medicationsForUser: Array<{
    prescriptionId: number;
    displayName: string;
    writtenDate: string;
    strength: string;
    quantity: string;
    medicationStatus: MedicationStatus;
    status: number;
  }>;
};

export type NextAppointmentTimeQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type NextAppointmentTimeQuery = {
  adminUser: { id: number; nextAppointmentTime?: Date | null };
};

export type GetAllNotesForUserQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type GetAllNotesForUserQuery = {
  adminUser: {
    id: number;
    notes: Array<{
      id: number;
      key: string;
      submittedAt?: Date | null;
      createdAt: Date;
      updatedAt: Date;
      template: {
        key: string;
        uuid: string;
        title: string;
        questions: Array<{
          key: string;
          format: SectionFormat;
          title?: string | null;
          sectionTitle?: string | null;
          required?: boolean | null;
          uncounted?: boolean | null;
          value?: string | null;
          children?: Array<{
            key: string;
            format: SectionFormat;
            title?: string | null;
            sectionTitle?: string | null;
            required?: boolean | null;
            uncounted?: boolean | null;
            value?: string | null;
            beforeCopy?: Array<
              | {
                  showInView?: boolean | null;
                  bullets: Array<string>;
                  style?: {
                    tag?: TextTag | null;
                    kind?: TextKind | null;
                    italic?: boolean | null;
                  } | null;
                }
              | {
                  showInView?: boolean | null;
                  text: string;
                  style?: {
                    tag?: TextTag | null;
                    kind?: TextKind | null;
                    italic?: boolean | null;
                  } | null;
                }
            > | null;
            afterCopy?: Array<
              | {
                  showInView?: boolean | null;
                  bullets: Array<string>;
                  style?: {
                    tag?: TextTag | null;
                    kind?: TextKind | null;
                    italic?: boolean | null;
                  } | null;
                }
              | {
                  showInView?: boolean | null;
                  text: string;
                  style?: {
                    tag?: TextTag | null;
                    kind?: TextKind | null;
                    italic?: boolean | null;
                  } | null;
                }
            > | null;
          }> | null;
          beforeCopy?: Array<
            | {
                showInView?: boolean | null;
                bullets: Array<string>;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
            | {
                showInView?: boolean | null;
                text: string;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
          > | null;
          afterCopy?: Array<
            | {
                showInView?: boolean | null;
                bullets: Array<string>;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
            | {
                showInView?: boolean | null;
                text: string;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
          > | null;
        }>;
      };
      provider: { id: number; name: string };
      appointment?: { id: number; startTime: Date; description?: string | null } | null;
    }>;
  };
};

export type GetEligibilityResultQueryVariables = Exact<{
  submissionId: Scalars['Float'];
}>;

export type GetEligibilityResultQuery = {
  getEligibilityResult: {
    providerInState: boolean;
    canContinueCare: boolean;
    needsReferral: boolean;
    isEligible: boolean;
  };
};

export type GetEligibilityCarePeriodValuesQueryVariables = Exact<{
  submissionId: Scalars['Float'];
}>;

export type GetEligibilityCarePeriodValuesQuery = {
  getEligibilityCarePeriodValues: {
    isCarePeriodEnding?: boolean | null;
    firstDayOfBreak?: string | null;
    lastDayOfBreak?: string | null;
    lastDayOfTerm?: string | null;
    isEndingDueToBreak?: boolean | null;
    currentPeriod: { startDate: string; endDate: string };
    nextPeriod?: { startDate: string; endDate: string } | null;
  };
};

export type GetAllNotesAndFormSubmissionsForUserQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type GetAllNotesAndFormSubmissionsForUserQuery = {
  adminUser: {
    id: number;
    notes: Array<{
      id: number;
      key: string;
      submittedAt?: Date | null;
      createdAt: Date;
      updatedAt: Date;
      noteType: 'MedicalNote';
      template: {
        key: string;
        uuid: string;
        title: string;
        questions: Array<{
          key: string;
          format: SectionFormat;
          title?: string | null;
          sectionTitle?: string | null;
          required?: boolean | null;
          uncounted?: boolean | null;
          value?: string | null;
          children?: Array<{
            key: string;
            format: SectionFormat;
            title?: string | null;
            sectionTitle?: string | null;
            required?: boolean | null;
            uncounted?: boolean | null;
            value?: string | null;
            beforeCopy?: Array<
              | {
                  showInView?: boolean | null;
                  bullets: Array<string>;
                  style?: {
                    tag?: TextTag | null;
                    kind?: TextKind | null;
                    italic?: boolean | null;
                  } | null;
                }
              | {
                  showInView?: boolean | null;
                  text: string;
                  style?: {
                    tag?: TextTag | null;
                    kind?: TextKind | null;
                    italic?: boolean | null;
                  } | null;
                }
            > | null;
            afterCopy?: Array<
              | {
                  showInView?: boolean | null;
                  bullets: Array<string>;
                  style?: {
                    tag?: TextTag | null;
                    kind?: TextKind | null;
                    italic?: boolean | null;
                  } | null;
                }
              | {
                  showInView?: boolean | null;
                  text: string;
                  style?: {
                    tag?: TextTag | null;
                    kind?: TextKind | null;
                    italic?: boolean | null;
                  } | null;
                }
            > | null;
          }> | null;
          beforeCopy?: Array<
            | {
                showInView?: boolean | null;
                bullets: Array<string>;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
            | {
                showInView?: boolean | null;
                text: string;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
          > | null;
          afterCopy?: Array<
            | {
                showInView?: boolean | null;
                bullets: Array<string>;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
            | {
                showInView?: boolean | null;
                text: string;
                style?: {
                  tag?: TextTag | null;
                  kind?: TextKind | null;
                  italic?: boolean | null;
                } | null;
              }
          > | null;
        }>;
      };
      provider: { id: number; name: string };
      appointment?: { id: number; startTime: Date; description?: string | null } | null;
    }>;
    continuityOfCareEligibility: Array<{
      id: number;
      careType: CareType;
      residentialLocationDuringBreak: string;
      interestedInContinuingCare: boolean;
      enrollment: boolean;
      additionalNotes?: string | null;
      createdAt: Date;
      updatedAt: Date;
      paymentType: string;
      remainingSessions?: number | null;
      noteType: 'ContinuityOfCareEligibilityModel';
      provider?: { id: number; name: string } | null;
      completedBy: { id: number; name: string };
    }>;
    triageInfo?: Array<{
      assessmentId: number;
      createdAt: Date;
      updatedAt: Date;
      answers: Array<{
        key: string;
        value: { [key: string]: any };
        text?: string | null;
        legend?: Array<string> | null;
      }>;
    }> | null;
  };
  adminUserDemographics?: {
    id: number;
    genderIdentity: string;
    ethnicity: string;
    isInternationalStudent: boolean;
    sexualOrientation: string;
    academicProgram: string;
    yearInProgram: string;
    createdAt: Date;
    updatedAt: Date;
  } | null;
};

export type AdminGetAllocationsQueryVariables = Exact<{
  providerId: Scalars['Float'];
}>;

export type AdminGetAllocationsQuery = {
  adminGetAllocations: Array<{
    id: number;
    startTime: Date;
    endTime: Date;
    isFeeForServiceTime?: boolean | null;
    repeatsUntil?: Date | null;
    weekly: boolean;
    purpose: TimeAllocationPurpose;
    timezone: string;
    appointmentTypes?: Array<string> | null;
    provider: { id: number; name: string; careTypes: Array<CareType> };
    organization?: {
      childrenCount: number;
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
    } | null;
    childOrganizations?: Array<{
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
    }> | null;
  }>;
};

export type AdminAppointmentsQueryVariables = Exact<{
  allProviders?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Float']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
}>;

export type AdminAppointmentsQuery = {
  adminAppointments: Array<{
    id: number;
    startTime: Date;
    endTime: Date;
    description?: string | null;
    appointmentType: string;
    status: string;
    isBlocked: boolean;
    checkedInAt?: Date | null;
    interpretationServices?: string | null;
    lateCancelOrReschedule: boolean;
    provider: { id: number; name: string };
    user: {
      id: number;
      firstName: string;
      lastName: string;
      customerId?: string | null;
      email: string;
      timeZone?: string | null;
      phone?: string | null;
      primaryAddressState?: string | null;
      organization?: {
        id: number;
        name: string;
        abbreviation: string;
        entitlements: Array<{ key: Entitlement; config: { [key: string]: any } }>;
      } | null;
      selfie?: { url: string } | null;
      campusTeam?: Array<{
        relationshipType: UniversityRelationship;
        provider: { id: number };
      }> | null;
    };
  }>;
};

export type AdminDosespotLinkQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type AdminDosespotLinkQuery = { adminDosespotLink?: string | null };

export type AdminUserPaymentInfoQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type AdminUserPaymentInfoQuery = {
  adminUser: {
    id: number;
    invoices: Array<{
      id: string;
      description?: string | null;
      amount: number;
      paid: boolean;
      createdAt: Date;
    }>;
    paymentInfo?: {
      id: string;
      name: string;
      lastFour: string;
      expirationDate: string;
      cardType: string;
      url: string;
    } | null;
  };
};

export type SelectAttendeesQueryVariables = Exact<{
  includeNewPatients?: InputMaybe<Scalars['Boolean']>;
}>;

export type SelectAttendeesQuery = {
  currentProvider: {
    id: number;
    role: Role;
    name: string;
    careTypes: Array<CareType>;
    organizations: Array<{
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
    }>;
  };
  users: Array<{
    id: number;
    customerId?: string | null;
    createdAt: Date;
    firstName: string;
    lastName: string;
    preferredName?: string | null;
  }>;
  providers: Array<{
    id: number;
    name: string;
    role: Role;
    careTypes: Array<CareType>;
    geoStates: Array<StateCodes>;
    qualifications: Array<string>;
    organizations: Array<{ id: number }>;
  }>;
};

export type AttendeeDetailsQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type AttendeeDetailsQuery = {
  patient: {
    id: number;
    firstName: string;
    preferredName?: string | null;
    lastName: string;
    customerId?: string | null;
    careTypes: Array<CareType>;
    primaryAddressState?: string | null;
    primaryAddress?: { state?: StateCodes | null } | null;
    organization?: {
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
      parent?: {
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      } | null;
      entitlements: Array<{ key: Entitlement; config: { [key: string]: any } }>;
    } | null;
    provider?: { id: number; name: string } | null;
    therapist?: { id: number; name: string } | null;
    careFlows: Array<{ careType: CareType; paymentSource: PaymentSource }>;
    therapyUpcoming?: { id: number } | null;
    therapyComplete?: { id: number } | null;
    psychUpcoming?: { id: number } | null;
    psychComplete?: { id: number } | null;
  };
};

export type AppointmentAttendeeQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type AppointmentAttendeeQuery = {
  adminUser: {
    id: number;
    firstName: string;
    preferredName?: string | null;
    lastName: string;
    customerId?: string | null;
    provider?: { id: number; name: string; role: Role; careTypes: Array<CareType> } | null;
    therapist?: { id: number; name: string; role: Role } | null;
    organization?: {
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
    } | null;
  };
};

export type ProvidersQueryVariables = Exact<{ [key: string]: never }>;

export type ProvidersQuery = {
  providers: Array<{
    id: number;
    name: string;
    firstName?: string | null;
    lastName?: string | null;
    classification: string;
    role: Role;
    email?: string | null;
    geoStates: Array<StateCodes>;
    postNominalTitles?: string | null;
    personalTitle?: string | null;
    careTypes: Array<CareType>;
    organizations: Array<{
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
      children: Array<{ id: number; name: string }>;
    }>;
    portrait?: { url: string } | null;
  }>;
};

export type EditPatientInfoQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type EditPatientInfoQuery = {
  providersForUser: {
    blockedReason?: ProviderServiceBlockedReason | null;
    data?: Array<{
      id: number;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      classification: string;
      role: Role;
      email?: string | null;
      geoStates: Array<StateCodes>;
      postNominalTitles?: string | null;
      personalTitle?: string | null;
      careTypes: Array<CareType>;
      organizations: Array<{
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
        children: Array<{ id: number; name: string }>;
      }>;
      portrait?: { url: string } | null;
    }> | null;
  };
  organizations: Array<{
    id: number;
    name: string;
    emailDomains: Array<string>;
    children: Array<{ id: number }>;
  }>;
};

export type CurrentProviderQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentProviderQuery = {
  patientsMonitoredCount?: number | null;
  currentProvider: {
    firstName?: string | null;
    lastName?: string | null;
    postNominalTitles?: string | null;
    pronouns?: string | null;
    classification: string;
    accountSecurityStatus: string;
    permissions: Array<Permission>;
    appView: AppView;
    phone?: string | null;
    email?: string | null;
    npi?: string | null;
    careTypes: Array<CareType>;
    hasDosespot: boolean;
    hasSso?: boolean | null;
    id: number;
    name: string;
    role: Role;
    geoStates: Array<StateCodes>;
    portrait?: { url: string } | null;
    twoFactorConfig?: { enabled: boolean; phoneVerified: boolean; phone: string } | null;
    organizations: Array<{
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
      entitlements: Array<{ key: Entitlement; config: { [key: string]: any } }>;
      careFlows: Array<{
        id: number;
        careType: CareType;
        defaultPaymentSource: PaymentSource;
        statesAvailable: Array<StateCodes>;
        referralRestrictedStates: Array<StateCodes>;
      }>;
      children: Array<{
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      }>;
      parent?: {
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      } | null;
    }>;
    insuranceCredentials: Array<{
      id: number;
      state: string;
      active: boolean;
      payer: { id: number; name: string };
    }>;
  };
};

export type ConfigQueryVariables = Exact<{ [key: string]: never }>;

export type ConfigQuery = {
  featureFlags: Array<string>;
  dtcPsychiatryStates: Array<string>;
  dtcTherapyStates: Array<string>;
};

export type AdminProviderTwoFactorConfigQueryVariables = Exact<{
  providerId: Scalars['Float'];
}>;

export type AdminProviderTwoFactorConfigQuery = {
  adminProvider: {
    id: number;
    name: string;
    role: Role;
    geoStates: Array<StateCodes>;
    twoFactorConfig?: { enabled: boolean; phoneVerified: boolean; phone: string } | null;
  };
};

export type SimpleAdminProviderQueryVariables = Exact<{
  providerId: Scalars['Float'];
}>;

export type SimpleAdminProviderQuery = {
  adminProvider: {
    firstName?: string | null;
    lastName?: string | null;
    personalTitle?: string | null;
    postNominalTitles?: string | null;
    classification: string;
    phone?: string | null;
    pronouns?: string | null;
    email?: string | null;
    npi?: string | null;
    unsubscribedAt?: Date | null;
    id: number;
    name: string;
    role: Role;
    geoStates: Array<StateCodes>;
    portrait?: { url: string } | null;
    organizations: Array<{
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
    }>;
    qualifications2: Array<{
      id: number;
      name: string;
      abbreviation: string;
      states: Array<string>;
      licences: Array<string>;
    }>;
    degrees: Array<{ id: number; university: string; degree: string; abbreviation: string }>;
    certifications: Array<{ id: number; name: string; abbreviation: string }>;
    insuranceCredentials: Array<{
      id: number;
      state: string;
      active: boolean;
      createdAt: Date;
      updatedAt: Date;
      payer: { id: number; name: string };
    }>;
  };
};

export type AdminProviderQueryVariables = Exact<{
  providerId: Scalars['Float'];
}>;

export type AdminProviderQuery = {
  adminProvider: {
    firstName?: string | null;
    lastName?: string | null;
    personalTitle?: string | null;
    postNominalTitles?: string | null;
    classification: string;
    phone?: string | null;
    pronouns?: string | null;
    email?: string | null;
    npi?: string | null;
    unsubscribedAt?: Date | null;
    id: number;
    name: string;
    role: Role;
    geoStates: Array<StateCodes>;
    twoFactorConfig?: { enabled: boolean; phoneVerified: boolean; phone: string } | null;
    portrait?: { url: string } | null;
    patients: Array<{
      createdAt: Date;
      id: number;
      customerId?: string | null;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      selfie?: {
        id: number;
        createdAt: Date;
        contentType: string;
        name: string;
        url: string;
        type: UploadType;
        globallyShared?: boolean | null;
        canEdit?: boolean | null;
        createdByProvider?: { id: number; name: string } | null;
      } | null;
    }>;
    organizations: Array<{
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
    }>;
    qualifications2: Array<{
      id: number;
      name: string;
      abbreviation: string;
      states: Array<string>;
      licences: Array<string>;
    }>;
    degrees: Array<{ id: number; university: string; degree: string; abbreviation: string }>;
    certifications: Array<{ id: number; name: string; abbreviation: string }>;
    insuranceCredentials: Array<{
      id: number;
      state: string;
      active: boolean;
      createdAt: Date;
      updatedAt: Date;
      payer: { id: number; name: string };
    }>;
  };
};

export type EventsQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type EventsQuery = {
  events: Array<{ id: number; tag: string; createdAt: Date; data?: { [key: string]: any } | null }>;
};

export type GetAllUploadsOnUserQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type GetAllUploadsOnUserQuery = {
  adminUser: {
    id: number;
    uploads: Array<{
      id: number;
      createdAt: Date;
      contentType: string;
      name: string;
      url: string;
      type: UploadType;
      globallyShared?: boolean | null;
      canEdit?: boolean | null;
      createdByProvider?: { id: number; name: string } | null;
    }>;
  };
};

export type AdminAppointmentQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type AdminAppointmentQuery = {
  adminAppointment: {
    id: number;
    status: string;
    startTime: Date;
    endTime: Date;
    appointmentType: string;
    description?: string | null;
    careType: CareType;
    checkedInAt?: Date | null;
    user: {
      id: number;
      firstName: string;
      preferredName?: string | null;
      lastName: string;
      customerId?: string | null;
      careTypes: Array<CareType>;
      primaryAddressState?: string | null;
      provider?: { id: number; name: string; role: Role; careTypes: Array<CareType> } | null;
      therapist?: { id: number; name: string; role: Role } | null;
      appointmentTemplates: Array<{
        id: number;
        description: string;
        duration: number;
        appointmentType: string;
        careType: CareType;
      }>;
      organization?: {
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
        parent?: {
          id: number;
          name: string;
          abbreviation: string;
          emailDomains: Array<string>;
        } | null;
        entitlements: Array<{ key: Entitlement; config: { [key: string]: any } }>;
      } | null;
      careFlows: Array<{ careType: CareType; paymentSource: PaymentSource }>;
      therapyUpcoming?: { id: number } | null;
      therapyComplete?: { id: number } | null;
      psychUpcoming?: { id: number } | null;
      psychComplete?: { id: number } | null;
    };
    provider: { id: number; name: string; careTypes: Array<CareType> };
  };
};

export type OrganizationForStateAndCareTypeValidationQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type OrganizationForStateAndCareTypeValidationQuery = {
  organization: {
    careFlows: Array<{
      careType: CareType;
      referralRestrictedStates: Array<StateCodes>;
      statesAvailable: Array<StateCodes>;
    }>;
  };
};

export type OrganizationForEnrollPageQueryVariables = Exact<{
  id: Scalars['Float'];
  includeProviders: Scalars['Boolean'];
  nextTimeSlotEnd?: InputMaybe<Scalars['String']>;
}>;

export type OrganizationForEnrollPageQuery = {
  organization: {
    id: number;
    name: string;
    abbreviation: string;
    emailDomains: Array<string>;
    nextAvailableIntakeAppts: Array<{ start: string; end: string; careType: CareType }>;
    careFlows: Array<{
      careType: CareType;
      referralRestrictedStates: Array<StateCodes>;
      statesAvailable: Array<StateCodes>;
      canReferrerUsersRefer: boolean;
      canMcpUsersRefer: boolean;
      defaultPaymentSource: PaymentSource;
    }>;
    providers?: Array<{
      id: number;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      classification: string;
      role: Role;
      email?: string | null;
      geoStates: Array<StateCodes>;
      postNominalTitles?: string | null;
      personalTitle?: string | null;
      careTypes: Array<CareType>;
      portrait?: { url: string } | null;
      organizations: Array<{
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      }>;
    }>;
    entitlements: Array<{ key: Entitlement; config: { [key: string]: any } }>;
    parent?: {
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
      providers?: Array<{ id: number; name: string; role: Role }>;
    } | null;
    children: Array<{
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
      providers?: Array<{ id: number; name: string; role: Role }>;
    }>;
    director?: { email?: string | null } | null;
    referralCredits?: {
      availableCredits: number;
      therapyCreditWeight: number;
      psychiatryCreditWeight: number;
      referralPeriodEnd?: Date | null;
    } | null;
  };
};

export type OrganizationEntitlementsQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type OrganizationEntitlementsQuery = {
  organization: {
    entitlements: Array<{ id: number; key: Entitlement; config: { [key: string]: any } }>;
  };
};

export type OrganizationProvidersQueryVariables = Exact<{
  id: Scalars['Float'];
  withMantraAdmins: Scalars['Boolean'];
}>;

export type OrganizationProvidersQuery = {
  organization: {
    id: number;
    name: string;
    abbreviation: string;
    emailDomains: Array<string>;
    providers: Array<{
      id: number;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      classification: string;
      role: Role;
      email?: string | null;
      geoStates: Array<StateCodes>;
      postNominalTitles?: string | null;
      personalTitle?: string | null;
      careTypes: Array<CareType>;
      portrait?: { url: string } | null;
      organizations: Array<{
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      }>;
    }>;
    parent?: {
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
      providers: Array<{ id: number; name: string; role: Role }>;
    } | null;
    children: Array<{
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
      providers: Array<{ id: number; name: string; role: Role }>;
    }>;
  };
  mantraAdmins?: Array<{ id: number; name: string; role: Role }>;
};

export type AdminAvailabilityQueryVariables = Exact<{
  providerIds: Array<Scalars['Float']> | Scalars['Float'];
  start: Scalars['String'];
  duration: Scalars['Float'];
  careType: CareType;
  appointmentType: Scalars['String'];
  userId: Scalars['Float'];
  rescheduleId?: InputMaybe<Scalars['Float']>;
  end?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  patientState?: InputMaybe<StateCodes>;
}>;

export type AdminAvailabilityQuery = {
  adminAvailability: {
    interval: number;
    data: Array<{
      allocationId: number;
      organizationId?: number | null;
      start: string;
      end: string;
      blockers: Array<AvailabilityBlockedReason>;
      isFeeForServiceTime?: boolean | null;
      provider: { id: number; name: string; careTypes: Array<CareType> };
    }>;
    globalBlockers: Array<{
      providerId: number;
      date: string;
      type: GlobalAvailabilityBlockerType;
    }>;
  };
};

export type AdminExistingCalendarEventsInRangeQueryVariables = Exact<{
  providerId: Scalars['Float'];
  start: Scalars['String'];
  careType: CareType;
  apptTypes: Array<Scalars['String']> | Scalars['String'];
}>;

export type AdminExistingCalendarEventsInRangeQuery = {
  adminExistingCalendarEventsInRange: Array<{
    startTime: Date;
    endTime: Date;
    appointmentType: string;
  }>;
};

export type AdminOrgIntakeAvailabilityQueryVariables = Exact<{
  start: Scalars['String'];
  end: Scalars['String'];
  providerIds: Array<Scalars['Float']> | Scalars['Float'];
  careType: CareType;
  organizationId?: InputMaybe<Scalars['Float']>;
  timezone?: InputMaybe<Scalars['String']>;
  patientState?: InputMaybe<StateCodes>;
}>;

export type AdminOrgIntakeAvailabilityQuery = {
  adminOrgIntakeAvailability: {
    interval: number;
    duration: number;
    data: Array<{
      allocationId: number;
      start: string;
      end: string;
      blockers: Array<AvailabilityBlockedReason>;
      organizationId?: number | null;
      isFeeForServiceTime?: boolean | null;
      provider: { id: number; name: string; careTypes: Array<CareType> };
    }>;
  };
};

export type CounselorDashboardQueryVariables = Exact<{
  organizationId: Scalars['Float'];
  notesKey?: InputMaybe<Scalars['String']>;
}>;

export type CounselorDashboardQuery = {
  organization: {
    usersWithoutReferrals: Array<{
      id: number;
      customerId?: string | null;
      createdAt: Date;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
    }>;
    usersWithOnlyIncompleteReferrals: Array<{
      id: number;
      customerId?: string | null;
      createdAt: Date;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
    }>;
    highRiskUsers: Array<{
      id: number;
      customerId?: string | null;
      createdAt: Date;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      assessmentInfo: { riskLevel?: string | null };
    }>;
    suicidalRiskUsers: Array<{
      id: number;
      customerId?: string | null;
      createdAt: Date;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
    }>;
    latestAssessments: Array<{
      createdAt: Date;
      user: {
        id: number;
        customerId?: string | null;
        createdAt: Date;
        firstName: string;
        lastName: string;
        preferredName?: string | null;
      };
    }>;
    latestNotes: Array<{
      createdAt: Date;
      user: {
        id: number;
        customerId?: string | null;
        createdAt: Date;
        firstName: string;
        lastName: string;
        preferredName?: string | null;
      };
    }>;
    incompletePlans: Array<{
      id: number;
      createdAt: Date;
      updatedAt: Date;
      user: {
        id: number;
        customerId?: string | null;
        createdAt: Date;
        firstName: string;
        lastName: string;
        preferredName?: string | null;
      };
    }>;
  };
};

export type MissedAppointmentsQueryVariables = Exact<{
  organizationId: Scalars['Float'];
}>;

export type MissedAppointmentsQuery = {
  organization: {
    missedAppointments: Array<{
      id: number;
      startTime: Date;
      user: {
        id: number;
        customerId?: string | null;
        createdAt: Date;
        firstName: string;
        lastName: string;
        preferredName?: string | null;
      };
    }>;
  };
};

export type LogoQueryVariables = Exact<{
  organizationId: Scalars['Float'];
}>;

export type LogoQuery = {
  organization: {
    id: number;
    name: string;
    logo?: { id: number; url: string } | null;
    parent?: { name: string; logo?: { id: number; url: string } | null } | null;
  };
};

export type AdminCallMetaDataForAppointmentQueryVariables = Exact<{
  userId: Scalars['Float'];
  appointmentId?: InputMaybe<Scalars['Float']>;
}>;

export type AdminCallMetaDataForAppointmentQuery = {
  adminAppointmentByUser: {
    id: number;
    status: string;
    startTime: Date;
    endTime: Date;
    videoSessionId: string;
    videoToken: string;
    appointmentType: string;
    description?: string | null;
    adminHostCrisisId?: string | null;
    adminCrisisTeamNotifiedAt?: Date | null;
    adminCrisisProtocolEngagedAt?: Date | null;
    user: {
      id: number;
      customerId?: string | null;
      createdAt: Date;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      provider?: { id: number; name: string } | null;
      organization?: {
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
        currentCrisisHours?: { startTime: Date; endTime: Date } | null;
        crisisTeamProviders: Array<{ role: Role }>;
        entitlements: Array<{ key: Entitlement; config: { [key: string]: any } }>;
      } | null;
      selfie?: { id: number; url: string } | null;
    };
    provider: { id: number; name: string };
  };
};

export type PatientsQueryVariables = Exact<{
  providerId: Scalars['Float'];
}>;

export type PatientsQuery = {
  adminProvider: {
    patients: Array<{
      id: number;
      customerId?: string | null;
      createdAt: Date;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
    }>;
  };
};

export type AppointmentTemplatesForUserQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type AppointmentTemplatesForUserQuery = {
  adminUser: {
    id: number;
    careFlows: Array<{ careStatus: CareStatus }>;
    appointmentTemplates: Array<{
      id: number;
      description: string;
      duration: number;
      appointmentType: string;
      careType: CareType;
    }>;
  };
};

export type SearchPharmacyQueryVariables = Exact<{
  query: Scalars['String'];
}>;

export type SearchPharmacyQuery = {
  adminSearchPharmacy: Array<{
    pharmacyId: number;
    storeName: string;
    address1: string;
    address2?: string | null;
    city: string;
    state: string;
    zipCode: string;
    primaryPhone?: string | null;
    primaryFax?: string | null;
    serviceLevel?: number | null;
    latitude?: number | null;
    longitude?: number | null;
    primary?: boolean | null;
  }>;
};

export type CareDashboardQueryVariables = Exact<{
  admin: Scalars['Boolean'];
}>;

export type CareDashboardQuery = {
  adminTimeAllocationsToday: Array<{
    id: number;
    startTime: Date;
    endTime: Date;
    purpose: TimeAllocationPurpose;
    appointmentTypes?: Array<string> | null;
    provider: {
      id: number;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      classification: string;
      role: Role;
      email?: string | null;
      geoStates: Array<StateCodes>;
      postNominalTitles?: string | null;
      personalTitle?: string | null;
      careTypes: Array<CareType>;
      portrait?: { url: string } | null;
      organizations: Array<{
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      }>;
    };
    organization?: {
      childrenCount: number;
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
    } | null;
    childOrganizations?: Array<{ id: number }> | null;
  }>;
  adminAppointmentsToday: Array<{
    id: number;
    startTime: Date;
    endTime: Date;
    appointmentType: string;
    description?: string | null;
    user: {
      identityVerified: boolean;
      intakeComplete: boolean;
      id: number;
      customerId?: string | null;
      createdAt: Date;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      selfie?: { url: string } | null;
      organization?: { id: number } | null;
    };
    provider: {
      id: number;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      classification: string;
      role: Role;
      email?: string | null;
      geoStates: Array<StateCodes>;
      postNominalTitles?: string | null;
      personalTitle?: string | null;
      careTypes: Array<CareType>;
      portrait?: { url: string } | null;
      organizations: Array<{
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      }>;
    };
  }>;
  adminPatientMonitoring: Array<{
    id: number;
    firstName: string;
    preferredName?: string | null;
    lastName: string;
    customerId?: string | null;
    createdAt: Date;
    assessmentInfo: { riskLevel?: string | null; suicidal?: Array<string> | null };
    safetyPlan?: { complete: boolean } | null;
    provider?: { id: number; name: string; role: Role; careTypes: Array<CareType> } | null;
    therapist?: { id: number; name: string; role: Role } | null;
    organization?: {
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
    } | null;
  }>;
  unsubmittedMedicalNotes?: Array<{
    id: number;
    updatedAt: Date;
    createdAt: Date;
    title: string;
    dos?: string | null;
    user: {
      id: number;
      customerId?: string | null;
      createdAt: Date;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
    };
  }>;
  safetyPlans?: Array<{
    id: number;
    createdAt: Date;
    updatedAt: Date;
    user: {
      id: number;
      customerId?: string | null;
      createdAt: Date;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
    };
  }>;
  adminUrgentActionUsers: Array<{
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    nextAppointmentTime?: Date | null;
    careTypes: Array<CareType>;
    taskStatus: string;
    engagementTags: Array<EngagementTag>;
    lastOutreachAttempt?: Date | null;
  }>;
};

export type AdminNeedsPatientOutreachQueryVariables = Exact<{ [key: string]: never }>;

export type AdminNeedsPatientOutreachQuery = {
  adminNeedsPatientOutreach: Array<{
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    careTypes: Array<CareType>;
    taskStatus: string;
    engagementTags: Array<EngagementTag>;
    lastOutreachAttempt?: Date | null;
  }>;
};

export type GetAsyncJobQueryVariables = Exact<{
  jobId: Scalars['Float'];
}>;

export type GetAsyncJobQuery = { adminGetJob: { id: number; status: string; url?: string | null } };

export type GetClaimQueryVariables = Exact<{
  claimId: Scalars['Float'];
}>;

export type GetClaimQuery = {
  getClaim: {
    id: number;
    internalStatus: InternalStatus;
    sentDate: Date;
    balanceCents: number;
    visitDate: Date;
    geoState: string;
    user: {
      customerId?: string | null;
      id: number;
      createdAt: Date;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
    };
    provider: { name: string };
    payer: { id: number; name: string };
    events: Array<{
      id: number;
      updatedAt: Date;
      action: ClaimAction;
      status: InternalStatus;
      details: string;
      actor?: { name: string } | null;
    }>;
  };
};

export type GetClaimsQueryVariables = Exact<{
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;

export type GetClaimsQuery = {
  getClaims: Array<{
    id: number;
    visitDate: Date;
    geoState: string;
    balanceCents: number;
    internalStatus: InternalStatus;
    updatedAt: Date;
    payer: { id: number; name: string };
    provider: { id: number; name: string };
    user: {
      id: number;
      customerId?: string | null;
      createdAt: Date;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
    };
  }>;
};

export type NewClaimsCountQueryVariables = Exact<{ [key: string]: never }>;

export type NewClaimsCountQuery = { newClaimsCount: number };

export type AdminUserPharmaciesQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type AdminUserPharmaciesQuery = {
  adminUserPharmacies: Array<{
    pharmacyId: number;
    storeName: string;
    address1: string;
    address2?: string | null;
    city: string;
    state: string;
    zipCode: string;
    primaryPhone?: string | null;
    primaryFax?: string | null;
    serviceLevel?: number | null;
    latitude?: number | null;
    longitude?: number | null;
    primary?: boolean | null;
  }>;
};

export type CareteamPreferencesQueryVariables = Exact<{ [key: string]: never }>;

export type CareteamPreferencesQuery = {
  configs: Array<{
    key: string;
    title: string;
    description: string;
    subText?: string | null;
    value: { [key: string]: any };
  }>;
};

export type OrganizationsListQueryVariables = Exact<{ [key: string]: never }>;

export type OrganizationsListQuery = {
  organizations: Array<{
    id: number;
    name: string;
    abbreviation: string;
    activityStatus: string;
    entitlements: Array<{ key: Entitlement }>;
    careFlows: Array<{ careType: CareType }>;
    providers: Array<{ id: number; name: string; role: Role }>;
  }>;
};

export type AdminCheckQueryVariables = Exact<{
  organizationId: Scalars['Float'];
  isMantraAdmin: Scalars['Boolean'];
}>;

export type AdminCheckQuery = {
  adminOrgs: Array<{ id: number; name: string; entitlements: Array<{ key: Entitlement }> }>;
  singleOrg: {
    entitlements: Array<{ id: number; key: Entitlement; config: { [key: string]: any } }>;
  };
};

export type OrganizationTopLevelQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type OrganizationTopLevelQuery = {
  organization: {
    id: number;
    name: string;
    abbreviation: string;
    activityStatus: string;
    logo?: { url: string } | null;
    entitlements: Array<{ key: Entitlement }>;
  };
};

export type OrganizationOverviewQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type OrganizationOverviewQuery = {
  organization: {
    id: number;
    name: string;
    abbreviation: string;
    activityStatus: string;
    departmentName: string;
    departmentAddress: string;
    departmentHours: string;
    emailDomains: Array<string>;
    safeOperatingHandbookUrl?: string | null;
    logo?: { id: number; url: string } | null;
    entitlements: Array<{ key: Entitlement }>;
    careFlows: Array<{ careType: CareType }>;
    providers: Array<{
      id: number;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      classification: string;
      role: Role;
      email?: string | null;
      geoStates: Array<StateCodes>;
      postNominalTitles?: string | null;
      personalTitle?: string | null;
      careTypes: Array<CareType>;
      portrait?: { url: string } | null;
      organizations: Array<{
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      }>;
    }>;
    appointmentTemplates: Array<{
      id: number;
      description: string;
      appointmentType: string;
      careType: CareType;
      duration: number;
    }>;
    director?: {
      id: number;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      postNominalTitles?: string | null;
      classification: string;
      phone?: string | null;
      email?: string | null;
    } | null;
  };
};

export type OrganizationStaffQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type OrganizationStaffQuery = {
  organization: {
    providers: Array<{
      id: number;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      classification: string;
      role: Role;
      email?: string | null;
      geoStates: Array<StateCodes>;
      postNominalTitles?: string | null;
      personalTitle?: string | null;
      careTypes: Array<CareType>;
      portrait?: { url: string } | null;
      organizations: Array<{
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      }>;
    }>;
  };
};

export type AdminAlertsQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type AdminAlertsQuery = {
  adminUser: {
    adminAlerts: Array<{
      type: AdminAlertType;
      message: string;
      careType?: CareType | null;
      resolveBy?: string | null;
    }>;
  };
};

export type SidebarDataQueryVariables = Exact<{
  careTeamMessages: Scalars['Boolean'];
  patientMessages: Scalars['Boolean'];
  unverified: Scalars['Boolean'];
  newTicketCount: Scalars['Boolean'];
}>;

export type SidebarDataQuery = {
  unreadPatientMessages?: number;
  unreadCareTeamMessages?: number;
  unverifiedUserCount?: number;
  tickets?: Array<{ id: number }>;
};

export type ProviderEmailsQueryVariables = Exact<{
  providerId: Scalars['Float'];
}>;

export type ProviderEmailsQuery = {
  providerEmails: Array<{
    id: number;
    sentAt: Date;
    template: string;
    subject: string;
    delivered: boolean;
    bounced: boolean;
    opened: boolean;
  }>;
};

export type AllEventsQueryVariables = Exact<{
  before?: InputMaybe<Scalars['DateTime']>;
}>;

export type AllEventsQuery = {
  allEvents: Array<{
    id: number;
    tag: string;
    createdAt: Date;
    data?: { [key: string]: any } | null;
    user?: {
      id: number;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
    } | null;
  }>;
};

export type CouponsQueryVariables = Exact<{ [key: string]: never }>;

export type CouponsQuery = {
  coupons: Array<{
    id: number;
    code: string;
    discountType: DiscountType;
    percentDiscount?: number | null;
    replacePriceCents?: number | null;
    maxUses: number;
    active: boolean;
    autoApplyOnConversion: boolean;
    careTypes: Array<CareType>;
    organization?: { id: number; name: string } | null;
  }>;
};

export type OrganizationEntitlementsPageQueryVariables = Exact<{
  organizationId: Scalars['Float'];
}>;

export type OrganizationEntitlementsPageQuery = {
  organization: {
    name: string;
    entitlementsCoupledToParent: boolean;
    parent?: { id: number; name: string; abbreviation: string; emailDomains: Array<string> } | null;
    entitlements: Array<{ key: Entitlement; config: { [key: string]: any } }>;
  };
  entitlementDefinitions: Array<{
    key: Entitlement;
    name: string;
    description: string;
    config: Array<{
      key: string;
      name: string;
      type: string;
      placeholder?: string | null;
      size?: EntitlementDefinitionConfigSize | null;
      required?: boolean | null;
      smallText?: string | null;
      maxLength?: number | null;
    }>;
  }>;
};

export type OrganizationResourcesQueryVariables = Exact<{
  organizationId: Scalars['Float'];
}>;

export type OrganizationResourcesQuery = {
  organization: {
    emergencyResources: Array<{
      id: number;
      name: string;
      description?: string | null;
      resourceType: ResourceType;
      tags: Array<ResourceTag>;
    }>;
  };
};

export type OrganizationCarePeriodsQueryVariables = Exact<{
  organizationId: Scalars['Float'];
}>;

export type OrganizationCarePeriodsQuery = {
  organization: {
    parent?: { id: number; name: string; abbreviation: string; emailDomains: Array<string> } | null;
    carePeriods: Array<{ id: number; startDate: string; endDate: string; periodType: PeriodType }>;
  };
};

export type OrganizationUtilizationQueryVariables = Exact<{
  organizationId: Scalars['Float'];
}>;

export type OrganizationUtilizationQuery = {
  organization: {
    parent?: { id: number; name: string; abbreviation: string; emailDomains: Array<string> } | null;
    careFlows: Array<{ careType: CareType }>;
    organizationUtilization?: {
      carePeriodUtilizations: Array<{
        carePeriod: { startDate: string; endDate: string; periodType: PeriodType };
        weeklyUtilizationRecords: Array<{
          id: number;
          organizationId: number;
          startDate: string;
          endDate: string;
          therapyHours: {
            maxHours?: number | null;
            maxIntakeHours?: number | null;
            hoursUtilized?: number | null;
            hoursScheduled?: number | null;
            intakesUtilized?: number | null;
            intakesScheduled?: number | null;
          };
          psychiatryHours: {
            maxHours?: number | null;
            maxIntakeHours?: number | null;
            hoursUtilized?: number | null;
            hoursScheduled?: number | null;
            intakesUtilized?: number | null;
            intakesScheduled?: number | null;
          };
        }>;
      }>;
    } | null;
  };
};

export type ContractWeekFragment = {
  id: number;
  startDate: string;
  endDate: string;
  sessionsMax: number;
  sessionsUtilized: number;
  sessionsScheduled: number;
  intakesMax: number;
  intakesUtilized: number;
  intakesScheduled: number;
};

export type ContractUsageByCareTypeFragment = {
  studentCapacityRemaining: number;
  studentCapacityUtilized: number;
  sessionsScheduled: number;
  sessionsUtilized: number;
  sessionsRemaining: number;
};

export type ListContractsQueryVariables = Exact<{
  organizationId: Scalars['Float'];
}>;

export type ListContractsQuery = {
  listContracts: { contracts: Array<{ id: number; startDate: string; endDate: string }> };
};

export type GetContractQueryVariables = Exact<{
  organizationId: Scalars['Float'];
  contractId: Scalars['Float'];
}>;

export type GetContractQuery = {
  organization: { careFlows: Array<{ careType: CareType }>; parent?: { id: number } | null };
  getContract:
    | {
        __typename: 'Contract';
        id: number;
        scope: {
          startDate: string;
          endDate: string;
          therapySessions: number;
          therapyPatients: number;
          psychiatrySessions: number;
          psychiatryPatients: number;
        };
        utilization: {
          therapyUsage: {
            studentCapacityRemaining: number;
            studentCapacityUtilized: number;
            sessionsScheduled: number;
            sessionsUtilized: number;
            sessionsRemaining: number;
          };
          psychiatryUsage: {
            studentCapacityRemaining: number;
            studentCapacityUtilized: number;
            sessionsScheduled: number;
            sessionsUtilized: number;
            sessionsRemaining: number;
          };
        };
        therapyWeeks: {
          past: Array<{
            id: number;
            startDate: string;
            endDate: string;
            sessionsMax: number;
            sessionsUtilized: number;
            sessionsScheduled: number;
            intakesMax: number;
            intakesUtilized: number;
            intakesScheduled: number;
          }>;
          current?: {
            id: number;
            startDate: string;
            endDate: string;
            sessionsMax: number;
            sessionsUtilized: number;
            sessionsScheduled: number;
            intakesMax: number;
            intakesUtilized: number;
            intakesScheduled: number;
          } | null;
          upcoming: Array<{
            id: number;
            startDate: string;
            endDate: string;
            sessionsMax: number;
            sessionsUtilized: number;
            sessionsScheduled: number;
            intakesMax: number;
            intakesUtilized: number;
            intakesScheduled: number;
          }>;
        };
        psychiatryWeeks: {
          past: Array<{
            id: number;
            startDate: string;
            endDate: string;
            sessionsMax: number;
            sessionsUtilized: number;
            sessionsScheduled: number;
            intakesMax: number;
            intakesUtilized: number;
            intakesScheduled: number;
          }>;
          current?: {
            id: number;
            startDate: string;
            endDate: string;
            sessionsMax: number;
            sessionsUtilized: number;
            sessionsScheduled: number;
            intakesMax: number;
            intakesUtilized: number;
            intakesScheduled: number;
          } | null;
          upcoming: Array<{
            id: number;
            startDate: string;
            endDate: string;
            sessionsMax: number;
            sessionsUtilized: number;
            sessionsScheduled: number;
            intakesMax: number;
            intakesUtilized: number;
            intakesScheduled: number;
          }>;
        };
      }
    | { __typename: 'GetContractError'; errorCode: GetContractErrorCode; message: string };
};

export type OrganizationCareFlowsQueryVariables = Exact<{
  organizationId: Scalars['Float'];
}>;

export type OrganizationCareFlowsQuery = {
  organization: {
    id: number;
    parent?: { id: number; name: string } | null;
    careFlows: Array<{
      id: number;
      careType: CareType;
      sponsoredSessionsLimitType: SponsoredSessionsLimitType;
      sponsoredSessions?: number | null;
      canSelfRefer: boolean;
      canReferrerUsersRefer: boolean;
      canMcpUsersRefer: boolean;
      defaultPaymentSource: PaymentSource;
      referralRestrictedStates: Array<StateCodes>;
      hideSessionCount: boolean;
    }>;
  };
};

export type OrganizationsSimpleQueryVariables = Exact<{ [key: string]: never }>;

export type OrganizationsSimpleQuery = {
  organizations: Array<{ id: number; name: string; children: Array<{ id: number; name: string }> }>;
};

export type PayersQueryVariables = Exact<{ [key: string]: never }>;

export type PayersQuery = {
  payers: Array<{
    id: number;
    name: string;
    inNetworkStates: Array<string>;
    visible: boolean;
    tradingPartnerId: string;
    standardPayerId: string;
    billToProviderName: boolean;
    useAddressForAllStates?: string | null;
    stateAddressOverrides: Array<{ overrideForState: StateCodes; stateToUseInstead: StateCodes }>;
  }>;
};

export type AllPayersQueryVariables = Exact<{ [key: string]: never }>;

export type AllPayersQuery = {
  allPayers: Array<{
    id: number;
    name: string;
    inNetworkStates: Array<string>;
    visible: boolean;
    tradingPartnerId: string;
    standardPayerId: string;
    billToProviderName: boolean;
    useAddressForAllStates?: string | null;
    stateAddressOverrides: Array<{ overrideForState: StateCodes; stateToUseInstead: StateCodes }>;
  }>;
};

export type OrganizationReportQueryVariables = Exact<{
  organizationId: Scalars['Float'];
  referralSource?: InputMaybe<ReferralSource>;
  carePeriodId?: InputMaybe<Scalars['Float']>;
}>;

export type OrganizationReportQuery = {
  organization: {
    id: number;
    name: string;
    abbreviation: string;
    emailDomains: Array<string>;
    reporting: {
      activeStudentsCount: number;
      referralCompletionRate?: number | null;
      averageReferralToVisitTime?: number | null;
      patientMonitoring: Array<{
        id: number;
        customerId?: string | null;
        createdAt: Date;
        firstName: string;
        lastName: string;
        preferredName?: string | null;
        assessmentInfo: { riskLevel?: string | null; suicidal?: Array<string> | null };
        safetyPlan?: { complete: boolean } | null;
      }>;
      careTeamReferrals: Array<{
        referralsMade: number;
        activePatients: number;
        activeReferrals: number;
        provider: { id: number; name: string; role: Role; portrait?: { url: string } | null };
      }>;
      visitAttendance: {
        carePeriod?: { startDate: string } | null;
        attendance: Array<{ key: string; value: number }>;
      };
      visitSatisfactionRatings: { averageRating?: number | null; numberOfRatings: number };
    };
  };
};

export type DiagnosesBreakdownQueryVariables = Exact<{
  organizationId: Scalars['Float'];
  referralSource?: InputMaybe<ReferralSource>;
}>;

export type DiagnosesBreakdownQuery = {
  organization: {
    reporting: {
      diagnosesBreakdown: Array<{
        userId: number;
        code: string;
        key: string;
        category: string;
        subCategory?: string | null;
      }>;
    };
  };
};

export type OverviewReportQueryVariables = Exact<{
  scope: ReportScope;
}>;

export type OverviewReportQuery = {
  adminOverviewReport: {
    activeStudentsCount: number;
    referralCompletionRate?: number | null;
    averageReferralToVisitTime?: number | null;
    patientMonitoring: Array<{
      id: number;
      customerId?: string | null;
      createdAt: Date;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      assessmentInfo: { riskLevel?: string | null; suicidal?: Array<string> | null };
      safetyPlan?: { complete: boolean } | null;
    }>;
    careTeamReferrals: Array<{
      referralsMade: number;
      activePatients: number;
      activeReferrals: number;
      provider: { id: number; name: string; role: Role; portrait?: { url: string } | null };
    }>;
    visitAttendance: {
      carePeriod?: { startDate: string } | null;
      attendance: Array<{ key: string; value: number }>;
    };
    visitSatisfactionRatings: { averageRating?: number | null; numberOfRatings: number };
  };
};

export type OverviewDiagnosesBreakdownQueryVariables = Exact<{
  scope: ReportScope;
}>;

export type OverviewDiagnosesBreakdownQuery = {
  adminOverviewReport: {
    diagnosesBreakdown: Array<{
      userId: number;
      code: string;
      key: string;
      category: string;
      subCategory?: string | null;
    }>;
  };
};

export type SuperAdminReferralsQueryVariables = Exact<{
  organizationId: Scalars['Float'];
}>;

export type SuperAdminReferralsQuery = {
  organization: {
    id: number;
    name: string;
    abbreviation: string;
    emailDomains: Array<string>;
    children: Array<{
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
      reporting: {
        referralCompletionRate?: number | null;
        patientMonitoring: Array<{
          id: number;
          customerId?: string | null;
          createdAt: Date;
          firstName: string;
          lastName: string;
          preferredName?: string | null;
          campusTeam?: Array<{ provider: { id: number } }> | null;
          assessmentInfo: { riskLevel?: string | null; suicidal?: Array<string> | null };
          safetyPlan?: { complete: boolean } | null;
        }>;
        careTeamReferrals: Array<{
          referralsMade: number;
          activePatients: number;
          activeReferrals: number;
          referralCompletionRate: number;
          provider: { id: number; name: string; role: Role };
        }>;
      };
    }>;
  };
};

export type FullMantraQueryVariables = Exact<{ [key: string]: never }>;

export type FullMantraQuery = {
  organizations: Array<{
    id: number;
    name: string;
    abbreviation: string;
    emailDomains: Array<string>;
    children: Array<{
      id: number;
      name: string;
      abbreviation: string;
      emailDomains: Array<string>;
    }>;
    providers: Array<{
      id: number;
      name: string;
      firstName?: string | null;
      lastName?: string | null;
      classification: string;
      role: Role;
      email?: string | null;
      geoStates: Array<StateCodes>;
      postNominalTitles?: string | null;
      personalTitle?: string | null;
      careTypes: Array<CareType>;
      patients: Array<{
        id: number;
        customerId?: string | null;
        createdAt: Date;
        firstName: string;
        lastName: string;
        preferredName?: string | null;
      }>;
      portrait?: { url: string } | null;
      organizations: Array<{
        id: number;
        name: string;
        abbreviation: string;
        emailDomains: Array<string>;
      }>;
    }>;
  }>;
};

export type AdminUsersListFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type AdminUsersListFiltersQuery = {
  organizations: Array<{ id: number; name: string }>;
  providers: Array<{
    id: number;
    firstName?: string | null;
    lastName?: string | null;
    postNominalTitles?: string | null;
  }>;
};

export type CapacityDropdownsQueryVariables = Exact<{ [key: string]: never }>;

export type CapacityDropdownsQuery = {
  organizations: Array<{
    id: number;
    name: string;
    parent?: { id: number } | null;
    providers: Array<{ id: number; name: string; role: Role }>;
  }>;
  providers: Array<{ id: number; name: string; careTypes: Array<CareType> }>;
};

export type CapacityQueryVariables = Exact<{
  startTime: Scalars['DateTime'];
  weeks: Scalars['Int'];
  organizationId?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['Int']>;
}>;

export type CapacityQuery = {
  weeklyCapacityUsage: Array<{
    startTime: Date;
    endTime: Date;
    entries: Array<{ careType: CareType; minutesUsed: number; minutesAvailable: number }>;
  }>;
};

export type SamlConfigurationQueryVariables = Exact<{
  organizationId: Scalars['Float'];
  type: SamlConfigurationType;
}>;

export type SamlConfigurationQuery = {
  organization: {
    id: number;
    entitlements: Array<{ id: number; key: Entitlement }>;
    samlConfiguration?: {
      id: number;
      entryPoint: string;
      certificates: Array<string>;
      metadata: string;
      profileEmailField: string;
      profileUniqueIdField?: string | null;
      profileMatchingField: string;
      issuer?: string | null;
      authnRequestBinding?: string | null;
      disableRequestedAuthnContext: boolean;
      enableManualUniqueIdEdits: boolean;
      automaticallyUpdateEmail: boolean;
      profileCampusField?: string | null;
      campusOrgMappings?: Array<{ campus: string; organizationId: number }> | null;
    } | null;
  };
};

export type AppointmentsForNoteSelectorQueryVariables = Exact<{
  userId: Scalars['Float'];
  startTime: Scalars['DateTime'];
}>;

export type AppointmentsForNoteSelectorQuery = {
  adminAppointments: Array<{
    id: number;
    startTime: Date;
    description?: string | null;
    status: string;
    appointmentType: string;
    provider: { id: number };
    medicalNote?: { id: number } | null;
  }>;
};

export type TraitCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type TraitCategoriesQuery = {
  traitCategories: Array<{ category: TraitCategory; values: Array<string> }>;
};

export type ProviderProfileQueryVariables = Exact<{
  providerId: Scalars['Float'];
}>;

export type ProviderProfileQuery = {
  adminProvider: {
    id: number;
    personalTitle?: string | null;
    activityStatus?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    postNominalTitles?: string | null;
    email?: string | null;
    phone?: string | null;
    gender?: string | null;
    pronouns?: string | null;
    funFact: string;
    geoStates: Array<StateCodes>;
    role: Role;
    guaranteedPay: boolean;
    classification: string;
    careTypes: Array<CareType>;
    providerType?: string | null;
    npi?: string | null;
    dosespotId?: number | null;
    traits: Array<{ category: TraitCategory; values: Array<string> }>;
    organizations: Array<{
      id: number;
      name: string;
      entitlements: Array<{ key: Entitlement; config: { [key: string]: any } }>;
      children: Array<{ id: number; name: string }>;
    }>;
    organizationCrisisTeams: Array<{ crisisPhone: string; organization: { id: number } }>;
    insuranceCredentials: Array<{ id: number; state: string; payer: { id: number; name: string } }>;
    portrait?: { url: string } | null;
  };
};

export type OrganizationEligibilityQueryVariables = Exact<{
  organizationId: Scalars['Float'];
}>;

export type OrganizationEligibilityQuery = {
  organization: {
    eligibilityCriteria: Array<{
      id: number;
      minimumAge?: number | null;
      states?: Array<string> | null;
      permittedCareTypes?: Array<CareType> | null;
    }>;
  };
};

export type SuggestedPharmacyQueryVariables = Exact<{
  organizationId: Scalars['Float'];
}>;

export type SuggestedPharmacyQuery = {
  organization: {
    suggestedPharmacy?: {
      pharmacyId: number;
      storeName: string;
      address1: string;
      address2?: string | null;
      city: string;
      state: string;
      zipCode: string;
      primaryPhone?: string | null;
      primaryFax?: string | null;
      serviceLevel?: number | null;
      latitude?: number | null;
      longitude?: number | null;
      primary?: boolean | null;
    } | null;
  };
};

export type AuthInstructionsForEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type AuthInstructionsForEmailQuery = {
  authInstructionsForEmail: { authMethod: AuthMethod; samlEntryPoint?: string | null };
};

export type OrganizationCrisisTeamQueryVariables = Exact<{
  organizationId: Scalars['Float'];
}>;

export type OrganizationCrisisTeamQuery = {
  organization: {
    crisisHours: Array<{ dayOfWeek: DayOfWeek; startTime: Date; endTime: Date }>;
    crisisTeamProviders: Array<{
      id: number;
      name: string;
      role: Role;
      portrait?: { url: string } | null;
    }>;
  };
};

export type OrganizationHandbookQueryVariables = Exact<{
  organizationId: Scalars['Float'];
}>;

export type OrganizationHandbookQuery = {
  organizationHandbook: {
    sessionLimitsRenew: string;
    noShowsCountForSessionLimits: string;
    defaultTherapyCadence: string;
    coPayChargedByPartner: string;
    labsNearCampus: string;
    ekgsNearCampus: string;
    vitalsNearCampus: string;
    highLevelProtocolManagingEmergency: string;
    campusSafetyPoliceNumber: string;
    supportingClinician: string;
    hospitalization: string;
    mainPointOfContactCrisis: string;
    campusCrisisTeamInProduct: string;
    requestingAcademicAccomodations: string;
    fullADHDEvaluation: string;
    protocolForMedicalLeaveDoc: string;
    winterBreakEligibility: string;
    summerBreakEligibility: string;
    hasManualReactivationEntitlement: boolean;
  };
};

export type OrganizationAnnouncementsByOrgIdQueryVariables = Exact<{
  organizationId: Scalars['Float'];
  limit?: InputMaybe<Scalars['Float']>;
}>;

export type OrganizationAnnouncementsByOrgIdQuery = {
  organizationAnnouncementsByOrgId: Array<{
    id: number;
    createdByProviderName: string;
    title: string;
    details?: string | null;
    createdAt: Date;
    inCurrentCarePeriod: boolean;
  }>;
};

export type OrganizationAnnouncementsByDaysQueryVariables = Exact<{
  organizationId: Scalars['Float'];
  days?: InputMaybe<Scalars['Float']>;
}>;

export type OrganizationAnnouncementsByDaysQuery = {
  organizationAnnouncementsByDays: Array<{
    id: number;
    createdByProviderName: string;
    title: string;
    details?: string | null;
    createdAt: Date;
    inCurrentCarePeriod: boolean;
  }>;
};

export type CrisisReportsForOrgQueryVariables = Exact<{ [key: string]: never }>;

export type CrisisReportsForOrgQuery = {
  crisisReportsForOrg: Array<{
    id: number;
    ddhFileEntityId: number;
    organizationId: number;
    reportDate: string;
    reports?: Array<{
      id: number;
      ddhFileSuccessId: number;
      callId: string;
      callStart: Date;
    }> | null;
  }>;
};

export type CrisisReportsZipQueryVariables = Exact<{
  ddhFileSuccessId: Scalars['Int'];
}>;

export type CrisisReportsZipQuery = { crisisReportsZip: { zipLink: string } };

export type AdminScreenUserResultQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type AdminScreenUserResultQuery = {
  adminScreenUserResult?: { screenedOut: boolean; screenedOutFromHub: boolean } | null;
};

export type ConnectNowCallReportsQueryVariables = Exact<{
  params: ConnectNowReportsInput;
}>;

export type ConnectNowCallReportsQuery = {
  connectNowOrgSessions?: {
    hasMore: boolean;
    cursor: string;
    count?: number | null;
    limitCountExceeded?: boolean | null;
    items: Array<{
      id: number;
      callStartTime: Date;
      callType: string;
      callerFirstName: string;
      callerPreferredName?: string | null;
      callerLastName: string;
      healthieNoteId: string;
      userId?: number | null;
      customerId?: string | null;
      organization?: { id: number; name: string } | null;
    }>;
  } | null;
};

export type HealthieProviderSyncedQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type HealthieProviderSyncedQuery = { healthieProviderSynced: boolean };

export type PaginatedIopReferralsQueryVariables = Exact<{
  params: PaginatedIopReferralInput;
}>;

export type PaginatedIopReferralsQuery = {
  paginatedIopReferrals: {
    hasMore: boolean;
    cursor: string;
    count: number;
    items: Array<{
      id: number;
      userId?: number | null;
      email?: string | null;
      firstName: string;
      preferredName?: string | null;
      lastName: string;
      referralDateTime: Date;
      organizationId?: number | null;
      organizationName: string;
      status: string;
    }>;
  };
};

export type PriceConfigurationQueryVariables = Exact<{ [key: string]: never }>;

export type PriceConfigurationQuery = {
  pricesInCents: {
    psychiatryIntakePriceInCents: number;
    psychiatryFollowUpPriceInCents: number;
    therapyIntakePriceInCents: number;
    therapyFollowUpPriceInCents: number;
    noShowFeeInCents: number;
    lateCancellationFeeInCents: number;
  };
};

export type ConnectNowSessionDocumentQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ConnectNowSessionDocumentQuery = {
  connectNowSessionReport?: { document?: { expiringUrl: string } | null } | null;
};

export type McpCurrentContractUtilizationQueryVariables = Exact<{ [key: string]: never }>;

export type McpCurrentContractUtilizationQuery = {
  mcpCurrentContractUtilization: {
    utilization?: {
      scope: {
        startDate: string;
        endDate: string;
        therapySessions: number;
        therapyPatients: number;
        psychiatrySessions: number;
        psychiatryPatients: number;
      };
      psychiatryUsage: {
        studentCapacityRemaining: number;
        studentCapacityUtilized: number;
        sessionsScheduled: number;
        sessionsUtilized: number;
        sessionsRemaining: number;
      };
      therapyUsage: {
        studentCapacityRemaining: number;
        studentCapacityUtilized: number;
        sessionsScheduled: number;
        sessionsUtilized: number;
        sessionsRemaining: number;
      };
    } | null;
  };
};

export type DeactivateOrganizationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeactivateOrganizationMutation = { deactivateOrganization: boolean };

export const CampusTeamOptionFragmentDoc = gql`
  fragment campusTeamOption on Provider {
    id
    name
    portrait {
      url
    }
  }
`;
export const AppointmentTemplateFragmentDoc = gql`
  fragment appointmentTemplate on AppointmentTemplate {
    id
    description
    duration
    appointmentType
    careType
  }
`;
export const NetworkReferralUserFragmentDoc = gql`
  fragment networkReferralUser on User {
    id
    firstName
    lastName
    birthDate
    careFlows {
      careType
      paymentSource
      organizationCareFlow {
        defaultPaymentSource
      }
    }
    insuranceDetailsIfVisible {
      payer {
        id
        name
      }
      memberId
      state
      inNetwork
      eligibilityVerified
    }
    insuranceDetailsIfBookingForSelfPayPatient {
      payer {
        id
        name
      }
      memberId
      state
      inNetwork
      eligibilityVerified
    }
    appointmentTemplates {
      ...appointmentTemplate
    }
    suggestedProviders {
      id
    }
  }
  ${AppointmentTemplateFragmentDoc}
`;
export const OrganizationFragmentDoc = gql`
  fragment organization on Organization {
    id
    name
    abbreviation
    emailDomains
  }
`;
export const OrganizationEntitlementFragmentDoc = gql`
  fragment organizationEntitlement on OrganizationEntitlement {
    key
    config
  }
`;
export const AppointmentUserV2FragmentDoc = gql`
  fragment appointmentUserV2 on User {
    id
    firstName
    preferredName
    lastName
    customerId
    therapyUpcoming: nextAppointment(careType: Therapy, status: "upcoming") {
      id
    }
    therapyComplete: lastAppointment(careType: Therapy, status: "complete") {
      id
    }
    psychUpcoming: nextAppointment(careType: Psychiatry, status: "upcoming") {
      id
    }
    psychComplete: lastAppointment(careType: Psychiatry, status: "complete") {
      id
    }
    primaryAddressState
    organization {
      ...organization
      parent {
        ...organization
      }
      entitlements {
        ...organizationEntitlement
      }
    }
    careFlows {
      careType
      canBookAppointment
      paymentSource
    }
    hasEligibleInsurance
    hasTherapist
    hasProvider
    isPaymentMethodValid
    hasPaymentMethod
    appointmentTemplates {
      ...appointmentTemplate
    }
    careTypes
  }
  ${OrganizationFragmentDoc}
  ${OrganizationEntitlementFragmentDoc}
  ${AppointmentTemplateFragmentDoc}
`;
export const BookingProviderFragmentDoc = gql`
  fragment bookingProvider on Provider {
    id
    name
    role
    geoStates
    careTypes
    organizations {
      id
    }
  }
`;
export const ReschedulingAppointmentFragmentDoc = gql`
  fragment reschedulingAppointment on CalendarEvent {
    id
    status
    startTime
    endTime
    appointmentType
    careType
    user {
      ...appointmentUserV2
    }
    provider {
      ...bookingProvider
    }
  }
  ${AppointmentUserV2FragmentDoc}
  ${BookingProviderFragmentDoc}
`;
export const UserNamesFragmentDoc = gql`
  fragment userNames on User {
    firstName
    lastName
    preferredName
  }
`;
export const ReferralAppointmentDataFragmentDoc = gql`
  fragment referralAppointmentData on User {
    therapyUpcoming: nextAppointment(
      careType: Therapy
      status: "upcoming"
      appointmentType: intake
    ) {
      id
      startTime
    }
    therapyComplete: lastAppointment(
      careType: Therapy
      status: "complete"
      appointmentType: intake
    ) {
      id
      startTime
    }
    psychUpcoming: nextAppointment(
      careType: Psychiatry
      status: "upcoming"
      appointmentType: intake
    ) {
      id
      startTime
    }
    psychComplete: lastAppointment(
      careType: Psychiatry
      status: "complete"
      appointmentType: intake
    ) {
      id
      startTime
    }
  }
`;
export const ReferralUserDataFragmentDoc = gql`
  fragment referralUserData on User {
    id
    ...userNames
    email: emailIfVisible
    customerId
    createdAt
    onHoldOrActiveCareTypes
    ...referralAppointmentData
  }
  ${UserNamesFragmentDoc}
  ${ReferralAppointmentDataFragmentDoc}
`;
export const TicketFragmentDoc = gql`
  fragment ticket on Ticket {
    id
    title
    priority
    description
    blockedReason
    status
    updatedAt
    createdAt
    resolveIn24Hrs
    category
  }
`;
export const TicketUserFragmentDoc = gql`
  fragment ticketUser on User {
    id
    customerId
    ...userNames
    organization {
      id
      name
    }
  }
  ${UserNamesFragmentDoc}
`;
export const UserBasicFragmentDoc = gql`
  fragment userBasic on User {
    id
    ...userNames
    customerId
    createdAt
  }
  ${UserNamesFragmentDoc}
`;
export const ProviderFragmentDoc = gql`
  fragment provider on Provider {
    id
    name
    firstName
    lastName
    classification
    role
    email
    geoStates
    postNominalTitles
    personalTitle
    careTypes
    portrait {
      url
    }
    organizations {
      ...organization
    }
  }
  ${OrganizationFragmentDoc}
`;
export const CareFlowFragmentDoc = gql`
  fragment careFlow on UserCareFlow {
    id
    careType
    careStatus
    paymentSource
  }
`;
export const CareSessionInfoFragmentDoc = gql`
  fragment careSessionInfo on CareSessionInfo {
    unlimitedAppts
    remainingAppts
    flowLimit
    completedAppts
  }
`;
export const CareFlowAndSessionsFragmentDoc = gql`
  fragment careFlowAndSessions on UserCareFlow {
    ...careFlow
    careSessionInfo {
      ...careSessionInfo
    }
  }
  ${CareFlowFragmentDoc}
  ${CareSessionInfoFragmentDoc}
`;
export const UserListItemFragmentDoc = gql`
  fragment userListItem on User {
    ...userBasic
    email
    createdAt
    careStatus
    taskStatus
    provider {
      id
      name
    }
    therapist {
      ...provider
    }
    organization {
      ...organization
    }
    continuityOfCareTransitions {
      user {
        id
        organization {
          id
          latestCarePeriod {
            id
            endDate
          }
        }
      }
      careType
      nextSteps
      pendingUser
    }
    careFlows {
      ...careFlowAndSessions
    }
  }
  ${UserBasicFragmentDoc}
  ${ProviderFragmentDoc}
  ${OrganizationFragmentDoc}
  ${CareFlowAndSessionsFragmentDoc}
`;
export const VideoCallDataFragmentDoc = gql`
  fragment videoCallData on CalendarEvent {
    id
    status
    startTime
    endTime
    videoSessionId
    videoToken
    appointmentType
    description
    adminHostCrisisId
    adminCrisisTeamNotifiedAt
    adminCrisisProtocolEngagedAt
    user {
      ...userBasic
      provider {
        id
        name
      }
      organization {
        currentCrisisHours {
          startTime
          endTime
        }
        crisisTeamProviders {
          role
        }
        entitlements {
          ...organizationEntitlement
        }
        ...organization
      }
      selfie {
        id
        url
      }
    }
    provider {
      id
      name
    }
  }
  ${UserBasicFragmentDoc}
  ${OrganizationEntitlementFragmentDoc}
  ${OrganizationFragmentDoc}
`;
export const TaskFragmentDoc = gql`
  fragment task on Task {
    id
    ref
    title
    createdAt
    done
    userId
    updatedAt
  }
`;
export const SafetyPlanContactFragmentDoc = gql`
  fragment safetyPlanContact on SafetyPlanContact {
    name
    contact
    address
  }
`;
export const SafetyPlanDataFragmentDoc = gql`
  fragment safetyPlanData on SafetyPlanData {
    reasonToLive
    firearmAccess
    firearmAccessPlan
    dangerousMeans
    dangerousMeansList
    dangerousMeansPlan
    warningSigns
    copingStrategies
    distractions {
      ...safetyPlanContact
    }
    crisisContacts {
      ...safetyPlanContact
    }
    professionalContacts {
      ...safetyPlanContact
    }
    crisisResources {
      ...safetyPlanContact
    }
    crisisFacilities {
      ...safetyPlanContact
    }
  }
  ${SafetyPlanContactFragmentDoc}
`;
export const OrganizationUtilizationFragmentDoc = gql`
  fragment organizationUtilization on Organization {
    organizationUtilization {
      carePeriodUtilizations {
        carePeriod {
          startDate
          endDate
          periodType
        }
        weeklyUtilizationRecords {
          id
          organizationId
          startDate
          endDate
          therapyHours {
            maxHours
            maxIntakeHours
            hoursUtilized
            hoursScheduled
            intakesUtilized
            intakesScheduled
          }
          psychiatryHours {
            maxHours
            maxIntakeHours
            hoursUtilized
            hoursScheduled
            intakesUtilized
            intakesScheduled
          }
        }
      }
    }
  }
`;
export const BaseProviderFragmentDoc = gql`
  fragment baseProvider on Provider {
    id
    name
    role
    geoStates
  }
`;
export const AppointmentAttendeeFragmentDoc = gql`
  fragment appointmentAttendee on User {
    id
    firstName
    preferredName
    lastName
    customerId
    provider {
      id
      name
      role
      careTypes
    }
    therapist {
      id
      name
      role
    }
    organization {
      ...organization
    }
  }
  ${OrganizationFragmentDoc}
`;
export const BasicAppointmentUserFragmentDoc = gql`
  fragment basicAppointmentUser on User {
    id
    firstName
    preferredName
    lastName
    customerId
    careTypes
    primaryAddressState
    therapyUpcoming: nextAppointment(careType: Therapy, status: "upcoming") {
      id
    }
    therapyComplete: lastAppointment(careType: Therapy, status: "complete") {
      id
    }
    psychUpcoming: nextAppointment(careType: Psychiatry, status: "upcoming") {
      id
    }
    psychComplete: lastAppointment(careType: Psychiatry, status: "complete") {
      id
    }
  }
`;
export const AddressFragmentDoc = gql`
  fragment address on Address {
    name
    line1
    line2
    city
    state
    zip
  }
`;
export const PharmacyFragmentDoc = gql`
  fragment pharmacy on Pharmacy {
    pharmacyId
    storeName
    address1
    address2
    city
    state
    zipCode
    primaryPhone
    primaryFax
    serviceLevel
    latitude
    longitude
    primary
  }
`;
export const InsuranceClaimEventFragmentDoc = gql`
  fragment insuranceClaimEvent on InsuranceClaimEvent {
    id
    updatedAt
    actor {
      name
    }
    action
    status
    details
  }
`;
export const UploadFragmentDoc = gql`
  fragment upload on Upload {
    id
    createdAt
    contentType
    name
    url
    type
    globallyShared
    canEdit
    createdByProvider {
      id
      name
    }
  }
`;
export const UserDetailFragmentDoc = gql`
  fragment userDetail on User {
    ...userBasic
    organization {
      ...organization
    }
    selfie {
      ...upload
    }
  }
  ${UserBasicFragmentDoc}
  ${OrganizationFragmentDoc}
  ${UploadFragmentDoc}
`;
export const SentEmailFragmentDoc = gql`
  fragment sentEmail on SentEmail {
    id
    sentAt
    template
    subject
    delivered
    bounced
    opened
  }
`;
export const OrganizationCareFlowFragmentDoc = gql`
  fragment organizationCareFlow on OrganizationCareFlow {
    careType
    canReferrerUsersRefer
    canMcpUsersRefer
    defaultPaymentSource
  }
`;
export const CouponFragmentDoc = gql`
  fragment coupon on ServiceCoupon {
    id
    code
    discountType
    percentDiscount
    replacePriceCents
    maxUses
    active
    autoApplyOnConversion
    careTypes
    organization {
      id
      name
    }
  }
`;
export const PayerFragmentDoc = gql`
  fragment payer on Payer {
    id
    name
    inNetworkStates
    visible
    tradingPartnerId
    standardPayerId
    billToProviderName
    useAddressForAllStates
    stateAddressOverrides {
      overrideForState
      stateToUseInstead
    }
  }
`;
export const MonitorUserFragmentDoc = gql`
  fragment monitorUser on User {
    ...userBasic
    assessmentInfo {
      riskLevel
      suicidal
    }
    safetyPlan {
      complete
    }
  }
  ${UserBasicFragmentDoc}
`;
export const BaseReportFragmentDoc = gql`
  fragment baseReport on OrganizationReport {
    activeStudentsCount
    referralCompletionRate
    averageReferralToVisitTime
    patientMonitoring {
      ...monitorUser
    }
    careTeamReferrals {
      referralsMade
      activePatients
      activeReferrals
      provider {
        id
        name
        role
        portrait {
          url
        }
      }
    }
    visitAttendance {
      carePeriod {
        startDate
      }
      attendance {
        key
        value
      }
    }
    visitSatisfactionRatings {
      averageRating
      numberOfRatings
    }
  }
  ${MonitorUserFragmentDoc}
`;
export const DiagnosisReportFragmentDoc = gql`
  fragment diagnosisReport on OrganizationReport {
    diagnosesBreakdown {
      userId
      code
      key
      category
      subCategory
    }
  }
`;
export const UserNotificationFragmentDoc = gql`
  fragment userNotification on InAppNotification {
    id
    createdAt
    key
    data
    isDismissible
    isRequired
  }
`;
export const OrganizationHandbookFragmentDoc = gql`
  fragment organizationHandbook on OrganizationHandbook {
    sessionLimitsRenew
    noShowsCountForSessionLimits
    defaultTherapyCadence
    coPayChargedByPartner
    labsNearCampus
    ekgsNearCampus
    vitalsNearCampus
    highLevelProtocolManagingEmergency
    campusSafetyPoliceNumber
    supportingClinician
    hospitalization
    mainPointOfContactCrisis
    campusCrisisTeamInProduct
    requestingAcademicAccomodations
    fullADHDEvaluation
    protocolForMedicalLeaveDoc
    winterBreakEligibility
    summerBreakEligibility
    hasManualReactivationEntitlement
  }
`;
export const MessageFragmentDoc = gql`
  fragment message on MessageV2 {
    id
    content
    providerId
    userId
    createdAt
  }
`;
export const ListChannelFragmentDoc = gql`
  fragment listChannel on Channel {
    id
    unreadMessageCount
    lastMessage {
      ...message
      provider {
        id
        name
        portrait {
          ...upload
        }
      }
    }
  }
  ${MessageFragmentDoc}
  ${UploadFragmentDoc}
`;
export const NoteTextStyleFragmentDoc = gql`
  fragment noteTextStyle on TextStyle {
    tag
    kind
    italic
  }
`;
export const SectionCopyFragmentDoc = gql`
  fragment sectionCopy on SectionCopy {
    ... on TextCopy {
      showInView
      text
      style {
        ...noteTextStyle
      }
    }
    ... on ListCopy {
      showInView
      bullets
      style {
        ...noteTextStyle
      }
    }
  }
  ${NoteTextStyleFragmentDoc}
`;
export const MedNoteSectionBaseFragmentDoc = gql`
  fragment medNoteSectionBase on FormSection {
    key
    format
    title
    sectionTitle
    required
    uncounted
    value
    beforeCopy {
      ...sectionCopy
    }
    afterCopy {
      ...sectionCopy
    }
  }
  ${SectionCopyFragmentDoc}
`;
export const MedNoteSectionDetailFragmentDoc = gql`
  fragment medNoteSectionDetail on FormSection {
    ...medNoteSectionBase
    options
    placeholder
    warning {
      copy {
        ...sectionCopy
      }
      triggers
      dependsKey
      background
      border
    }
    isolatedOption
    maxSelections
    border
    background
    closeable
    startClosed
    hooks {
      type
      targetKey
    }
    dependsOn {
      key
      showValues
    }
    selectDependentValues {
      targetKey
      dependsValues
      targetSelectValue
    }
    requiredIf {
      dependentQuestions {
        targetKey
        requiredIfValues
        requiredIfEmpty
      }
    }
  }
  ${MedNoteSectionBaseFragmentDoc}
  ${SectionCopyFragmentDoc}
`;
export const MedicalNoteTemplateFragmentDoc = gql`
  fragment medicalNoteTemplate on MedicalNoteTemplate {
    key
    title
    titleTag
    patientDetails {
      key
      value
      breakLine
    }
    uuid
    questions {
      ...medNoteSectionDetail
      children {
        ...medNoteSectionDetail
      }
    }
  }
  ${MedNoteSectionDetailFragmentDoc}
`;
export const ContractWeekFragmentDoc = gql`
  fragment contractWeek on ContractWeek {
    id
    startDate
    endDate
    sessionsMax
    sessionsUtilized
    sessionsScheduled
    intakesMax
    intakesUtilized
    intakesScheduled
  }
`;
export const ContractUsageByCareTypeFragmentDoc = gql`
  fragment contractUsageByCareType on ContractCareTypeUtilization {
    studentCapacityRemaining
    studentCapacityUtilized
    sessionsScheduled
    sessionsUtilized
    sessionsRemaining
  }
`;
export const ForceNextScalesToNowDocument = gql`
  mutation forceNextScalesToNow($userId: Float!) {
    forceNextScalesToNow(userId: $userId)
  }
`;
export type ForceNextScalesToNowMutationFn = ApolloReactCommon.MutationFunction<
  ForceNextScalesToNowMutation,
  ForceNextScalesToNowMutationVariables
>;

/**
 * __useForceNextScalesToNowMutation__
 *
 * To run a mutation, you first call `useForceNextScalesToNowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceNextScalesToNowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceNextScalesToNowMutation, { data, loading, error }] = useForceNextScalesToNowMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useForceNextScalesToNowMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ForceNextScalesToNowMutation,
    ForceNextScalesToNowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ForceNextScalesToNowMutation,
    ForceNextScalesToNowMutationVariables
  >(ForceNextScalesToNowDocument, options);
}
export type ForceNextScalesToNowMutationHookResult = ReturnType<
  typeof useForceNextScalesToNowMutation
>;
export type ForceNextScalesToNowMutationResult =
  ApolloReactCommon.MutationResult<ForceNextScalesToNowMutation>;
export type ForceNextScalesToNowMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ForceNextScalesToNowMutation,
  ForceNextScalesToNowMutationVariables
>;
export const PushAssessmentDocument = gql`
  mutation pushAssessment($assessment: AssessmentKey!, $userId: Float!) {
    pushAssessment(assessment: $assessment, userId: $userId)
  }
`;
export type PushAssessmentMutationFn = ApolloReactCommon.MutationFunction<
  PushAssessmentMutation,
  PushAssessmentMutationVariables
>;

/**
 * __usePushAssessmentMutation__
 *
 * To run a mutation, you first call `usePushAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePushAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pushAssessmentMutation, { data, loading, error }] = usePushAssessmentMutation({
 *   variables: {
 *      assessment: // value for 'assessment'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePushAssessmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PushAssessmentMutation,
    PushAssessmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<PushAssessmentMutation, PushAssessmentMutationVariables>(
    PushAssessmentDocument,
    options
  );
}
export type PushAssessmentMutationHookResult = ReturnType<typeof usePushAssessmentMutation>;
export type PushAssessmentMutationResult = ApolloReactCommon.MutationResult<PushAssessmentMutation>;
export type PushAssessmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PushAssessmentMutation,
  PushAssessmentMutationVariables
>;
export const AdminAssessmentDocument = gql`
  query adminAssessment($assessment: AssessmentKey!, $userId: Float!) {
    assessment: adminAssessment(assessment: $assessment, userId: $userId) {
      questionnaires {
        title
        key
        description
        questions {
          text
          key
          label
          type
          overrideDescription
          dependency {
            foreignKey
            optionText
          }
          options {
            text
            score
          }
        }
      }
    }
  }
`;

/**
 * __useAdminAssessmentQuery__
 *
 * To run a query within a React component, call `useAdminAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAssessmentQuery({
 *   variables: {
 *      assessment: // value for 'assessment'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminAssessmentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminAssessmentQuery,
    AdminAssessmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminAssessmentQuery, AdminAssessmentQueryVariables>(
    AdminAssessmentDocument,
    options
  );
}
export function useAdminAssessmentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminAssessmentQuery,
    AdminAssessmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminAssessmentQuery, AdminAssessmentQueryVariables>(
    AdminAssessmentDocument,
    options
  );
}
export type AdminAssessmentQueryHookResult = ReturnType<typeof useAdminAssessmentQuery>;
export type AdminAssessmentLazyQueryHookResult = ReturnType<typeof useAdminAssessmentLazyQuery>;
export type AdminAssessmentQueryResult = ApolloReactCommon.QueryResult<
  AdminAssessmentQuery,
  AdminAssessmentQueryVariables
>;
export const AdminSubmitAssessmentDocument = gql`
  mutation adminSubmitAssessment(
    $questionnaires: [QuestionnaireSubmission!]!
    $userId: Float!
    $assessment: AssessmentKey!
  ) {
    result: adminSubmitAssessment(
      questionnaires: $questionnaires
      userId: $userId
      assessment: $assessment
    ) {
      id
      questionnaires {
        key
        score
        symptoms
      }
    }
  }
`;
export type AdminSubmitAssessmentMutationFn = ApolloReactCommon.MutationFunction<
  AdminSubmitAssessmentMutation,
  AdminSubmitAssessmentMutationVariables
>;

/**
 * __useAdminSubmitAssessmentMutation__
 *
 * To run a mutation, you first call `useAdminSubmitAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSubmitAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSubmitAssessmentMutation, { data, loading, error }] = useAdminSubmitAssessmentMutation({
 *   variables: {
 *      questionnaires: // value for 'questionnaires'
 *      userId: // value for 'userId'
 *      assessment: // value for 'assessment'
 *   },
 * });
 */
export function useAdminSubmitAssessmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminSubmitAssessmentMutation,
    AdminSubmitAssessmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminSubmitAssessmentMutation,
    AdminSubmitAssessmentMutationVariables
  >(AdminSubmitAssessmentDocument, options);
}
export type AdminSubmitAssessmentMutationHookResult = ReturnType<
  typeof useAdminSubmitAssessmentMutation
>;
export type AdminSubmitAssessmentMutationResult =
  ApolloReactCommon.MutationResult<AdminSubmitAssessmentMutation>;
export type AdminSubmitAssessmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminSubmitAssessmentMutation,
  AdminSubmitAssessmentMutationVariables
>;
export const ProviderCampusTeamEventsDocument = gql`
  query providerCampusTeamEvents {
    providerCampusTeamEvents {
      relationship {
        user {
          ...userDetail
        }
        relationshipType
      }
      events {
        id
        createdAt
        tag
        data
      }
    }
  }
  ${UserDetailFragmentDoc}
`;

/**
 * __useProviderCampusTeamEventsQuery__
 *
 * To run a query within a React component, call `useProviderCampusTeamEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderCampusTeamEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderCampusTeamEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProviderCampusTeamEventsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ProviderCampusTeamEventsQuery,
    ProviderCampusTeamEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ProviderCampusTeamEventsQuery,
    ProviderCampusTeamEventsQueryVariables
  >(ProviderCampusTeamEventsDocument, options);
}
export function useProviderCampusTeamEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProviderCampusTeamEventsQuery,
    ProviderCampusTeamEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ProviderCampusTeamEventsQuery,
    ProviderCampusTeamEventsQueryVariables
  >(ProviderCampusTeamEventsDocument, options);
}
export type ProviderCampusTeamEventsQueryHookResult = ReturnType<
  typeof useProviderCampusTeamEventsQuery
>;
export type ProviderCampusTeamEventsLazyQueryHookResult = ReturnType<
  typeof useProviderCampusTeamEventsLazyQuery
>;
export type ProviderCampusTeamEventsQueryResult = ApolloReactCommon.QueryResult<
  ProviderCampusTeamEventsQuery,
  ProviderCampusTeamEventsQueryVariables
>;
export const CampusTeamOptionsDocument = gql`
  query campusTeamOptions($organizationId: Float!) {
    organization(id: $organizationId) {
      campusTeamOptions: providersWithParent(
        roles: [
          "university-admin"
          "university-supporting-clinician"
          "university-referrer"
          "university-staff"
        ]
      ) {
        ...campusTeamOption
      }
    }
  }
  ${CampusTeamOptionFragmentDoc}
`;

/**
 * __useCampusTeamOptionsQuery__
 *
 * To run a query within a React component, call `useCampusTeamOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampusTeamOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampusTeamOptionsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCampusTeamOptionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CampusTeamOptionsQuery,
    CampusTeamOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CampusTeamOptionsQuery, CampusTeamOptionsQueryVariables>(
    CampusTeamOptionsDocument,
    options
  );
}
export function useCampusTeamOptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CampusTeamOptionsQuery,
    CampusTeamOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CampusTeamOptionsQuery, CampusTeamOptionsQueryVariables>(
    CampusTeamOptionsDocument,
    options
  );
}
export type CampusTeamOptionsQueryHookResult = ReturnType<typeof useCampusTeamOptionsQuery>;
export type CampusTeamOptionsLazyQueryHookResult = ReturnType<typeof useCampusTeamOptionsLazyQuery>;
export type CampusTeamOptionsQueryResult = ApolloReactCommon.QueryResult<
  CampusTeamOptionsQuery,
  CampusTeamOptionsQueryVariables
>;
export const CampusTeamOptionsForUserDocument = gql`
  query campusTeamOptionsForUser($organizationId: Float!, $userId: Float!) {
    organization(id: $organizationId) {
      campusTeamOptions: providersWithParent(
        roles: [
          "university-admin"
          "university-supporting-clinician"
          "university-referrer"
          "university-staff"
        ]
      ) {
        ...campusTeamOption
      }
    }
    adminUser(id: $userId) {
      campusTeam {
        relationshipType
        provider {
          ...campusTeamOption
        }
      }
    }
  }
  ${CampusTeamOptionFragmentDoc}
`;

/**
 * __useCampusTeamOptionsForUserQuery__
 *
 * To run a query within a React component, call `useCampusTeamOptionsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampusTeamOptionsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampusTeamOptionsForUserQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCampusTeamOptionsForUserQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CampusTeamOptionsForUserQuery,
    CampusTeamOptionsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CampusTeamOptionsForUserQuery,
    CampusTeamOptionsForUserQueryVariables
  >(CampusTeamOptionsForUserDocument, options);
}
export function useCampusTeamOptionsForUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CampusTeamOptionsForUserQuery,
    CampusTeamOptionsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CampusTeamOptionsForUserQuery,
    CampusTeamOptionsForUserQueryVariables
  >(CampusTeamOptionsForUserDocument, options);
}
export type CampusTeamOptionsForUserQueryHookResult = ReturnType<
  typeof useCampusTeamOptionsForUserQuery
>;
export type CampusTeamOptionsForUserLazyQueryHookResult = ReturnType<
  typeof useCampusTeamOptionsForUserLazyQuery
>;
export type CampusTeamOptionsForUserQueryResult = ApolloReactCommon.QueryResult<
  CampusTeamOptionsForUserQuery,
  CampusTeamOptionsForUserQueryVariables
>;
export const ProviderNetworkSearchDocument = gql`
  query providerNetworkSearch(
    $gender: [ProviderGenderSearch!]
    $state: stateCodes
    $careType: CareType!
    $traits: [ProviderTraitInput!]
    $organizationId: Float
    $endTime: DateTime
    $payerId: Float
    $userId: Float
    $apptType: String!
  ) {
    providerNetworkSearch(
      gender: $gender
      state: $state
      careType: $careType
      traits: $traits
      organizationId: $organizationId
      payerId: $payerId
      userId: $userId
    ) {
      providers {
        ...baseProvider
        careTypes
        classification
        funFact
        eligibleForSchedulingPromotion
        portrait {
          ...upload
        }
        traits {
          category
          values
        }
        upcomingAvailabilityV4(
          organizationId: $organizationId
          careType: $careType
          endTime: $endTime
          apptType: $apptType
          patientState: $state
        ) {
          availability {
            start
            end
            providerId
            organizationId
          }
          globalBlockers {
            providerId
            date
            type
          }
        }
      }
      outOfNetworkCount
      stateNotAllowed
    }
    promotionWindowInDays
  }
  ${BaseProviderFragmentDoc}
  ${UploadFragmentDoc}
`;

/**
 * __useProviderNetworkSearchQuery__
 *
 * To run a query within a React component, call `useProviderNetworkSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderNetworkSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderNetworkSearchQuery({
 *   variables: {
 *      gender: // value for 'gender'
 *      state: // value for 'state'
 *      careType: // value for 'careType'
 *      traits: // value for 'traits'
 *      organizationId: // value for 'organizationId'
 *      endTime: // value for 'endTime'
 *      payerId: // value for 'payerId'
 *      userId: // value for 'userId'
 *      apptType: // value for 'apptType'
 *   },
 * });
 */
export function useProviderNetworkSearchQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ProviderNetworkSearchQuery,
    ProviderNetworkSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ProviderNetworkSearchQuery, ProviderNetworkSearchQueryVariables>(
    ProviderNetworkSearchDocument,
    options
  );
}
export function useProviderNetworkSearchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProviderNetworkSearchQuery,
    ProviderNetworkSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ProviderNetworkSearchQuery,
    ProviderNetworkSearchQueryVariables
  >(ProviderNetworkSearchDocument, options);
}
export type ProviderNetworkSearchQueryHookResult = ReturnType<typeof useProviderNetworkSearchQuery>;
export type ProviderNetworkSearchLazyQueryHookResult = ReturnType<
  typeof useProviderNetworkSearchLazyQuery
>;
export type ProviderNetworkSearchQueryResult = ApolloReactCommon.QueryResult<
  ProviderNetworkSearchQuery,
  ProviderNetworkSearchQueryVariables
>;
export const ProviderNetworkProfileDocument = gql`
  query providerNetworkProfile($providerId: Float!, $organizationId: Float, $endTime: DateTime) {
    provider: adminProvider(providerId: $providerId) {
      id
      firstName
      lastName
      pronouns
      gender
      careTypes
      portrait {
        url
      }
      organizations {
        id
        name
      }
      acceptingNewPatients(organizationId: $organizationId)
      classification
      geoStates
      funFact
      traits {
        category
        values
      }
      insuranceCredentials {
        active
        payer {
          name
        }
        state
      }
      qualifications2 {
        id
        name
        abbreviation
        states
        licences
      }
      degrees {
        id
        university
        degree
        abbreviation
      }
      upcomingIntakeAvailabiility: upcomingAvailability(
        organizationId: $organizationId
        endTime: $endTime
        apptType: "intake"
      ) {
        start
        end
        providerId
      }
    }
  }
`;

/**
 * __useProviderNetworkProfileQuery__
 *
 * To run a query within a React component, call `useProviderNetworkProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderNetworkProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderNetworkProfileQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      organizationId: // value for 'organizationId'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useProviderNetworkProfileQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ProviderNetworkProfileQuery,
    ProviderNetworkProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ProviderNetworkProfileQuery,
    ProviderNetworkProfileQueryVariables
  >(ProviderNetworkProfileDocument, options);
}
export function useProviderNetworkProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProviderNetworkProfileQuery,
    ProviderNetworkProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ProviderNetworkProfileQuery,
    ProviderNetworkProfileQueryVariables
  >(ProviderNetworkProfileDocument, options);
}
export type ProviderNetworkProfileQueryHookResult = ReturnType<
  typeof useProviderNetworkProfileQuery
>;
export type ProviderNetworkProfileLazyQueryHookResult = ReturnType<
  typeof useProviderNetworkProfileLazyQuery
>;
export type ProviderNetworkProfileQueryResult = ApolloReactCommon.QueryResult<
  ProviderNetworkProfileQuery,
  ProviderNetworkProfileQueryVariables
>;
export const UpsertSuggestedProvidersDocument = gql`
  mutation upsertSuggestedProviders($userId: Float!, $providerIds: [Float!]!) {
    upsertSuggestedProviders(userId: $userId, providerIds: $providerIds)
  }
`;
export type UpsertSuggestedProvidersMutationFn = ApolloReactCommon.MutationFunction<
  UpsertSuggestedProvidersMutation,
  UpsertSuggestedProvidersMutationVariables
>;

/**
 * __useUpsertSuggestedProvidersMutation__
 *
 * To run a mutation, you first call `useUpsertSuggestedProvidersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSuggestedProvidersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSuggestedProvidersMutation, { data, loading, error }] = useUpsertSuggestedProvidersMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      providerIds: // value for 'providerIds'
 *   },
 * });
 */
export function useUpsertSuggestedProvidersMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertSuggestedProvidersMutation,
    UpsertSuggestedProvidersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpsertSuggestedProvidersMutation,
    UpsertSuggestedProvidersMutationVariables
  >(UpsertSuggestedProvidersDocument, options);
}
export type UpsertSuggestedProvidersMutationHookResult = ReturnType<
  typeof useUpsertSuggestedProvidersMutation
>;
export type UpsertSuggestedProvidersMutationResult =
  ApolloReactCommon.MutationResult<UpsertSuggestedProvidersMutation>;
export type UpsertSuggestedProvidersMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpsertSuggestedProvidersMutation,
  UpsertSuggestedProvidersMutationVariables
>;
export const EnrollModalDocument = gql`
  query enrollModal($organizationId: Float!, $providerIds: [Int!]!) {
    organization(id: $organizationId) {
      providers {
        id
        name
        role
      }
      children {
        id
        name
        providers {
          id
          name
          role
        }
      }
      parent {
        id
        name
        providers {
          id
          name
          role
        }
      }
      careFlows {
        id
        careType
        statesAvailable
      }
      referralCredits {
        availableCredits
        therapyCreditWeight
        psychiatryCreditWeight
        referralPeriodEnd
      }
    }
    providersById(ids: $providerIds) {
      ...baseProvider
      careTypes
      classification
      funFact
      portrait {
        ...upload
      }
    }
  }
  ${BaseProviderFragmentDoc}
  ${UploadFragmentDoc}
`;

/**
 * __useEnrollModalQuery__
 *
 * To run a query within a React component, call `useEnrollModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollModalQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      providerIds: // value for 'providerIds'
 *   },
 * });
 */
export function useEnrollModalQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<EnrollModalQuery, EnrollModalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EnrollModalQuery, EnrollModalQueryVariables>(
    EnrollModalDocument,
    options
  );
}
export function useEnrollModalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EnrollModalQuery, EnrollModalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EnrollModalQuery, EnrollModalQueryVariables>(
    EnrollModalDocument,
    options
  );
}
export type EnrollModalQueryHookResult = ReturnType<typeof useEnrollModalQuery>;
export type EnrollModalLazyQueryHookResult = ReturnType<typeof useEnrollModalLazyQuery>;
export type EnrollModalQueryResult = ApolloReactCommon.QueryResult<
  EnrollModalQuery,
  EnrollModalQueryVariables
>;
export const AdminCreateUserWithAppointmentDocument = gql`
  mutation adminCreateUserWithAppointment($input: CreateUser!, $intakeAppointment: CreateIntake!) {
    adminCreateUser(input: $input, intakeAppointment: $intakeAppointment) {
      id
      firstName
      lastName
    }
  }
`;
export type AdminCreateUserWithAppointmentMutationFn = ApolloReactCommon.MutationFunction<
  AdminCreateUserWithAppointmentMutation,
  AdminCreateUserWithAppointmentMutationVariables
>;

/**
 * __useAdminCreateUserWithAppointmentMutation__
 *
 * To run a mutation, you first call `useAdminCreateUserWithAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateUserWithAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateUserWithAppointmentMutation, { data, loading, error }] = useAdminCreateUserWithAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      intakeAppointment: // value for 'intakeAppointment'
 *   },
 * });
 */
export function useAdminCreateUserWithAppointmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminCreateUserWithAppointmentMutation,
    AdminCreateUserWithAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminCreateUserWithAppointmentMutation,
    AdminCreateUserWithAppointmentMutationVariables
  >(AdminCreateUserWithAppointmentDocument, options);
}
export type AdminCreateUserWithAppointmentMutationHookResult = ReturnType<
  typeof useAdminCreateUserWithAppointmentMutation
>;
export type AdminCreateUserWithAppointmentMutationResult =
  ApolloReactCommon.MutationResult<AdminCreateUserWithAppointmentMutation>;
export type AdminCreateUserWithAppointmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminCreateUserWithAppointmentMutation,
  AdminCreateUserWithAppointmentMutationVariables
>;
export const RemainingHoursForRangeDocument = gql`
  query remainingHoursForRange(
    $careType: CareType!
    $organizationId: Float!
    $appointmentType: String!
    $start: String!
    $end: String!
    $userId: Float
  ) {
    remainingHoursForRange(
      careType: $careType
      organizationId: $organizationId
      appointmentType: $appointmentType
      start: $start
      end: $end
    ) {
      date
      remainingHours
      dgmAllocationId
    }
    nextAvailableAppointment(
      careType: $careType
      organizationId: $organizationId
      appointmentType: $appointmentType
      userId: $userId
      startDate: $end
    )
  }
`;

/**
 * __useRemainingHoursForRangeQuery__
 *
 * To run a query within a React component, call `useRemainingHoursForRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemainingHoursForRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemainingHoursForRangeQuery({
 *   variables: {
 *      careType: // value for 'careType'
 *      organizationId: // value for 'organizationId'
 *      appointmentType: // value for 'appointmentType'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemainingHoursForRangeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    RemainingHoursForRangeQuery,
    RemainingHoursForRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    RemainingHoursForRangeQuery,
    RemainingHoursForRangeQueryVariables
  >(RemainingHoursForRangeDocument, options);
}
export function useRemainingHoursForRangeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RemainingHoursForRangeQuery,
    RemainingHoursForRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    RemainingHoursForRangeQuery,
    RemainingHoursForRangeQueryVariables
  >(RemainingHoursForRangeDocument, options);
}
export type RemainingHoursForRangeQueryHookResult = ReturnType<
  typeof useRemainingHoursForRangeQuery
>;
export type RemainingHoursForRangeLazyQueryHookResult = ReturnType<
  typeof useRemainingHoursForRangeLazyQuery
>;
export type RemainingHoursForRangeQueryResult = ApolloReactCommon.QueryResult<
  RemainingHoursForRangeQuery,
  RemainingHoursForRangeQueryVariables
>;
export const ProviderNetworkOrganizationDocument = gql`
  query providerNetworkOrganization($organizationId: Float!) {
    organization(id: $organizationId) {
      name
      entitlements {
        ...organizationEntitlement
      }
    }
  }
  ${OrganizationEntitlementFragmentDoc}
`;

/**
 * __useProviderNetworkOrganizationQuery__
 *
 * To run a query within a React component, call `useProviderNetworkOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderNetworkOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderNetworkOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useProviderNetworkOrganizationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ProviderNetworkOrganizationQuery,
    ProviderNetworkOrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ProviderNetworkOrganizationQuery,
    ProviderNetworkOrganizationQueryVariables
  >(ProviderNetworkOrganizationDocument, options);
}
export function useProviderNetworkOrganizationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProviderNetworkOrganizationQuery,
    ProviderNetworkOrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ProviderNetworkOrganizationQuery,
    ProviderNetworkOrganizationQueryVariables
  >(ProviderNetworkOrganizationDocument, options);
}
export type ProviderNetworkOrganizationQueryHookResult = ReturnType<
  typeof useProviderNetworkOrganizationQuery
>;
export type ProviderNetworkOrganizationLazyQueryHookResult = ReturnType<
  typeof useProviderNetworkOrganizationLazyQuery
>;
export type ProviderNetworkOrganizationQueryResult = ApolloReactCommon.QueryResult<
  ProviderNetworkOrganizationQuery,
  ProviderNetworkOrganizationQueryVariables
>;
export const ProviderPopoverDocument = gql`
  query providerPopover($providerId: Float!) {
    adminProvider(providerId: $providerId) {
      id
      name
    }
  }
`;

/**
 * __useProviderPopoverQuery__
 *
 * To run a query within a React component, call `useProviderPopoverQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderPopoverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderPopoverQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useProviderPopoverQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ProviderPopoverQuery,
    ProviderPopoverQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ProviderPopoverQuery, ProviderPopoverQueryVariables>(
    ProviderPopoverDocument,
    options
  );
}
export function useProviderPopoverLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProviderPopoverQuery,
    ProviderPopoverQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ProviderPopoverQuery, ProviderPopoverQueryVariables>(
    ProviderPopoverDocument,
    options
  );
}
export type ProviderPopoverQueryHookResult = ReturnType<typeof useProviderPopoverQuery>;
export type ProviderPopoverLazyQueryHookResult = ReturnType<typeof useProviderPopoverLazyQuery>;
export type ProviderPopoverQueryResult = ApolloReactCommon.QueryResult<
  ProviderPopoverQuery,
  ProviderPopoverQueryVariables
>;
export const NetworkReferralUserDocument = gql`
  query networkReferralUser($id: Float!) {
    adminUser(id: $id) {
      ...networkReferralUser
    }
  }
  ${NetworkReferralUserFragmentDoc}
`;

/**
 * __useNetworkReferralUserQuery__
 *
 * To run a query within a React component, call `useNetworkReferralUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkReferralUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkReferralUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNetworkReferralUserQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    NetworkReferralUserQuery,
    NetworkReferralUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<NetworkReferralUserQuery, NetworkReferralUserQueryVariables>(
    NetworkReferralUserDocument,
    options
  );
}
export function useNetworkReferralUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    NetworkReferralUserQuery,
    NetworkReferralUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<NetworkReferralUserQuery, NetworkReferralUserQueryVariables>(
    NetworkReferralUserDocument,
    options
  );
}
export type NetworkReferralUserQueryHookResult = ReturnType<typeof useNetworkReferralUserQuery>;
export type NetworkReferralUserLazyQueryHookResult = ReturnType<
  typeof useNetworkReferralUserLazyQuery
>;
export type NetworkReferralUserQueryResult = ApolloReactCommon.QueryResult<
  NetworkReferralUserQuery,
  NetworkReferralUserQueryVariables
>;
export const UserBookingDocument = gql`
  query userBooking($userId: Float!) {
    adminUser(id: $userId) {
      ...appointmentUserV2
    }
  }
  ${AppointmentUserV2FragmentDoc}
`;

/**
 * __useUserBookingQuery__
 *
 * To run a query within a React component, call `useUserBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBookingQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserBookingQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<UserBookingQuery, UserBookingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserBookingQuery, UserBookingQueryVariables>(
    UserBookingDocument,
    options
  );
}
export function useUserBookingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserBookingQuery, UserBookingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserBookingQuery, UserBookingQueryVariables>(
    UserBookingDocument,
    options
  );
}
export type UserBookingQueryHookResult = ReturnType<typeof useUserBookingQuery>;
export type UserBookingLazyQueryHookResult = ReturnType<typeof useUserBookingLazyQuery>;
export type UserBookingQueryResult = ApolloReactCommon.QueryResult<
  UserBookingQuery,
  UserBookingQueryVariables
>;
export const ReferralUserBookingDocument = gql`
  query referralUserBooking($userId: Float!) {
    adminUser(id: $userId) {
      ...appointmentUserV2
    }
    providers {
      ...bookingProvider
    }
  }
  ${AppointmentUserV2FragmentDoc}
  ${BookingProviderFragmentDoc}
`;

/**
 * __useReferralUserBookingQuery__
 *
 * To run a query within a React component, call `useReferralUserBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralUserBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralUserBookingQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useReferralUserBookingQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ReferralUserBookingQuery,
    ReferralUserBookingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ReferralUserBookingQuery, ReferralUserBookingQueryVariables>(
    ReferralUserBookingDocument,
    options
  );
}
export function useReferralUserBookingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ReferralUserBookingQuery,
    ReferralUserBookingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ReferralUserBookingQuery, ReferralUserBookingQueryVariables>(
    ReferralUserBookingDocument,
    options
  );
}
export type ReferralUserBookingQueryHookResult = ReturnType<typeof useReferralUserBookingQuery>;
export type ReferralUserBookingLazyQueryHookResult = ReturnType<
  typeof useReferralUserBookingLazyQuery
>;
export type ReferralUserBookingQueryResult = ApolloReactCommon.QueryResult<
  ReferralUserBookingQuery,
  ReferralUserBookingQueryVariables
>;
export const ReschedulingAppointmentDocument = gql`
  query reschedulingAppointment($rescheduleId: Float!) {
    adminAppointment(id: $rescheduleId) {
      ...reschedulingAppointment
    }
  }
  ${ReschedulingAppointmentFragmentDoc}
`;

/**
 * __useReschedulingAppointmentQuery__
 *
 * To run a query within a React component, call `useReschedulingAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useReschedulingAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReschedulingAppointmentQuery({
 *   variables: {
 *      rescheduleId: // value for 'rescheduleId'
 *   },
 * });
 */
export function useReschedulingAppointmentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ReschedulingAppointmentQuery,
    ReschedulingAppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ReschedulingAppointmentQuery,
    ReschedulingAppointmentQueryVariables
  >(ReschedulingAppointmentDocument, options);
}
export function useReschedulingAppointmentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ReschedulingAppointmentQuery,
    ReschedulingAppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ReschedulingAppointmentQuery,
    ReschedulingAppointmentQueryVariables
  >(ReschedulingAppointmentDocument, options);
}
export type ReschedulingAppointmentQueryHookResult = ReturnType<
  typeof useReschedulingAppointmentQuery
>;
export type ReschedulingAppointmentLazyQueryHookResult = ReturnType<
  typeof useReschedulingAppointmentLazyQuery
>;
export type ReschedulingAppointmentQueryResult = ApolloReactCommon.QueryResult<
  ReschedulingAppointmentQuery,
  ReschedulingAppointmentQueryVariables
>;
export const ProviderForCareTypeDocument = gql`
  query providerForCareType(
    $userId: Float!
    $careType: CareType!
    $apptType: String!
    $organizationId: Float
    $endTime: DateTime
    $patientState: stateCodes
  ) {
    adminUser(id: $userId) {
      providerForCareType(careType: $careType) {
        ...bookingProvider
        upcomingAvailabilityV4(
          apptType: $apptType
          organizationId: $organizationId
          careType: $careType
          endTime: $endTime
          patientState: $patientState
        ) {
          availability {
            start
          }
        }
      }
    }
  }
  ${BookingProviderFragmentDoc}
`;

/**
 * __useProviderForCareTypeQuery__
 *
 * To run a query within a React component, call `useProviderForCareTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderForCareTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderForCareTypeQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      careType: // value for 'careType'
 *      apptType: // value for 'apptType'
 *      organizationId: // value for 'organizationId'
 *      endTime: // value for 'endTime'
 *      patientState: // value for 'patientState'
 *   },
 * });
 */
export function useProviderForCareTypeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ProviderForCareTypeQuery,
    ProviderForCareTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ProviderForCareTypeQuery, ProviderForCareTypeQueryVariables>(
    ProviderForCareTypeDocument,
    options
  );
}
export function useProviderForCareTypeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProviderForCareTypeQuery,
    ProviderForCareTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ProviderForCareTypeQuery, ProviderForCareTypeQueryVariables>(
    ProviderForCareTypeDocument,
    options
  );
}
export type ProviderForCareTypeQueryHookResult = ReturnType<typeof useProviderForCareTypeQuery>;
export type ProviderForCareTypeLazyQueryHookResult = ReturnType<
  typeof useProviderForCareTypeLazyQuery
>;
export type ProviderForCareTypeQueryResult = ApolloReactCommon.QueryResult<
  ProviderForCareTypeQuery,
  ProviderForCareTypeQueryVariables
>;
export const AdminFindApptsAtRiskDocument = gql`
  query adminFindApptsAtRisk(
    $date: String!
    $providerId: Float!
    $timezone: String!
    $weekly: Boolean!
    $allocations: [CreateAllocationInput!]!
  ) {
    adminFindApptsAtRisk(
      date: $date
      providerId: $providerId
      timezone: $timezone
      weekly: $weekly
      allocations: $allocations
    ) {
      startTime
      endTime
      user {
        id
        firstName
        preferredName
        lastName
        customerId
      }
    }
  }
`;

/**
 * __useAdminFindApptsAtRiskQuery__
 *
 * To run a query within a React component, call `useAdminFindApptsAtRiskQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFindApptsAtRiskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFindApptsAtRiskQuery({
 *   variables: {
 *      date: // value for 'date'
 *      providerId: // value for 'providerId'
 *      timezone: // value for 'timezone'
 *      weekly: // value for 'weekly'
 *      allocations: // value for 'allocations'
 *   },
 * });
 */
export function useAdminFindApptsAtRiskQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminFindApptsAtRiskQuery,
    AdminFindApptsAtRiskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminFindApptsAtRiskQuery, AdminFindApptsAtRiskQueryVariables>(
    AdminFindApptsAtRiskDocument,
    options
  );
}
export function useAdminFindApptsAtRiskLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminFindApptsAtRiskQuery,
    AdminFindApptsAtRiskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminFindApptsAtRiskQuery,
    AdminFindApptsAtRiskQueryVariables
  >(AdminFindApptsAtRiskDocument, options);
}
export type AdminFindApptsAtRiskQueryHookResult = ReturnType<typeof useAdminFindApptsAtRiskQuery>;
export type AdminFindApptsAtRiskLazyQueryHookResult = ReturnType<
  typeof useAdminFindApptsAtRiskLazyQuery
>;
export type AdminFindApptsAtRiskQueryResult = ApolloReactCommon.QueryResult<
  AdminFindApptsAtRiskQuery,
  AdminFindApptsAtRiskQueryVariables
>;
export const AllocationsTabDocument = gql`
  query allocationsTab($providerId: Float!) {
    adminGetAllocations(providerId: $providerId) {
      id
      startTime
      endTime
      repeatsUntil
      weekly
      isFeeForServiceTime
      purpose
      timezone
      provider {
        id
        name
        careTypes
      }
      organization {
        id
        name
        abbreviation
        emailDomains
        childrenCount
      }
      childOrganizations {
        id
        name
        abbreviation
        emailDomains
      }
      appointmentTypes
    }
    adminGetStateAllocations(providerId: $providerId) {
      id
      startDate
      endDate
      maxHours
      maxIntakeHours
      providerState {
        state
      }
    }
    adminProvider(providerId: $providerId) {
      id
      providerStates {
        id
        state
      }
      organizations {
        id
        name
        children {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useAllocationsTabQuery__
 *
 * To run a query within a React component, call `useAllocationsTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllocationsTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllocationsTabQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useAllocationsTabQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AllocationsTabQuery, AllocationsTabQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllocationsTabQuery, AllocationsTabQueryVariables>(
    AllocationsTabDocument,
    options
  );
}
export function useAllocationsTabLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllocationsTabQuery,
    AllocationsTabQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllocationsTabQuery, AllocationsTabQueryVariables>(
    AllocationsTabDocument,
    options
  );
}
export type AllocationsTabQueryHookResult = ReturnType<typeof useAllocationsTabQuery>;
export type AllocationsTabLazyQueryHookResult = ReturnType<typeof useAllocationsTabLazyQuery>;
export type AllocationsTabQueryResult = ApolloReactCommon.QueryResult<
  AllocationsTabQuery,
  AllocationsTabQueryVariables
>;
export const AdminUpdateAllocationsForDateDocument = gql`
  mutation adminUpdateAllocationsForDate(
    $date: String!
    $providerId: Float!
    $timezone: String!
    $weekly: Boolean!
    $allocations: [CreateAllocationInput!]!
  ) {
    adminUpdateAllocationsForDate(
      date: $date
      providerId: $providerId
      timezone: $timezone
      weekly: $weekly
      allocations: $allocations
    )
  }
`;
export type AdminUpdateAllocationsForDateMutationFn = ApolloReactCommon.MutationFunction<
  AdminUpdateAllocationsForDateMutation,
  AdminUpdateAllocationsForDateMutationVariables
>;

/**
 * __useAdminUpdateAllocationsForDateMutation__
 *
 * To run a mutation, you first call `useAdminUpdateAllocationsForDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateAllocationsForDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateAllocationsForDateMutation, { data, loading, error }] = useAdminUpdateAllocationsForDateMutation({
 *   variables: {
 *      date: // value for 'date'
 *      providerId: // value for 'providerId'
 *      timezone: // value for 'timezone'
 *      weekly: // value for 'weekly'
 *      allocations: // value for 'allocations'
 *   },
 * });
 */
export function useAdminUpdateAllocationsForDateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminUpdateAllocationsForDateMutation,
    AdminUpdateAllocationsForDateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminUpdateAllocationsForDateMutation,
    AdminUpdateAllocationsForDateMutationVariables
  >(AdminUpdateAllocationsForDateDocument, options);
}
export type AdminUpdateAllocationsForDateMutationHookResult = ReturnType<
  typeof useAdminUpdateAllocationsForDateMutation
>;
export type AdminUpdateAllocationsForDateMutationResult =
  ApolloReactCommon.MutationResult<AdminUpdateAllocationsForDateMutation>;
export type AdminUpdateAllocationsForDateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminUpdateAllocationsForDateMutation,
  AdminUpdateAllocationsForDateMutationVariables
>;
export const AdminUpdateStateAllocationsForDateDocument = gql`
  mutation adminUpdateStateAllocationsForDate(
    $allocationsToCreate: [CreateProviderStateAllocationsModelInput!]!
    $allocationsToUpdate: [UpdateProviderStateAllocationsModelInput!]!
  ) {
    adminUpdateStateAllocationsForDate(
      allocationsToCreate: $allocationsToCreate
      allocationsToUpdate: $allocationsToUpdate
    )
  }
`;
export type AdminUpdateStateAllocationsForDateMutationFn = ApolloReactCommon.MutationFunction<
  AdminUpdateStateAllocationsForDateMutation,
  AdminUpdateStateAllocationsForDateMutationVariables
>;

/**
 * __useAdminUpdateStateAllocationsForDateMutation__
 *
 * To run a mutation, you first call `useAdminUpdateStateAllocationsForDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateStateAllocationsForDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateStateAllocationsForDateMutation, { data, loading, error }] = useAdminUpdateStateAllocationsForDateMutation({
 *   variables: {
 *      allocationsToCreate: // value for 'allocationsToCreate'
 *      allocationsToUpdate: // value for 'allocationsToUpdate'
 *   },
 * });
 */
export function useAdminUpdateStateAllocationsForDateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminUpdateStateAllocationsForDateMutation,
    AdminUpdateStateAllocationsForDateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminUpdateStateAllocationsForDateMutation,
    AdminUpdateStateAllocationsForDateMutationVariables
  >(AdminUpdateStateAllocationsForDateDocument, options);
}
export type AdminUpdateStateAllocationsForDateMutationHookResult = ReturnType<
  typeof useAdminUpdateStateAllocationsForDateMutation
>;
export type AdminUpdateStateAllocationsForDateMutationResult =
  ApolloReactCommon.MutationResult<AdminUpdateStateAllocationsForDateMutation>;
export type AdminUpdateStateAllocationsForDateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AdminUpdateStateAllocationsForDateMutation,
    AdminUpdateStateAllocationsForDateMutationVariables
  >;
export const ReferralListDocument = gql`
  query referralList($fetchReferrer: Boolean!, $fetchOrganization: Boolean!) {
    referrals {
      ...referralUserData
      referringProvider @include(if: $fetchReferrer) {
        id
        name
      }
      organization @include(if: $fetchOrganization) {
        id
        name
      }
    }
  }
  ${ReferralUserDataFragmentDoc}
`;

/**
 * __useReferralListQuery__
 *
 * To run a query within a React component, call `useReferralListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralListQuery({
 *   variables: {
 *      fetchReferrer: // value for 'fetchReferrer'
 *      fetchOrganization: // value for 'fetchOrganization'
 *   },
 * });
 */
export function useReferralListQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ReferralListQuery, ReferralListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ReferralListQuery, ReferralListQueryVariables>(
    ReferralListDocument,
    options
  );
}
export function useReferralListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReferralListQuery, ReferralListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ReferralListQuery, ReferralListQueryVariables>(
    ReferralListDocument,
    options
  );
}
export type ReferralListQueryHookResult = ReturnType<typeof useReferralListQuery>;
export type ReferralListLazyQueryHookResult = ReturnType<typeof useReferralListLazyQuery>;
export type ReferralListQueryResult = ApolloReactCommon.QueryResult<
  ReferralListQuery,
  ReferralListQueryVariables
>;
export const UserReferralStatusDocument = gql`
  query userReferralStatus($patientId: Float!) {
    userReferralStatus(userId: $patientId) {
      ...referralAppointmentData
    }
  }
  ${ReferralAppointmentDataFragmentDoc}
`;

/**
 * __useUserReferralStatusQuery__
 *
 * To run a query within a React component, call `useUserReferralStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserReferralStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserReferralStatusQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useUserReferralStatusQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    UserReferralStatusQuery,
    UserReferralStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserReferralStatusQuery, UserReferralStatusQueryVariables>(
    UserReferralStatusDocument,
    options
  );
}
export function useUserReferralStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserReferralStatusQuery,
    UserReferralStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserReferralStatusQuery, UserReferralStatusQueryVariables>(
    UserReferralStatusDocument,
    options
  );
}
export type UserReferralStatusQueryHookResult = ReturnType<typeof useUserReferralStatusQuery>;
export type UserReferralStatusLazyQueryHookResult = ReturnType<
  typeof useUserReferralStatusLazyQuery
>;
export type UserReferralStatusQueryResult = ApolloReactCommon.QueryResult<
  UserReferralStatusQuery,
  UserReferralStatusQueryVariables
>;
export const StudentsDocument = gql`
  query students($params: UserListParams!) {
    paginatedUsers(params: $params) {
      items {
        ...userBasic
        email: emailIfVisible
        careStatus
        careTypes
        taskStatus: taskStatusIfVisible
        organizationIdentification
        provider: providerIfVisible {
          id
          name
        }
        therapist: therapistIfVisible {
          ...provider
        }
        organization {
          ...organization
        }
        campusTeam {
          relationshipType
          provider {
            name
          }
        }
      }
      hasMore
      cursor
      count
    }
  }
  ${UserBasicFragmentDoc}
  ${ProviderFragmentDoc}
  ${OrganizationFragmentDoc}
`;

/**
 * __useStudentsQuery__
 *
 * To run a query within a React component, call `useStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useStudentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<StudentsQuery, StudentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<StudentsQuery, StudentsQueryVariables>(
    StudentsDocument,
    options
  );
}
export function useStudentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StudentsQuery, StudentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<StudentsQuery, StudentsQueryVariables>(
    StudentsDocument,
    options
  );
}
export type StudentsQueryHookResult = ReturnType<typeof useStudentsQuery>;
export type StudentsLazyQueryHookResult = ReturnType<typeof useStudentsLazyQuery>;
export type StudentsQueryResult = ApolloReactCommon.QueryResult<
  StudentsQuery,
  StudentsQueryVariables
>;
export const StudentListOptionsDocument = gql`
  query studentListOptions($organizationId: Float!) {
    providers {
      ...baseProvider
    }
    organizationCampusTeam(organizationId: $organizationId) {
      provider {
        id
        name
      }
    }
    organization(id: $organizationId) {
      entitlements {
        key
      }
      children {
        ...organization
      }
    }
  }
  ${BaseProviderFragmentDoc}
  ${OrganizationFragmentDoc}
`;

/**
 * __useStudentListOptionsQuery__
 *
 * To run a query within a React component, call `useStudentListOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentListOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentListOptionsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useStudentListOptionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    StudentListOptionsQuery,
    StudentListOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<StudentListOptionsQuery, StudentListOptionsQueryVariables>(
    StudentListOptionsDocument,
    options
  );
}
export function useStudentListOptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StudentListOptionsQuery,
    StudentListOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<StudentListOptionsQuery, StudentListOptionsQueryVariables>(
    StudentListOptionsDocument,
    options
  );
}
export type StudentListOptionsQueryHookResult = ReturnType<typeof useStudentListOptionsQuery>;
export type StudentListOptionsLazyQueryHookResult = ReturnType<
  typeof useStudentListOptionsLazyQuery
>;
export type StudentListOptionsQueryResult = ApolloReactCommon.QueryResult<
  StudentListOptionsQuery,
  StudentListOptionsQueryVariables
>;
export const TicketsDocument = gql`
  query tickets($view: TicketView!, $userId: Float) {
    newTickets: tickets(view: New) {
      id
    }
    tickets(view: $view, userId: $userId) {
      ...ticket
      user {
        ...ticketUser
      }
      requester {
        id
        name
      }
      assignee {
        id
        name
      }
    }
  }
  ${TicketFragmentDoc}
  ${TicketUserFragmentDoc}
`;

/**
 * __useTicketsQuery__
 *
 * To run a query within a React component, call `useTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketsQuery({
 *   variables: {
 *      view: // value for 'view'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useTicketsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TicketsQuery, TicketsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TicketsQuery, TicketsQueryVariables>(TicketsDocument, options);
}
export function useTicketsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TicketsQuery, TicketsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TicketsQuery, TicketsQueryVariables>(
    TicketsDocument,
    options
  );
}
export type TicketsQueryHookResult = ReturnType<typeof useTicketsQuery>;
export type TicketsLazyQueryHookResult = ReturnType<typeof useTicketsLazyQuery>;
export type TicketsQueryResult = ApolloReactCommon.QueryResult<TicketsQuery, TicketsQueryVariables>;
export const TicketDocument = gql`
  query ticket($id: Float!) {
    ticket(id: $id) {
      ...ticket
      assignOptions
      canEdit
      canResolve
      user {
        id
        ...userNames
      }
      requester {
        ...provider
      }
      assignee {
        ...provider
      }
      events {
        id
        tag
        data
        createdAt
      }
    }
  }
  ${TicketFragmentDoc}
  ${UserNamesFragmentDoc}
  ${ProviderFragmentDoc}
`;

/**
 * __useTicketQuery__
 *
 * To run a query within a React component, call `useTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTicketQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TicketQuery, TicketQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TicketQuery, TicketQueryVariables>(TicketDocument, options);
}
export function useTicketLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TicketQuery, TicketQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TicketQuery, TicketQueryVariables>(TicketDocument, options);
}
export type TicketQueryHookResult = ReturnType<typeof useTicketQuery>;
export type TicketLazyQueryHookResult = ReturnType<typeof useTicketLazyQuery>;
export type TicketQueryResult = ApolloReactCommon.QueryResult<TicketQuery, TicketQueryVariables>;
export const PossibleAssigneesDocument = gql`
  query possibleAssignees($userId: Float!, $ticketId: Float) {
    providers: getPossibleAssignees(userId: $userId, ticketId: $ticketId) {
      id
      name
      role
      classification
    }
  }
`;

/**
 * __usePossibleAssigneesQuery__
 *
 * To run a query within a React component, call `usePossibleAssigneesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePossibleAssigneesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePossibleAssigneesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function usePossibleAssigneesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PossibleAssigneesQuery,
    PossibleAssigneesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PossibleAssigneesQuery, PossibleAssigneesQueryVariables>(
    PossibleAssigneesDocument,
    options
  );
}
export function usePossibleAssigneesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PossibleAssigneesQuery,
    PossibleAssigneesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PossibleAssigneesQuery, PossibleAssigneesQueryVariables>(
    PossibleAssigneesDocument,
    options
  );
}
export type PossibleAssigneesQueryHookResult = ReturnType<typeof usePossibleAssigneesQuery>;
export type PossibleAssigneesLazyQueryHookResult = ReturnType<typeof usePossibleAssigneesLazyQuery>;
export type PossibleAssigneesQueryResult = ApolloReactCommon.QueryResult<
  PossibleAssigneesQuery,
  PossibleAssigneesQueryVariables
>;
export const CreateTicketDocument = gql`
  mutation createTicket($input: CreateTicketInput!) {
    ticket: createTicket(ticket: $input) {
      id
    }
  }
`;
export type CreateTicketMutationFn = ApolloReactCommon.MutationFunction<
  CreateTicketMutation,
  CreateTicketMutationVariables
>;

/**
 * __useCreateTicketMutation__
 *
 * To run a mutation, you first call `useCreateTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketMutation, { data, loading, error }] = useCreateTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTicketMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTicketMutation,
    CreateTicketMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateTicketMutation, CreateTicketMutationVariables>(
    CreateTicketDocument,
    options
  );
}
export type CreateTicketMutationHookResult = ReturnType<typeof useCreateTicketMutation>;
export type CreateTicketMutationResult = ApolloReactCommon.MutationResult<CreateTicketMutation>;
export type CreateTicketMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTicketMutation,
  CreateTicketMutationVariables
>;
export const EditTicketDocument = gql`
  mutation editTicket($id: Float!, $input: EditTicketInput!) {
    ticket: editTicket(id: $id, ticket: $input) {
      id
    }
  }
`;
export type EditTicketMutationFn = ApolloReactCommon.MutationFunction<
  EditTicketMutation,
  EditTicketMutationVariables
>;

/**
 * __useEditTicketMutation__
 *
 * To run a mutation, you first call `useEditTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTicketMutation, { data, loading, error }] = useEditTicketMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTicketMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EditTicketMutation,
    EditTicketMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<EditTicketMutation, EditTicketMutationVariables>(
    EditTicketDocument,
    options
  );
}
export type EditTicketMutationHookResult = ReturnType<typeof useEditTicketMutation>;
export type EditTicketMutationResult = ApolloReactCommon.MutationResult<EditTicketMutation>;
export type EditTicketMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EditTicketMutation,
  EditTicketMutationVariables
>;
export const SearchUsersDocument = gql`
  query searchUsers($search: SearchUserInput!) {
    searchUsers(search: $search) {
      id
      ...userNames
      email
      customerId
      createdAt
      careStatus
      organization {
        ...organization
      }
    }
  }
  ${UserNamesFragmentDoc}
  ${OrganizationFragmentDoc}
`;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchUsersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(
    SearchUsersDocument,
    options
  );
}
export function useSearchUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(
    SearchUsersDocument,
    options
  );
}
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = ApolloReactCommon.QueryResult<
  SearchUsersQuery,
  SearchUsersQueryVariables
>;
export const PaginatedUsersDocument = gql`
  query paginatedUsers($params: UserListParams!) {
    paginatedUsers(params: $params) {
      items {
        ...userListItem
      }
      count
      cursor
      hasMore
    }
  }
  ${UserListItemFragmentDoc}
`;

/**
 * __usePaginatedUsersQuery__
 *
 * To run a query within a React component, call `usePaginatedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedUsersQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function usePaginatedUsersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PaginatedUsersQuery, PaginatedUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PaginatedUsersQuery, PaginatedUsersQueryVariables>(
    PaginatedUsersDocument,
    options
  );
}
export function usePaginatedUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaginatedUsersQuery,
    PaginatedUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PaginatedUsersQuery, PaginatedUsersQueryVariables>(
    PaginatedUsersDocument,
    options
  );
}
export type PaginatedUsersQueryHookResult = ReturnType<typeof usePaginatedUsersQuery>;
export type PaginatedUsersLazyQueryHookResult = ReturnType<typeof usePaginatedUsersLazyQuery>;
export type PaginatedUsersQueryResult = ApolloReactCommon.QueryResult<
  PaginatedUsersQuery,
  PaginatedUsersQueryVariables
>;
export const UsersOrganizationsDocument = gql`
  query usersOrganizations($params: UserListParams!) {
    usersOrganizations(params: $params) {
      id
      name
    }
  }
`;

/**
 * __useUsersOrganizationsQuery__
 *
 * To run a query within a React component, call `useUsersOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersOrganizationsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUsersOrganizationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    UsersOrganizationsQuery,
    UsersOrganizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UsersOrganizationsQuery, UsersOrganizationsQueryVariables>(
    UsersOrganizationsDocument,
    options
  );
}
export function useUsersOrganizationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UsersOrganizationsQuery,
    UsersOrganizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UsersOrganizationsQuery, UsersOrganizationsQueryVariables>(
    UsersOrganizationsDocument,
    options
  );
}
export type UsersOrganizationsQueryHookResult = ReturnType<typeof useUsersOrganizationsQuery>;
export type UsersOrganizationsLazyQueryHookResult = ReturnType<
  typeof useUsersOrganizationsLazyQuery
>;
export type UsersOrganizationsQueryResult = ApolloReactCommon.QueryResult<
  UsersOrganizationsQuery,
  UsersOrganizationsQueryVariables
>;
export const GetConsentHistoryDocument = gql`
  query getConsentHistory($userId: Float!) {
    adminUser(id: $userId) {
      organization {
        abbreviation
        carePeriods {
          id
          startDate
          endDate
        }
      }
    }
    requests: debugDataSharingRequestsForUser(userId: $userId) {
      id
      createdAt
      pending
    }
    consents: debugDataSharingConsentsForUser(userId: $userId) {
      id
      startDate
      endDate
      revokedAt
    }
    careFlows: debugCareFlowsForUser(userId: $userId) {
      id
      careType
      careStatus
      validStart
      validEnd
      paymentSource
    }
    appointments: adminAppointments(userId: $userId) {
      id
      startTime
      endTime
      description
      appointmentType
      status
    }
  }
`;

/**
 * __useGetConsentHistoryQuery__
 *
 * To run a query within a React component, call `useGetConsentHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsentHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsentHistoryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetConsentHistoryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetConsentHistoryQuery,
    GetConsentHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetConsentHistoryQuery, GetConsentHistoryQueryVariables>(
    GetConsentHistoryDocument,
    options
  );
}
export function useGetConsentHistoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetConsentHistoryQuery,
    GetConsentHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetConsentHistoryQuery, GetConsentHistoryQueryVariables>(
    GetConsentHistoryDocument,
    options
  );
}
export type GetConsentHistoryQueryHookResult = ReturnType<typeof useGetConsentHistoryQuery>;
export type GetConsentHistoryLazyQueryHookResult = ReturnType<typeof useGetConsentHistoryLazyQuery>;
export type GetConsentHistoryQueryResult = ApolloReactCommon.QueryResult<
  GetConsentHistoryQuery,
  GetConsentHistoryQueryVariables
>;
export const ModifyExistingRequestDocument = gql`
  mutation modifyExistingRequest($id: Float!, $date: DateTime!, $pending: Boolean!) {
    request: debugModifyDataSharingRequest(id: $id, pending: $pending, date: $date) {
      id
      createdAt
      pending
    }
  }
`;
export type ModifyExistingRequestMutationFn = ApolloReactCommon.MutationFunction<
  ModifyExistingRequestMutation,
  ModifyExistingRequestMutationVariables
>;

/**
 * __useModifyExistingRequestMutation__
 *
 * To run a mutation, you first call `useModifyExistingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyExistingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyExistingRequestMutation, { data, loading, error }] = useModifyExistingRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *      pending: // value for 'pending'
 *   },
 * });
 */
export function useModifyExistingRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ModifyExistingRequestMutation,
    ModifyExistingRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ModifyExistingRequestMutation,
    ModifyExistingRequestMutationVariables
  >(ModifyExistingRequestDocument, options);
}
export type ModifyExistingRequestMutationHookResult = ReturnType<
  typeof useModifyExistingRequestMutation
>;
export type ModifyExistingRequestMutationResult =
  ApolloReactCommon.MutationResult<ModifyExistingRequestMutation>;
export type ModifyExistingRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ModifyExistingRequestMutation,
  ModifyExistingRequestMutationVariables
>;
export const ModifyExistingConsentDocument = gql`
  mutation modifyExistingConsent(
    $id: Float!
    $startDate: DateTime!
    $endDate: DateTime!
    $revokedAt: DateTime
  ) {
    consent: debugModifyDataSharingConsent(
      id: $id
      startDate: $startDate
      endDate: $endDate
      revokedAt: $revokedAt
    ) {
      id
      startDate
      endDate
      revokedAt
    }
  }
`;
export type ModifyExistingConsentMutationFn = ApolloReactCommon.MutationFunction<
  ModifyExistingConsentMutation,
  ModifyExistingConsentMutationVariables
>;

/**
 * __useModifyExistingConsentMutation__
 *
 * To run a mutation, you first call `useModifyExistingConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyExistingConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyExistingConsentMutation, { data, loading, error }] = useModifyExistingConsentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      revokedAt: // value for 'revokedAt'
 *   },
 * });
 */
export function useModifyExistingConsentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ModifyExistingConsentMutation,
    ModifyExistingConsentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ModifyExistingConsentMutation,
    ModifyExistingConsentMutationVariables
  >(ModifyExistingConsentDocument, options);
}
export type ModifyExistingConsentMutationHookResult = ReturnType<
  typeof useModifyExistingConsentMutation
>;
export type ModifyExistingConsentMutationResult =
  ApolloReactCommon.MutationResult<ModifyExistingConsentMutation>;
export type ModifyExistingConsentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ModifyExistingConsentMutation,
  ModifyExistingConsentMutationVariables
>;
export const AdminUserDocument = gql`
  query adminUser(
    $id: Float!
    $assessmentInfo: Boolean!
    $restricted: Boolean!
    $full: Boolean!
    $phi: Boolean!
  ) {
    adminUser(id: $id) {
      samlUniqueId
      id
      ...userNames
      customerId
      createdAt
      birthDate
      email @skip(if: $restricted)
      phone @skip(if: $restricted)
      emailVerified @skip(if: $restricted)
      latestMedicalHistoryResult @include(if: $phi) {
        uuid
        questionnaires {
          questions {
            key
            text
            type
            value
          }
        }
        createdAt
        updatedAt
      }
      cocEligibility {
        careType
        paymentType
      }
      hasDosespot
      pronouns
      careTypes
      secondaryState
      organizationIdentification
      referralComplete
      origin
      unsubscribedAt
      pendingSponsoredCareReenrollment
      editableFields
      careFlows {
        id
        careType
        careStatus @skip(if: $restricted)
        paymentSource @skip(if: $restricted)
        careSessionInfo {
          ...careSessionInfo
        }
        pendingTransition {
          nextSteps
        }
      }
      primaryAddressState
      primaryAddress @skip(if: $restricted) {
        ...address
      }
      billingAddress @skip(if: $restricted) {
        ...address
      }
      therapist @skip(if: $restricted) {
        ...provider
      }
      suggestedProviders {
        ...provider
      }
      insuranceDetails @include(if: $phi) @skip(if: $restricted) {
        memberId
        groupNumber
        updatedAt
        state
        subscriberFirstName
        subscriberLastName
        subscriberDateOfBirth
        subscriberGender
        patientRelationshipToSubscriber
        payer {
          id
          name
          inNetworkStates
        }
        lastChecked
        eligibilityVerified
        deductibleRemainingCents
        noMentalHealthBenefits
      }
      emergencyContacts @include(if: $phi) @skip(if: $restricted) {
        id
        name
        relationship
        phoneNumber
        primary
      }
      selfie {
        ...upload
      }
      assessmentInfo @include(if: $assessmentInfo) {
        diagnoses
        primaryDiagnosis
        additionalDiagnoses
        riskLevel
        allergies
        wellnessGoals
        otherMeds
        additionalProviderName
        additionalProviderContact
        psychiatricHistory
      }
      latestQuestionnaires @include(if: $assessmentInfo) {
        key
        score
        symptoms
        description
        assessment {
          createdAt
          provider {
            ...baseProvider
          }
        }
      }
      baselineQuestionnaires @include(if: $assessmentInfo) {
        key
        score
        symptoms
        description
      }
      continuityOfCareEligibility @include(if: $phi) {
        residentialLocationDuringBreak
        interestedInContinuingCare
        enrollment
        additionalNotes
        careType
        createdAt
        updatedAt
        provider {
          id
          name
        }
        completedBy {
          id
          name
        }
      }
      lastPrescriptionWrittenDate @include(if: $phi)
      taskStatus @include(if: $full)
      tasks @include(if: $full) {
        ...task
      }
      provider @skip(if: $restricted) {
        ...provider
      }
      mantraAdminCareTeam {
        ...provider
      }
      organization {
        ...organization
        usesDedicatedGroupModel
        entitlements {
          key
        }
        parent {
          id
          name
          abbreviation
        }
        latestCarePeriod {
          endDate
        }
        careFlows {
          careType
          defaultPaymentSource
          sponsoredSessionsLimitType
        }
      }
      nextScales @include(if: $phi)
      needsScales @include(if: $phi)
      safetyPlan @include(if: $phi) @skip(if: $restricted) {
        id
        createdAt
        complete
        activatingProvider {
          firstName
          lastName
          postNominalTitles
        }
        editHistory {
          createdAt
          provider {
            id
            firstName
            lastName
            postNominalTitles
          }
        }
        data {
          ...safetyPlanData
        }
      }
      nextAppointment(status: "upcoming") @skip(if: $restricted) {
        id
        startTime
      }
      continuityOfCareTransitions @skip(if: $restricted) {
        careType
        nextSteps
        pendingUser
        reason
        requiresReferral
        completedOn
        updatedAt
        user {
          id
          organization {
            id
            latestCarePeriod {
              id
              endDate
            }
          }
        }
      }
      dataSharingRequest @include(if: $phi) {
        canProviderSendReminder
        lastReminderSentDate
        createdAt
        provider {
          name
        }
      }
      dataSharingConsent @include(if: $phi) {
        isActive
        startDate
        endDate
      }
      campusTeam {
        relationshipType
        provider {
          id
          firstName
          lastName
          name
          role
          classification
          portrait {
            url
          }
          organizations {
            id
            name
          }
        }
      }
      hubOnboardingStatus {
        isHubEnabled
        hasHubOnboarded
      }
    }
    events(userId: $id) {
      id
      tag
      createdAt
      data
    }
    permissibleUploadTypes
    careNavigators {
      id
    }
  }
  ${UserNamesFragmentDoc}
  ${CareSessionInfoFragmentDoc}
  ${AddressFragmentDoc}
  ${ProviderFragmentDoc}
  ${UploadFragmentDoc}
  ${BaseProviderFragmentDoc}
  ${TaskFragmentDoc}
  ${OrganizationFragmentDoc}
  ${SafetyPlanDataFragmentDoc}
`;

/**
 * __useAdminUserQuery__
 *
 * To run a query within a React component, call `useAdminUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *      assessmentInfo: // value for 'assessmentInfo'
 *      restricted: // value for 'restricted'
 *      full: // value for 'full'
 *      phi: // value for 'phi'
 *   },
 * });
 */
export function useAdminUserQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AdminUserQuery, AdminUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminUserQuery, AdminUserQueryVariables>(
    AdminUserDocument,
    options
  );
}
export function useAdminUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminUserQuery, AdminUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminUserQuery, AdminUserQueryVariables>(
    AdminUserDocument,
    options
  );
}
export type AdminUserQueryHookResult = ReturnType<typeof useAdminUserQuery>;
export type AdminUserLazyQueryHookResult = ReturnType<typeof useAdminUserLazyQuery>;
export type AdminUserQueryResult = ApolloReactCommon.QueryResult<
  AdminUserQuery,
  AdminUserQueryVariables
>;
export const UserHealthieStatusDocument = gql`
  query userHealthieStatus($userId: Float!) {
    userHealthieStatus(userId: $userId) {
      userIsInitialized
    }
  }
`;

/**
 * __useUserHealthieStatusQuery__
 *
 * To run a query within a React component, call `useUserHealthieStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHealthieStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHealthieStatusQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserHealthieStatusQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    UserHealthieStatusQuery,
    UserHealthieStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserHealthieStatusQuery, UserHealthieStatusQueryVariables>(
    UserHealthieStatusDocument,
    options
  );
}
export function useUserHealthieStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserHealthieStatusQuery,
    UserHealthieStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserHealthieStatusQuery, UserHealthieStatusQueryVariables>(
    UserHealthieStatusDocument,
    options
  );
}
export type UserHealthieStatusQueryHookResult = ReturnType<typeof useUserHealthieStatusQuery>;
export type UserHealthieStatusLazyQueryHookResult = ReturnType<
  typeof useUserHealthieStatusLazyQuery
>;
export type UserHealthieStatusQueryResult = ApolloReactCommon.QueryResult<
  UserHealthieStatusQuery,
  UserHealthieStatusQueryVariables
>;
export const UserCoachingHealthieStatusDocument = gql`
  query userCoachingHealthieStatus($userId: Float!) {
    userCoachingHealthieStatus(userId: $userId) {
      userCoachingHealthieStatus
    }
  }
`;

/**
 * __useUserCoachingHealthieStatusQuery__
 *
 * To run a query within a React component, call `useUserCoachingHealthieStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCoachingHealthieStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCoachingHealthieStatusQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserCoachingHealthieStatusQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    UserCoachingHealthieStatusQuery,
    UserCoachingHealthieStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserCoachingHealthieStatusQuery,
    UserCoachingHealthieStatusQueryVariables
  >(UserCoachingHealthieStatusDocument, options);
}
export function useUserCoachingHealthieStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserCoachingHealthieStatusQuery,
    UserCoachingHealthieStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserCoachingHealthieStatusQuery,
    UserCoachingHealthieStatusQueryVariables
  >(UserCoachingHealthieStatusDocument, options);
}
export type UserCoachingHealthieStatusQueryHookResult = ReturnType<
  typeof useUserCoachingHealthieStatusQuery
>;
export type UserCoachingHealthieStatusLazyQueryHookResult = ReturnType<
  typeof useUserCoachingHealthieStatusLazyQuery
>;
export type UserCoachingHealthieStatusQueryResult = ApolloReactCommon.QueryResult<
  UserCoachingHealthieStatusQuery,
  UserCoachingHealthieStatusQueryVariables
>;
export const AdminUserViewDocument = gql`
  query adminUserView($id: Float!) {
    adminUser(id: $id) {
      id
      recordViewStatus
    }
  }
`;

/**
 * __useAdminUserViewQuery__
 *
 * To run a query within a React component, call `useAdminUserViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUserViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUserViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminUserViewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AdminUserViewQuery, AdminUserViewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminUserViewQuery, AdminUserViewQueryVariables>(
    AdminUserViewDocument,
    options
  );
}
export function useAdminUserViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminUserViewQuery,
    AdminUserViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminUserViewQuery, AdminUserViewQueryVariables>(
    AdminUserViewDocument,
    options
  );
}
export type AdminUserViewQueryHookResult = ReturnType<typeof useAdminUserViewQuery>;
export type AdminUserViewLazyQueryHookResult = ReturnType<typeof useAdminUserViewLazyQuery>;
export type AdminUserViewQueryResult = ApolloReactCommon.QueryResult<
  AdminUserViewQuery,
  AdminUserViewQueryVariables
>;
export const ZendeskLinkDocument = gql`
  query zendeskLink($userId: Float!) {
    zendeskLink(userId: $userId)
  }
`;

/**
 * __useZendeskLinkQuery__
 *
 * To run a query within a React component, call `useZendeskLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useZendeskLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZendeskLinkQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useZendeskLinkQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ZendeskLinkQuery, ZendeskLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ZendeskLinkQuery, ZendeskLinkQueryVariables>(
    ZendeskLinkDocument,
    options
  );
}
export function useZendeskLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ZendeskLinkQuery, ZendeskLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ZendeskLinkQuery, ZendeskLinkQueryVariables>(
    ZendeskLinkDocument,
    options
  );
}
export type ZendeskLinkQueryHookResult = ReturnType<typeof useZendeskLinkQuery>;
export type ZendeskLinkLazyQueryHookResult = ReturnType<typeof useZendeskLinkLazyQuery>;
export type ZendeskLinkQueryResult = ApolloReactCommon.QueryResult<
  ZendeskLinkQuery,
  ZendeskLinkQueryVariables
>;
export const AssessmentResultsDocument = gql`
  query assessmentResults($userId: Float!) {
    results: adminAssessmentResults(userId: $userId) {
      id
      createdAt
      provider {
        ...baseProvider
      }
      questionnaires {
        key
        score
        symptoms
        description
        answers {
          text
          textForProviders
          score
          key
          answer
          multiText
        }
      }
    }
  }
  ${BaseProviderFragmentDoc}
`;

/**
 * __useAssessmentResultsQuery__
 *
 * To run a query within a React component, call `useAssessmentResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentResultsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAssessmentResultsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AssessmentResultsQuery,
    AssessmentResultsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AssessmentResultsQuery, AssessmentResultsQueryVariables>(
    AssessmentResultsDocument,
    options
  );
}
export function useAssessmentResultsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AssessmentResultsQuery,
    AssessmentResultsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AssessmentResultsQuery, AssessmentResultsQueryVariables>(
    AssessmentResultsDocument,
    options
  );
}
export type AssessmentResultsQueryHookResult = ReturnType<typeof useAssessmentResultsQuery>;
export type AssessmentResultsLazyQueryHookResult = ReturnType<typeof useAssessmentResultsLazyQuery>;
export type AssessmentResultsQueryResult = ApolloReactCommon.QueryResult<
  AssessmentResultsQuery,
  AssessmentResultsQueryVariables
>;
export const OutreachDocument = gql`
  query outreach($userId: Int!, $userIdFloat: Float!) {
    adminUser(id: $userIdFloat) {
      unsubscribedAt
    }
    userEmails(userId: $userId) {
      id
      subject
      sent
      delivered
      opened
      bounced
      sentAt
      outcome
    }
    userSmses(userId: $userId) {
      id
      description
      createdAt
    }
    userOutreachAttempts(userId: $userId) {
      id
      createdAt
      updatedAt
      date
      subject
      methods
      outcome
      attemptNumber
      description
      createdBy {
        id
        name
      }
      createdByCustom
    }
    admins: providers(roles: ["admin"]) {
      id
      name
    }
  }
`;

/**
 * __useOutreachQuery__
 *
 * To run a query within a React component, call `useOutreachQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutreachQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutreachQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      userIdFloat: // value for 'userIdFloat'
 *   },
 * });
 */
export function useOutreachQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<OutreachQuery, OutreachQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OutreachQuery, OutreachQueryVariables>(
    OutreachDocument,
    options
  );
}
export function useOutreachLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OutreachQuery, OutreachQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OutreachQuery, OutreachQueryVariables>(
    OutreachDocument,
    options
  );
}
export type OutreachQueryHookResult = ReturnType<typeof useOutreachQuery>;
export type OutreachLazyQueryHookResult = ReturnType<typeof useOutreachLazyQuery>;
export type OutreachQueryResult = ApolloReactCommon.QueryResult<
  OutreachQuery,
  OutreachQueryVariables
>;
export const UserNotificationsDocument = gql`
  query userNotifications($userId: Float!) {
    adminUserNotifications(userId: $userId) {
      ...userNotification
    }
  }
  ${UserNotificationFragmentDoc}
`;

/**
 * __useUserNotificationsQuery__
 *
 * To run a query within a React component, call `useUserNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNotificationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserNotificationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    UserNotificationsQuery,
    UserNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(
    UserNotificationsDocument,
    options
  );
}
export function useUserNotificationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserNotificationsQuery,
    UserNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(
    UserNotificationsDocument,
    options
  );
}
export type UserNotificationsQueryHookResult = ReturnType<typeof useUserNotificationsQuery>;
export type UserNotificationsLazyQueryHookResult = ReturnType<typeof useUserNotificationsLazyQuery>;
export type UserNotificationsQueryResult = ApolloReactCommon.QueryResult<
  UserNotificationsQuery,
  UserNotificationsQueryVariables
>;
export const BeginSponsoredCareReenrollmentDocument = gql`
  mutation beginSponsoredCareReenrollment($userId: Float!) {
    beginSponsoredCareReenrollment(userId: $userId)
  }
`;
export type BeginSponsoredCareReenrollmentMutationFn = ApolloReactCommon.MutationFunction<
  BeginSponsoredCareReenrollmentMutation,
  BeginSponsoredCareReenrollmentMutationVariables
>;

/**
 * __useBeginSponsoredCareReenrollmentMutation__
 *
 * To run a mutation, you first call `useBeginSponsoredCareReenrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeginSponsoredCareReenrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beginSponsoredCareReenrollmentMutation, { data, loading, error }] = useBeginSponsoredCareReenrollmentMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBeginSponsoredCareReenrollmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BeginSponsoredCareReenrollmentMutation,
    BeginSponsoredCareReenrollmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BeginSponsoredCareReenrollmentMutation,
    BeginSponsoredCareReenrollmentMutationVariables
  >(BeginSponsoredCareReenrollmentDocument, options);
}
export type BeginSponsoredCareReenrollmentMutationHookResult = ReturnType<
  typeof useBeginSponsoredCareReenrollmentMutation
>;
export type BeginSponsoredCareReenrollmentMutationResult =
  ApolloReactCommon.MutationResult<BeginSponsoredCareReenrollmentMutation>;
export type BeginSponsoredCareReenrollmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BeginSponsoredCareReenrollmentMutation,
  BeginSponsoredCareReenrollmentMutationVariables
>;
export const CancelSponsoredCareReenrollmentDocument = gql`
  mutation cancelSponsoredCareReenrollment($userId: Float!) {
    cancelSponsoredCareReenrollment(userId: $userId)
  }
`;
export type CancelSponsoredCareReenrollmentMutationFn = ApolloReactCommon.MutationFunction<
  CancelSponsoredCareReenrollmentMutation,
  CancelSponsoredCareReenrollmentMutationVariables
>;

/**
 * __useCancelSponsoredCareReenrollmentMutation__
 *
 * To run a mutation, you first call `useCancelSponsoredCareReenrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSponsoredCareReenrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSponsoredCareReenrollmentMutation, { data, loading, error }] = useCancelSponsoredCareReenrollmentMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCancelSponsoredCareReenrollmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelSponsoredCareReenrollmentMutation,
    CancelSponsoredCareReenrollmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CancelSponsoredCareReenrollmentMutation,
    CancelSponsoredCareReenrollmentMutationVariables
  >(CancelSponsoredCareReenrollmentDocument, options);
}
export type CancelSponsoredCareReenrollmentMutationHookResult = ReturnType<
  typeof useCancelSponsoredCareReenrollmentMutation
>;
export type CancelSponsoredCareReenrollmentMutationResult =
  ApolloReactCommon.MutationResult<CancelSponsoredCareReenrollmentMutation>;
export type CancelSponsoredCareReenrollmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CancelSponsoredCareReenrollmentMutation,
  CancelSponsoredCareReenrollmentMutationVariables
>;
export const SuggestedProviderUserDocument = gql`
  query suggestedProviderUser($id: Float!) {
    adminUser(id: $id) {
      id
      firstName
      lastName
      preferredName
      birthDate
      suggestedProviders {
        id
      }
      organization {
        id
      }
    }
  }
`;

/**
 * __useSuggestedProviderUserQuery__
 *
 * To run a query within a React component, call `useSuggestedProviderUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedProviderUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedProviderUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSuggestedProviderUserQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SuggestedProviderUserQuery,
    SuggestedProviderUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SuggestedProviderUserQuery, SuggestedProviderUserQueryVariables>(
    SuggestedProviderUserDocument,
    options
  );
}
export function useSuggestedProviderUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SuggestedProviderUserQuery,
    SuggestedProviderUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SuggestedProviderUserQuery,
    SuggestedProviderUserQueryVariables
  >(SuggestedProviderUserDocument, options);
}
export type SuggestedProviderUserQueryHookResult = ReturnType<typeof useSuggestedProviderUserQuery>;
export type SuggestedProviderUserLazyQueryHookResult = ReturnType<
  typeof useSuggestedProviderUserLazyQuery
>;
export type SuggestedProviderUserQueryResult = ApolloReactCommon.QueryResult<
  SuggestedProviderUserQuery,
  SuggestedProviderUserQueryVariables
>;
export const CrisisCalendarEventDocument = gql`
  query crisisCalendarEvent($crisisId: String!) {
    maybeEvent: adminAppointmentByCrisisId(crisisId: $crisisId) {
      blockedReason
      body
      data {
        ...videoCallData
      }
    }
  }
  ${VideoCallDataFragmentDoc}
`;

/**
 * __useCrisisCalendarEventQuery__
 *
 * To run a query within a React component, call `useCrisisCalendarEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrisisCalendarEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrisisCalendarEventQuery({
 *   variables: {
 *      crisisId: // value for 'crisisId'
 *   },
 * });
 */
export function useCrisisCalendarEventQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CrisisCalendarEventQuery,
    CrisisCalendarEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CrisisCalendarEventQuery, CrisisCalendarEventQueryVariables>(
    CrisisCalendarEventDocument,
    options
  );
}
export function useCrisisCalendarEventLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CrisisCalendarEventQuery,
    CrisisCalendarEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CrisisCalendarEventQuery, CrisisCalendarEventQueryVariables>(
    CrisisCalendarEventDocument,
    options
  );
}
export type CrisisCalendarEventQueryHookResult = ReturnType<typeof useCrisisCalendarEventQuery>;
export type CrisisCalendarEventLazyQueryHookResult = ReturnType<
  typeof useCrisisCalendarEventLazyQuery
>;
export type CrisisCalendarEventQueryResult = ApolloReactCommon.QueryResult<
  CrisisCalendarEventQuery,
  CrisisCalendarEventQueryVariables
>;
export const VideoCallSidebarDocument = gql`
  query videoCallSidebar($id: Float!) {
    user: adminUser(id: $id) {
      ...userBasic
      birthDate
      pronouns
      phone
      organization {
        safeOperatingHandbookUrl
        safeOperatingHandbook {
          ...organizationHandbook
        }
        ...organization
      }
      primaryAddress {
        ...address
      }
      emergencyContacts {
        id
        name
        relationship
        phoneNumber
        primary
      }
    }
  }
  ${UserBasicFragmentDoc}
  ${OrganizationHandbookFragmentDoc}
  ${OrganizationFragmentDoc}
  ${AddressFragmentDoc}
`;

/**
 * __useVideoCallSidebarQuery__
 *
 * To run a query within a React component, call `useVideoCallSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoCallSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoCallSidebarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVideoCallSidebarQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    VideoCallSidebarQuery,
    VideoCallSidebarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<VideoCallSidebarQuery, VideoCallSidebarQueryVariables>(
    VideoCallSidebarDocument,
    options
  );
}
export function useVideoCallSidebarLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VideoCallSidebarQuery,
    VideoCallSidebarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<VideoCallSidebarQuery, VideoCallSidebarQueryVariables>(
    VideoCallSidebarDocument,
    options
  );
}
export type VideoCallSidebarQueryHookResult = ReturnType<typeof useVideoCallSidebarQuery>;
export type VideoCallSidebarLazyQueryHookResult = ReturnType<typeof useVideoCallSidebarLazyQuery>;
export type VideoCallSidebarQueryResult = ApolloReactCommon.QueryResult<
  VideoCallSidebarQuery,
  VideoCallSidebarQueryVariables
>;
export const ProviderIsLoggedInDocument = gql`
  query providerIsLoggedIn {
    providerIsLoggedIn {
      isLoggedIn
      authToken
    }
  }
`;

/**
 * __useProviderIsLoggedInQuery__
 *
 * To run a query within a React component, call `useProviderIsLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderIsLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderIsLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useProviderIsLoggedInQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ProviderIsLoggedInQuery,
    ProviderIsLoggedInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ProviderIsLoggedInQuery, ProviderIsLoggedInQueryVariables>(
    ProviderIsLoggedInDocument,
    options
  );
}
export function useProviderIsLoggedInLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProviderIsLoggedInQuery,
    ProviderIsLoggedInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ProviderIsLoggedInQuery, ProviderIsLoggedInQueryVariables>(
    ProviderIsLoggedInDocument,
    options
  );
}
export type ProviderIsLoggedInQueryHookResult = ReturnType<typeof useProviderIsLoggedInQuery>;
export type ProviderIsLoggedInLazyQueryHookResult = ReturnType<
  typeof useProviderIsLoggedInLazyQuery
>;
export type ProviderIsLoggedInQueryResult = ApolloReactCommon.QueryResult<
  ProviderIsLoggedInQuery,
  ProviderIsLoggedInQueryVariables
>;
export const CampusResourcesDocument = gql`
  query campusResources($organizationId: Float!) {
    organization(id: $organizationId) {
      resources {
        id
        name
        description
        position
        resourceType
        resourceSubtype
        showAsCrisisResource
        contact
        location
        phone
        email
        hours
        website
        tags
      }
      resourcesUpdatedAt
      resourcesUpdatedBy {
        id
        name
      }
    }
  }
`;

/**
 * __useCampusResourcesQuery__
 *
 * To run a query within a React component, call `useCampusResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampusResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampusResourcesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCampusResourcesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CampusResourcesQuery,
    CampusResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CampusResourcesQuery, CampusResourcesQueryVariables>(
    CampusResourcesDocument,
    options
  );
}
export function useCampusResourcesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CampusResourcesQuery,
    CampusResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CampusResourcesQuery, CampusResourcesQueryVariables>(
    CampusResourcesDocument,
    options
  );
}
export type CampusResourcesQueryHookResult = ReturnType<typeof useCampusResourcesQuery>;
export type CampusResourcesLazyQueryHookResult = ReturnType<typeof useCampusResourcesLazyQuery>;
export type CampusResourcesQueryResult = ApolloReactCommon.QueryResult<
  CampusResourcesQuery,
  CampusResourcesQueryVariables
>;
export const CreateOrUpdateOrganizationResourceDocument = gql`
  mutation createOrUpdateOrganizationResource(
    $organizationId: Int!
    $resource: OrganizationResourceInput!
  ) {
    createOrUpdateOrganizationResource(organizationId: $organizationId, resource: $resource) {
      id
    }
  }
`;
export type CreateOrUpdateOrganizationResourceMutationFn = ApolloReactCommon.MutationFunction<
  CreateOrUpdateOrganizationResourceMutation,
  CreateOrUpdateOrganizationResourceMutationVariables
>;

/**
 * __useCreateOrUpdateOrganizationResourceMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateOrganizationResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateOrganizationResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateOrganizationResourceMutation, { data, loading, error }] = useCreateOrUpdateOrganizationResourceMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      resource: // value for 'resource'
 *   },
 * });
 */
export function useCreateOrUpdateOrganizationResourceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrUpdateOrganizationResourceMutation,
    CreateOrUpdateOrganizationResourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateOrUpdateOrganizationResourceMutation,
    CreateOrUpdateOrganizationResourceMutationVariables
  >(CreateOrUpdateOrganizationResourceDocument, options);
}
export type CreateOrUpdateOrganizationResourceMutationHookResult = ReturnType<
  typeof useCreateOrUpdateOrganizationResourceMutation
>;
export type CreateOrUpdateOrganizationResourceMutationResult =
  ApolloReactCommon.MutationResult<CreateOrUpdateOrganizationResourceMutation>;
export type CreateOrUpdateOrganizationResourceMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateOrUpdateOrganizationResourceMutation,
    CreateOrUpdateOrganizationResourceMutationVariables
  >;
export const DeleteOrganizationResourceDocument = gql`
  mutation deleteOrganizationResource($resourceId: Int!) {
    deleteOrganizationResource(resourceId: $resourceId)
  }
`;
export type DeleteOrganizationResourceMutationFn = ApolloReactCommon.MutationFunction<
  DeleteOrganizationResourceMutation,
  DeleteOrganizationResourceMutationVariables
>;

/**
 * __useDeleteOrganizationResourceMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationResourceMutation, { data, loading, error }] = useDeleteOrganizationResourceMutation({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useDeleteOrganizationResourceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteOrganizationResourceMutation,
    DeleteOrganizationResourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteOrganizationResourceMutation,
    DeleteOrganizationResourceMutationVariables
  >(DeleteOrganizationResourceDocument, options);
}
export type DeleteOrganizationResourceMutationHookResult = ReturnType<
  typeof useDeleteOrganizationResourceMutation
>;
export type DeleteOrganizationResourceMutationResult =
  ApolloReactCommon.MutationResult<DeleteOrganizationResourceMutation>;
export type DeleteOrganizationResourceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteOrganizationResourceMutation,
  DeleteOrganizationResourceMutationVariables
>;
export const DebugLoginAsUserDocument = gql`
  mutation debugLoginAsUser($userId: Float!) {
    debugLoginAsUser(userId: $userId)
  }
`;
export type DebugLoginAsUserMutationFn = ApolloReactCommon.MutationFunction<
  DebugLoginAsUserMutation,
  DebugLoginAsUserMutationVariables
>;

/**
 * __useDebugLoginAsUserMutation__
 *
 * To run a mutation, you first call `useDebugLoginAsUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebugLoginAsUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debugLoginAsUserMutation, { data, loading, error }] = useDebugLoginAsUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDebugLoginAsUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DebugLoginAsUserMutation,
    DebugLoginAsUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DebugLoginAsUserMutation, DebugLoginAsUserMutationVariables>(
    DebugLoginAsUserDocument,
    options
  );
}
export type DebugLoginAsUserMutationHookResult = ReturnType<typeof useDebugLoginAsUserMutation>;
export type DebugLoginAsUserMutationResult =
  ApolloReactCommon.MutationResult<DebugLoginAsUserMutation>;
export type DebugLoginAsUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DebugLoginAsUserMutation,
  DebugLoginAsUserMutationVariables
>;
export const DebugImpersonateAsUserDocument = gql`
  mutation debugImpersonateAsUser($userId: Float!, $reason: String!) {
    debugImpersonateAsUser(userId: $userId, reason: $reason)
  }
`;
export type DebugImpersonateAsUserMutationFn = ApolloReactCommon.MutationFunction<
  DebugImpersonateAsUserMutation,
  DebugImpersonateAsUserMutationVariables
>;

/**
 * __useDebugImpersonateAsUserMutation__
 *
 * To run a mutation, you first call `useDebugImpersonateAsUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebugImpersonateAsUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debugImpersonateAsUserMutation, { data, loading, error }] = useDebugImpersonateAsUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useDebugImpersonateAsUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DebugImpersonateAsUserMutation,
    DebugImpersonateAsUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DebugImpersonateAsUserMutation,
    DebugImpersonateAsUserMutationVariables
  >(DebugImpersonateAsUserDocument, options);
}
export type DebugImpersonateAsUserMutationHookResult = ReturnType<
  typeof useDebugImpersonateAsUserMutation
>;
export type DebugImpersonateAsUserMutationResult =
  ApolloReactCommon.MutationResult<DebugImpersonateAsUserMutation>;
export type DebugImpersonateAsUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DebugImpersonateAsUserMutation,
  DebugImpersonateAsUserMutationVariables
>;
export const UserMessagesDocument = gql`
  query userMessages($id: Float!, $providerId: Float) {
    user: adminUser(id: $id) {
      id
      channel: privateChannel(providerId: $providerId) {
        id
        unreadMessageCount
        messages {
          ...message
          attachments {
            ...upload
          }
        }
      }
    }
  }
  ${MessageFragmentDoc}
  ${UploadFragmentDoc}
`;

/**
 * __useUserMessagesQuery__
 *
 * To run a query within a React component, call `useUserMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useUserMessagesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<UserMessagesQuery, UserMessagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserMessagesQuery, UserMessagesQueryVariables>(
    UserMessagesDocument,
    options
  );
}
export function useUserMessagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserMessagesQuery, UserMessagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserMessagesQuery, UserMessagesQueryVariables>(
    UserMessagesDocument,
    options
  );
}
export type UserMessagesQueryHookResult = ReturnType<typeof useUserMessagesQuery>;
export type UserMessagesLazyQueryHookResult = ReturnType<typeof useUserMessagesLazyQuery>;
export type UserMessagesQueryResult = ApolloReactCommon.QueryResult<
  UserMessagesQuery,
  UserMessagesQueryVariables
>;
export const UserCareTeamChannelDocument = gql`
  query userCareTeamChannel($userId: Float!) {
    user: adminUser(id: $userId) {
      channel: careTeamChannel {
        id
        unreadMessageCount
        uniqProviders {
          ...provider
        }
        messages {
          ...message
        }
      }
    }
  }
  ${ProviderFragmentDoc}
  ${MessageFragmentDoc}
`;

/**
 * __useUserCareTeamChannelQuery__
 *
 * To run a query within a React component, call `useUserCareTeamChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCareTeamChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCareTeamChannelQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserCareTeamChannelQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    UserCareTeamChannelQuery,
    UserCareTeamChannelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserCareTeamChannelQuery, UserCareTeamChannelQueryVariables>(
    UserCareTeamChannelDocument,
    options
  );
}
export function useUserCareTeamChannelLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserCareTeamChannelQuery,
    UserCareTeamChannelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserCareTeamChannelQuery, UserCareTeamChannelQueryVariables>(
    UserCareTeamChannelDocument,
    options
  );
}
export type UserCareTeamChannelQueryHookResult = ReturnType<typeof useUserCareTeamChannelQuery>;
export type UserCareTeamChannelLazyQueryHookResult = ReturnType<
  typeof useUserCareTeamChannelLazyQuery
>;
export type UserCareTeamChannelQueryResult = ApolloReactCommon.QueryResult<
  UserCareTeamChannelQuery,
  UserCareTeamChannelQueryVariables
>;
export const MessageUsersDocument = gql`
  query messageUsers($providerId: Float, $privateChannels: Boolean!, $careTeamChannels: Boolean!) {
    careTeamChannels(providerId: $providerId) @include(if: $careTeamChannels) {
      id
      user {
        ...userDetail
      }
      channel {
        ...listChannel
      }
    }
    privateChannels(providerId: $providerId) @include(if: $privateChannels) {
      id
      user {
        ...userDetail
      }
      channel {
        ...listChannel
      }
    }
  }
  ${UserDetailFragmentDoc}
  ${ListChannelFragmentDoc}
`;

/**
 * __useMessageUsersQuery__
 *
 * To run a query within a React component, call `useMessageUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageUsersQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      privateChannels: // value for 'privateChannels'
 *      careTeamChannels: // value for 'careTeamChannels'
 *   },
 * });
 */
export function useMessageUsersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<MessageUsersQuery, MessageUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MessageUsersQuery, MessageUsersQueryVariables>(
    MessageUsersDocument,
    options
  );
}
export function useMessageUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MessageUsersQuery, MessageUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MessageUsersQuery, MessageUsersQueryVariables>(
    MessageUsersDocument,
    options
  );
}
export type MessageUsersQueryHookResult = ReturnType<typeof useMessageUsersQuery>;
export type MessageUsersLazyQueryHookResult = ReturnType<typeof useMessageUsersLazyQuery>;
export type MessageUsersQueryResult = ApolloReactCommon.QueryResult<
  MessageUsersQuery,
  MessageUsersQueryVariables
>;
export const SendCareTeamMessageDocument = gql`
  mutation sendCareTeamMessage($userId: Float!, $content: String!) {
    sendCareTeamMessage(userId: $userId, content: $content) {
      ...message
    }
  }
  ${MessageFragmentDoc}
`;
export type SendCareTeamMessageMutationFn = ApolloReactCommon.MutationFunction<
  SendCareTeamMessageMutation,
  SendCareTeamMessageMutationVariables
>;

/**
 * __useSendCareTeamMessageMutation__
 *
 * To run a mutation, you first call `useSendCareTeamMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCareTeamMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCareTeamMessageMutation, { data, loading, error }] = useSendCareTeamMessageMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useSendCareTeamMessageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendCareTeamMessageMutation,
    SendCareTeamMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SendCareTeamMessageMutation,
    SendCareTeamMessageMutationVariables
  >(SendCareTeamMessageDocument, options);
}
export type SendCareTeamMessageMutationHookResult = ReturnType<
  typeof useSendCareTeamMessageMutation
>;
export type SendCareTeamMessageMutationResult =
  ApolloReactCommon.MutationResult<SendCareTeamMessageMutation>;
export type SendCareTeamMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendCareTeamMessageMutation,
  SendCareTeamMessageMutationVariables
>;
export const SendMessageToDocument = gql`
  mutation sendMessageTo($userId: Float!, $content: String!, $attachmentIds: [Float!]) {
    providerMessagePatient(userId: $userId, content: $content, attachmentIds: $attachmentIds) {
      ...message
      attachments {
        ...upload
      }
    }
  }
  ${MessageFragmentDoc}
  ${UploadFragmentDoc}
`;
export type SendMessageToMutationFn = ApolloReactCommon.MutationFunction<
  SendMessageToMutation,
  SendMessageToMutationVariables
>;

/**
 * __useSendMessageToMutation__
 *
 * To run a mutation, you first call `useSendMessageToMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageToMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageToMutation, { data, loading, error }] = useSendMessageToMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      content: // value for 'content'
 *      attachmentIds: // value for 'attachmentIds'
 *   },
 * });
 */
export function useSendMessageToMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendMessageToMutation,
    SendMessageToMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SendMessageToMutation, SendMessageToMutationVariables>(
    SendMessageToDocument,
    options
  );
}
export type SendMessageToMutationHookResult = ReturnType<typeof useSendMessageToMutation>;
export type SendMessageToMutationResult = ApolloReactCommon.MutationResult<SendMessageToMutation>;
export type SendMessageToMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendMessageToMutation,
  SendMessageToMutationVariables
>;
export const MarkPrivateChannelsDocument = gql`
  mutation markPrivateChannels($channels: [Float!]!) {
    providerMarkPrivateChannelsAsRead(channels: $channels)
  }
`;
export type MarkPrivateChannelsMutationFn = ApolloReactCommon.MutationFunction<
  MarkPrivateChannelsMutation,
  MarkPrivateChannelsMutationVariables
>;

/**
 * __useMarkPrivateChannelsMutation__
 *
 * To run a mutation, you first call `useMarkPrivateChannelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkPrivateChannelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markPrivateChannelsMutation, { data, loading, error }] = useMarkPrivateChannelsMutation({
 *   variables: {
 *      channels: // value for 'channels'
 *   },
 * });
 */
export function useMarkPrivateChannelsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkPrivateChannelsMutation,
    MarkPrivateChannelsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MarkPrivateChannelsMutation,
    MarkPrivateChannelsMutationVariables
  >(MarkPrivateChannelsDocument, options);
}
export type MarkPrivateChannelsMutationHookResult = ReturnType<
  typeof useMarkPrivateChannelsMutation
>;
export type MarkPrivateChannelsMutationResult =
  ApolloReactCommon.MutationResult<MarkPrivateChannelsMutation>;
export type MarkPrivateChannelsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MarkPrivateChannelsMutation,
  MarkPrivateChannelsMutationVariables
>;
export const MarkCareTeamChannelsDocument = gql`
  mutation markCareTeamChannels($channels: [Float!]!) {
    markCareTeamChannelsAsRead(channels: $channels)
  }
`;
export type MarkCareTeamChannelsMutationFn = ApolloReactCommon.MutationFunction<
  MarkCareTeamChannelsMutation,
  MarkCareTeamChannelsMutationVariables
>;

/**
 * __useMarkCareTeamChannelsMutation__
 *
 * To run a mutation, you first call `useMarkCareTeamChannelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkCareTeamChannelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markCareTeamChannelsMutation, { data, loading, error }] = useMarkCareTeamChannelsMutation({
 *   variables: {
 *      channels: // value for 'channels'
 *   },
 * });
 */
export function useMarkCareTeamChannelsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkCareTeamChannelsMutation,
    MarkCareTeamChannelsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MarkCareTeamChannelsMutation,
    MarkCareTeamChannelsMutationVariables
  >(MarkCareTeamChannelsDocument, options);
}
export type MarkCareTeamChannelsMutationHookResult = ReturnType<
  typeof useMarkCareTeamChannelsMutation
>;
export type MarkCareTeamChannelsMutationResult =
  ApolloReactCommon.MutationResult<MarkCareTeamChannelsMutation>;
export type MarkCareTeamChannelsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MarkCareTeamChannelsMutation,
  MarkCareTeamChannelsMutationVariables
>;
export const MinorConsentStatusForUserDocument = gql`
  query minorConsentStatusForUser($userId: Int!) {
    minorConsentStatusForUser(userId: $userId) {
      status
    }
  }
`;

/**
 * __useMinorConsentStatusForUserQuery__
 *
 * To run a query within a React component, call `useMinorConsentStatusForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useMinorConsentStatusForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMinorConsentStatusForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMinorConsentStatusForUserQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    MinorConsentStatusForUserQuery,
    MinorConsentStatusForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    MinorConsentStatusForUserQuery,
    MinorConsentStatusForUserQueryVariables
  >(MinorConsentStatusForUserDocument, options);
}
export function useMinorConsentStatusForUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MinorConsentStatusForUserQuery,
    MinorConsentStatusForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    MinorConsentStatusForUserQuery,
    MinorConsentStatusForUserQueryVariables
  >(MinorConsentStatusForUserDocument, options);
}
export type MinorConsentStatusForUserQueryHookResult = ReturnType<
  typeof useMinorConsentStatusForUserQuery
>;
export type MinorConsentStatusForUserLazyQueryHookResult = ReturnType<
  typeof useMinorConsentStatusForUserLazyQuery
>;
export type MinorConsentStatusForUserQueryResult = ApolloReactCommon.QueryResult<
  MinorConsentStatusForUserQuery,
  MinorConsentStatusForUserQueryVariables
>;
export const MinorConsentApproveUserDocument = gql`
  mutation minorConsentApproveUser($userId: Int!) {
    minorConsentApproveUser(userId: $userId)
  }
`;
export type MinorConsentApproveUserMutationFn = ApolloReactCommon.MutationFunction<
  MinorConsentApproveUserMutation,
  MinorConsentApproveUserMutationVariables
>;

/**
 * __useMinorConsentApproveUserMutation__
 *
 * To run a mutation, you first call `useMinorConsentApproveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMinorConsentApproveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [minorConsentApproveUserMutation, { data, loading, error }] = useMinorConsentApproveUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMinorConsentApproveUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MinorConsentApproveUserMutation,
    MinorConsentApproveUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MinorConsentApproveUserMutation,
    MinorConsentApproveUserMutationVariables
  >(MinorConsentApproveUserDocument, options);
}
export type MinorConsentApproveUserMutationHookResult = ReturnType<
  typeof useMinorConsentApproveUserMutation
>;
export type MinorConsentApproveUserMutationResult =
  ApolloReactCommon.MutationResult<MinorConsentApproveUserMutation>;
export type MinorConsentApproveUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MinorConsentApproveUserMutation,
  MinorConsentApproveUserMutationVariables
>;
export const MinorConsentOverrideUserDocument = gql`
  mutation minorConsentOverrideUser($userId: Int!, $birthDate: UserBirthdate!) {
    minorConsentOverrideUser(userId: $userId, birthDate: $birthDate)
  }
`;
export type MinorConsentOverrideUserMutationFn = ApolloReactCommon.MutationFunction<
  MinorConsentOverrideUserMutation,
  MinorConsentOverrideUserMutationVariables
>;

/**
 * __useMinorConsentOverrideUserMutation__
 *
 * To run a mutation, you first call `useMinorConsentOverrideUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMinorConsentOverrideUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [minorConsentOverrideUserMutation, { data, loading, error }] = useMinorConsentOverrideUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      birthDate: // value for 'birthDate'
 *   },
 * });
 */
export function useMinorConsentOverrideUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MinorConsentOverrideUserMutation,
    MinorConsentOverrideUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MinorConsentOverrideUserMutation,
    MinorConsentOverrideUserMutationVariables
  >(MinorConsentOverrideUserDocument, options);
}
export type MinorConsentOverrideUserMutationHookResult = ReturnType<
  typeof useMinorConsentOverrideUserMutation
>;
export type MinorConsentOverrideUserMutationResult =
  ApolloReactCommon.MutationResult<MinorConsentOverrideUserMutation>;
export type MinorConsentOverrideUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MinorConsentOverrideUserMutation,
  MinorConsentOverrideUserMutationVariables
>;
export const AdminAuthenticateDocument = gql`
  mutation adminAuthenticate($password: String!, $email: String!, $deviceToken: String) {
    adminAuthenticate(password: $password, email: $email, deviceToken: $deviceToken) {
      passwordCorrect
      authToken
      firstFactorResult {
        firstFactorToken
        phoneLastFour
      }
      twoFactorNeeded
    }
  }
`;
export type AdminAuthenticateMutationFn = ApolloReactCommon.MutationFunction<
  AdminAuthenticateMutation,
  AdminAuthenticateMutationVariables
>;

/**
 * __useAdminAuthenticateMutation__
 *
 * To run a mutation, you first call `useAdminAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAuthenticateMutation, { data, loading, error }] = useAdminAuthenticateMutation({
 *   variables: {
 *      password: // value for 'password'
 *      email: // value for 'email'
 *      deviceToken: // value for 'deviceToken'
 *   },
 * });
 */
export function useAdminAuthenticateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminAuthenticateMutation,
    AdminAuthenticateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminAuthenticateMutation,
    AdminAuthenticateMutationVariables
  >(AdminAuthenticateDocument, options);
}
export type AdminAuthenticateMutationHookResult = ReturnType<typeof useAdminAuthenticateMutation>;
export type AdminAuthenticateMutationResult =
  ApolloReactCommon.MutationResult<AdminAuthenticateMutation>;
export type AdminAuthenticateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminAuthenticateMutation,
  AdminAuthenticateMutationVariables
>;
export const ResendTwoFactorCodeDocument = gql`
  mutation resendTwoFactorCode($firstFactorToken: String!) {
    resendTwoFactorCode(firstFactorToken: $firstFactorToken)
  }
`;
export type ResendTwoFactorCodeMutationFn = ApolloReactCommon.MutationFunction<
  ResendTwoFactorCodeMutation,
  ResendTwoFactorCodeMutationVariables
>;

/**
 * __useResendTwoFactorCodeMutation__
 *
 * To run a mutation, you first call `useResendTwoFactorCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendTwoFactorCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendTwoFactorCodeMutation, { data, loading, error }] = useResendTwoFactorCodeMutation({
 *   variables: {
 *      firstFactorToken: // value for 'firstFactorToken'
 *   },
 * });
 */
export function useResendTwoFactorCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResendTwoFactorCodeMutation,
    ResendTwoFactorCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ResendTwoFactorCodeMutation,
    ResendTwoFactorCodeMutationVariables
  >(ResendTwoFactorCodeDocument, options);
}
export type ResendTwoFactorCodeMutationHookResult = ReturnType<
  typeof useResendTwoFactorCodeMutation
>;
export type ResendTwoFactorCodeMutationResult =
  ApolloReactCommon.MutationResult<ResendTwoFactorCodeMutation>;
export type ResendTwoFactorCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResendTwoFactorCodeMutation,
  ResendTwoFactorCodeMutationVariables
>;
export const AdminEnterSecondFactorDocument = gql`
  mutation adminEnterSecondFactor($firstFactorToken: String!, $code: String!) {
    adminEnterSecondFactor(firstFactorToken: $firstFactorToken, code: $code) {
      authToken
      codeValid
      deviceToken
    }
  }
`;
export type AdminEnterSecondFactorMutationFn = ApolloReactCommon.MutationFunction<
  AdminEnterSecondFactorMutation,
  AdminEnterSecondFactorMutationVariables
>;

/**
 * __useAdminEnterSecondFactorMutation__
 *
 * To run a mutation, you first call `useAdminEnterSecondFactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEnterSecondFactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEnterSecondFactorMutation, { data, loading, error }] = useAdminEnterSecondFactorMutation({
 *   variables: {
 *      firstFactorToken: // value for 'firstFactorToken'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAdminEnterSecondFactorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminEnterSecondFactorMutation,
    AdminEnterSecondFactorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminEnterSecondFactorMutation,
    AdminEnterSecondFactorMutationVariables
  >(AdminEnterSecondFactorDocument, options);
}
export type AdminEnterSecondFactorMutationHookResult = ReturnType<
  typeof useAdminEnterSecondFactorMutation
>;
export type AdminEnterSecondFactorMutationResult =
  ApolloReactCommon.MutationResult<AdminEnterSecondFactorMutation>;
export type AdminEnterSecondFactorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminEnterSecondFactorMutation,
  AdminEnterSecondFactorMutationVariables
>;
export const ForgotProviderPasswordDocument = gql`
  mutation forgotProviderPassword($email: String!) {
    forgotProviderPassword(email: $email)
  }
`;
export type ForgotProviderPasswordMutationFn = ApolloReactCommon.MutationFunction<
  ForgotProviderPasswordMutation,
  ForgotProviderPasswordMutationVariables
>;

/**
 * __useForgotProviderPasswordMutation__
 *
 * To run a mutation, you first call `useForgotProviderPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotProviderPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotProviderPasswordMutation, { data, loading, error }] = useForgotProviderPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotProviderPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ForgotProviderPasswordMutation,
    ForgotProviderPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ForgotProviderPasswordMutation,
    ForgotProviderPasswordMutationVariables
  >(ForgotProviderPasswordDocument, options);
}
export type ForgotProviderPasswordMutationHookResult = ReturnType<
  typeof useForgotProviderPasswordMutation
>;
export type ForgotProviderPasswordMutationResult =
  ApolloReactCommon.MutationResult<ForgotProviderPasswordMutation>;
export type ForgotProviderPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ForgotProviderPasswordMutation,
  ForgotProviderPasswordMutationVariables
>;
export const ChangeProviderPasswordDocument = gql`
  mutation changeProviderPassword($password: String!) {
    changeProviderPassword(password: $password)
  }
`;
export type ChangeProviderPasswordMutationFn = ApolloReactCommon.MutationFunction<
  ChangeProviderPasswordMutation,
  ChangeProviderPasswordMutationVariables
>;

/**
 * __useChangeProviderPasswordMutation__
 *
 * To run a mutation, you first call `useChangeProviderPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProviderPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProviderPasswordMutation, { data, loading, error }] = useChangeProviderPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangeProviderPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangeProviderPasswordMutation,
    ChangeProviderPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ChangeProviderPasswordMutation,
    ChangeProviderPasswordMutationVariables
  >(ChangeProviderPasswordDocument, options);
}
export type ChangeProviderPasswordMutationHookResult = ReturnType<
  typeof useChangeProviderPasswordMutation
>;
export type ChangeProviderPasswordMutationResult =
  ApolloReactCommon.MutationResult<ChangeProviderPasswordMutation>;
export type ChangeProviderPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangeProviderPasswordMutation,
  ChangeProviderPasswordMutationVariables
>;
export const AdminBeginUploadDocument = gql`
  mutation adminBeginUpload($contentType: String!, $userId: Float) {
    adminBeginUpload(contentType: $contentType, userId: $userId) {
      url
      key
      fields
    }
  }
`;
export type AdminBeginUploadMutationFn = ApolloReactCommon.MutationFunction<
  AdminBeginUploadMutation,
  AdminBeginUploadMutationVariables
>;

/**
 * __useAdminBeginUploadMutation__
 *
 * To run a mutation, you first call `useAdminBeginUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminBeginUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminBeginUploadMutation, { data, loading, error }] = useAdminBeginUploadMutation({
 *   variables: {
 *      contentType: // value for 'contentType'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminBeginUploadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminBeginUploadMutation,
    AdminBeginUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AdminBeginUploadMutation, AdminBeginUploadMutationVariables>(
    AdminBeginUploadDocument,
    options
  );
}
export type AdminBeginUploadMutationHookResult = ReturnType<typeof useAdminBeginUploadMutation>;
export type AdminBeginUploadMutationResult =
  ApolloReactCommon.MutationResult<AdminBeginUploadMutation>;
export type AdminBeginUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminBeginUploadMutation,
  AdminBeginUploadMutationVariables
>;
export const AdminCompleteProviderPhotoUploadDocument = gql`
  mutation adminCompleteProviderPhotoUpload($key: String, $providerId: Float!) {
    adminCompleteProviderPhotoUpload(key: $key, providerId: $providerId) {
      id
    }
  }
`;
export type AdminCompleteProviderPhotoUploadMutationFn = ApolloReactCommon.MutationFunction<
  AdminCompleteProviderPhotoUploadMutation,
  AdminCompleteProviderPhotoUploadMutationVariables
>;

/**
 * __useAdminCompleteProviderPhotoUploadMutation__
 *
 * To run a mutation, you first call `useAdminCompleteProviderPhotoUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCompleteProviderPhotoUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCompleteProviderPhotoUploadMutation, { data, loading, error }] = useAdminCompleteProviderPhotoUploadMutation({
 *   variables: {
 *      key: // value for 'key'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useAdminCompleteProviderPhotoUploadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminCompleteProviderPhotoUploadMutation,
    AdminCompleteProviderPhotoUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminCompleteProviderPhotoUploadMutation,
    AdminCompleteProviderPhotoUploadMutationVariables
  >(AdminCompleteProviderPhotoUploadDocument, options);
}
export type AdminCompleteProviderPhotoUploadMutationHookResult = ReturnType<
  typeof useAdminCompleteProviderPhotoUploadMutation
>;
export type AdminCompleteProviderPhotoUploadMutationResult =
  ApolloReactCommon.MutationResult<AdminCompleteProviderPhotoUploadMutation>;
export type AdminCompleteProviderPhotoUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminCompleteProviderPhotoUploadMutation,
  AdminCompleteProviderPhotoUploadMutationVariables
>;
export const AdminCancelAppointmentDocument = gql`
  mutation adminCancelAppointment($id: Float!, $outOfPolicy: Boolean) {
    adminCancelAppointment(id: $id, outOfPolicy: $outOfPolicy) {
      id
      status
    }
  }
`;
export type AdminCancelAppointmentMutationFn = ApolloReactCommon.MutationFunction<
  AdminCancelAppointmentMutation,
  AdminCancelAppointmentMutationVariables
>;

/**
 * __useAdminCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useAdminCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCancelAppointmentMutation, { data, loading, error }] = useAdminCancelAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      outOfPolicy: // value for 'outOfPolicy'
 *   },
 * });
 */
export function useAdminCancelAppointmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminCancelAppointmentMutation,
    AdminCancelAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminCancelAppointmentMutation,
    AdminCancelAppointmentMutationVariables
  >(AdminCancelAppointmentDocument, options);
}
export type AdminCancelAppointmentMutationHookResult = ReturnType<
  typeof useAdminCancelAppointmentMutation
>;
export type AdminCancelAppointmentMutationResult =
  ApolloReactCommon.MutationResult<AdminCancelAppointmentMutation>;
export type AdminCancelAppointmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminCancelAppointmentMutation,
  AdminCancelAppointmentMutationVariables
>;
export const AdminMarkUpcomingDocument = gql`
  mutation adminMarkUpcoming($id: Float!) {
    adminMarkUpcoming(id: $id)
  }
`;
export type AdminMarkUpcomingMutationFn = ApolloReactCommon.MutationFunction<
  AdminMarkUpcomingMutation,
  AdminMarkUpcomingMutationVariables
>;

/**
 * __useAdminMarkUpcomingMutation__
 *
 * To run a mutation, you first call `useAdminMarkUpcomingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminMarkUpcomingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminMarkUpcomingMutation, { data, loading, error }] = useAdminMarkUpcomingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminMarkUpcomingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminMarkUpcomingMutation,
    AdminMarkUpcomingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminMarkUpcomingMutation,
    AdminMarkUpcomingMutationVariables
  >(AdminMarkUpcomingDocument, options);
}
export type AdminMarkUpcomingMutationHookResult = ReturnType<typeof useAdminMarkUpcomingMutation>;
export type AdminMarkUpcomingMutationResult =
  ApolloReactCommon.MutationResult<AdminMarkUpcomingMutation>;
export type AdminMarkUpcomingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminMarkUpcomingMutation,
  AdminMarkUpcomingMutationVariables
>;
export const AdminRescheduleAppointmentDocument = gql`
  mutation adminRescheduleAppointment(
    $id: Float!
    $start: DateTime!
    $end: DateTime!
    $outOfPolicy: Boolean
  ) {
    adminRescheduleAppointment(id: $id, start: $start, end: $end, outOfPolicy: $outOfPolicy) {
      id
      status
    }
  }
`;
export type AdminRescheduleAppointmentMutationFn = ApolloReactCommon.MutationFunction<
  AdminRescheduleAppointmentMutation,
  AdminRescheduleAppointmentMutationVariables
>;

/**
 * __useAdminRescheduleAppointmentMutation__
 *
 * To run a mutation, you first call `useAdminRescheduleAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRescheduleAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRescheduleAppointmentMutation, { data, loading, error }] = useAdminRescheduleAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      outOfPolicy: // value for 'outOfPolicy'
 *   },
 * });
 */
export function useAdminRescheduleAppointmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminRescheduleAppointmentMutation,
    AdminRescheduleAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminRescheduleAppointmentMutation,
    AdminRescheduleAppointmentMutationVariables
  >(AdminRescheduleAppointmentDocument, options);
}
export type AdminRescheduleAppointmentMutationHookResult = ReturnType<
  typeof useAdminRescheduleAppointmentMutation
>;
export type AdminRescheduleAppointmentMutationResult =
  ApolloReactCommon.MutationResult<AdminRescheduleAppointmentMutation>;
export type AdminRescheduleAppointmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminRescheduleAppointmentMutation,
  AdminRescheduleAppointmentMutationVariables
>;
export const AdminCreateUserDocument = gql`
  mutation adminCreateUser($input: CreateUser!) {
    adminCreateUser(input: $input) {
      id
    }
  }
`;
export type AdminCreateUserMutationFn = ApolloReactCommon.MutationFunction<
  AdminCreateUserMutation,
  AdminCreateUserMutationVariables
>;

/**
 * __useAdminCreateUserMutation__
 *
 * To run a mutation, you first call `useAdminCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateUserMutation, { data, loading, error }] = useAdminCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminCreateUserMutation,
    AdminCreateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AdminCreateUserMutation, AdminCreateUserMutationVariables>(
    AdminCreateUserDocument,
    options
  );
}
export type AdminCreateUserMutationHookResult = ReturnType<typeof useAdminCreateUserMutation>;
export type AdminCreateUserMutationResult =
  ApolloReactCommon.MutationResult<AdminCreateUserMutation>;
export type AdminCreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminCreateUserMutation,
  AdminCreateUserMutationVariables
>;
export const UpsertUserEmergencyContactDocument = gql`
  mutation upsertUserEmergencyContact(
    $id: Float
    $userId: Float!
    $name: String!
    $relationship: String!
    $phoneNumber: String!
    $primary: Boolean
  ) {
    upsertEmergencyContact(
      id: $id
      userId: $userId
      name: $name
      relationship: $relationship
      phoneNumber: $phoneNumber
      primary: $primary
    ) {
      id
    }
  }
`;
export type UpsertUserEmergencyContactMutationFn = ApolloReactCommon.MutationFunction<
  UpsertUserEmergencyContactMutation,
  UpsertUserEmergencyContactMutationVariables
>;

/**
 * __useUpsertUserEmergencyContactMutation__
 *
 * To run a mutation, you first call `useUpsertUserEmergencyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserEmergencyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserEmergencyContactMutation, { data, loading, error }] = useUpsertUserEmergencyContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *      name: // value for 'name'
 *      relationship: // value for 'relationship'
 *      phoneNumber: // value for 'phoneNumber'
 *      primary: // value for 'primary'
 *   },
 * });
 */
export function useUpsertUserEmergencyContactMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertUserEmergencyContactMutation,
    UpsertUserEmergencyContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpsertUserEmergencyContactMutation,
    UpsertUserEmergencyContactMutationVariables
  >(UpsertUserEmergencyContactDocument, options);
}
export type UpsertUserEmergencyContactMutationHookResult = ReturnType<
  typeof useUpsertUserEmergencyContactMutation
>;
export type UpsertUserEmergencyContactMutationResult =
  ApolloReactCommon.MutationResult<UpsertUserEmergencyContactMutation>;
export type UpsertUserEmergencyContactMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpsertUserEmergencyContactMutation,
  UpsertUserEmergencyContactMutationVariables
>;
export const DeleteEmergencyContactDocument = gql`
  mutation deleteEmergencyContact($id: Float!) {
    deleteEmergencyContact(id: $id) {
      id
    }
  }
`;
export type DeleteEmergencyContactMutationFn = ApolloReactCommon.MutationFunction<
  DeleteEmergencyContactMutation,
  DeleteEmergencyContactMutationVariables
>;

/**
 * __useDeleteEmergencyContactMutation__
 *
 * To run a mutation, you first call `useDeleteEmergencyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmergencyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmergencyContactMutation, { data, loading, error }] = useDeleteEmergencyContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmergencyContactMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEmergencyContactMutation,
    DeleteEmergencyContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteEmergencyContactMutation,
    DeleteEmergencyContactMutationVariables
  >(DeleteEmergencyContactDocument, options);
}
export type DeleteEmergencyContactMutationHookResult = ReturnType<
  typeof useDeleteEmergencyContactMutation
>;
export type DeleteEmergencyContactMutationResult =
  ApolloReactCommon.MutationResult<DeleteEmergencyContactMutation>;
export type DeleteEmergencyContactMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteEmergencyContactMutation,
  DeleteEmergencyContactMutationVariables
>;
export const SwapPrimaryEmergencyContactDocument = gql`
  mutation swapPrimaryEmergencyContact($id: Float!, $userId: Float!) {
    swapPrimaryEmergencyContact(id: $id, userId: $userId) {
      id
    }
  }
`;
export type SwapPrimaryEmergencyContactMutationFn = ApolloReactCommon.MutationFunction<
  SwapPrimaryEmergencyContactMutation,
  SwapPrimaryEmergencyContactMutationVariables
>;

/**
 * __useSwapPrimaryEmergencyContactMutation__
 *
 * To run a mutation, you first call `useSwapPrimaryEmergencyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwapPrimaryEmergencyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [swapPrimaryEmergencyContactMutation, { data, loading, error }] = useSwapPrimaryEmergencyContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSwapPrimaryEmergencyContactMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SwapPrimaryEmergencyContactMutation,
    SwapPrimaryEmergencyContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SwapPrimaryEmergencyContactMutation,
    SwapPrimaryEmergencyContactMutationVariables
  >(SwapPrimaryEmergencyContactDocument, options);
}
export type SwapPrimaryEmergencyContactMutationHookResult = ReturnType<
  typeof useSwapPrimaryEmergencyContactMutation
>;
export type SwapPrimaryEmergencyContactMutationResult =
  ApolloReactCommon.MutationResult<SwapPrimaryEmergencyContactMutation>;
export type SwapPrimaryEmergencyContactMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SwapPrimaryEmergencyContactMutation,
  SwapPrimaryEmergencyContactMutationVariables
>;
export const AdminCreateStaffDocument = gql`
  mutation adminCreateStaff($input: CreateStaff!) {
    adminCreateStaff(input: $input) {
      id
    }
  }
`;
export type AdminCreateStaffMutationFn = ApolloReactCommon.MutationFunction<
  AdminCreateStaffMutation,
  AdminCreateStaffMutationVariables
>;

/**
 * __useAdminCreateStaffMutation__
 *
 * To run a mutation, you first call `useAdminCreateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateStaffMutation, { data, loading, error }] = useAdminCreateStaffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateStaffMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminCreateStaffMutation,
    AdminCreateStaffMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AdminCreateStaffMutation, AdminCreateStaffMutationVariables>(
    AdminCreateStaffDocument,
    options
  );
}
export type AdminCreateStaffMutationHookResult = ReturnType<typeof useAdminCreateStaffMutation>;
export type AdminCreateStaffMutationResult =
  ApolloReactCommon.MutationResult<AdminCreateStaffMutation>;
export type AdminCreateStaffMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminCreateStaffMutation,
  AdminCreateStaffMutationVariables
>;
export const AdminUpdateProviderDocument = gql`
  mutation adminUpdateProvider($input: UpdateProvider!) {
    adminUpdateProvider(input: $input) {
      id
    }
  }
`;
export type AdminUpdateProviderMutationFn = ApolloReactCommon.MutationFunction<
  AdminUpdateProviderMutation,
  AdminUpdateProviderMutationVariables
>;

/**
 * __useAdminUpdateProviderMutation__
 *
 * To run a mutation, you first call `useAdminUpdateProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateProviderMutation, { data, loading, error }] = useAdminUpdateProviderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateProviderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminUpdateProviderMutation,
    AdminUpdateProviderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminUpdateProviderMutation,
    AdminUpdateProviderMutationVariables
  >(AdminUpdateProviderDocument, options);
}
export type AdminUpdateProviderMutationHookResult = ReturnType<
  typeof useAdminUpdateProviderMutation
>;
export type AdminUpdateProviderMutationResult =
  ApolloReactCommon.MutationResult<AdminUpdateProviderMutation>;
export type AdminUpdateProviderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminUpdateProviderMutation,
  AdminUpdateProviderMutationVariables
>;
export const EditSafetyPlanDocument = gql`
  mutation editSafetyPlan($userId: Float!, $plan: SafetyPlanInput!) {
    adminEditSafetyPlan(userId: $userId, plan: $plan) {
      data {
        ...safetyPlanData
      }
    }
  }
  ${SafetyPlanDataFragmentDoc}
`;
export type EditSafetyPlanMutationFn = ApolloReactCommon.MutationFunction<
  EditSafetyPlanMutation,
  EditSafetyPlanMutationVariables
>;

/**
 * __useEditSafetyPlanMutation__
 *
 * To run a mutation, you first call `useEditSafetyPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSafetyPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSafetyPlanMutation, { data, loading, error }] = useEditSafetyPlanMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      plan: // value for 'plan'
 *   },
 * });
 */
export function useEditSafetyPlanMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EditSafetyPlanMutation,
    EditSafetyPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<EditSafetyPlanMutation, EditSafetyPlanMutationVariables>(
    EditSafetyPlanDocument,
    options
  );
}
export type EditSafetyPlanMutationHookResult = ReturnType<typeof useEditSafetyPlanMutation>;
export type EditSafetyPlanMutationResult = ApolloReactCommon.MutationResult<EditSafetyPlanMutation>;
export type EditSafetyPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EditSafetyPlanMutation,
  EditSafetyPlanMutationVariables
>;
export const ActivateSafetyPlanDocument = gql`
  mutation activateSafetyPlan($userId: Float!) {
    activateSafetyPlan(userId: $userId) {
      id
      data {
        ...safetyPlanData
      }
    }
  }
  ${SafetyPlanDataFragmentDoc}
`;
export type ActivateSafetyPlanMutationFn = ApolloReactCommon.MutationFunction<
  ActivateSafetyPlanMutation,
  ActivateSafetyPlanMutationVariables
>;

/**
 * __useActivateSafetyPlanMutation__
 *
 * To run a mutation, you first call `useActivateSafetyPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSafetyPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSafetyPlanMutation, { data, loading, error }] = useActivateSafetyPlanMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useActivateSafetyPlanMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ActivateSafetyPlanMutation,
    ActivateSafetyPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ActivateSafetyPlanMutation,
    ActivateSafetyPlanMutationVariables
  >(ActivateSafetyPlanDocument, options);
}
export type ActivateSafetyPlanMutationHookResult = ReturnType<typeof useActivateSafetyPlanMutation>;
export type ActivateSafetyPlanMutationResult =
  ApolloReactCommon.MutationResult<ActivateSafetyPlanMutation>;
export type ActivateSafetyPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ActivateSafetyPlanMutation,
  ActivateSafetyPlanMutationVariables
>;
export const AdminScheduleAppointmentDocument = gql`
  mutation adminScheduleAppointment(
    $start: DateTime!
    $end: DateTime!
    $userId: Float!
    $providerId: Float!
    $appointmentType: String!
    $organizationId: Float
    $isFeeForServiceTime: Boolean
  ) {
    adminScheduleAppointment(
      start: $start
      end: $end
      userId: $userId
      providerId: $providerId
      appointmentType: $appointmentType
      organizationId: $organizationId
      isFeeForServiceTime: $isFeeForServiceTime
    ) {
      id
      description
      startTime
      endTime
    }
  }
`;
export type AdminScheduleAppointmentMutationFn = ApolloReactCommon.MutationFunction<
  AdminScheduleAppointmentMutation,
  AdminScheduleAppointmentMutationVariables
>;

/**
 * __useAdminScheduleAppointmentMutation__
 *
 * To run a mutation, you first call `useAdminScheduleAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminScheduleAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminScheduleAppointmentMutation, { data, loading, error }] = useAdminScheduleAppointmentMutation({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      userId: // value for 'userId'
 *      providerId: // value for 'providerId'
 *      appointmentType: // value for 'appointmentType'
 *      organizationId: // value for 'organizationId'
 *      isFeeForServiceTime: // value for 'isFeeForServiceTime'
 *   },
 * });
 */
export function useAdminScheduleAppointmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminScheduleAppointmentMutation,
    AdminScheduleAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminScheduleAppointmentMutation,
    AdminScheduleAppointmentMutationVariables
  >(AdminScheduleAppointmentDocument, options);
}
export type AdminScheduleAppointmentMutationHookResult = ReturnType<
  typeof useAdminScheduleAppointmentMutation
>;
export type AdminScheduleAppointmentMutationResult =
  ApolloReactCommon.MutationResult<AdminScheduleAppointmentMutation>;
export type AdminScheduleAppointmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminScheduleAppointmentMutation,
  AdminScheduleAppointmentMutationVariables
>;
export const ChangePasswordAndAcceptTosDocument = gql`
  mutation changePasswordAndAcceptTos($password: String!) {
    changeProviderPassword(password: $password)
    adminAcceptTermsOfService
  }
`;
export type ChangePasswordAndAcceptTosMutationFn = ApolloReactCommon.MutationFunction<
  ChangePasswordAndAcceptTosMutation,
  ChangePasswordAndAcceptTosMutationVariables
>;

/**
 * __useChangePasswordAndAcceptTosMutation__
 *
 * To run a mutation, you first call `useChangePasswordAndAcceptTosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordAndAcceptTosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordAndAcceptTosMutation, { data, loading, error }] = useChangePasswordAndAcceptTosMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangePasswordAndAcceptTosMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangePasswordAndAcceptTosMutation,
    ChangePasswordAndAcceptTosMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ChangePasswordAndAcceptTosMutation,
    ChangePasswordAndAcceptTosMutationVariables
  >(ChangePasswordAndAcceptTosDocument, options);
}
export type ChangePasswordAndAcceptTosMutationHookResult = ReturnType<
  typeof useChangePasswordAndAcceptTosMutation
>;
export type ChangePasswordAndAcceptTosMutationResult =
  ApolloReactCommon.MutationResult<ChangePasswordAndAcceptTosMutation>;
export type ChangePasswordAndAcceptTosMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangePasswordAndAcceptTosMutation,
  ChangePasswordAndAcceptTosMutationVariables
>;
export const AcceptTosDocument = gql`
  mutation acceptTos {
    adminAcceptTermsOfService
  }
`;
export type AcceptTosMutationFn = ApolloReactCommon.MutationFunction<
  AcceptTosMutation,
  AcceptTosMutationVariables
>;

/**
 * __useAcceptTosMutation__
 *
 * To run a mutation, you first call `useAcceptTosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTosMutation, { data, loading, error }] = useAcceptTosMutation({
 *   variables: {
 *   },
 * });
 */
export function useAcceptTosMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptTosMutation, AcceptTosMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AcceptTosMutation, AcceptTosMutationVariables>(
    AcceptTosDocument,
    options
  );
}
export type AcceptTosMutationHookResult = ReturnType<typeof useAcceptTosMutation>;
export type AcceptTosMutationResult = ApolloReactCommon.MutationResult<AcceptTosMutation>;
export type AcceptTosMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AcceptTosMutation,
  AcceptTosMutationVariables
>;
export const VerifyUserDocument = gql`
  mutation verifyUser($id: Float!, $verify: Boolean!) {
    setUserVerified(id: $id, verify: $verify)
  }
`;
export type VerifyUserMutationFn = ApolloReactCommon.MutationFunction<
  VerifyUserMutation,
  VerifyUserMutationVariables
>;

/**
 * __useVerifyUserMutation__
 *
 * To run a mutation, you first call `useVerifyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserMutation, { data, loading, error }] = useVerifyUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      verify: // value for 'verify'
 *   },
 * });
 */
export function useVerifyUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    VerifyUserMutation,
    VerifyUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<VerifyUserMutation, VerifyUserMutationVariables>(
    VerifyUserDocument,
    options
  );
}
export type VerifyUserMutationHookResult = ReturnType<typeof useVerifyUserMutation>;
export type VerifyUserMutationResult = ApolloReactCommon.MutationResult<VerifyUserMutation>;
export type VerifyUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  VerifyUserMutation,
  VerifyUserMutationVariables
>;
export const MarkTaskDoneDocument = gql`
  mutation markTaskDone($taskId: Float!) {
    adminMarkTaskDone(taskId: $taskId)
  }
`;
export type MarkTaskDoneMutationFn = ApolloReactCommon.MutationFunction<
  MarkTaskDoneMutation,
  MarkTaskDoneMutationVariables
>;

/**
 * __useMarkTaskDoneMutation__
 *
 * To run a mutation, you first call `useMarkTaskDoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkTaskDoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markTaskDoneMutation, { data, loading, error }] = useMarkTaskDoneMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useMarkTaskDoneMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkTaskDoneMutation,
    MarkTaskDoneMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<MarkTaskDoneMutation, MarkTaskDoneMutationVariables>(
    MarkTaskDoneDocument,
    options
  );
}
export type MarkTaskDoneMutationHookResult = ReturnType<typeof useMarkTaskDoneMutation>;
export type MarkTaskDoneMutationResult = ApolloReactCommon.MutationResult<MarkTaskDoneMutation>;
export type MarkTaskDoneMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MarkTaskDoneMutation,
  MarkTaskDoneMutationVariables
>;
export const UndoTaskDoneDocument = gql`
  mutation undoTaskDone($taskId: Float!) {
    adminUndoTaskDone(taskId: $taskId)
  }
`;
export type UndoTaskDoneMutationFn = ApolloReactCommon.MutationFunction<
  UndoTaskDoneMutation,
  UndoTaskDoneMutationVariables
>;

/**
 * __useUndoTaskDoneMutation__
 *
 * To run a mutation, you first call `useUndoTaskDoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoTaskDoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoTaskDoneMutation, { data, loading, error }] = useUndoTaskDoneMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useUndoTaskDoneMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UndoTaskDoneMutation,
    UndoTaskDoneMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UndoTaskDoneMutation, UndoTaskDoneMutationVariables>(
    UndoTaskDoneDocument,
    options
  );
}
export type UndoTaskDoneMutationHookResult = ReturnType<typeof useUndoTaskDoneMutation>;
export type UndoTaskDoneMutationResult = ApolloReactCommon.MutationResult<UndoTaskDoneMutation>;
export type UndoTaskDoneMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UndoTaskDoneMutation,
  UndoTaskDoneMutationVariables
>;
export const AdminEndCallDocument = gql`
  mutation adminEndCall($userId: Float!, $appointmentId: Float!) {
    adminEndCall(userId: $userId, appointmentId: $appointmentId)
  }
`;
export type AdminEndCallMutationFn = ApolloReactCommon.MutationFunction<
  AdminEndCallMutation,
  AdminEndCallMutationVariables
>;

/**
 * __useAdminEndCallMutation__
 *
 * To run a mutation, you first call `useAdminEndCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEndCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEndCallMutation, { data, loading, error }] = useAdminEndCallMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useAdminEndCallMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminEndCallMutation,
    AdminEndCallMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AdminEndCallMutation, AdminEndCallMutationVariables>(
    AdminEndCallDocument,
    options
  );
}
export type AdminEndCallMutationHookResult = ReturnType<typeof useAdminEndCallMutation>;
export type AdminEndCallMutationResult = ApolloReactCommon.MutationResult<AdminEndCallMutation>;
export type AdminEndCallMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminEndCallMutation,
  AdminEndCallMutationVariables
>;
export const AdminMarkNoShowDocument = gql`
  mutation adminMarkNoShow($userId: Float!, $appointmentId: Float!) {
    adminMarkNoShow(userId: $userId, appointmentId: $appointmentId)
  }
`;
export type AdminMarkNoShowMutationFn = ApolloReactCommon.MutationFunction<
  AdminMarkNoShowMutation,
  AdminMarkNoShowMutationVariables
>;

/**
 * __useAdminMarkNoShowMutation__
 *
 * To run a mutation, you first call `useAdminMarkNoShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminMarkNoShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminMarkNoShowMutation, { data, loading, error }] = useAdminMarkNoShowMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useAdminMarkNoShowMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminMarkNoShowMutation,
    AdminMarkNoShowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AdminMarkNoShowMutation, AdminMarkNoShowMutationVariables>(
    AdminMarkNoShowDocument,
    options
  );
}
export type AdminMarkNoShowMutationHookResult = ReturnType<typeof useAdminMarkNoShowMutation>;
export type AdminMarkNoShowMutationResult =
  ApolloReactCommon.MutationResult<AdminMarkNoShowMutation>;
export type AdminMarkNoShowMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminMarkNoShowMutation,
  AdminMarkNoShowMutationVariables
>;
export const RefreshProviderLoginDocument = gql`
  mutation refreshProviderLogin {
    refreshProviderLogin
  }
`;
export type RefreshProviderLoginMutationFn = ApolloReactCommon.MutationFunction<
  RefreshProviderLoginMutation,
  RefreshProviderLoginMutationVariables
>;

/**
 * __useRefreshProviderLoginMutation__
 *
 * To run a mutation, you first call `useRefreshProviderLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshProviderLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshProviderLoginMutation, { data, loading, error }] = useRefreshProviderLoginMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshProviderLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RefreshProviderLoginMutation,
    RefreshProviderLoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RefreshProviderLoginMutation,
    RefreshProviderLoginMutationVariables
  >(RefreshProviderLoginDocument, options);
}
export type RefreshProviderLoginMutationHookResult = ReturnType<
  typeof useRefreshProviderLoginMutation
>;
export type RefreshProviderLoginMutationResult =
  ApolloReactCommon.MutationResult<RefreshProviderLoginMutation>;
export type RefreshProviderLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RefreshProviderLoginMutation,
  RefreshProviderLoginMutationVariables
>;
export const AdminEditUserDocument = gql`
  mutation adminEditUser($editUser: EditUser!) {
    adminEditUser(editUser: $editUser) {
      id
      email
    }
  }
`;
export type AdminEditUserMutationFn = ApolloReactCommon.MutationFunction<
  AdminEditUserMutation,
  AdminEditUserMutationVariables
>;

/**
 * __useAdminEditUserMutation__
 *
 * To run a mutation, you first call `useAdminEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditUserMutation, { data, loading, error }] = useAdminEditUserMutation({
 *   variables: {
 *      editUser: // value for 'editUser'
 *   },
 * });
 */
export function useAdminEditUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminEditUserMutation,
    AdminEditUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AdminEditUserMutation, AdminEditUserMutationVariables>(
    AdminEditUserDocument,
    options
  );
}
export type AdminEditUserMutationHookResult = ReturnType<typeof useAdminEditUserMutation>;
export type AdminEditUserMutationResult = ApolloReactCommon.MutationResult<AdminEditUserMutation>;
export type AdminEditUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminEditUserMutation,
  AdminEditUserMutationVariables
>;
export const SetPharmacyDocument = gql`
  mutation setPharmacy($pharmacyId: Float, $userId: Float!) {
    adminSetPharmacyOnPatient(pharmacyId: $pharmacyId, userId: $userId)
  }
`;
export type SetPharmacyMutationFn = ApolloReactCommon.MutationFunction<
  SetPharmacyMutation,
  SetPharmacyMutationVariables
>;

/**
 * __useSetPharmacyMutation__
 *
 * To run a mutation, you first call `useSetPharmacyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPharmacyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPharmacyMutation, { data, loading, error }] = useSetPharmacyMutation({
 *   variables: {
 *      pharmacyId: // value for 'pharmacyId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetPharmacyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetPharmacyMutation,
    SetPharmacyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SetPharmacyMutation, SetPharmacyMutationVariables>(
    SetPharmacyDocument,
    options
  );
}
export type SetPharmacyMutationHookResult = ReturnType<typeof useSetPharmacyMutation>;
export type SetPharmacyMutationResult = ApolloReactCommon.MutationResult<SetPharmacyMutation>;
export type SetPharmacyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetPharmacyMutation,
  SetPharmacyMutationVariables
>;
export const AdminContinuityOfCareExportDocument = gql`
  mutation adminContinuityOfCareExport($userId: Float!) {
    adminContinuityOfCareExport(userId: $userId) {
      id
      status
      url
    }
  }
`;
export type AdminContinuityOfCareExportMutationFn = ApolloReactCommon.MutationFunction<
  AdminContinuityOfCareExportMutation,
  AdminContinuityOfCareExportMutationVariables
>;

/**
 * __useAdminContinuityOfCareExportMutation__
 *
 * To run a mutation, you first call `useAdminContinuityOfCareExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminContinuityOfCareExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminContinuityOfCareExportMutation, { data, loading, error }] = useAdminContinuityOfCareExportMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminContinuityOfCareExportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminContinuityOfCareExportMutation,
    AdminContinuityOfCareExportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminContinuityOfCareExportMutation,
    AdminContinuityOfCareExportMutationVariables
  >(AdminContinuityOfCareExportDocument, options);
}
export type AdminContinuityOfCareExportMutationHookResult = ReturnType<
  typeof useAdminContinuityOfCareExportMutation
>;
export type AdminContinuityOfCareExportMutationResult =
  ApolloReactCommon.MutationResult<AdminContinuityOfCareExportMutation>;
export type AdminContinuityOfCareExportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminContinuityOfCareExportMutation,
  AdminContinuityOfCareExportMutationVariables
>;
export const FinishPasswordResetDocument = gql`
  mutation finishPasswordReset($email: String!, $password: String!, $token: String!) {
    finishProviderPasswordReset(email: $email, password: $password, token: $token)
  }
`;
export type FinishPasswordResetMutationFn = ApolloReactCommon.MutationFunction<
  FinishPasswordResetMutation,
  FinishPasswordResetMutationVariables
>;

/**
 * __useFinishPasswordResetMutation__
 *
 * To run a mutation, you first call `useFinishPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishPasswordResetMutation, { data, loading, error }] = useFinishPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFinishPasswordResetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FinishPasswordResetMutation,
    FinishPasswordResetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FinishPasswordResetMutation,
    FinishPasswordResetMutationVariables
  >(FinishPasswordResetDocument, options);
}
export type FinishPasswordResetMutationHookResult = ReturnType<
  typeof useFinishPasswordResetMutation
>;
export type FinishPasswordResetMutationResult =
  ApolloReactCommon.MutationResult<FinishPasswordResetMutation>;
export type FinishPasswordResetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FinishPasswordResetMutation,
  FinishPasswordResetMutationVariables
>;
export const CreateNoteForClaimDocument = gql`
  mutation createNoteForClaim($note: String!, $id: Float!) {
    createNoteForClaim(claimId: $id, note: $note) {
      id
    }
  }
`;
export type CreateNoteForClaimMutationFn = ApolloReactCommon.MutationFunction<
  CreateNoteForClaimMutation,
  CreateNoteForClaimMutationVariables
>;

/**
 * __useCreateNoteForClaimMutation__
 *
 * To run a mutation, you first call `useCreateNoteForClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteForClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteForClaimMutation, { data, loading, error }] = useCreateNoteForClaimMutation({
 *   variables: {
 *      note: // value for 'note'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateNoteForClaimMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateNoteForClaimMutation,
    CreateNoteForClaimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateNoteForClaimMutation,
    CreateNoteForClaimMutationVariables
  >(CreateNoteForClaimDocument, options);
}
export type CreateNoteForClaimMutationHookResult = ReturnType<typeof useCreateNoteForClaimMutation>;
export type CreateNoteForClaimMutationResult =
  ApolloReactCommon.MutationResult<CreateNoteForClaimMutation>;
export type CreateNoteForClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateNoteForClaimMutation,
  CreateNoteForClaimMutationVariables
>;
export const ChangeStatusOfClaimDocument = gql`
  mutation changeStatusOfClaim($status: InternalStatus!, $details: String!, $claimId: Float!) {
    changeStatusOfClaim(status: $status, details: $details, claimId: $claimId) {
      id
    }
  }
`;
export type ChangeStatusOfClaimMutationFn = ApolloReactCommon.MutationFunction<
  ChangeStatusOfClaimMutation,
  ChangeStatusOfClaimMutationVariables
>;

/**
 * __useChangeStatusOfClaimMutation__
 *
 * To run a mutation, you first call `useChangeStatusOfClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeStatusOfClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeStatusOfClaimMutation, { data, loading, error }] = useChangeStatusOfClaimMutation({
 *   variables: {
 *      status: // value for 'status'
 *      details: // value for 'details'
 *      claimId: // value for 'claimId'
 *   },
 * });
 */
export function useChangeStatusOfClaimMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangeStatusOfClaimMutation,
    ChangeStatusOfClaimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ChangeStatusOfClaimMutation,
    ChangeStatusOfClaimMutationVariables
  >(ChangeStatusOfClaimDocument, options);
}
export type ChangeStatusOfClaimMutationHookResult = ReturnType<
  typeof useChangeStatusOfClaimMutation
>;
export type ChangeStatusOfClaimMutationResult =
  ApolloReactCommon.MutationResult<ChangeStatusOfClaimMutation>;
export type ChangeStatusOfClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangeStatusOfClaimMutation,
  ChangeStatusOfClaimMutationVariables
>;
export const AdminCreateTestUserDocument = gql`
  mutation adminCreateTestUser($args: CreateTestUser!) {
    adminCreateTestUser(args: $args) {
      ...userBasic
    }
  }
  ${UserBasicFragmentDoc}
`;
export type AdminCreateTestUserMutationFn = ApolloReactCommon.MutationFunction<
  AdminCreateTestUserMutation,
  AdminCreateTestUserMutationVariables
>;

/**
 * __useAdminCreateTestUserMutation__
 *
 * To run a mutation, you first call `useAdminCreateTestUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateTestUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateTestUserMutation, { data, loading, error }] = useAdminCreateTestUserMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useAdminCreateTestUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminCreateTestUserMutation,
    AdminCreateTestUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminCreateTestUserMutation,
    AdminCreateTestUserMutationVariables
  >(AdminCreateTestUserDocument, options);
}
export type AdminCreateTestUserMutationHookResult = ReturnType<
  typeof useAdminCreateTestUserMutation
>;
export type AdminCreateTestUserMutationResult =
  ApolloReactCommon.MutationResult<AdminCreateTestUserMutation>;
export type AdminCreateTestUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminCreateTestUserMutation,
  AdminCreateTestUserMutationVariables
>;
export const UpdateCareteamPreferencesDocument = gql`
  mutation updateCareteamPreferences($preferences: [CareTeamPreference!]!) {
    updatePreferences(preferences: $preferences)
  }
`;
export type UpdateCareteamPreferencesMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCareteamPreferencesMutation,
  UpdateCareteamPreferencesMutationVariables
>;

/**
 * __useUpdateCareteamPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateCareteamPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCareteamPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCareteamPreferencesMutation, { data, loading, error }] = useUpdateCareteamPreferencesMutation({
 *   variables: {
 *      preferences: // value for 'preferences'
 *   },
 * });
 */
export function useUpdateCareteamPreferencesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCareteamPreferencesMutation,
    UpdateCareteamPreferencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateCareteamPreferencesMutation,
    UpdateCareteamPreferencesMutationVariables
  >(UpdateCareteamPreferencesDocument, options);
}
export type UpdateCareteamPreferencesMutationHookResult = ReturnType<
  typeof useUpdateCareteamPreferencesMutation
>;
export type UpdateCareteamPreferencesMutationResult =
  ApolloReactCommon.MutationResult<UpdateCareteamPreferencesMutation>;
export type UpdateCareteamPreferencesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCareteamPreferencesMutation,
  UpdateCareteamPreferencesMutationVariables
>;
export const AdminPasswordlessLoginDocument = gql`
  mutation adminPasswordlessLogin($email: String!, $token: String!) {
    adminPasswordlessLogin(email: $email, token: $token)
  }
`;
export type AdminPasswordlessLoginMutationFn = ApolloReactCommon.MutationFunction<
  AdminPasswordlessLoginMutation,
  AdminPasswordlessLoginMutationVariables
>;

/**
 * __useAdminPasswordlessLoginMutation__
 *
 * To run a mutation, you first call `useAdminPasswordlessLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminPasswordlessLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminPasswordlessLoginMutation, { data, loading, error }] = useAdminPasswordlessLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAdminPasswordlessLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminPasswordlessLoginMutation,
    AdminPasswordlessLoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminPasswordlessLoginMutation,
    AdminPasswordlessLoginMutationVariables
  >(AdminPasswordlessLoginDocument, options);
}
export type AdminPasswordlessLoginMutationHookResult = ReturnType<
  typeof useAdminPasswordlessLoginMutation
>;
export type AdminPasswordlessLoginMutationResult =
  ApolloReactCommon.MutationResult<AdminPasswordlessLoginMutation>;
export type AdminPasswordlessLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminPasswordlessLoginMutation,
  AdminPasswordlessLoginMutationVariables
>;
export const AdminResetProviderPasswordDocument = gql`
  mutation adminResetProviderPassword($id: Float!) {
    adminResetProviderPassword(id: $id)
  }
`;
export type AdminResetProviderPasswordMutationFn = ApolloReactCommon.MutationFunction<
  AdminResetProviderPasswordMutation,
  AdminResetProviderPasswordMutationVariables
>;

/**
 * __useAdminResetProviderPasswordMutation__
 *
 * To run a mutation, you first call `useAdminResetProviderPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminResetProviderPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminResetProviderPasswordMutation, { data, loading, error }] = useAdminResetProviderPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminResetProviderPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminResetProviderPasswordMutation,
    AdminResetProviderPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminResetProviderPasswordMutation,
    AdminResetProviderPasswordMutationVariables
  >(AdminResetProviderPasswordDocument, options);
}
export type AdminResetProviderPasswordMutationHookResult = ReturnType<
  typeof useAdminResetProviderPasswordMutation
>;
export type AdminResetProviderPasswordMutationResult =
  ApolloReactCommon.MutationResult<AdminResetProviderPasswordMutation>;
export type AdminResetProviderPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminResetProviderPasswordMutation,
  AdminResetProviderPasswordMutationVariables
>;
export const SyncHealthieProviderDocument = gql`
  mutation syncHealthieProvider($id: Float!) {
    syncHealthieProvider(id: $id)
  }
`;
export type SyncHealthieProviderMutationFn = ApolloReactCommon.MutationFunction<
  SyncHealthieProviderMutation,
  SyncHealthieProviderMutationVariables
>;

/**
 * __useSyncHealthieProviderMutation__
 *
 * To run a mutation, you first call `useSyncHealthieProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncHealthieProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncHealthieProviderMutation, { data, loading, error }] = useSyncHealthieProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSyncHealthieProviderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SyncHealthieProviderMutation,
    SyncHealthieProviderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SyncHealthieProviderMutation,
    SyncHealthieProviderMutationVariables
  >(SyncHealthieProviderDocument, options);
}
export type SyncHealthieProviderMutationHookResult = ReturnType<
  typeof useSyncHealthieProviderMutation
>;
export type SyncHealthieProviderMutationResult =
  ApolloReactCommon.MutationResult<SyncHealthieProviderMutation>;
export type SyncHealthieProviderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SyncHealthieProviderMutation,
  SyncHealthieProviderMutationVariables
>;
export const NetworkSearchAccommodationRequestDocument = gql`
  mutation networkSearchAccommodationRequest($accommodationRequestDetails: String!) {
    networkSearchAccommodationRequest(accommodationRequestDetails: $accommodationRequestDetails)
  }
`;
export type NetworkSearchAccommodationRequestMutationFn = ApolloReactCommon.MutationFunction<
  NetworkSearchAccommodationRequestMutation,
  NetworkSearchAccommodationRequestMutationVariables
>;

/**
 * __useNetworkSearchAccommodationRequestMutation__
 *
 * To run a mutation, you first call `useNetworkSearchAccommodationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNetworkSearchAccommodationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [networkSearchAccommodationRequestMutation, { data, loading, error }] = useNetworkSearchAccommodationRequestMutation({
 *   variables: {
 *      accommodationRequestDetails: // value for 'accommodationRequestDetails'
 *   },
 * });
 */
export function useNetworkSearchAccommodationRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NetworkSearchAccommodationRequestMutation,
    NetworkSearchAccommodationRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    NetworkSearchAccommodationRequestMutation,
    NetworkSearchAccommodationRequestMutationVariables
  >(NetworkSearchAccommodationRequestDocument, options);
}
export type NetworkSearchAccommodationRequestMutationHookResult = ReturnType<
  typeof useNetworkSearchAccommodationRequestMutation
>;
export type NetworkSearchAccommodationRequestMutationResult =
  ApolloReactCommon.MutationResult<NetworkSearchAccommodationRequestMutation>;
export type NetworkSearchAccommodationRequestMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    NetworkSearchAccommodationRequestMutation,
    NetworkSearchAccommodationRequestMutationVariables
  >;
export const MarkReferralCompleteDocument = gql`
  mutation markReferralComplete($userId: Float!) {
    markReferralComplete(userId: $userId)
  }
`;
export type MarkReferralCompleteMutationFn = ApolloReactCommon.MutationFunction<
  MarkReferralCompleteMutation,
  MarkReferralCompleteMutationVariables
>;

/**
 * __useMarkReferralCompleteMutation__
 *
 * To run a mutation, you first call `useMarkReferralCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkReferralCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markReferralCompleteMutation, { data, loading, error }] = useMarkReferralCompleteMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMarkReferralCompleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkReferralCompleteMutation,
    MarkReferralCompleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MarkReferralCompleteMutation,
    MarkReferralCompleteMutationVariables
  >(MarkReferralCompleteDocument, options);
}
export type MarkReferralCompleteMutationHookResult = ReturnType<
  typeof useMarkReferralCompleteMutation
>;
export type MarkReferralCompleteMutationResult =
  ApolloReactCommon.MutationResult<MarkReferralCompleteMutation>;
export type MarkReferralCompleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MarkReferralCompleteMutation,
  MarkReferralCompleteMutationVariables
>;
export const EmailUnsubscribeDocument = gql`
  mutation emailUnsubscribe($token: String!) {
    emailUnsubscribe(token: $token)
  }
`;
export type EmailUnsubscribeMutationFn = ApolloReactCommon.MutationFunction<
  EmailUnsubscribeMutation,
  EmailUnsubscribeMutationVariables
>;

/**
 * __useEmailUnsubscribeMutation__
 *
 * To run a mutation, you first call `useEmailUnsubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailUnsubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailUnsubscribeMutation, { data, loading, error }] = useEmailUnsubscribeMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useEmailUnsubscribeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EmailUnsubscribeMutation,
    EmailUnsubscribeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<EmailUnsubscribeMutation, EmailUnsubscribeMutationVariables>(
    EmailUnsubscribeDocument,
    options
  );
}
export type EmailUnsubscribeMutationHookResult = ReturnType<typeof useEmailUnsubscribeMutation>;
export type EmailUnsubscribeMutationResult =
  ApolloReactCommon.MutationResult<EmailUnsubscribeMutation>;
export type EmailUnsubscribeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EmailUnsubscribeMutation,
  EmailUnsubscribeMutationVariables
>;
export const AdminCompleteOrganizationPhotoUploadDocument = gql`
  mutation adminCompleteOrganizationPhotoUpload($key: String!, $organizationId: Float!) {
    adminCompleteOrganizationPhotoUpload(key: $key, organizationId: $organizationId)
  }
`;
export type AdminCompleteOrganizationPhotoUploadMutationFn = ApolloReactCommon.MutationFunction<
  AdminCompleteOrganizationPhotoUploadMutation,
  AdminCompleteOrganizationPhotoUploadMutationVariables
>;

/**
 * __useAdminCompleteOrganizationPhotoUploadMutation__
 *
 * To run a mutation, you first call `useAdminCompleteOrganizationPhotoUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCompleteOrganizationPhotoUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCompleteOrganizationPhotoUploadMutation, { data, loading, error }] = useAdminCompleteOrganizationPhotoUploadMutation({
 *   variables: {
 *      key: // value for 'key'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useAdminCompleteOrganizationPhotoUploadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminCompleteOrganizationPhotoUploadMutation,
    AdminCompleteOrganizationPhotoUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminCompleteOrganizationPhotoUploadMutation,
    AdminCompleteOrganizationPhotoUploadMutationVariables
  >(AdminCompleteOrganizationPhotoUploadDocument, options);
}
export type AdminCompleteOrganizationPhotoUploadMutationHookResult = ReturnType<
  typeof useAdminCompleteOrganizationPhotoUploadMutation
>;
export type AdminCompleteOrganizationPhotoUploadMutationResult =
  ApolloReactCommon.MutationResult<AdminCompleteOrganizationPhotoUploadMutation>;
export type AdminCompleteOrganizationPhotoUploadMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AdminCompleteOrganizationPhotoUploadMutation,
    AdminCompleteOrganizationPhotoUploadMutationVariables
  >;
export const AdminCreateOrganizationDocument = gql`
  mutation adminCreateOrganization($input: CreateOrganization!) {
    createOrganization(input: $input) {
      ...organization
    }
  }
  ${OrganizationFragmentDoc}
`;
export type AdminCreateOrganizationMutationFn = ApolloReactCommon.MutationFunction<
  AdminCreateOrganizationMutation,
  AdminCreateOrganizationMutationVariables
>;

/**
 * __useAdminCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useAdminCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateOrganizationMutation, { data, loading, error }] = useAdminCreateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminCreateOrganizationMutation,
    AdminCreateOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminCreateOrganizationMutation,
    AdminCreateOrganizationMutationVariables
  >(AdminCreateOrganizationDocument, options);
}
export type AdminCreateOrganizationMutationHookResult = ReturnType<
  typeof useAdminCreateOrganizationMutation
>;
export type AdminCreateOrganizationMutationResult =
  ApolloReactCommon.MutationResult<AdminCreateOrganizationMutation>;
export type AdminCreateOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminCreateOrganizationMutation,
  AdminCreateOrganizationMutationVariables
>;
export const AdminCompleteUploadDocument = gql`
  mutation adminCompleteUpload($input: CreateFileUpload!) {
    adminCompleteUpload(input: $input) {
      id
      name
    }
  }
`;
export type AdminCompleteUploadMutationFn = ApolloReactCommon.MutationFunction<
  AdminCompleteUploadMutation,
  AdminCompleteUploadMutationVariables
>;

/**
 * __useAdminCompleteUploadMutation__
 *
 * To run a mutation, you first call `useAdminCompleteUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCompleteUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCompleteUploadMutation, { data, loading, error }] = useAdminCompleteUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCompleteUploadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminCompleteUploadMutation,
    AdminCompleteUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminCompleteUploadMutation,
    AdminCompleteUploadMutationVariables
  >(AdminCompleteUploadDocument, options);
}
export type AdminCompleteUploadMutationHookResult = ReturnType<
  typeof useAdminCompleteUploadMutation
>;
export type AdminCompleteUploadMutationResult =
  ApolloReactCommon.MutationResult<AdminCompleteUploadMutation>;
export type AdminCompleteUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminCompleteUploadMutation,
  AdminCompleteUploadMutationVariables
>;
export const AdminUpdateUploadDocument = gql`
  mutation adminUpdateUpload($input: UpdateFileUpload!) {
    adminUpdateUpload(input: $input)
  }
`;
export type AdminUpdateUploadMutationFn = ApolloReactCommon.MutationFunction<
  AdminUpdateUploadMutation,
  AdminUpdateUploadMutationVariables
>;

/**
 * __useAdminUpdateUploadMutation__
 *
 * To run a mutation, you first call `useAdminUpdateUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateUploadMutation, { data, loading, error }] = useAdminUpdateUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateUploadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminUpdateUploadMutation,
    AdminUpdateUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminUpdateUploadMutation,
    AdminUpdateUploadMutationVariables
  >(AdminUpdateUploadDocument, options);
}
export type AdminUpdateUploadMutationHookResult = ReturnType<typeof useAdminUpdateUploadMutation>;
export type AdminUpdateUploadMutationResult =
  ApolloReactCommon.MutationResult<AdminUpdateUploadMutation>;
export type AdminUpdateUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminUpdateUploadMutation,
  AdminUpdateUploadMutationVariables
>;
export const AdminDeleteUploadDocument = gql`
  mutation adminDeleteUpload($uploadId: Float!) {
    adminDeleteUpload(uploadId: $uploadId)
  }
`;
export type AdminDeleteUploadMutationFn = ApolloReactCommon.MutationFunction<
  AdminDeleteUploadMutation,
  AdminDeleteUploadMutationVariables
>;

/**
 * __useAdminDeleteUploadMutation__
 *
 * To run a mutation, you first call `useAdminDeleteUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteUploadMutation, { data, loading, error }] = useAdminDeleteUploadMutation({
 *   variables: {
 *      uploadId: // value for 'uploadId'
 *   },
 * });
 */
export function useAdminDeleteUploadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminDeleteUploadMutation,
    AdminDeleteUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminDeleteUploadMutation,
    AdminDeleteUploadMutationVariables
  >(AdminDeleteUploadDocument, options);
}
export type AdminDeleteUploadMutationHookResult = ReturnType<typeof useAdminDeleteUploadMutation>;
export type AdminDeleteUploadMutationResult =
  ApolloReactCommon.MutationResult<AdminDeleteUploadMutation>;
export type AdminDeleteUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminDeleteUploadMutation,
  AdminDeleteUploadMutationVariables
>;
export const UpdateCouponDocument = gql`
  mutation updateCoupon($updateCoupon: UpdateCoupon!) {
    updateCoupon(updateCoupon: $updateCoupon) {
      id
    }
  }
`;
export type UpdateCouponMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCouponMutation,
  UpdateCouponMutationVariables
>;

/**
 * __useUpdateCouponMutation__
 *
 * To run a mutation, you first call `useUpdateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCouponMutation, { data, loading, error }] = useUpdateCouponMutation({
 *   variables: {
 *      updateCoupon: // value for 'updateCoupon'
 *   },
 * });
 */
export function useUpdateCouponMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCouponMutation,
    UpdateCouponMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCouponMutation, UpdateCouponMutationVariables>(
    UpdateCouponDocument,
    options
  );
}
export type UpdateCouponMutationHookResult = ReturnType<typeof useUpdateCouponMutation>;
export type UpdateCouponMutationResult = ApolloReactCommon.MutationResult<UpdateCouponMutation>;
export type UpdateCouponMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCouponMutation,
  UpdateCouponMutationVariables
>;
export const UpdateBioDocument = gql`
  mutation updateBio(
    $providerId: Float!
    $qualifications: [ProviderQualificationInput!]!
    $education: [ProviderEducationInput!]!
    $certifications: [ProviderCertificationInput!]!
  ) {
    updateQualifications(providerId: $providerId, qualifications: $qualifications) {
      id
    }
    updateEducation(providerId: $providerId, education: $education) {
      id
    }
    updateCertifications(providerId: $providerId, certifications: $certifications) {
      id
    }
  }
`;
export type UpdateBioMutationFn = ApolloReactCommon.MutationFunction<
  UpdateBioMutation,
  UpdateBioMutationVariables
>;

/**
 * __useUpdateBioMutation__
 *
 * To run a mutation, you first call `useUpdateBioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBioMutation, { data, loading, error }] = useUpdateBioMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      qualifications: // value for 'qualifications'
 *      education: // value for 'education'
 *      certifications: // value for 'certifications'
 *   },
 * });
 */
export function useUpdateBioMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBioMutation, UpdateBioMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateBioMutation, UpdateBioMutationVariables>(
    UpdateBioDocument,
    options
  );
}
export type UpdateBioMutationHookResult = ReturnType<typeof useUpdateBioMutation>;
export type UpdateBioMutationResult = ApolloReactCommon.MutationResult<UpdateBioMutation>;
export type UpdateBioMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateBioMutation,
  UpdateBioMutationVariables
>;
export const UpdateProviderOrganizationsDocument = gql`
  mutation updateProviderOrganizations($providerId: Float!, $organizationIds: [Float!]!) {
    updateOrganizations(providerId: $providerId, organizationIds: $organizationIds) {
      id
    }
  }
`;
export type UpdateProviderOrganizationsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateProviderOrganizationsMutation,
  UpdateProviderOrganizationsMutationVariables
>;

/**
 * __useUpdateProviderOrganizationsMutation__
 *
 * To run a mutation, you first call `useUpdateProviderOrganizationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProviderOrganizationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProviderOrganizationsMutation, { data, loading, error }] = useUpdateProviderOrganizationsMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      organizationIds: // value for 'organizationIds'
 *   },
 * });
 */
export function useUpdateProviderOrganizationsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProviderOrganizationsMutation,
    UpdateProviderOrganizationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateProviderOrganizationsMutation,
    UpdateProviderOrganizationsMutationVariables
  >(UpdateProviderOrganizationsDocument, options);
}
export type UpdateProviderOrganizationsMutationHookResult = ReturnType<
  typeof useUpdateProviderOrganizationsMutation
>;
export type UpdateProviderOrganizationsMutationResult =
  ApolloReactCommon.MutationResult<UpdateProviderOrganizationsMutation>;
export type UpdateProviderOrganizationsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateProviderOrganizationsMutation,
  UpdateProviderOrganizationsMutationVariables
>;
export const CreateProviderDocument = gql`
  mutation createProvider(
    $firstName: String!
    $lastName: String!
    $email: String!
    $classification: String!
    $postNominalTitles: String
    $careTypes: [CareType!]!
    $funFact: String
    $geoStates: [String!]!
    $npi: String!
    $phone: String!
    $withTwoFactor: Boolean
  ) {
    createProvider(
      firstName: $firstName
      lastName: $lastName
      email: $email
      classification: $classification
      postNominalTitles: $postNominalTitles
      careTypes: $careTypes
      funFact: $funFact
      geoStates: $geoStates
      npi: $npi
      phone: $phone
      withTwoFactor: $withTwoFactor
    ) {
      id
    }
  }
`;
export type CreateProviderMutationFn = ApolloReactCommon.MutationFunction<
  CreateProviderMutation,
  CreateProviderMutationVariables
>;

/**
 * __useCreateProviderMutation__
 *
 * To run a mutation, you first call `useCreateProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProviderMutation, { data, loading, error }] = useCreateProviderMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      classification: // value for 'classification'
 *      postNominalTitles: // value for 'postNominalTitles'
 *      careTypes: // value for 'careTypes'
 *      funFact: // value for 'funFact'
 *      geoStates: // value for 'geoStates'
 *      npi: // value for 'npi'
 *      phone: // value for 'phone'
 *      withTwoFactor: // value for 'withTwoFactor'
 *   },
 * });
 */
export function useCreateProviderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateProviderMutation,
    CreateProviderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateProviderMutation, CreateProviderMutationVariables>(
    CreateProviderDocument,
    options
  );
}
export type CreateProviderMutationHookResult = ReturnType<typeof useCreateProviderMutation>;
export type CreateProviderMutationResult = ApolloReactCommon.MutationResult<CreateProviderMutation>;
export type CreateProviderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateProviderMutation,
  CreateProviderMutationVariables
>;
export const TriggerPasswordlessAuthFlowDocument = gql`
  mutation triggerPasswordlessAuthFlow($email: String!) {
    triggerPasswordlessAuthFlow(email: $email)
  }
`;
export type TriggerPasswordlessAuthFlowMutationFn = ApolloReactCommon.MutationFunction<
  TriggerPasswordlessAuthFlowMutation,
  TriggerPasswordlessAuthFlowMutationVariables
>;

/**
 * __useTriggerPasswordlessAuthFlowMutation__
 *
 * To run a mutation, you first call `useTriggerPasswordlessAuthFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerPasswordlessAuthFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerPasswordlessAuthFlowMutation, { data, loading, error }] = useTriggerPasswordlessAuthFlowMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useTriggerPasswordlessAuthFlowMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TriggerPasswordlessAuthFlowMutation,
    TriggerPasswordlessAuthFlowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    TriggerPasswordlessAuthFlowMutation,
    TriggerPasswordlessAuthFlowMutationVariables
  >(TriggerPasswordlessAuthFlowDocument, options);
}
export type TriggerPasswordlessAuthFlowMutationHookResult = ReturnType<
  typeof useTriggerPasswordlessAuthFlowMutation
>;
export type TriggerPasswordlessAuthFlowMutationResult =
  ApolloReactCommon.MutationResult<TriggerPasswordlessAuthFlowMutation>;
export type TriggerPasswordlessAuthFlowMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TriggerPasswordlessAuthFlowMutation,
  TriggerPasswordlessAuthFlowMutationVariables
>;
export const UpdateEntitlementsDocument = gql`
  mutation updateEntitlements(
    $organizationId: Float!
    $entitlements: [UpdateOrganizationEntitlementInput!]!
  ) {
    updateEntitlements(organizationId: $organizationId, entitlements: $entitlements)
  }
`;
export type UpdateEntitlementsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateEntitlementsMutation,
  UpdateEntitlementsMutationVariables
>;

/**
 * __useUpdateEntitlementsMutation__
 *
 * To run a mutation, you first call `useUpdateEntitlementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntitlementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntitlementsMutation, { data, loading, error }] = useUpdateEntitlementsMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      entitlements: // value for 'entitlements'
 *   },
 * });
 */
export function useUpdateEntitlementsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEntitlementsMutation,
    UpdateEntitlementsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateEntitlementsMutation,
    UpdateEntitlementsMutationVariables
  >(UpdateEntitlementsDocument, options);
}
export type UpdateEntitlementsMutationHookResult = ReturnType<typeof useUpdateEntitlementsMutation>;
export type UpdateEntitlementsMutationResult =
  ApolloReactCommon.MutationResult<UpdateEntitlementsMutation>;
export type UpdateEntitlementsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateEntitlementsMutation,
  UpdateEntitlementsMutationVariables
>;
export const CreateCareTransitionDocument = gql`
  mutation createCareTransition($transition: CreateCareTransition!) {
    adminCreateCareTransition(createTransition: $transition) {
      id
    }
  }
`;
export type CreateCareTransitionMutationFn = ApolloReactCommon.MutationFunction<
  CreateCareTransitionMutation,
  CreateCareTransitionMutationVariables
>;

/**
 * __useCreateCareTransitionMutation__
 *
 * To run a mutation, you first call `useCreateCareTransitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCareTransitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCareTransitionMutation, { data, loading, error }] = useCreateCareTransitionMutation({
 *   variables: {
 *      transition: // value for 'transition'
 *   },
 * });
 */
export function useCreateCareTransitionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCareTransitionMutation,
    CreateCareTransitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateCareTransitionMutation,
    CreateCareTransitionMutationVariables
  >(CreateCareTransitionDocument, options);
}
export type CreateCareTransitionMutationHookResult = ReturnType<
  typeof useCreateCareTransitionMutation
>;
export type CreateCareTransitionMutationResult =
  ApolloReactCommon.MutationResult<CreateCareTransitionMutation>;
export type CreateCareTransitionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCareTransitionMutation,
  CreateCareTransitionMutationVariables
>;
export const UpdateOrganizationCarePeriodsDocument = gql`
  mutation updateOrganizationCarePeriods(
    $organizationId: Int!
    $newPeriods: [NewOrganizationCarePeriod!]!
    $editedPeriods: [EditOrganizationCarePeriod!]!
  ) {
    updateOrganizationCarePeriods(
      organizationId: $organizationId
      newPeriods: $newPeriods
      editedPeriods: $editedPeriods
    ) {
      id
    }
  }
`;
export type UpdateOrganizationCarePeriodsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateOrganizationCarePeriodsMutation,
  UpdateOrganizationCarePeriodsMutationVariables
>;

/**
 * __useUpdateOrganizationCarePeriodsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationCarePeriodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationCarePeriodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationCarePeriodsMutation, { data, loading, error }] = useUpdateOrganizationCarePeriodsMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      newPeriods: // value for 'newPeriods'
 *      editedPeriods: // value for 'editedPeriods'
 *   },
 * });
 */
export function useUpdateOrganizationCarePeriodsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrganizationCarePeriodsMutation,
    UpdateOrganizationCarePeriodsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateOrganizationCarePeriodsMutation,
    UpdateOrganizationCarePeriodsMutationVariables
  >(UpdateOrganizationCarePeriodsDocument, options);
}
export type UpdateOrganizationCarePeriodsMutationHookResult = ReturnType<
  typeof useUpdateOrganizationCarePeriodsMutation
>;
export type UpdateOrganizationCarePeriodsMutationResult =
  ApolloReactCommon.MutationResult<UpdateOrganizationCarePeriodsMutation>;
export type UpdateOrganizationCarePeriodsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateOrganizationCarePeriodsMutation,
  UpdateOrganizationCarePeriodsMutationVariables
>;
export const UpdateOrganizationDedicatedGroupModelAllocationsDocument = gql`
  mutation updateOrganizationDedicatedGroupModelAllocations(
    $organizationId: Int!
    $editedOrganizationDedicatedGroupModelAllocations: [OrganizationDedicatedGroupModelAllocationInput!]!
  ) {
    updateOrganizationDedicatedGroupModelAllocations(
      organizationId: $organizationId
      editedOrganizationDedicatedGroupModelAllocations: $editedOrganizationDedicatedGroupModelAllocations
    )
  }
`;
export type UpdateOrganizationDedicatedGroupModelAllocationsMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateOrganizationDedicatedGroupModelAllocationsMutation,
    UpdateOrganizationDedicatedGroupModelAllocationsMutationVariables
  >;

/**
 * __useUpdateOrganizationDedicatedGroupModelAllocationsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationDedicatedGroupModelAllocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationDedicatedGroupModelAllocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationDedicatedGroupModelAllocationsMutation, { data, loading, error }] = useUpdateOrganizationDedicatedGroupModelAllocationsMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      editedOrganizationDedicatedGroupModelAllocations: // value for 'editedOrganizationDedicatedGroupModelAllocations'
 *   },
 * });
 */
export function useUpdateOrganizationDedicatedGroupModelAllocationsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrganizationDedicatedGroupModelAllocationsMutation,
    UpdateOrganizationDedicatedGroupModelAllocationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateOrganizationDedicatedGroupModelAllocationsMutation,
    UpdateOrganizationDedicatedGroupModelAllocationsMutationVariables
  >(UpdateOrganizationDedicatedGroupModelAllocationsDocument, options);
}
export type UpdateOrganizationDedicatedGroupModelAllocationsMutationHookResult = ReturnType<
  typeof useUpdateOrganizationDedicatedGroupModelAllocationsMutation
>;
export type UpdateOrganizationDedicatedGroupModelAllocationsMutationResult =
  ApolloReactCommon.MutationResult<UpdateOrganizationDedicatedGroupModelAllocationsMutation>;
export type UpdateOrganizationDedicatedGroupModelAllocationsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateOrganizationDedicatedGroupModelAllocationsMutation,
    UpdateOrganizationDedicatedGroupModelAllocationsMutationVariables
  >;
export const UpdateContractWeeksDocument = gql`
  mutation updateContractWeeks($input: EditContractWeeksInput!) {
    __typename
    editContractWeeks(input: $input) {
      ... on EditContractWeeksAggregateError {
        aggregateErrors {
          weekId
          errorCode
        }
      }
      ... on EditContractWeeksSuccess {
        successMessage
      }
    }
  }
`;
export type UpdateContractWeeksMutationFn = ApolloReactCommon.MutationFunction<
  UpdateContractWeeksMutation,
  UpdateContractWeeksMutationVariables
>;

/**
 * __useUpdateContractWeeksMutation__
 *
 * To run a mutation, you first call `useUpdateContractWeeksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractWeeksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractWeeksMutation, { data, loading, error }] = useUpdateContractWeeksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractWeeksMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateContractWeeksMutation,
    UpdateContractWeeksMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateContractWeeksMutation,
    UpdateContractWeeksMutationVariables
  >(UpdateContractWeeksDocument, options);
}
export type UpdateContractWeeksMutationHookResult = ReturnType<
  typeof useUpdateContractWeeksMutation
>;
export type UpdateContractWeeksMutationResult =
  ApolloReactCommon.MutationResult<UpdateContractWeeksMutation>;
export type UpdateContractWeeksMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateContractWeeksMutation,
  UpdateContractWeeksMutationVariables
>;
export const UpdateOrganizationCareFlowsDocument = gql`
  mutation updateOrganizationCareFlows(
    $organizationId: Int!
    $careFlows: [OrganizationCareFlowInput!]!
  ) {
    updateOrganizationCareFlows(organizationId: $organizationId, careFlows: $careFlows) {
      id
    }
  }
`;
export type UpdateOrganizationCareFlowsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateOrganizationCareFlowsMutation,
  UpdateOrganizationCareFlowsMutationVariables
>;

/**
 * __useUpdateOrganizationCareFlowsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationCareFlowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationCareFlowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationCareFlowsMutation, { data, loading, error }] = useUpdateOrganizationCareFlowsMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      careFlows: // value for 'careFlows'
 *   },
 * });
 */
export function useUpdateOrganizationCareFlowsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrganizationCareFlowsMutation,
    UpdateOrganizationCareFlowsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateOrganizationCareFlowsMutation,
    UpdateOrganizationCareFlowsMutationVariables
  >(UpdateOrganizationCareFlowsDocument, options);
}
export type UpdateOrganizationCareFlowsMutationHookResult = ReturnType<
  typeof useUpdateOrganizationCareFlowsMutation
>;
export type UpdateOrganizationCareFlowsMutationResult =
  ApolloReactCommon.MutationResult<UpdateOrganizationCareFlowsMutation>;
export type UpdateOrganizationCareFlowsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateOrganizationCareFlowsMutation,
  UpdateOrganizationCareFlowsMutationVariables
>;
export const UpsertInsuranceCredentialDocument = gql`
  mutation upsertInsuranceCredential($credential: UpsertProviderInsuranceCredential!) {
    upsertInsuranceCredential(credential: $credential) {
      id
    }
  }
`;
export type UpsertInsuranceCredentialMutationFn = ApolloReactCommon.MutationFunction<
  UpsertInsuranceCredentialMutation,
  UpsertInsuranceCredentialMutationVariables
>;

/**
 * __useUpsertInsuranceCredentialMutation__
 *
 * To run a mutation, you first call `useUpsertInsuranceCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertInsuranceCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertInsuranceCredentialMutation, { data, loading, error }] = useUpsertInsuranceCredentialMutation({
 *   variables: {
 *      credential: // value for 'credential'
 *   },
 * });
 */
export function useUpsertInsuranceCredentialMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertInsuranceCredentialMutation,
    UpsertInsuranceCredentialMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpsertInsuranceCredentialMutation,
    UpsertInsuranceCredentialMutationVariables
  >(UpsertInsuranceCredentialDocument, options);
}
export type UpsertInsuranceCredentialMutationHookResult = ReturnType<
  typeof useUpsertInsuranceCredentialMutation
>;
export type UpsertInsuranceCredentialMutationResult =
  ApolloReactCommon.MutationResult<UpsertInsuranceCredentialMutation>;
export type UpsertInsuranceCredentialMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpsertInsuranceCredentialMutation,
  UpsertInsuranceCredentialMutationVariables
>;
export const DeleteInsuranceCredentialDocument = gql`
  mutation deleteInsuranceCredential($id: Float!) {
    deleteInsuranceCredential(id: $id)
  }
`;
export type DeleteInsuranceCredentialMutationFn = ApolloReactCommon.MutationFunction<
  DeleteInsuranceCredentialMutation,
  DeleteInsuranceCredentialMutationVariables
>;

/**
 * __useDeleteInsuranceCredentialMutation__
 *
 * To run a mutation, you first call `useDeleteInsuranceCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInsuranceCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInsuranceCredentialMutation, { data, loading, error }] = useDeleteInsuranceCredentialMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInsuranceCredentialMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteInsuranceCredentialMutation,
    DeleteInsuranceCredentialMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteInsuranceCredentialMutation,
    DeleteInsuranceCredentialMutationVariables
  >(DeleteInsuranceCredentialDocument, options);
}
export type DeleteInsuranceCredentialMutationHookResult = ReturnType<
  typeof useDeleteInsuranceCredentialMutation
>;
export type DeleteInsuranceCredentialMutationResult =
  ApolloReactCommon.MutationResult<DeleteInsuranceCredentialMutation>;
export type DeleteInsuranceCredentialMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteInsuranceCredentialMutation,
  DeleteInsuranceCredentialMutationVariables
>;
export const ReactivateUserDocument = gql`
  mutation reactivateUser($userId: Float!, $careType: CareType!, $paymentSource: PaymentSource) {
    reactivateUserCareFlow(userId: $userId, careType: $careType, paymentSource: $paymentSource) {
      id
      careStatus
    }
  }
`;
export type ReactivateUserMutationFn = ApolloReactCommon.MutationFunction<
  ReactivateUserMutation,
  ReactivateUserMutationVariables
>;

/**
 * __useReactivateUserMutation__
 *
 * To run a mutation, you first call `useReactivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateUserMutation, { data, loading, error }] = useReactivateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      careType: // value for 'careType'
 *      paymentSource: // value for 'paymentSource'
 *   },
 * });
 */
export function useReactivateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReactivateUserMutation,
    ReactivateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ReactivateUserMutation, ReactivateUserMutationVariables>(
    ReactivateUserDocument,
    options
  );
}
export type ReactivateUserMutationHookResult = ReturnType<typeof useReactivateUserMutation>;
export type ReactivateUserMutationResult = ApolloReactCommon.MutationResult<ReactivateUserMutation>;
export type ReactivateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ReactivateUserMutation,
  ReactivateUserMutationVariables
>;
export const SetUserInsuranceDocument = gql`
  mutation setUserInsurance($userId: Int!, $insurance: InsuranceDetailsInput!) {
    setUserInsurance(userId: $userId, insurance: $insurance) {
      valid
      inNetwork
    }
  }
`;
export type SetUserInsuranceMutationFn = ApolloReactCommon.MutationFunction<
  SetUserInsuranceMutation,
  SetUserInsuranceMutationVariables
>;

/**
 * __useSetUserInsuranceMutation__
 *
 * To run a mutation, you first call `useSetUserInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserInsuranceMutation, { data, loading, error }] = useSetUserInsuranceMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      insurance: // value for 'insurance'
 *   },
 * });
 */
export function useSetUserInsuranceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetUserInsuranceMutation,
    SetUserInsuranceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SetUserInsuranceMutation, SetUserInsuranceMutationVariables>(
    SetUserInsuranceDocument,
    options
  );
}
export type SetUserInsuranceMutationHookResult = ReturnType<typeof useSetUserInsuranceMutation>;
export type SetUserInsuranceMutationResult =
  ApolloReactCommon.MutationResult<SetUserInsuranceMutation>;
export type SetUserInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetUserInsuranceMutation,
  SetUserInsuranceMutationVariables
>;
export const SetProviderSubscribedDocument = gql`
  mutation setProviderSubscribed($providerId: Int!, $subscribed: Boolean!) {
    setProviderSubscribed(providerId: $providerId, subscribed: $subscribed)
  }
`;
export type SetProviderSubscribedMutationFn = ApolloReactCommon.MutationFunction<
  SetProviderSubscribedMutation,
  SetProviderSubscribedMutationVariables
>;

/**
 * __useSetProviderSubscribedMutation__
 *
 * To run a mutation, you first call `useSetProviderSubscribedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProviderSubscribedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProviderSubscribedMutation, { data, loading, error }] = useSetProviderSubscribedMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      subscribed: // value for 'subscribed'
 *   },
 * });
 */
export function useSetProviderSubscribedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetProviderSubscribedMutation,
    SetProviderSubscribedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SetProviderSubscribedMutation,
    SetProviderSubscribedMutationVariables
  >(SetProviderSubscribedDocument, options);
}
export type SetProviderSubscribedMutationHookResult = ReturnType<
  typeof useSetProviderSubscribedMutation
>;
export type SetProviderSubscribedMutationResult =
  ApolloReactCommon.MutationResult<SetProviderSubscribedMutation>;
export type SetProviderSubscribedMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetProviderSubscribedMutation,
  SetProviderSubscribedMutationVariables
>;
export const SetUserSubscribedDocument = gql`
  mutation setUserSubscribed($userId: Int!, $subscribed: Boolean!) {
    setUserSubscribed(userId: $userId, subscribed: $subscribed)
  }
`;
export type SetUserSubscribedMutationFn = ApolloReactCommon.MutationFunction<
  SetUserSubscribedMutation,
  SetUserSubscribedMutationVariables
>;

/**
 * __useSetUserSubscribedMutation__
 *
 * To run a mutation, you first call `useSetUserSubscribedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserSubscribedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserSubscribedMutation, { data, loading, error }] = useSetUserSubscribedMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      subscribed: // value for 'subscribed'
 *   },
 * });
 */
export function useSetUserSubscribedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetUserSubscribedMutation,
    SetUserSubscribedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SetUserSubscribedMutation,
    SetUserSubscribedMutationVariables
  >(SetUserSubscribedDocument, options);
}
export type SetUserSubscribedMutationHookResult = ReturnType<typeof useSetUserSubscribedMutation>;
export type SetUserSubscribedMutationResult =
  ApolloReactCommon.MutationResult<SetUserSubscribedMutation>;
export type SetUserSubscribedMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetUserSubscribedMutation,
  SetUserSubscribedMutationVariables
>;
export const AdminTransitionForCarePeriodDocument = gql`
  mutation adminTransitionForCarePeriod($carePeriodId: Float!) {
    adminTransitionForCarePeriod(carePeriodId: $carePeriodId)
  }
`;
export type AdminTransitionForCarePeriodMutationFn = ApolloReactCommon.MutationFunction<
  AdminTransitionForCarePeriodMutation,
  AdminTransitionForCarePeriodMutationVariables
>;

/**
 * __useAdminTransitionForCarePeriodMutation__
 *
 * To run a mutation, you first call `useAdminTransitionForCarePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminTransitionForCarePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminTransitionForCarePeriodMutation, { data, loading, error }] = useAdminTransitionForCarePeriodMutation({
 *   variables: {
 *      carePeriodId: // value for 'carePeriodId'
 *   },
 * });
 */
export function useAdminTransitionForCarePeriodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminTransitionForCarePeriodMutation,
    AdminTransitionForCarePeriodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminTransitionForCarePeriodMutation,
    AdminTransitionForCarePeriodMutationVariables
  >(AdminTransitionForCarePeriodDocument, options);
}
export type AdminTransitionForCarePeriodMutationHookResult = ReturnType<
  typeof useAdminTransitionForCarePeriodMutation
>;
export type AdminTransitionForCarePeriodMutationResult =
  ApolloReactCommon.MutationResult<AdminTransitionForCarePeriodMutation>;
export type AdminTransitionForCarePeriodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminTransitionForCarePeriodMutation,
  AdminTransitionForCarePeriodMutationVariables
>;
export const ResubmitClaimDocument = gql`
  mutation resubmitClaim($noteId: Int!) {
    resubmitClaim(noteId: $noteId)
  }
`;
export type ResubmitClaimMutationFn = ApolloReactCommon.MutationFunction<
  ResubmitClaimMutation,
  ResubmitClaimMutationVariables
>;

/**
 * __useResubmitClaimMutation__
 *
 * To run a mutation, you first call `useResubmitClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResubmitClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resubmitClaimMutation, { data, loading, error }] = useResubmitClaimMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useResubmitClaimMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResubmitClaimMutation,
    ResubmitClaimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ResubmitClaimMutation, ResubmitClaimMutationVariables>(
    ResubmitClaimDocument,
    options
  );
}
export type ResubmitClaimMutationHookResult = ReturnType<typeof useResubmitClaimMutation>;
export type ResubmitClaimMutationResult = ApolloReactCommon.MutationResult<ResubmitClaimMutation>;
export type ResubmitClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResubmitClaimMutation,
  ResubmitClaimMutationVariables
>;
export const CreateOrUpdatePayerDocument = gql`
  mutation createOrUpdatePayer($params: UpdatePayerParams!) {
    createOrUpdatePayer(params: $params) {
      id
    }
  }
`;
export type CreateOrUpdatePayerMutationFn = ApolloReactCommon.MutationFunction<
  CreateOrUpdatePayerMutation,
  CreateOrUpdatePayerMutationVariables
>;

/**
 * __useCreateOrUpdatePayerMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdatePayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdatePayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdatePayerMutation, { data, loading, error }] = useCreateOrUpdatePayerMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateOrUpdatePayerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrUpdatePayerMutation,
    CreateOrUpdatePayerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateOrUpdatePayerMutation,
    CreateOrUpdatePayerMutationVariables
  >(CreateOrUpdatePayerDocument, options);
}
export type CreateOrUpdatePayerMutationHookResult = ReturnType<
  typeof useCreateOrUpdatePayerMutation
>;
export type CreateOrUpdatePayerMutationResult =
  ApolloReactCommon.MutationResult<CreateOrUpdatePayerMutation>;
export type CreateOrUpdatePayerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOrUpdatePayerMutation,
  CreateOrUpdatePayerMutationVariables
>;
export const UpdateOrganizationDocument = gql`
  mutation updateOrganization($update: UpdateOrganization!) {
    updateOrganization(update: $update) {
      id
    }
  }
`;
export type UpdateOrganizationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >(UpdateOrganizationDocument, options);
}
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult =
  ApolloReactCommon.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;
export const UpdateAppointmentTemplatesDocument = gql`
  mutation updateAppointmentTemplates(
    $organizationId: Int!
    $templates: [AppointmentTemplateUpdate!]!
  ) {
    updateAppointmentTemplates(organizationId: $organizationId, templates: $templates)
  }
`;
export type UpdateAppointmentTemplatesMutationFn = ApolloReactCommon.MutationFunction<
  UpdateAppointmentTemplatesMutation,
  UpdateAppointmentTemplatesMutationVariables
>;

/**
 * __useUpdateAppointmentTemplatesMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentTemplatesMutation, { data, loading, error }] = useUpdateAppointmentTemplatesMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      templates: // value for 'templates'
 *   },
 * });
 */
export function useUpdateAppointmentTemplatesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateAppointmentTemplatesMutation,
    UpdateAppointmentTemplatesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateAppointmentTemplatesMutation,
    UpdateAppointmentTemplatesMutationVariables
  >(UpdateAppointmentTemplatesDocument, options);
}
export type UpdateAppointmentTemplatesMutationHookResult = ReturnType<
  typeof useUpdateAppointmentTemplatesMutation
>;
export type UpdateAppointmentTemplatesMutationResult =
  ApolloReactCommon.MutationResult<UpdateAppointmentTemplatesMutation>;
export type UpdateAppointmentTemplatesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateAppointmentTemplatesMutation,
  UpdateAppointmentTemplatesMutationVariables
>;
export const UpdateSamlConfigurationDocument = gql`
  mutation updateSamlConfiguration(
    $organizationId: Int!
    $type: SamlConfigurationType!
    $update: UpdateSamlConfiguration!
  ) {
    updateSamlConfiguration(organizationId: $organizationId, type: $type, update: $update) {
      entryPoint
    }
  }
`;
export type UpdateSamlConfigurationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSamlConfigurationMutation,
  UpdateSamlConfigurationMutationVariables
>;

/**
 * __useUpdateSamlConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateSamlConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSamlConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSamlConfigurationMutation, { data, loading, error }] = useUpdateSamlConfigurationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      type: // value for 'type'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateSamlConfigurationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSamlConfigurationMutation,
    UpdateSamlConfigurationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateSamlConfigurationMutation,
    UpdateSamlConfigurationMutationVariables
  >(UpdateSamlConfigurationDocument, options);
}
export type UpdateSamlConfigurationMutationHookResult = ReturnType<
  typeof useUpdateSamlConfigurationMutation
>;
export type UpdateSamlConfigurationMutationResult =
  ApolloReactCommon.MutationResult<UpdateSamlConfigurationMutation>;
export type UpdateSamlConfigurationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSamlConfigurationMutation,
  UpdateSamlConfigurationMutationVariables
>;
export const AdminSendTwoFactorCodeDocument = gql`
  mutation adminSendTwoFactorCode {
    adminSendTwoFactorCode
  }
`;
export type AdminSendTwoFactorCodeMutationFn = ApolloReactCommon.MutationFunction<
  AdminSendTwoFactorCodeMutation,
  AdminSendTwoFactorCodeMutationVariables
>;

/**
 * __useAdminSendTwoFactorCodeMutation__
 *
 * To run a mutation, you first call `useAdminSendTwoFactorCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSendTwoFactorCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSendTwoFactorCodeMutation, { data, loading, error }] = useAdminSendTwoFactorCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useAdminSendTwoFactorCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminSendTwoFactorCodeMutation,
    AdminSendTwoFactorCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminSendTwoFactorCodeMutation,
    AdminSendTwoFactorCodeMutationVariables
  >(AdminSendTwoFactorCodeDocument, options);
}
export type AdminSendTwoFactorCodeMutationHookResult = ReturnType<
  typeof useAdminSendTwoFactorCodeMutation
>;
export type AdminSendTwoFactorCodeMutationResult =
  ApolloReactCommon.MutationResult<AdminSendTwoFactorCodeMutation>;
export type AdminSendTwoFactorCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminSendTwoFactorCodeMutation,
  AdminSendTwoFactorCodeMutationVariables
>;
export const AdminVerifyTwoFactorPhoneDocument = gql`
  mutation adminVerifyTwoFactorPhone($phone: String!, $code: String!) {
    adminVerifyTwoFactorPhone(phone: $phone, code: $code)
  }
`;
export type AdminVerifyTwoFactorPhoneMutationFn = ApolloReactCommon.MutationFunction<
  AdminVerifyTwoFactorPhoneMutation,
  AdminVerifyTwoFactorPhoneMutationVariables
>;

/**
 * __useAdminVerifyTwoFactorPhoneMutation__
 *
 * To run a mutation, you first call `useAdminVerifyTwoFactorPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminVerifyTwoFactorPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminVerifyTwoFactorPhoneMutation, { data, loading, error }] = useAdminVerifyTwoFactorPhoneMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAdminVerifyTwoFactorPhoneMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminVerifyTwoFactorPhoneMutation,
    AdminVerifyTwoFactorPhoneMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminVerifyTwoFactorPhoneMutation,
    AdminVerifyTwoFactorPhoneMutationVariables
  >(AdminVerifyTwoFactorPhoneDocument, options);
}
export type AdminVerifyTwoFactorPhoneMutationHookResult = ReturnType<
  typeof useAdminVerifyTwoFactorPhoneMutation
>;
export type AdminVerifyTwoFactorPhoneMutationResult =
  ApolloReactCommon.MutationResult<AdminVerifyTwoFactorPhoneMutation>;
export type AdminVerifyTwoFactorPhoneMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminVerifyTwoFactorPhoneMutation,
  AdminVerifyTwoFactorPhoneMutationVariables
>;
export const AdminSetTwoFactorConfigDocument = gql`
  mutation adminSetTwoFactorConfig($phone: String!, $enabled: Boolean, $providerId: Float) {
    adminSetTwoFactorConfig(phone: $phone, enabled: $enabled, providerId: $providerId)
  }
`;
export type AdminSetTwoFactorConfigMutationFn = ApolloReactCommon.MutationFunction<
  AdminSetTwoFactorConfigMutation,
  AdminSetTwoFactorConfigMutationVariables
>;

/**
 * __useAdminSetTwoFactorConfigMutation__
 *
 * To run a mutation, you first call `useAdminSetTwoFactorConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSetTwoFactorConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSetTwoFactorConfigMutation, { data, loading, error }] = useAdminSetTwoFactorConfigMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      enabled: // value for 'enabled'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useAdminSetTwoFactorConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminSetTwoFactorConfigMutation,
    AdminSetTwoFactorConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminSetTwoFactorConfigMutation,
    AdminSetTwoFactorConfigMutationVariables
  >(AdminSetTwoFactorConfigDocument, options);
}
export type AdminSetTwoFactorConfigMutationHookResult = ReturnType<
  typeof useAdminSetTwoFactorConfigMutation
>;
export type AdminSetTwoFactorConfigMutationResult =
  ApolloReactCommon.MutationResult<AdminSetTwoFactorConfigMutation>;
export type AdminSetTwoFactorConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminSetTwoFactorConfigMutation,
  AdminSetTwoFactorConfigMutationVariables
>;
export const RecheckInsuranceEligibilityDocument = gql`
  mutation recheckInsuranceEligibility($userId: Int!) {
    recheckInsuranceEligibility(userId: $userId)
  }
`;
export type RecheckInsuranceEligibilityMutationFn = ApolloReactCommon.MutationFunction<
  RecheckInsuranceEligibilityMutation,
  RecheckInsuranceEligibilityMutationVariables
>;

/**
 * __useRecheckInsuranceEligibilityMutation__
 *
 * To run a mutation, you first call `useRecheckInsuranceEligibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecheckInsuranceEligibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recheckInsuranceEligibilityMutation, { data, loading, error }] = useRecheckInsuranceEligibilityMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRecheckInsuranceEligibilityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RecheckInsuranceEligibilityMutation,
    RecheckInsuranceEligibilityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RecheckInsuranceEligibilityMutation,
    RecheckInsuranceEligibilityMutationVariables
  >(RecheckInsuranceEligibilityDocument, options);
}
export type RecheckInsuranceEligibilityMutationHookResult = ReturnType<
  typeof useRecheckInsuranceEligibilityMutation
>;
export type RecheckInsuranceEligibilityMutationResult =
  ApolloReactCommon.MutationResult<RecheckInsuranceEligibilityMutation>;
export type RecheckInsuranceEligibilityMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RecheckInsuranceEligibilityMutation,
  RecheckInsuranceEligibilityMutationVariables
>;
export const TrackDocument = gql`
  mutation track($tag: String!, $data: JSON) {
    track(tag: $tag, data: $data)
  }
`;
export type TrackMutationFn = ApolloReactCommon.MutationFunction<
  TrackMutation,
  TrackMutationVariables
>;

/**
 * __useTrackMutation__
 *
 * To run a mutation, you first call `useTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackMutation, { data, loading, error }] = useTrackMutation({
 *   variables: {
 *      tag: // value for 'tag'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTrackMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<TrackMutation, TrackMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<TrackMutation, TrackMutationVariables>(
    TrackDocument,
    options
  );
}
export type TrackMutationHookResult = ReturnType<typeof useTrackMutation>;
export type TrackMutationResult = ApolloReactCommon.MutationResult<TrackMutation>;
export type TrackMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TrackMutation,
  TrackMutationVariables
>;
export const UpdateProviderAndTraitsDocument = gql`
  mutation updateProviderAndTraits(
    $updateProvider: UpdateProvider!
    $providerId: Int!
    $traits: [ProviderTraitInput!]!
  ) {
    adminUpdateProvider(input: $updateProvider) {
      id
    }
    updateProviderTraits(providerId: $providerId, traits: $traits)
  }
`;
export type UpdateProviderAndTraitsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateProviderAndTraitsMutation,
  UpdateProviderAndTraitsMutationVariables
>;

/**
 * __useUpdateProviderAndTraitsMutation__
 *
 * To run a mutation, you first call `useUpdateProviderAndTraitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProviderAndTraitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProviderAndTraitsMutation, { data, loading, error }] = useUpdateProviderAndTraitsMutation({
 *   variables: {
 *      updateProvider: // value for 'updateProvider'
 *      providerId: // value for 'providerId'
 *      traits: // value for 'traits'
 *   },
 * });
 */
export function useUpdateProviderAndTraitsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProviderAndTraitsMutation,
    UpdateProviderAndTraitsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateProviderAndTraitsMutation,
    UpdateProviderAndTraitsMutationVariables
  >(UpdateProviderAndTraitsDocument, options);
}
export type UpdateProviderAndTraitsMutationHookResult = ReturnType<
  typeof useUpdateProviderAndTraitsMutation
>;
export type UpdateProviderAndTraitsMutationResult =
  ApolloReactCommon.MutationResult<UpdateProviderAndTraitsMutation>;
export type UpdateProviderAndTraitsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateProviderAndTraitsMutation,
  UpdateProviderAndTraitsMutationVariables
>;
export const ClearProviderLockoutDocument = gql`
  mutation clearProviderLockout($providerId: Int!) {
    clearProviderLockout(providerId: $providerId)
  }
`;
export type ClearProviderLockoutMutationFn = ApolloReactCommon.MutationFunction<
  ClearProviderLockoutMutation,
  ClearProviderLockoutMutationVariables
>;

/**
 * __useClearProviderLockoutMutation__
 *
 * To run a mutation, you first call `useClearProviderLockoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearProviderLockoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearProviderLockoutMutation, { data, loading, error }] = useClearProviderLockoutMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useClearProviderLockoutMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ClearProviderLockoutMutation,
    ClearProviderLockoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ClearProviderLockoutMutation,
    ClearProviderLockoutMutationVariables
  >(ClearProviderLockoutDocument, options);
}
export type ClearProviderLockoutMutationHookResult = ReturnType<
  typeof useClearProviderLockoutMutation
>;
export type ClearProviderLockoutMutationResult =
  ApolloReactCommon.MutationResult<ClearProviderLockoutMutation>;
export type ClearProviderLockoutMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ClearProviderLockoutMutation,
  ClearProviderLockoutMutationVariables
>;
export const UpdateEligibilityCriteriaDocument = gql`
  mutation updateEligibilityCriteria(
    $organizationId: Int!
    $criteria: [OrganizationEligibilityCriteriaInput!]!
  ) {
    updateEligibilityCriteria(organizationId: $organizationId, criteria: $criteria)
  }
`;
export type UpdateEligibilityCriteriaMutationFn = ApolloReactCommon.MutationFunction<
  UpdateEligibilityCriteriaMutation,
  UpdateEligibilityCriteriaMutationVariables
>;

/**
 * __useUpdateEligibilityCriteriaMutation__
 *
 * To run a mutation, you first call `useUpdateEligibilityCriteriaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEligibilityCriteriaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEligibilityCriteriaMutation, { data, loading, error }] = useUpdateEligibilityCriteriaMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      criteria: // value for 'criteria'
 *   },
 * });
 */
export function useUpdateEligibilityCriteriaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEligibilityCriteriaMutation,
    UpdateEligibilityCriteriaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateEligibilityCriteriaMutation,
    UpdateEligibilityCriteriaMutationVariables
  >(UpdateEligibilityCriteriaDocument, options);
}
export type UpdateEligibilityCriteriaMutationHookResult = ReturnType<
  typeof useUpdateEligibilityCriteriaMutation
>;
export type UpdateEligibilityCriteriaMutationResult =
  ApolloReactCommon.MutationResult<UpdateEligibilityCriteriaMutation>;
export type UpdateEligibilityCriteriaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateEligibilityCriteriaMutation,
  UpdateEligibilityCriteriaMutationVariables
>;
export const CreateDataSharingRequestDocument = gql`
  mutation createDataSharingRequest($userId: Float!) {
    createDataSharingRequest(userId: $userId) {
      id
    }
  }
`;
export type CreateDataSharingRequestMutationFn = ApolloReactCommon.MutationFunction<
  CreateDataSharingRequestMutation,
  CreateDataSharingRequestMutationVariables
>;

/**
 * __useCreateDataSharingRequestMutation__
 *
 * To run a mutation, you first call `useCreateDataSharingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataSharingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataSharingRequestMutation, { data, loading, error }] = useCreateDataSharingRequestMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateDataSharingRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateDataSharingRequestMutation,
    CreateDataSharingRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateDataSharingRequestMutation,
    CreateDataSharingRequestMutationVariables
  >(CreateDataSharingRequestDocument, options);
}
export type CreateDataSharingRequestMutationHookResult = ReturnType<
  typeof useCreateDataSharingRequestMutation
>;
export type CreateDataSharingRequestMutationResult =
  ApolloReactCommon.MutationResult<CreateDataSharingRequestMutation>;
export type CreateDataSharingRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateDataSharingRequestMutation,
  CreateDataSharingRequestMutationVariables
>;
export const SendDataSharingRequestReminderDocument = gql`
  mutation sendDataSharingRequestReminder($userId: Float!) {
    sendDataSharingRequestReminder(userId: $userId)
  }
`;
export type SendDataSharingRequestReminderMutationFn = ApolloReactCommon.MutationFunction<
  SendDataSharingRequestReminderMutation,
  SendDataSharingRequestReminderMutationVariables
>;

/**
 * __useSendDataSharingRequestReminderMutation__
 *
 * To run a mutation, you first call `useSendDataSharingRequestReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDataSharingRequestReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDataSharingRequestReminderMutation, { data, loading, error }] = useSendDataSharingRequestReminderMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSendDataSharingRequestReminderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendDataSharingRequestReminderMutation,
    SendDataSharingRequestReminderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SendDataSharingRequestReminderMutation,
    SendDataSharingRequestReminderMutationVariables
  >(SendDataSharingRequestReminderDocument, options);
}
export type SendDataSharingRequestReminderMutationHookResult = ReturnType<
  typeof useSendDataSharingRequestReminderMutation
>;
export type SendDataSharingRequestReminderMutationResult =
  ApolloReactCommon.MutationResult<SendDataSharingRequestReminderMutation>;
export type SendDataSharingRequestReminderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendDataSharingRequestReminderMutation,
  SendDataSharingRequestReminderMutationVariables
>;
export const ModifyAppointmentDocument = gql`
  mutation modifyAppointment($id: Float!, $start: DateTime!, $end: DateTime!) {
    adminModifyAppointment(id: $id, start: $start, end: $end) {
      id
    }
  }
`;
export type ModifyAppointmentMutationFn = ApolloReactCommon.MutationFunction<
  ModifyAppointmentMutation,
  ModifyAppointmentMutationVariables
>;

/**
 * __useModifyAppointmentMutation__
 *
 * To run a mutation, you first call `useModifyAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyAppointmentMutation, { data, loading, error }] = useModifyAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useModifyAppointmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ModifyAppointmentMutation,
    ModifyAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ModifyAppointmentMutation,
    ModifyAppointmentMutationVariables
  >(ModifyAppointmentDocument, options);
}
export type ModifyAppointmentMutationHookResult = ReturnType<typeof useModifyAppointmentMutation>;
export type ModifyAppointmentMutationResult =
  ApolloReactCommon.MutationResult<ModifyAppointmentMutation>;
export type ModifyAppointmentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ModifyAppointmentMutation,
  ModifyAppointmentMutationVariables
>;
export const LogoutDocument = gql`
  mutation logout {
    logoutProvider
  }
`;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const UpdateOutreachAttemptDocument = gql`
  mutation updateOutreachAttempt($attempt: OutreachAttemptInput!) {
    updateOutreachAttempt(attempt: $attempt)
  }
`;
export type UpdateOutreachAttemptMutationFn = ApolloReactCommon.MutationFunction<
  UpdateOutreachAttemptMutation,
  UpdateOutreachAttemptMutationVariables
>;

/**
 * __useUpdateOutreachAttemptMutation__
 *
 * To run a mutation, you first call `useUpdateOutreachAttemptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOutreachAttemptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOutreachAttemptMutation, { data, loading, error }] = useUpdateOutreachAttemptMutation({
 *   variables: {
 *      attempt: // value for 'attempt'
 *   },
 * });
 */
export function useUpdateOutreachAttemptMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOutreachAttemptMutation,
    UpdateOutreachAttemptMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateOutreachAttemptMutation,
    UpdateOutreachAttemptMutationVariables
  >(UpdateOutreachAttemptDocument, options);
}
export type UpdateOutreachAttemptMutationHookResult = ReturnType<
  typeof useUpdateOutreachAttemptMutation
>;
export type UpdateOutreachAttemptMutationResult =
  ApolloReactCommon.MutationResult<UpdateOutreachAttemptMutation>;
export type UpdateOutreachAttemptMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateOutreachAttemptMutation,
  UpdateOutreachAttemptMutationVariables
>;
export const DeleteOutreachAttemptDocument = gql`
  mutation deleteOutreachAttempt($id: Int!) {
    deleteOutreachAttempt(id: $id)
  }
`;
export type DeleteOutreachAttemptMutationFn = ApolloReactCommon.MutationFunction<
  DeleteOutreachAttemptMutation,
  DeleteOutreachAttemptMutationVariables
>;

/**
 * __useDeleteOutreachAttemptMutation__
 *
 * To run a mutation, you first call `useDeleteOutreachAttemptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOutreachAttemptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOutreachAttemptMutation, { data, loading, error }] = useDeleteOutreachAttemptMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOutreachAttemptMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteOutreachAttemptMutation,
    DeleteOutreachAttemptMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteOutreachAttemptMutation,
    DeleteOutreachAttemptMutationVariables
  >(DeleteOutreachAttemptDocument, options);
}
export type DeleteOutreachAttemptMutationHookResult = ReturnType<
  typeof useDeleteOutreachAttemptMutation
>;
export type DeleteOutreachAttemptMutationResult =
  ApolloReactCommon.MutationResult<DeleteOutreachAttemptMutation>;
export type DeleteOutreachAttemptMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteOutreachAttemptMutation,
  DeleteOutreachAttemptMutationVariables
>;
export const SetSuggestedPharmacyIdDocument = gql`
  mutation setSuggestedPharmacyId($organizationId: Int!, $pharmacyId: Int) {
    setSuggestedPharmacyId(organizationId: $organizationId, pharmacyId: $pharmacyId)
  }
`;
export type SetSuggestedPharmacyIdMutationFn = ApolloReactCommon.MutationFunction<
  SetSuggestedPharmacyIdMutation,
  SetSuggestedPharmacyIdMutationVariables
>;

/**
 * __useSetSuggestedPharmacyIdMutation__
 *
 * To run a mutation, you first call `useSetSuggestedPharmacyIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSuggestedPharmacyIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSuggestedPharmacyIdMutation, { data, loading, error }] = useSetSuggestedPharmacyIdMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      pharmacyId: // value for 'pharmacyId'
 *   },
 * });
 */
export function useSetSuggestedPharmacyIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetSuggestedPharmacyIdMutation,
    SetSuggestedPharmacyIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SetSuggestedPharmacyIdMutation,
    SetSuggestedPharmacyIdMutationVariables
  >(SetSuggestedPharmacyIdDocument, options);
}
export type SetSuggestedPharmacyIdMutationHookResult = ReturnType<
  typeof useSetSuggestedPharmacyIdMutation
>;
export type SetSuggestedPharmacyIdMutationResult =
  ApolloReactCommon.MutationResult<SetSuggestedPharmacyIdMutation>;
export type SetSuggestedPharmacyIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetSuggestedPharmacyIdMutation,
  SetSuggestedPharmacyIdMutationVariables
>;
export const UpdateCareFlowForUserDocument = gql`
  mutation updateCareFlowForUser($userId: Float!, $careFlow: UserCareFlowInput!) {
    updateCareFlowForUser(userId: $userId, careFlow: $careFlow)
  }
`;
export type UpdateCareFlowForUserMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCareFlowForUserMutation,
  UpdateCareFlowForUserMutationVariables
>;

/**
 * __useUpdateCareFlowForUserMutation__
 *
 * To run a mutation, you first call `useUpdateCareFlowForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCareFlowForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCareFlowForUserMutation, { data, loading, error }] = useUpdateCareFlowForUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      careFlow: // value for 'careFlow'
 *   },
 * });
 */
export function useUpdateCareFlowForUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCareFlowForUserMutation,
    UpdateCareFlowForUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateCareFlowForUserMutation,
    UpdateCareFlowForUserMutationVariables
  >(UpdateCareFlowForUserDocument, options);
}
export type UpdateCareFlowForUserMutationHookResult = ReturnType<
  typeof useUpdateCareFlowForUserMutation
>;
export type UpdateCareFlowForUserMutationResult =
  ApolloReactCommon.MutationResult<UpdateCareFlowForUserMutation>;
export type UpdateCareFlowForUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCareFlowForUserMutation,
  UpdateCareFlowForUserMutationVariables
>;
export const AddUserCareEligibilitySubmissionDocument = gql`
  mutation addUserCareEligibilitySubmission($submission: ContinuityOfCareEligibilityInput!) {
    addUserCareEligibilitySubmission(submission: $submission) {
      providerInState
      needsReferral
      canContinueCare
      paymentType
      interestedInContinuingCare
      enrollment
      isEligible
    }
  }
`;
export type AddUserCareEligibilitySubmissionMutationFn = ApolloReactCommon.MutationFunction<
  AddUserCareEligibilitySubmissionMutation,
  AddUserCareEligibilitySubmissionMutationVariables
>;

/**
 * __useAddUserCareEligibilitySubmissionMutation__
 *
 * To run a mutation, you first call `useAddUserCareEligibilitySubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserCareEligibilitySubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserCareEligibilitySubmissionMutation, { data, loading, error }] = useAddUserCareEligibilitySubmissionMutation({
 *   variables: {
 *      submission: // value for 'submission'
 *   },
 * });
 */
export function useAddUserCareEligibilitySubmissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddUserCareEligibilitySubmissionMutation,
    AddUserCareEligibilitySubmissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddUserCareEligibilitySubmissionMutation,
    AddUserCareEligibilitySubmissionMutationVariables
  >(AddUserCareEligibilitySubmissionDocument, options);
}
export type AddUserCareEligibilitySubmissionMutationHookResult = ReturnType<
  typeof useAddUserCareEligibilitySubmissionMutation
>;
export type AddUserCareEligibilitySubmissionMutationResult =
  ApolloReactCommon.MutationResult<AddUserCareEligibilitySubmissionMutation>;
export type AddUserCareEligibilitySubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddUserCareEligibilitySubmissionMutation,
  AddUserCareEligibilitySubmissionMutationVariables
>;
export const DeleteUserCareEligibilitySubmissionDocument = gql`
  mutation deleteUserCareEligibilitySubmission($id: Float!, $userId: Float!) {
    deleteUserCareEligibilitySubmission(id: $id, userId: $userId)
  }
`;
export type DeleteUserCareEligibilitySubmissionMutationFn = ApolloReactCommon.MutationFunction<
  DeleteUserCareEligibilitySubmissionMutation,
  DeleteUserCareEligibilitySubmissionMutationVariables
>;

/**
 * __useDeleteUserCareEligibilitySubmissionMutation__
 *
 * To run a mutation, you first call `useDeleteUserCareEligibilitySubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserCareEligibilitySubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserCareEligibilitySubmissionMutation, { data, loading, error }] = useDeleteUserCareEligibilitySubmissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserCareEligibilitySubmissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteUserCareEligibilitySubmissionMutation,
    DeleteUserCareEligibilitySubmissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteUserCareEligibilitySubmissionMutation,
    DeleteUserCareEligibilitySubmissionMutationVariables
  >(DeleteUserCareEligibilitySubmissionDocument, options);
}
export type DeleteUserCareEligibilitySubmissionMutationHookResult = ReturnType<
  typeof useDeleteUserCareEligibilitySubmissionMutation
>;
export type DeleteUserCareEligibilitySubmissionMutationResult =
  ApolloReactCommon.MutationResult<DeleteUserCareEligibilitySubmissionMutation>;
export type DeleteUserCareEligibilitySubmissionMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteUserCareEligibilitySubmissionMutation,
    DeleteUserCareEligibilitySubmissionMutationVariables
  >;
export const RegenerateProviderPasswordDocument = gql`
  mutation regenerateProviderPassword($providerId: Int!) {
    regenerateProviderPassword(providerId: $providerId)
  }
`;
export type RegenerateProviderPasswordMutationFn = ApolloReactCommon.MutationFunction<
  RegenerateProviderPasswordMutation,
  RegenerateProviderPasswordMutationVariables
>;

/**
 * __useRegenerateProviderPasswordMutation__
 *
 * To run a mutation, you first call `useRegenerateProviderPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateProviderPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateProviderPasswordMutation, { data, loading, error }] = useRegenerateProviderPasswordMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useRegenerateProviderPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RegenerateProviderPasswordMutation,
    RegenerateProviderPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RegenerateProviderPasswordMutation,
    RegenerateProviderPasswordMutationVariables
  >(RegenerateProviderPasswordDocument, options);
}
export type RegenerateProviderPasswordMutationHookResult = ReturnType<
  typeof useRegenerateProviderPasswordMutation
>;
export type RegenerateProviderPasswordMutationResult =
  ApolloReactCommon.MutationResult<RegenerateProviderPasswordMutation>;
export type RegenerateProviderPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RegenerateProviderPasswordMutation,
  RegenerateProviderPasswordMutationVariables
>;
export const AddProviderToCrisisTeamsDocument = gql`
  mutation addProviderToCrisisTeams(
    $organizationIds: [Float!]!
    $providerId: Float!
    $phone: String!
  ) {
    addProviderToCrisisTeams(
      organizationIds: $organizationIds
      providerId: $providerId
      phone: $phone
    )
  }
`;
export type AddProviderToCrisisTeamsMutationFn = ApolloReactCommon.MutationFunction<
  AddProviderToCrisisTeamsMutation,
  AddProviderToCrisisTeamsMutationVariables
>;

/**
 * __useAddProviderToCrisisTeamsMutation__
 *
 * To run a mutation, you first call `useAddProviderToCrisisTeamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProviderToCrisisTeamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProviderToCrisisTeamsMutation, { data, loading, error }] = useAddProviderToCrisisTeamsMutation({
 *   variables: {
 *      organizationIds: // value for 'organizationIds'
 *      providerId: // value for 'providerId'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useAddProviderToCrisisTeamsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddProviderToCrisisTeamsMutation,
    AddProviderToCrisisTeamsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddProviderToCrisisTeamsMutation,
    AddProviderToCrisisTeamsMutationVariables
  >(AddProviderToCrisisTeamsDocument, options);
}
export type AddProviderToCrisisTeamsMutationHookResult = ReturnType<
  typeof useAddProviderToCrisisTeamsMutation
>;
export type AddProviderToCrisisTeamsMutationResult =
  ApolloReactCommon.MutationResult<AddProviderToCrisisTeamsMutation>;
export type AddProviderToCrisisTeamsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddProviderToCrisisTeamsMutation,
  AddProviderToCrisisTeamsMutationVariables
>;
export const RemoveProviderFromCrisisTeamsDocument = gql`
  mutation removeProviderFromCrisisTeams($providerId: Float!) {
    removeProviderFromCrisisTeams(providerId: $providerId)
  }
`;
export type RemoveProviderFromCrisisTeamsMutationFn = ApolloReactCommon.MutationFunction<
  RemoveProviderFromCrisisTeamsMutation,
  RemoveProviderFromCrisisTeamsMutationVariables
>;

/**
 * __useRemoveProviderFromCrisisTeamsMutation__
 *
 * To run a mutation, you first call `useRemoveProviderFromCrisisTeamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProviderFromCrisisTeamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProviderFromCrisisTeamsMutation, { data, loading, error }] = useRemoveProviderFromCrisisTeamsMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useRemoveProviderFromCrisisTeamsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveProviderFromCrisisTeamsMutation,
    RemoveProviderFromCrisisTeamsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RemoveProviderFromCrisisTeamsMutation,
    RemoveProviderFromCrisisTeamsMutationVariables
  >(RemoveProviderFromCrisisTeamsDocument, options);
}
export type RemoveProviderFromCrisisTeamsMutationHookResult = ReturnType<
  typeof useRemoveProviderFromCrisisTeamsMutation
>;
export type RemoveProviderFromCrisisTeamsMutationResult =
  ApolloReactCommon.MutationResult<RemoveProviderFromCrisisTeamsMutation>;
export type RemoveProviderFromCrisisTeamsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveProviderFromCrisisTeamsMutation,
  RemoveProviderFromCrisisTeamsMutationVariables
>;
export const UpdateCrisisTeamHoursDocument = gql`
  mutation updateCrisisTeamHours(
    $organizationId: Float!
    $crisisTeamHours: [CrisisTeamHoursInput!]!
  ) {
    updateCrisisTeamHours(organizationId: $organizationId, crisisTeamHours: $crisisTeamHours)
  }
`;
export type UpdateCrisisTeamHoursMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCrisisTeamHoursMutation,
  UpdateCrisisTeamHoursMutationVariables
>;

/**
 * __useUpdateCrisisTeamHoursMutation__
 *
 * To run a mutation, you first call `useUpdateCrisisTeamHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCrisisTeamHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCrisisTeamHoursMutation, { data, loading, error }] = useUpdateCrisisTeamHoursMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      crisisTeamHours: // value for 'crisisTeamHours'
 *   },
 * });
 */
export function useUpdateCrisisTeamHoursMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCrisisTeamHoursMutation,
    UpdateCrisisTeamHoursMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateCrisisTeamHoursMutation,
    UpdateCrisisTeamHoursMutationVariables
  >(UpdateCrisisTeamHoursDocument, options);
}
export type UpdateCrisisTeamHoursMutationHookResult = ReturnType<
  typeof useUpdateCrisisTeamHoursMutation
>;
export type UpdateCrisisTeamHoursMutationResult =
  ApolloReactCommon.MutationResult<UpdateCrisisTeamHoursMutation>;
export type UpdateCrisisTeamHoursMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCrisisTeamHoursMutation,
  UpdateCrisisTeamHoursMutationVariables
>;
export const ActivateCrisisModeDocument = gql`
  mutation activateCrisisMode($appointmentId: Float!) {
    activateCrisisMode(appointmentId: $appointmentId)
  }
`;
export type ActivateCrisisModeMutationFn = ApolloReactCommon.MutationFunction<
  ActivateCrisisModeMutation,
  ActivateCrisisModeMutationVariables
>;

/**
 * __useActivateCrisisModeMutation__
 *
 * To run a mutation, you first call `useActivateCrisisModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCrisisModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCrisisModeMutation, { data, loading, error }] = useActivateCrisisModeMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useActivateCrisisModeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ActivateCrisisModeMutation,
    ActivateCrisisModeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ActivateCrisisModeMutation,
    ActivateCrisisModeMutationVariables
  >(ActivateCrisisModeDocument, options);
}
export type ActivateCrisisModeMutationHookResult = ReturnType<typeof useActivateCrisisModeMutation>;
export type ActivateCrisisModeMutationResult =
  ApolloReactCommon.MutationResult<ActivateCrisisModeMutation>;
export type ActivateCrisisModeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ActivateCrisisModeMutation,
  ActivateCrisisModeMutationVariables
>;
export const NotifyOrganizationCrisisTeamDocument = gql`
  mutation notifyOrganizationCrisisTeam($crisisId: String!) {
    notifyOrganizationCrisisTeam(crisisId: $crisisId)
  }
`;
export type NotifyOrganizationCrisisTeamMutationFn = ApolloReactCommon.MutationFunction<
  NotifyOrganizationCrisisTeamMutation,
  NotifyOrganizationCrisisTeamMutationVariables
>;

/**
 * __useNotifyOrganizationCrisisTeamMutation__
 *
 * To run a mutation, you first call `useNotifyOrganizationCrisisTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyOrganizationCrisisTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyOrganizationCrisisTeamMutation, { data, loading, error }] = useNotifyOrganizationCrisisTeamMutation({
 *   variables: {
 *      crisisId: // value for 'crisisId'
 *   },
 * });
 */
export function useNotifyOrganizationCrisisTeamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NotifyOrganizationCrisisTeamMutation,
    NotifyOrganizationCrisisTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    NotifyOrganizationCrisisTeamMutation,
    NotifyOrganizationCrisisTeamMutationVariables
  >(NotifyOrganizationCrisisTeamDocument, options);
}
export type NotifyOrganizationCrisisTeamMutationHookResult = ReturnType<
  typeof useNotifyOrganizationCrisisTeamMutation
>;
export type NotifyOrganizationCrisisTeamMutationResult =
  ApolloReactCommon.MutationResult<NotifyOrganizationCrisisTeamMutation>;
export type NotifyOrganizationCrisisTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<
  NotifyOrganizationCrisisTeamMutation,
  NotifyOrganizationCrisisTeamMutationVariables
>;
export const SetSafeOperatingHandbookUrlDocument = gql`
  mutation setSafeOperatingHandbookUrl($organizationId: Int!, $url: String) {
    setSafeOperatingHandbookUrl(organizationId: $organizationId, url: $url)
  }
`;
export type SetSafeOperatingHandbookUrlMutationFn = ApolloReactCommon.MutationFunction<
  SetSafeOperatingHandbookUrlMutation,
  SetSafeOperatingHandbookUrlMutationVariables
>;

/**
 * __useSetSafeOperatingHandbookUrlMutation__
 *
 * To run a mutation, you first call `useSetSafeOperatingHandbookUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSafeOperatingHandbookUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSafeOperatingHandbookUrlMutation, { data, loading, error }] = useSetSafeOperatingHandbookUrlMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useSetSafeOperatingHandbookUrlMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetSafeOperatingHandbookUrlMutation,
    SetSafeOperatingHandbookUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SetSafeOperatingHandbookUrlMutation,
    SetSafeOperatingHandbookUrlMutationVariables
  >(SetSafeOperatingHandbookUrlDocument, options);
}
export type SetSafeOperatingHandbookUrlMutationHookResult = ReturnType<
  typeof useSetSafeOperatingHandbookUrlMutation
>;
export type SetSafeOperatingHandbookUrlMutationResult =
  ApolloReactCommon.MutationResult<SetSafeOperatingHandbookUrlMutation>;
export type SetSafeOperatingHandbookUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetSafeOperatingHandbookUrlMutation,
  SetSafeOperatingHandbookUrlMutationVariables
>;
export const ImpersonateDocument = gql`
  mutation impersonate($providerToImpersonateId: Float!, $reason: String!) {
    impersonate(providerToImpersonateId: $providerToImpersonateId, reason: $reason)
  }
`;
export type ImpersonateMutationFn = ApolloReactCommon.MutationFunction<
  ImpersonateMutation,
  ImpersonateMutationVariables
>;

/**
 * __useImpersonateMutation__
 *
 * To run a mutation, you first call `useImpersonateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImpersonateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [impersonateMutation, { data, loading, error }] = useImpersonateMutation({
 *   variables: {
 *      providerToImpersonateId: // value for 'providerToImpersonateId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useImpersonateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ImpersonateMutation,
    ImpersonateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ImpersonateMutation, ImpersonateMutationVariables>(
    ImpersonateDocument,
    options
  );
}
export type ImpersonateMutationHookResult = ReturnType<typeof useImpersonateMutation>;
export type ImpersonateMutationResult = ApolloReactCommon.MutationResult<ImpersonateMutation>;
export type ImpersonateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ImpersonateMutation,
  ImpersonateMutationVariables
>;
export const ReactivateUserForSponsoredCareDocument = gql`
  mutation reactivateUserForSponsoredCare($userId: Float!, $careType: CareType!) {
    reactivateUserForSponsoredCare(userId: $userId, careType: $careType)
  }
`;
export type ReactivateUserForSponsoredCareMutationFn = ApolloReactCommon.MutationFunction<
  ReactivateUserForSponsoredCareMutation,
  ReactivateUserForSponsoredCareMutationVariables
>;

/**
 * __useReactivateUserForSponsoredCareMutation__
 *
 * To run a mutation, you first call `useReactivateUserForSponsoredCareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateUserForSponsoredCareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateUserForSponsoredCareMutation, { data, loading, error }] = useReactivateUserForSponsoredCareMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      careType: // value for 'careType'
 *   },
 * });
 */
export function useReactivateUserForSponsoredCareMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReactivateUserForSponsoredCareMutation,
    ReactivateUserForSponsoredCareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ReactivateUserForSponsoredCareMutation,
    ReactivateUserForSponsoredCareMutationVariables
  >(ReactivateUserForSponsoredCareDocument, options);
}
export type ReactivateUserForSponsoredCareMutationHookResult = ReturnType<
  typeof useReactivateUserForSponsoredCareMutation
>;
export type ReactivateUserForSponsoredCareMutationResult =
  ApolloReactCommon.MutationResult<ReactivateUserForSponsoredCareMutation>;
export type ReactivateUserForSponsoredCareMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ReactivateUserForSponsoredCareMutation,
  ReactivateUserForSponsoredCareMutationVariables
>;
export const EditOrganizationHandbookDocument = gql`
  mutation editOrganizationHandbook(
    $organizationId: Float!
    $input: EditOrganizationHandbookInput!
  ) {
    editOrganizationHandbook(organizationId: $organizationId, input: $input)
  }
`;
export type EditOrganizationHandbookMutationFn = ApolloReactCommon.MutationFunction<
  EditOrganizationHandbookMutation,
  EditOrganizationHandbookMutationVariables
>;

/**
 * __useEditOrganizationHandbookMutation__
 *
 * To run a mutation, you first call `useEditOrganizationHandbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrganizationHandbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrganizationHandbookMutation, { data, loading, error }] = useEditOrganizationHandbookMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrganizationHandbookMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EditOrganizationHandbookMutation,
    EditOrganizationHandbookMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    EditOrganizationHandbookMutation,
    EditOrganizationHandbookMutationVariables
  >(EditOrganizationHandbookDocument, options);
}
export type EditOrganizationHandbookMutationHookResult = ReturnType<
  typeof useEditOrganizationHandbookMutation
>;
export type EditOrganizationHandbookMutationResult =
  ApolloReactCommon.MutationResult<EditOrganizationHandbookMutation>;
export type EditOrganizationHandbookMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EditOrganizationHandbookMutation,
  EditOrganizationHandbookMutationVariables
>;
export const CreateOrganizationAnnouncementDocument = gql`
  mutation createOrganizationAnnouncement($input: CreateOrganizationAnnouncementInput!) {
    createOrganizationAnnouncement(input: $input) {
      id
    }
  }
`;
export type CreateOrganizationAnnouncementMutationFn = ApolloReactCommon.MutationFunction<
  CreateOrganizationAnnouncementMutation,
  CreateOrganizationAnnouncementMutationVariables
>;

/**
 * __useCreateOrganizationAnnouncementMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationAnnouncementMutation, { data, loading, error }] = useCreateOrganizationAnnouncementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationAnnouncementMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrganizationAnnouncementMutation,
    CreateOrganizationAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateOrganizationAnnouncementMutation,
    CreateOrganizationAnnouncementMutationVariables
  >(CreateOrganizationAnnouncementDocument, options);
}
export type CreateOrganizationAnnouncementMutationHookResult = ReturnType<
  typeof useCreateOrganizationAnnouncementMutation
>;
export type CreateOrganizationAnnouncementMutationResult =
  ApolloReactCommon.MutationResult<CreateOrganizationAnnouncementMutation>;
export type CreateOrganizationAnnouncementMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOrganizationAnnouncementMutation,
  CreateOrganizationAnnouncementMutationVariables
>;
export const EditOrganizationAnnouncementDocument = gql`
  mutation editOrganizationAnnouncement($id: Float!, $input: EditOrganizationAnnouncementInput!) {
    editOrganizationAnnouncement(id: $id, input: $input)
  }
`;
export type EditOrganizationAnnouncementMutationFn = ApolloReactCommon.MutationFunction<
  EditOrganizationAnnouncementMutation,
  EditOrganizationAnnouncementMutationVariables
>;

/**
 * __useEditOrganizationAnnouncementMutation__
 *
 * To run a mutation, you first call `useEditOrganizationAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrganizationAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrganizationAnnouncementMutation, { data, loading, error }] = useEditOrganizationAnnouncementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrganizationAnnouncementMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EditOrganizationAnnouncementMutation,
    EditOrganizationAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    EditOrganizationAnnouncementMutation,
    EditOrganizationAnnouncementMutationVariables
  >(EditOrganizationAnnouncementDocument, options);
}
export type EditOrganizationAnnouncementMutationHookResult = ReturnType<
  typeof useEditOrganizationAnnouncementMutation
>;
export type EditOrganizationAnnouncementMutationResult =
  ApolloReactCommon.MutationResult<EditOrganizationAnnouncementMutation>;
export type EditOrganizationAnnouncementMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EditOrganizationAnnouncementMutation,
  EditOrganizationAnnouncementMutationVariables
>;
export const AdminUpdateScreenUserResultDocument = gql`
  mutation adminUpdateScreenUserResult($userId: Float!, $input: ScreenUserResultInput!) {
    adminUpdateScreenUserResult(userId: $userId, input: $input)
  }
`;
export type AdminUpdateScreenUserResultMutationFn = ApolloReactCommon.MutationFunction<
  AdminUpdateScreenUserResultMutation,
  AdminUpdateScreenUserResultMutationVariables
>;

/**
 * __useAdminUpdateScreenUserResultMutation__
 *
 * To run a mutation, you first call `useAdminUpdateScreenUserResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateScreenUserResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateScreenUserResultMutation, { data, loading, error }] = useAdminUpdateScreenUserResultMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateScreenUserResultMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminUpdateScreenUserResultMutation,
    AdminUpdateScreenUserResultMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminUpdateScreenUserResultMutation,
    AdminUpdateScreenUserResultMutationVariables
  >(AdminUpdateScreenUserResultDocument, options);
}
export type AdminUpdateScreenUserResultMutationHookResult = ReturnType<
  typeof useAdminUpdateScreenUserResultMutation
>;
export type AdminUpdateScreenUserResultMutationResult =
  ApolloReactCommon.MutationResult<AdminUpdateScreenUserResultMutation>;
export type AdminUpdateScreenUserResultMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminUpdateScreenUserResultMutation,
  AdminUpdateScreenUserResultMutationVariables
>;
export const AdminResetUserPasswordDocument = gql`
  mutation adminResetUserPassword($userId: Float!) {
    resetUserPassword(userId: $userId)
  }
`;
export type AdminResetUserPasswordMutationFn = ApolloReactCommon.MutationFunction<
  AdminResetUserPasswordMutation,
  AdminResetUserPasswordMutationVariables
>;

/**
 * __useAdminResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useAdminResetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminResetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminResetUserPasswordMutation, { data, loading, error }] = useAdminResetUserPasswordMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminResetUserPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminResetUserPasswordMutation,
    AdminResetUserPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminResetUserPasswordMutation,
    AdminResetUserPasswordMutationVariables
  >(AdminResetUserPasswordDocument, options);
}
export type AdminResetUserPasswordMutationHookResult = ReturnType<
  typeof useAdminResetUserPasswordMutation
>;
export type AdminResetUserPasswordMutationResult =
  ApolloReactCommon.MutationResult<AdminResetUserPasswordMutation>;
export type AdminResetUserPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminResetUserPasswordMutation,
  AdminResetUserPasswordMutationVariables
>;
export const SoftDeleteUserDocument = gql`
  mutation softDeleteUser($userId: Float!) {
    softDeleteUser(userId: $userId)
  }
`;
export type SoftDeleteUserMutationFn = ApolloReactCommon.MutationFunction<
  SoftDeleteUserMutation,
  SoftDeleteUserMutationVariables
>;

/**
 * __useSoftDeleteUserMutation__
 *
 * To run a mutation, you first call `useSoftDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteUserMutation, { data, loading, error }] = useSoftDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSoftDeleteUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SoftDeleteUserMutation,
    SoftDeleteUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SoftDeleteUserMutation, SoftDeleteUserMutationVariables>(
    SoftDeleteUserDocument,
    options
  );
}
export type SoftDeleteUserMutationHookResult = ReturnType<typeof useSoftDeleteUserMutation>;
export type SoftDeleteUserMutationResult = ApolloReactCommon.MutationResult<SoftDeleteUserMutation>;
export type SoftDeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SoftDeleteUserMutation,
  SoftDeleteUserMutationVariables
>;
export const CreatePartnerReferralDocument = gql`
  mutation createPartnerReferral($data: PartnerReferralInput!) {
    createPartnerReferral(data: $data)
  }
`;
export type CreatePartnerReferralMutationFn = ApolloReactCommon.MutationFunction<
  CreatePartnerReferralMutation,
  CreatePartnerReferralMutationVariables
>;

/**
 * __useCreatePartnerReferralMutation__
 *
 * To run a mutation, you first call `useCreatePartnerReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerReferralMutation, { data, loading, error }] = useCreatePartnerReferralMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePartnerReferralMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePartnerReferralMutation,
    CreatePartnerReferralMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreatePartnerReferralMutation,
    CreatePartnerReferralMutationVariables
  >(CreatePartnerReferralDocument, options);
}
export type CreatePartnerReferralMutationHookResult = ReturnType<
  typeof useCreatePartnerReferralMutation
>;
export type CreatePartnerReferralMutationResult =
  ApolloReactCommon.MutationResult<CreatePartnerReferralMutation>;
export type CreatePartnerReferralMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePartnerReferralMutation,
  CreatePartnerReferralMutationVariables
>;
export const CreateContractDocument = gql`
  mutation createContract($input: CreateContractInput!) {
    createContract(input: $input) {
      ... on CreateContractSuccess {
        id
      }
      ... on CreateContractError {
        errorCode
      }
    }
  }
`;
export type CreateContractMutationFn = ApolloReactCommon.MutationFunction<
  CreateContractMutation,
  CreateContractMutationVariables
>;

/**
 * __useCreateContractMutation__
 *
 * To run a mutation, you first call `useCreateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMutation, { data, loading, error }] = useCreateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateContractMutation,
    CreateContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateContractMutation, CreateContractMutationVariables>(
    CreateContractDocument,
    options
  );
}
export type CreateContractMutationHookResult = ReturnType<typeof useCreateContractMutation>;
export type CreateContractMutationResult = ApolloReactCommon.MutationResult<CreateContractMutation>;
export type CreateContractMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateContractMutation,
  CreateContractMutationVariables
>;
export const EditContractScopeDocument = gql`
  mutation editContractScope($input: EditContractScopeInput!) {
    editContractScope(input: $input) {
      ... on EditContractScopeSuccess {
        id
      }
      ... on EditContractScopeError {
        errorCode
      }
    }
  }
`;
export type EditContractScopeMutationFn = ApolloReactCommon.MutationFunction<
  EditContractScopeMutation,
  EditContractScopeMutationVariables
>;

/**
 * __useEditContractScopeMutation__
 *
 * To run a mutation, you first call `useEditContractScopeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditContractScopeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editContractScopeMutation, { data, loading, error }] = useEditContractScopeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditContractScopeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EditContractScopeMutation,
    EditContractScopeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    EditContractScopeMutation,
    EditContractScopeMutationVariables
  >(EditContractScopeDocument, options);
}
export type EditContractScopeMutationHookResult = ReturnType<typeof useEditContractScopeMutation>;
export type EditContractScopeMutationResult =
  ApolloReactCommon.MutationResult<EditContractScopeMutation>;
export type EditContractScopeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EditContractScopeMutation,
  EditContractScopeMutationVariables
>;
export const DecoupleOrgEntitlementsFromParentDocument = gql`
  mutation decoupleOrgEntitlementsFromParent($organizationId: Float!) {
    decoupleOrgEntitlementsFromParent(id: $organizationId)
  }
`;
export type DecoupleOrgEntitlementsFromParentMutationFn = ApolloReactCommon.MutationFunction<
  DecoupleOrgEntitlementsFromParentMutation,
  DecoupleOrgEntitlementsFromParentMutationVariables
>;

/**
 * __useDecoupleOrgEntitlementsFromParentMutation__
 *
 * To run a mutation, you first call `useDecoupleOrgEntitlementsFromParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDecoupleOrgEntitlementsFromParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [decoupleOrgEntitlementsFromParentMutation, { data, loading, error }] = useDecoupleOrgEntitlementsFromParentMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDecoupleOrgEntitlementsFromParentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DecoupleOrgEntitlementsFromParentMutation,
    DecoupleOrgEntitlementsFromParentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DecoupleOrgEntitlementsFromParentMutation,
    DecoupleOrgEntitlementsFromParentMutationVariables
  >(DecoupleOrgEntitlementsFromParentDocument, options);
}
export type DecoupleOrgEntitlementsFromParentMutationHookResult = ReturnType<
  typeof useDecoupleOrgEntitlementsFromParentMutation
>;
export type DecoupleOrgEntitlementsFromParentMutationResult =
  ApolloReactCommon.MutationResult<DecoupleOrgEntitlementsFromParentMutation>;
export type DecoupleOrgEntitlementsFromParentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DecoupleOrgEntitlementsFromParentMutation,
    DecoupleOrgEntitlementsFromParentMutationVariables
  >;
export const RecoupleOrgEntitlementsToParentDocument = gql`
  mutation recoupleOrgEntitlementsToParent($organizationId: Float!) {
    recoupleOrgEntitlementsToParent(id: $organizationId)
  }
`;
export type RecoupleOrgEntitlementsToParentMutationFn = ApolloReactCommon.MutationFunction<
  RecoupleOrgEntitlementsToParentMutation,
  RecoupleOrgEntitlementsToParentMutationVariables
>;

/**
 * __useRecoupleOrgEntitlementsToParentMutation__
 *
 * To run a mutation, you first call `useRecoupleOrgEntitlementsToParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoupleOrgEntitlementsToParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoupleOrgEntitlementsToParentMutation, { data, loading, error }] = useRecoupleOrgEntitlementsToParentMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useRecoupleOrgEntitlementsToParentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RecoupleOrgEntitlementsToParentMutation,
    RecoupleOrgEntitlementsToParentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RecoupleOrgEntitlementsToParentMutation,
    RecoupleOrgEntitlementsToParentMutationVariables
  >(RecoupleOrgEntitlementsToParentDocument, options);
}
export type RecoupleOrgEntitlementsToParentMutationHookResult = ReturnType<
  typeof useRecoupleOrgEntitlementsToParentMutation
>;
export type RecoupleOrgEntitlementsToParentMutationResult =
  ApolloReactCommon.MutationResult<RecoupleOrgEntitlementsToParentMutation>;
export type RecoupleOrgEntitlementsToParentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RecoupleOrgEntitlementsToParentMutation,
  RecoupleOrgEntitlementsToParentMutationVariables
>;
export const GetNoteByUuidDocument = gql`
  query getNoteByUuid($uuid: String!) {
    getNoteByUuid(uuid: $uuid) {
      id
      key
      submittedAt
      createdAt
      updatedAt
      template {
        ...medicalNoteTemplate
      }
      appointment {
        id
      }
    }
  }
  ${MedicalNoteTemplateFragmentDoc}
`;

/**
 * __useGetNoteByUuidQuery__
 *
 * To run a query within a React component, call `useGetNoteByUuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNoteByUuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNoteByUuidQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetNoteByUuidQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetNoteByUuidQuery, GetNoteByUuidQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetNoteByUuidQuery, GetNoteByUuidQueryVariables>(
    GetNoteByUuidDocument,
    options
  );
}
export function useGetNoteByUuidLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetNoteByUuidQuery,
    GetNoteByUuidQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetNoteByUuidQuery, GetNoteByUuidQueryVariables>(
    GetNoteByUuidDocument,
    options
  );
}
export type GetNoteByUuidQueryHookResult = ReturnType<typeof useGetNoteByUuidQuery>;
export type GetNoteByUuidLazyQueryHookResult = ReturnType<typeof useGetNoteByUuidLazyQuery>;
export type GetNoteByUuidQueryResult = ApolloReactCommon.QueryResult<
  GetNoteByUuidQuery,
  GetNoteByUuidQueryVariables
>;
export const ExportNoteDocument = gql`
  mutation exportNote($noteId: Float!) {
    adminCreateNoteExport(noteId: $noteId) {
      id
      status
      url
    }
  }
`;
export type ExportNoteMutationFn = ApolloReactCommon.MutationFunction<
  ExportNoteMutation,
  ExportNoteMutationVariables
>;

/**
 * __useExportNoteMutation__
 *
 * To run a mutation, you first call `useExportNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportNoteMutation, { data, loading, error }] = useExportNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useExportNoteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ExportNoteMutation,
    ExportNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ExportNoteMutation, ExportNoteMutationVariables>(
    ExportNoteDocument,
    options
  );
}
export type ExportNoteMutationHookResult = ReturnType<typeof useExportNoteMutation>;
export type ExportNoteMutationResult = ApolloReactCommon.MutationResult<ExportNoteMutation>;
export type ExportNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ExportNoteMutation,
  ExportNoteMutationVariables
>;
export const DeleteMedicalNoteDocument = gql`
  mutation deleteMedicalNote($uuid: String!) {
    deleteMedicalNote(uuid: $uuid)
  }
`;
export type DeleteMedicalNoteMutationFn = ApolloReactCommon.MutationFunction<
  DeleteMedicalNoteMutation,
  DeleteMedicalNoteMutationVariables
>;

/**
 * __useDeleteMedicalNoteMutation__
 *
 * To run a mutation, you first call `useDeleteMedicalNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMedicalNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMedicalNoteMutation, { data, loading, error }] = useDeleteMedicalNoteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteMedicalNoteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteMedicalNoteMutation,
    DeleteMedicalNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteMedicalNoteMutation,
    DeleteMedicalNoteMutationVariables
  >(DeleteMedicalNoteDocument, options);
}
export type DeleteMedicalNoteMutationHookResult = ReturnType<typeof useDeleteMedicalNoteMutation>;
export type DeleteMedicalNoteMutationResult =
  ApolloReactCommon.MutationResult<DeleteMedicalNoteMutation>;
export type DeleteMedicalNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteMedicalNoteMutation,
  DeleteMedicalNoteMutationVariables
>;
export const GetOrCreateNoteDocument = gql`
  mutation getOrCreateNote($key: String!, $userId: Float!) {
    note: getOrCreateNote(key: $key, userId: $userId) {
      id
      key
      appointment {
        id
      }
      template {
        ...medicalNoteTemplate
      }
    }
  }
  ${MedicalNoteTemplateFragmentDoc}
`;
export type GetOrCreateNoteMutationFn = ApolloReactCommon.MutationFunction<
  GetOrCreateNoteMutation,
  GetOrCreateNoteMutationVariables
>;

/**
 * __useGetOrCreateNoteMutation__
 *
 * To run a mutation, you first call `useGetOrCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetOrCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getOrCreateNoteMutation, { data, loading, error }] = useGetOrCreateNoteMutation({
 *   variables: {
 *      key: // value for 'key'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetOrCreateNoteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GetOrCreateNoteMutation,
    GetOrCreateNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<GetOrCreateNoteMutation, GetOrCreateNoteMutationVariables>(
    GetOrCreateNoteDocument,
    options
  );
}
export type GetOrCreateNoteMutationHookResult = ReturnType<typeof useGetOrCreateNoteMutation>;
export type GetOrCreateNoteMutationResult =
  ApolloReactCommon.MutationResult<GetOrCreateNoteMutation>;
export type GetOrCreateNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GetOrCreateNoteMutation,
  GetOrCreateNoteMutationVariables
>;
export const SaveNoteSectionsDocument = gql`
  mutation saveNoteSections($uuid: String!, $sections: [MedicalNoteSectionInput!]!) {
    saveNoteSections(uuid: $uuid, sections: $sections)
  }
`;
export type SaveNoteSectionsMutationFn = ApolloReactCommon.MutationFunction<
  SaveNoteSectionsMutation,
  SaveNoteSectionsMutationVariables
>;

/**
 * __useSaveNoteSectionsMutation__
 *
 * To run a mutation, you first call `useSaveNoteSectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNoteSectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNoteSectionsMutation, { data, loading, error }] = useSaveNoteSectionsMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      sections: // value for 'sections'
 *   },
 * });
 */
export function useSaveNoteSectionsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveNoteSectionsMutation,
    SaveNoteSectionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveNoteSectionsMutation, SaveNoteSectionsMutationVariables>(
    SaveNoteSectionsDocument,
    options
  );
}
export type SaveNoteSectionsMutationHookResult = ReturnType<typeof useSaveNoteSectionsMutation>;
export type SaveNoteSectionsMutationResult =
  ApolloReactCommon.MutationResult<SaveNoteSectionsMutation>;
export type SaveNoteSectionsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveNoteSectionsMutation,
  SaveNoteSectionsMutationVariables
>;
export const SubmitNoteDocument = gql`
  mutation submitNote($uuid: String!, $sections: [MedicalNoteSectionInput!]!) {
    submitNote(uuid: $uuid, sections: $sections) {
      id
      submittedAt
    }
  }
`;
export type SubmitNoteMutationFn = ApolloReactCommon.MutationFunction<
  SubmitNoteMutation,
  SubmitNoteMutationVariables
>;

/**
 * __useSubmitNoteMutation__
 *
 * To run a mutation, you first call `useSubmitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitNoteMutation, { data, loading, error }] = useSubmitNoteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      sections: // value for 'sections'
 *   },
 * });
 */
export function useSubmitNoteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubmitNoteMutation,
    SubmitNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SubmitNoteMutation, SubmitNoteMutationVariables>(
    SubmitNoteDocument,
    options
  );
}
export type SubmitNoteMutationHookResult = ReturnType<typeof useSubmitNoteMutation>;
export type SubmitNoteMutationResult = ApolloReactCommon.MutationResult<SubmitNoteMutation>;
export type SubmitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SubmitNoteMutation,
  SubmitNoteMutationVariables
>;
export const OrganizationUsersDocument = gql`
  query organizationUsers($organizationId: Float) {
    users(organizationId: $organizationId) {
      ...userBasic
      email
      createdAt
      careStatus
      taskStatus
      provider {
        id
        name
      }
      therapist {
        ...provider
      }
      organization {
        ...organization
      }
      continuityOfCareTransitions {
        user {
          id
          organization {
            id
            latestCarePeriod {
              id
              endDate
            }
          }
        }
        careType
        nextSteps
        pendingUser
      }
      careFlows {
        ...careFlowAndSessions
      }
    }
    providers {
      id
      name
      role
    }
  }
  ${UserBasicFragmentDoc}
  ${ProviderFragmentDoc}
  ${OrganizationFragmentDoc}
  ${CareFlowAndSessionsFragmentDoc}
`;

/**
 * __useOrganizationUsersQuery__
 *
 * To run a query within a React component, call `useOrganizationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationUsersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationUsersQuery,
    OrganizationUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrganizationUsersQuery, OrganizationUsersQueryVariables>(
    OrganizationUsersDocument,
    options
  );
}
export function useOrganizationUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationUsersQuery,
    OrganizationUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrganizationUsersQuery, OrganizationUsersQueryVariables>(
    OrganizationUsersDocument,
    options
  );
}
export type OrganizationUsersQueryHookResult = ReturnType<typeof useOrganizationUsersQuery>;
export type OrganizationUsersLazyQueryHookResult = ReturnType<typeof useOrganizationUsersLazyQuery>;
export type OrganizationUsersQueryResult = ApolloReactCommon.QueryResult<
  OrganizationUsersQuery,
  OrganizationUsersQueryVariables
>;
export const UsersVerificationListDocument = gql`
  query usersVerificationList {
    users {
      ...userBasic
      email
      birthDate
      verificationStatus
      identificationCard {
        ...upload
      }
      selfie {
        ...upload
      }
    }
  }
  ${UserBasicFragmentDoc}
  ${UploadFragmentDoc}
`;

/**
 * __useUsersVerificationListQuery__
 *
 * To run a query within a React component, call `useUsersVerificationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersVerificationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersVerificationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersVerificationListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UsersVerificationListQuery,
    UsersVerificationListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UsersVerificationListQuery, UsersVerificationListQueryVariables>(
    UsersVerificationListDocument,
    options
  );
}
export function useUsersVerificationListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UsersVerificationListQuery,
    UsersVerificationListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UsersVerificationListQuery,
    UsersVerificationListQueryVariables
  >(UsersVerificationListDocument, options);
}
export type UsersVerificationListQueryHookResult = ReturnType<typeof useUsersVerificationListQuery>;
export type UsersVerificationListLazyQueryHookResult = ReturnType<
  typeof useUsersVerificationListLazyQuery
>;
export type UsersVerificationListQueryResult = ApolloReactCommon.QueryResult<
  UsersVerificationListQuery,
  UsersVerificationListQueryVariables
>;
export const AdminUserMessagesDocument = gql`
  query adminUserMessages($id: Float!) {
    adminUser(id: $id) {
      id
      privateChannel {
        ...listChannel
      }
    }
  }
  ${ListChannelFragmentDoc}
`;

/**
 * __useAdminUserMessagesQuery__
 *
 * To run a query within a React component, call `useAdminUserMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUserMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUserMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminUserMessagesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminUserMessagesQuery,
    AdminUserMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminUserMessagesQuery, AdminUserMessagesQueryVariables>(
    AdminUserMessagesDocument,
    options
  );
}
export function useAdminUserMessagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminUserMessagesQuery,
    AdminUserMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminUserMessagesQuery, AdminUserMessagesQueryVariables>(
    AdminUserMessagesDocument,
    options
  );
}
export type AdminUserMessagesQueryHookResult = ReturnType<typeof useAdminUserMessagesQuery>;
export type AdminUserMessagesLazyQueryHookResult = ReturnType<typeof useAdminUserMessagesLazyQuery>;
export type AdminUserMessagesQueryResult = ApolloReactCommon.QueryResult<
  AdminUserMessagesQuery,
  AdminUserMessagesQueryVariables
>;
export const MedicationsForUserDocument = gql`
  query medicationsForUser($userId: Float!) {
    medicationsForUser(userId: $userId) {
      prescriptionId
      displayName
      writtenDate
      strength
      quantity
      medicationStatus
      status
    }
  }
`;

/**
 * __useMedicationsForUserQuery__
 *
 * To run a query within a React component, call `useMedicationsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicationsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicationsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMedicationsForUserQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    MedicationsForUserQuery,
    MedicationsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MedicationsForUserQuery, MedicationsForUserQueryVariables>(
    MedicationsForUserDocument,
    options
  );
}
export function useMedicationsForUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MedicationsForUserQuery,
    MedicationsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MedicationsForUserQuery, MedicationsForUserQueryVariables>(
    MedicationsForUserDocument,
    options
  );
}
export type MedicationsForUserQueryHookResult = ReturnType<typeof useMedicationsForUserQuery>;
export type MedicationsForUserLazyQueryHookResult = ReturnType<
  typeof useMedicationsForUserLazyQuery
>;
export type MedicationsForUserQueryResult = ApolloReactCommon.QueryResult<
  MedicationsForUserQuery,
  MedicationsForUserQueryVariables
>;
export const NextAppointmentTimeDocument = gql`
  query nextAppointmentTime($userId: Float!) {
    adminUser(id: $userId) {
      id
      nextAppointmentTime
    }
  }
`;

/**
 * __useNextAppointmentTimeQuery__
 *
 * To run a query within a React component, call `useNextAppointmentTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useNextAppointmentTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextAppointmentTimeQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useNextAppointmentTimeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    NextAppointmentTimeQuery,
    NextAppointmentTimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<NextAppointmentTimeQuery, NextAppointmentTimeQueryVariables>(
    NextAppointmentTimeDocument,
    options
  );
}
export function useNextAppointmentTimeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    NextAppointmentTimeQuery,
    NextAppointmentTimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<NextAppointmentTimeQuery, NextAppointmentTimeQueryVariables>(
    NextAppointmentTimeDocument,
    options
  );
}
export type NextAppointmentTimeQueryHookResult = ReturnType<typeof useNextAppointmentTimeQuery>;
export type NextAppointmentTimeLazyQueryHookResult = ReturnType<
  typeof useNextAppointmentTimeLazyQuery
>;
export type NextAppointmentTimeQueryResult = ApolloReactCommon.QueryResult<
  NextAppointmentTimeQuery,
  NextAppointmentTimeQueryVariables
>;
export const GetAllNotesForUserDocument = gql`
  query getAllNotesForUser($id: Float!) {
    adminUser(id: $id) {
      id
      notes {
        id
        key
        submittedAt
        template {
          key
          uuid
          title
          questions {
            ...medNoteSectionBase
            children {
              ...medNoteSectionBase
            }
          }
        }
        provider {
          id
          name
        }
        createdAt
        updatedAt
        appointment {
          id
          startTime
          description
        }
      }
    }
  }
  ${MedNoteSectionBaseFragmentDoc}
`;

/**
 * __useGetAllNotesForUserQuery__
 *
 * To run a query within a React component, call `useGetAllNotesForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNotesForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNotesForUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAllNotesForUserQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAllNotesForUserQuery,
    GetAllNotesForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetAllNotesForUserQuery, GetAllNotesForUserQueryVariables>(
    GetAllNotesForUserDocument,
    options
  );
}
export function useGetAllNotesForUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllNotesForUserQuery,
    GetAllNotesForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetAllNotesForUserQuery, GetAllNotesForUserQueryVariables>(
    GetAllNotesForUserDocument,
    options
  );
}
export type GetAllNotesForUserQueryHookResult = ReturnType<typeof useGetAllNotesForUserQuery>;
export type GetAllNotesForUserLazyQueryHookResult = ReturnType<
  typeof useGetAllNotesForUserLazyQuery
>;
export type GetAllNotesForUserQueryResult = ApolloReactCommon.QueryResult<
  GetAllNotesForUserQuery,
  GetAllNotesForUserQueryVariables
>;
export const GetEligibilityResultDocument = gql`
  query getEligibilityResult($submissionId: Float!) {
    getEligibilityResult(submissionId: $submissionId) {
      providerInState
      canContinueCare
      needsReferral
      isEligible
    }
  }
`;

/**
 * __useGetEligibilityResultQuery__
 *
 * To run a query within a React component, call `useGetEligibilityResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibilityResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligibilityResultQuery({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useGetEligibilityResultQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetEligibilityResultQuery,
    GetEligibilityResultQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetEligibilityResultQuery, GetEligibilityResultQueryVariables>(
    GetEligibilityResultDocument,
    options
  );
}
export function useGetEligibilityResultLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetEligibilityResultQuery,
    GetEligibilityResultQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetEligibilityResultQuery,
    GetEligibilityResultQueryVariables
  >(GetEligibilityResultDocument, options);
}
export type GetEligibilityResultQueryHookResult = ReturnType<typeof useGetEligibilityResultQuery>;
export type GetEligibilityResultLazyQueryHookResult = ReturnType<
  typeof useGetEligibilityResultLazyQuery
>;
export type GetEligibilityResultQueryResult = ApolloReactCommon.QueryResult<
  GetEligibilityResultQuery,
  GetEligibilityResultQueryVariables
>;
export const GetEligibilityCarePeriodValuesDocument = gql`
  query getEligibilityCarePeriodValues($submissionId: Float!) {
    getEligibilityCarePeriodValues(submissionId: $submissionId) {
      currentPeriod {
        startDate
        endDate
      }
      nextPeriod {
        startDate
        endDate
      }
      isCarePeriodEnding
      firstDayOfBreak
      lastDayOfBreak
      lastDayOfTerm
      isEndingDueToBreak
    }
  }
`;

/**
 * __useGetEligibilityCarePeriodValuesQuery__
 *
 * To run a query within a React component, call `useGetEligibilityCarePeriodValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibilityCarePeriodValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligibilityCarePeriodValuesQuery({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useGetEligibilityCarePeriodValuesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetEligibilityCarePeriodValuesQuery,
    GetEligibilityCarePeriodValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetEligibilityCarePeriodValuesQuery,
    GetEligibilityCarePeriodValuesQueryVariables
  >(GetEligibilityCarePeriodValuesDocument, options);
}
export function useGetEligibilityCarePeriodValuesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetEligibilityCarePeriodValuesQuery,
    GetEligibilityCarePeriodValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetEligibilityCarePeriodValuesQuery,
    GetEligibilityCarePeriodValuesQueryVariables
  >(GetEligibilityCarePeriodValuesDocument, options);
}
export type GetEligibilityCarePeriodValuesQueryHookResult = ReturnType<
  typeof useGetEligibilityCarePeriodValuesQuery
>;
export type GetEligibilityCarePeriodValuesLazyQueryHookResult = ReturnType<
  typeof useGetEligibilityCarePeriodValuesLazyQuery
>;
export type GetEligibilityCarePeriodValuesQueryResult = ApolloReactCommon.QueryResult<
  GetEligibilityCarePeriodValuesQuery,
  GetEligibilityCarePeriodValuesQueryVariables
>;
export const GetAllNotesAndFormSubmissionsForUserDocument = gql`
  query getAllNotesAndFormSubmissionsForUser($id: Float!) {
    adminUser(id: $id) {
      id
      notes {
        ... on MedicalNote {
          noteType: __typename
          id
          key
          submittedAt
          template {
            key
            uuid
            title
            questions {
              ...medNoteSectionBase
              children {
                ...medNoteSectionBase
              }
            }
          }
          provider {
            id
            name
          }
          createdAt
          updatedAt
          appointment {
            id
            startTime
            description
          }
        }
      }
      continuityOfCareEligibility {
        ... on ContinuityOfCareEligibilityModel {
          noteType: __typename
          id
          careType
          residentialLocationDuringBreak
          interestedInContinuingCare
          enrollment
          additionalNotes
          createdAt
          updatedAt
          paymentType
          remainingSessions
          provider {
            id
            name
          }
          completedBy {
            id
            name
          }
        }
      }
      triageInfo {
        assessmentId
        createdAt
        updatedAt
        answers {
          key
          value
          text
          legend
        }
      }
    }
    adminUserDemographics(id: $id) {
      id
      genderIdentity
      ethnicity
      isInternationalStudent
      sexualOrientation
      academicProgram
      yearInProgram
      createdAt
      updatedAt
    }
  }
  ${MedNoteSectionBaseFragmentDoc}
`;

/**
 * __useGetAllNotesAndFormSubmissionsForUserQuery__
 *
 * To run a query within a React component, call `useGetAllNotesAndFormSubmissionsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNotesAndFormSubmissionsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNotesAndFormSubmissionsForUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAllNotesAndFormSubmissionsForUserQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAllNotesAndFormSubmissionsForUserQuery,
    GetAllNotesAndFormSubmissionsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetAllNotesAndFormSubmissionsForUserQuery,
    GetAllNotesAndFormSubmissionsForUserQueryVariables
  >(GetAllNotesAndFormSubmissionsForUserDocument, options);
}
export function useGetAllNotesAndFormSubmissionsForUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllNotesAndFormSubmissionsForUserQuery,
    GetAllNotesAndFormSubmissionsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAllNotesAndFormSubmissionsForUserQuery,
    GetAllNotesAndFormSubmissionsForUserQueryVariables
  >(GetAllNotesAndFormSubmissionsForUserDocument, options);
}
export type GetAllNotesAndFormSubmissionsForUserQueryHookResult = ReturnType<
  typeof useGetAllNotesAndFormSubmissionsForUserQuery
>;
export type GetAllNotesAndFormSubmissionsForUserLazyQueryHookResult = ReturnType<
  typeof useGetAllNotesAndFormSubmissionsForUserLazyQuery
>;
export type GetAllNotesAndFormSubmissionsForUserQueryResult = ApolloReactCommon.QueryResult<
  GetAllNotesAndFormSubmissionsForUserQuery,
  GetAllNotesAndFormSubmissionsForUserQueryVariables
>;
export const AdminGetAllocationsDocument = gql`
  query adminGetAllocations($providerId: Float!) {
    adminGetAllocations(providerId: $providerId) {
      id
      startTime
      endTime
      isFeeForServiceTime
      repeatsUntil
      weekly
      purpose
      timezone
      provider {
        id
        name
        careTypes
      }
      organization {
        ...organization
        childrenCount
      }
      childOrganizations {
        ...organization
      }
      appointmentTypes
    }
  }
  ${OrganizationFragmentDoc}
`;

/**
 * __useAdminGetAllocationsQuery__
 *
 * To run a query within a React component, call `useAdminGetAllocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetAllocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetAllocationsQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useAdminGetAllocationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminGetAllocationsQuery,
    AdminGetAllocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminGetAllocationsQuery, AdminGetAllocationsQueryVariables>(
    AdminGetAllocationsDocument,
    options
  );
}
export function useAdminGetAllocationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminGetAllocationsQuery,
    AdminGetAllocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminGetAllocationsQuery, AdminGetAllocationsQueryVariables>(
    AdminGetAllocationsDocument,
    options
  );
}
export type AdminGetAllocationsQueryHookResult = ReturnType<typeof useAdminGetAllocationsQuery>;
export type AdminGetAllocationsLazyQueryHookResult = ReturnType<
  typeof useAdminGetAllocationsLazyQuery
>;
export type AdminGetAllocationsQueryResult = ApolloReactCommon.QueryResult<
  AdminGetAllocationsQuery,
  AdminGetAllocationsQueryVariables
>;
export const AdminAppointmentsDocument = gql`
  query adminAppointments(
    $allProviders: Boolean
    $userId: Float
    $startTime: DateTime
    $endTime: DateTime
  ) {
    adminAppointments(
      allProviders: $allProviders
      userId: $userId
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      startTime
      endTime
      description
      appointmentType
      lateCancelOrReschedule: cancelledOrRescheduleWithin(diffHours: 24)
      status
      isBlocked
      checkedInAt
      interpretationServices
      provider {
        id
        name
      }
      user {
        id
        firstName
        lastName
        customerId
        email
        organization {
          id
          name
          abbreviation
          entitlements {
            ...organizationEntitlement
          }
        }
        selfie {
          url
        }
        timeZone
        phone
        campusTeam {
          relationshipType
          provider {
            id
          }
        }
        primaryAddressState
      }
    }
  }
  ${OrganizationEntitlementFragmentDoc}
`;

/**
 * __useAdminAppointmentsQuery__
 *
 * To run a query within a React component, call `useAdminAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAppointmentsQuery({
 *   variables: {
 *      allProviders: // value for 'allProviders'
 *      userId: // value for 'userId'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useAdminAppointmentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminAppointmentsQuery,
    AdminAppointmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminAppointmentsQuery, AdminAppointmentsQueryVariables>(
    AdminAppointmentsDocument,
    options
  );
}
export function useAdminAppointmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminAppointmentsQuery,
    AdminAppointmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminAppointmentsQuery, AdminAppointmentsQueryVariables>(
    AdminAppointmentsDocument,
    options
  );
}
export type AdminAppointmentsQueryHookResult = ReturnType<typeof useAdminAppointmentsQuery>;
export type AdminAppointmentsLazyQueryHookResult = ReturnType<typeof useAdminAppointmentsLazyQuery>;
export type AdminAppointmentsQueryResult = ApolloReactCommon.QueryResult<
  AdminAppointmentsQuery,
  AdminAppointmentsQueryVariables
>;
export const AdminDosespotLinkDocument = gql`
  query adminDosespotLink($userId: Float!) {
    adminDosespotLink(userId: $userId)
  }
`;

/**
 * __useAdminDosespotLinkQuery__
 *
 * To run a query within a React component, call `useAdminDosespotLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDosespotLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDosespotLinkQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminDosespotLinkQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminDosespotLinkQuery,
    AdminDosespotLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminDosespotLinkQuery, AdminDosespotLinkQueryVariables>(
    AdminDosespotLinkDocument,
    options
  );
}
export function useAdminDosespotLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminDosespotLinkQuery,
    AdminDosespotLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminDosespotLinkQuery, AdminDosespotLinkQueryVariables>(
    AdminDosespotLinkDocument,
    options
  );
}
export type AdminDosespotLinkQueryHookResult = ReturnType<typeof useAdminDosespotLinkQuery>;
export type AdminDosespotLinkLazyQueryHookResult = ReturnType<typeof useAdminDosespotLinkLazyQuery>;
export type AdminDosespotLinkQueryResult = ApolloReactCommon.QueryResult<
  AdminDosespotLinkQuery,
  AdminDosespotLinkQueryVariables
>;
export const AdminUserPaymentInfoDocument = gql`
  query adminUserPaymentInfo($id: Float!) {
    adminUser(id: $id) {
      id
      invoices {
        id
        description
        amount
        paid
        createdAt
      }
      paymentInfo {
        id
        name
        lastFour
        expirationDate
        cardType
        url
      }
    }
  }
`;

/**
 * __useAdminUserPaymentInfoQuery__
 *
 * To run a query within a React component, call `useAdminUserPaymentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUserPaymentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUserPaymentInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminUserPaymentInfoQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminUserPaymentInfoQuery,
    AdminUserPaymentInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminUserPaymentInfoQuery, AdminUserPaymentInfoQueryVariables>(
    AdminUserPaymentInfoDocument,
    options
  );
}
export function useAdminUserPaymentInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminUserPaymentInfoQuery,
    AdminUserPaymentInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminUserPaymentInfoQuery,
    AdminUserPaymentInfoQueryVariables
  >(AdminUserPaymentInfoDocument, options);
}
export type AdminUserPaymentInfoQueryHookResult = ReturnType<typeof useAdminUserPaymentInfoQuery>;
export type AdminUserPaymentInfoLazyQueryHookResult = ReturnType<
  typeof useAdminUserPaymentInfoLazyQuery
>;
export type AdminUserPaymentInfoQueryResult = ApolloReactCommon.QueryResult<
  AdminUserPaymentInfoQuery,
  AdminUserPaymentInfoQueryVariables
>;
export const SelectAttendeesDocument = gql`
  query selectAttendees($includeNewPatients: Boolean) {
    currentProvider {
      id
      role
      name
      careTypes
      organizations {
        ...organization
      }
    }
    users(includeNewPatients: $includeNewPatients) {
      ...userBasic
    }
    providers(roles: "provider") {
      id
      name
      role
      careTypes
      geoStates
      qualifications
      organizations {
        id
      }
    }
  }
  ${OrganizationFragmentDoc}
  ${UserBasicFragmentDoc}
`;

/**
 * __useSelectAttendeesQuery__
 *
 * To run a query within a React component, call `useSelectAttendeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectAttendeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectAttendeesQuery({
 *   variables: {
 *      includeNewPatients: // value for 'includeNewPatients'
 *   },
 * });
 */
export function useSelectAttendeesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SelectAttendeesQuery,
    SelectAttendeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SelectAttendeesQuery, SelectAttendeesQueryVariables>(
    SelectAttendeesDocument,
    options
  );
}
export function useSelectAttendeesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SelectAttendeesQuery,
    SelectAttendeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SelectAttendeesQuery, SelectAttendeesQueryVariables>(
    SelectAttendeesDocument,
    options
  );
}
export type SelectAttendeesQueryHookResult = ReturnType<typeof useSelectAttendeesQuery>;
export type SelectAttendeesLazyQueryHookResult = ReturnType<typeof useSelectAttendeesLazyQuery>;
export type SelectAttendeesQueryResult = ApolloReactCommon.QueryResult<
  SelectAttendeesQuery,
  SelectAttendeesQueryVariables
>;
export const AttendeeDetailsDocument = gql`
  query attendeeDetails($id: Float!) {
    patient: adminUser(id: $id) {
      ...basicAppointmentUser
      primaryAddress {
        state
      }
      organization {
        ...organization
        parent {
          ...organization
        }
        entitlements {
          ...organizationEntitlement
        }
      }
      provider {
        id
        name
      }
      therapist {
        id
        name
      }
      careFlows {
        careType
        paymentSource
      }
    }
  }
  ${BasicAppointmentUserFragmentDoc}
  ${OrganizationFragmentDoc}
  ${OrganizationEntitlementFragmentDoc}
`;

/**
 * __useAttendeeDetailsQuery__
 *
 * To run a query within a React component, call `useAttendeeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeeDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttendeeDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AttendeeDetailsQuery,
    AttendeeDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AttendeeDetailsQuery, AttendeeDetailsQueryVariables>(
    AttendeeDetailsDocument,
    options
  );
}
export function useAttendeeDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AttendeeDetailsQuery,
    AttendeeDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AttendeeDetailsQuery, AttendeeDetailsQueryVariables>(
    AttendeeDetailsDocument,
    options
  );
}
export type AttendeeDetailsQueryHookResult = ReturnType<typeof useAttendeeDetailsQuery>;
export type AttendeeDetailsLazyQueryHookResult = ReturnType<typeof useAttendeeDetailsLazyQuery>;
export type AttendeeDetailsQueryResult = ApolloReactCommon.QueryResult<
  AttendeeDetailsQuery,
  AttendeeDetailsQueryVariables
>;
export const AppointmentAttendeeDocument = gql`
  query appointmentAttendee($id: Float!) {
    adminUser(id: $id) {
      ...appointmentAttendee
    }
  }
  ${AppointmentAttendeeFragmentDoc}
`;

/**
 * __useAppointmentAttendeeQuery__
 *
 * To run a query within a React component, call `useAppointmentAttendeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentAttendeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentAttendeeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppointmentAttendeeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AppointmentAttendeeQuery,
    AppointmentAttendeeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AppointmentAttendeeQuery, AppointmentAttendeeQueryVariables>(
    AppointmentAttendeeDocument,
    options
  );
}
export function useAppointmentAttendeeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AppointmentAttendeeQuery,
    AppointmentAttendeeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AppointmentAttendeeQuery, AppointmentAttendeeQueryVariables>(
    AppointmentAttendeeDocument,
    options
  );
}
export type AppointmentAttendeeQueryHookResult = ReturnType<typeof useAppointmentAttendeeQuery>;
export type AppointmentAttendeeLazyQueryHookResult = ReturnType<
  typeof useAppointmentAttendeeLazyQuery
>;
export type AppointmentAttendeeQueryResult = ApolloReactCommon.QueryResult<
  AppointmentAttendeeQuery,
  AppointmentAttendeeQueryVariables
>;
export const ProvidersDocument = gql`
  query providers {
    providers {
      ...provider
      organizations {
        id
        name
        children {
          id
          name
        }
      }
    }
  }
  ${ProviderFragmentDoc}
`;

/**
 * __useProvidersQuery__
 *
 * To run a query within a React component, call `useProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useProvidersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ProvidersQuery, ProvidersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ProvidersQuery, ProvidersQueryVariables>(
    ProvidersDocument,
    options
  );
}
export function useProvidersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProvidersQuery, ProvidersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ProvidersQuery, ProvidersQueryVariables>(
    ProvidersDocument,
    options
  );
}
export type ProvidersQueryHookResult = ReturnType<typeof useProvidersQuery>;
export type ProvidersLazyQueryHookResult = ReturnType<typeof useProvidersLazyQuery>;
export type ProvidersQueryResult = ApolloReactCommon.QueryResult<
  ProvidersQuery,
  ProvidersQueryVariables
>;
export const EditPatientInfoDocument = gql`
  query editPatientInfo($userId: Float!) {
    providersForUser(userId: $userId) {
      data {
        ...provider
        organizations {
          id
          name
          children {
            id
            name
          }
        }
      }
      blockedReason
    }
    organizations {
      id
      name
      emailDomains
      children {
        id
      }
    }
  }
  ${ProviderFragmentDoc}
`;

/**
 * __useEditPatientInfoQuery__
 *
 * To run a query within a React component, call `useEditPatientInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPatientInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPatientInfoQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEditPatientInfoQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EditPatientInfoQuery,
    EditPatientInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EditPatientInfoQuery, EditPatientInfoQueryVariables>(
    EditPatientInfoDocument,
    options
  );
}
export function useEditPatientInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EditPatientInfoQuery,
    EditPatientInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EditPatientInfoQuery, EditPatientInfoQueryVariables>(
    EditPatientInfoDocument,
    options
  );
}
export type EditPatientInfoQueryHookResult = ReturnType<typeof useEditPatientInfoQuery>;
export type EditPatientInfoLazyQueryHookResult = ReturnType<typeof useEditPatientInfoLazyQuery>;
export type EditPatientInfoQueryResult = ApolloReactCommon.QueryResult<
  EditPatientInfoQuery,
  EditPatientInfoQueryVariables
>;
export const CurrentProviderDocument = gql`
  query currentProvider {
    currentProvider {
      ...baseProvider
      firstName
      lastName
      postNominalTitles
      pronouns
      portrait {
        url
      }
      twoFactorConfig {
        enabled
        phoneVerified
        phone
      }
      classification
      accountSecurityStatus
      permissions
      appView
      phone
      email
      npi
      careTypes
      hasDosespot
      hasSso
      organizations {
        ...organization
        entitlements {
          key
          config
        }
        careFlows {
          id
          careType
          defaultPaymentSource
          statesAvailable
          referralRestrictedStates
        }
        children {
          ...organization
        }
        parent {
          ...organization
        }
      }
      insuranceCredentials {
        id
        payer {
          id
          name
        }
        state
        active
      }
    }
    patientsMonitoredCount
  }
  ${BaseProviderFragmentDoc}
  ${OrganizationFragmentDoc}
`;

/**
 * __useCurrentProviderQuery__
 *
 * To run a query within a React component, call `useCurrentProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentProviderQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentProviderQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CurrentProviderQuery,
    CurrentProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CurrentProviderQuery, CurrentProviderQueryVariables>(
    CurrentProviderDocument,
    options
  );
}
export function useCurrentProviderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CurrentProviderQuery,
    CurrentProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CurrentProviderQuery, CurrentProviderQueryVariables>(
    CurrentProviderDocument,
    options
  );
}
export type CurrentProviderQueryHookResult = ReturnType<typeof useCurrentProviderQuery>;
export type CurrentProviderLazyQueryHookResult = ReturnType<typeof useCurrentProviderLazyQuery>;
export type CurrentProviderQueryResult = ApolloReactCommon.QueryResult<
  CurrentProviderQuery,
  CurrentProviderQueryVariables
>;
export const ConfigDocument = gql`
  query config {
    featureFlags
    dtcPsychiatryStates
    dtcTherapyStates
  }
`;

/**
 * __useConfigQuery__
 *
 * To run a query within a React component, call `useConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ConfigQuery, ConfigQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConfigQuery, ConfigQueryVariables>(ConfigDocument, options);
}
export function useConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConfigQuery, ConfigQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ConfigQuery, ConfigQueryVariables>(ConfigDocument, options);
}
export type ConfigQueryHookResult = ReturnType<typeof useConfigQuery>;
export type ConfigLazyQueryHookResult = ReturnType<typeof useConfigLazyQuery>;
export type ConfigQueryResult = ApolloReactCommon.QueryResult<ConfigQuery, ConfigQueryVariables>;
export const AdminProviderTwoFactorConfigDocument = gql`
  query adminProviderTwoFactorConfig($providerId: Float!) {
    adminProvider(providerId: $providerId) {
      ...baseProvider
      twoFactorConfig {
        enabled
        phoneVerified
        phone
      }
    }
  }
  ${BaseProviderFragmentDoc}
`;

/**
 * __useAdminProviderTwoFactorConfigQuery__
 *
 * To run a query within a React component, call `useAdminProviderTwoFactorConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProviderTwoFactorConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProviderTwoFactorConfigQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useAdminProviderTwoFactorConfigQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminProviderTwoFactorConfigQuery,
    AdminProviderTwoFactorConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AdminProviderTwoFactorConfigQuery,
    AdminProviderTwoFactorConfigQueryVariables
  >(AdminProviderTwoFactorConfigDocument, options);
}
export function useAdminProviderTwoFactorConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminProviderTwoFactorConfigQuery,
    AdminProviderTwoFactorConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminProviderTwoFactorConfigQuery,
    AdminProviderTwoFactorConfigQueryVariables
  >(AdminProviderTwoFactorConfigDocument, options);
}
export type AdminProviderTwoFactorConfigQueryHookResult = ReturnType<
  typeof useAdminProviderTwoFactorConfigQuery
>;
export type AdminProviderTwoFactorConfigLazyQueryHookResult = ReturnType<
  typeof useAdminProviderTwoFactorConfigLazyQuery
>;
export type AdminProviderTwoFactorConfigQueryResult = ApolloReactCommon.QueryResult<
  AdminProviderTwoFactorConfigQuery,
  AdminProviderTwoFactorConfigQueryVariables
>;
export const SimpleAdminProviderDocument = gql`
  query simpleAdminProvider($providerId: Float!) {
    adminProvider(providerId: $providerId) {
      ...baseProvider
      firstName
      lastName
      personalTitle
      postNominalTitles
      classification
      phone
      pronouns
      email
      npi
      unsubscribedAt
      portrait {
        url
      }
      organizations {
        ...organization
      }
      qualifications2 {
        id
        name
        abbreviation
        states
        licences
      }
      degrees {
        id
        university
        degree
        abbreviation
      }
      certifications {
        id
        name
        abbreviation
      }
      insuranceCredentials {
        id
        payer {
          id
          name
        }
        state
        active
        createdAt
        updatedAt
      }
    }
  }
  ${BaseProviderFragmentDoc}
  ${OrganizationFragmentDoc}
`;

/**
 * __useSimpleAdminProviderQuery__
 *
 * To run a query within a React component, call `useSimpleAdminProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleAdminProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleAdminProviderQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useSimpleAdminProviderQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SimpleAdminProviderQuery,
    SimpleAdminProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SimpleAdminProviderQuery, SimpleAdminProviderQueryVariables>(
    SimpleAdminProviderDocument,
    options
  );
}
export function useSimpleAdminProviderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SimpleAdminProviderQuery,
    SimpleAdminProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SimpleAdminProviderQuery, SimpleAdminProviderQueryVariables>(
    SimpleAdminProviderDocument,
    options
  );
}
export type SimpleAdminProviderQueryHookResult = ReturnType<typeof useSimpleAdminProviderQuery>;
export type SimpleAdminProviderLazyQueryHookResult = ReturnType<
  typeof useSimpleAdminProviderLazyQuery
>;
export type SimpleAdminProviderQueryResult = ApolloReactCommon.QueryResult<
  SimpleAdminProviderQuery,
  SimpleAdminProviderQueryVariables
>;
export const AdminProviderDocument = gql`
  query adminProvider($providerId: Float!) {
    adminProvider(providerId: $providerId) {
      ...baseProvider
      firstName
      lastName
      personalTitle
      postNominalTitles
      classification
      phone
      pronouns
      email
      npi
      unsubscribedAt
      twoFactorConfig {
        enabled
        phoneVerified
        phone
      }
      portrait {
        url
      }
      patients {
        ...userBasic
        createdAt
        selfie {
          ...upload
        }
      }
      organizations {
        ...organization
      }
      qualifications2 {
        id
        name
        abbreviation
        states
        licences
      }
      degrees {
        id
        university
        degree
        abbreviation
      }
      certifications {
        id
        name
        abbreviation
      }
      insuranceCredentials {
        id
        payer {
          id
          name
        }
        state
        active
        createdAt
        updatedAt
      }
    }
  }
  ${BaseProviderFragmentDoc}
  ${UserBasicFragmentDoc}
  ${UploadFragmentDoc}
  ${OrganizationFragmentDoc}
`;

/**
 * __useAdminProviderQuery__
 *
 * To run a query within a React component, call `useAdminProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProviderQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useAdminProviderQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AdminProviderQuery, AdminProviderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminProviderQuery, AdminProviderQueryVariables>(
    AdminProviderDocument,
    options
  );
}
export function useAdminProviderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminProviderQuery,
    AdminProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminProviderQuery, AdminProviderQueryVariables>(
    AdminProviderDocument,
    options
  );
}
export type AdminProviderQueryHookResult = ReturnType<typeof useAdminProviderQuery>;
export type AdminProviderLazyQueryHookResult = ReturnType<typeof useAdminProviderLazyQuery>;
export type AdminProviderQueryResult = ApolloReactCommon.QueryResult<
  AdminProviderQuery,
  AdminProviderQueryVariables
>;
export const EventsDocument = gql`
  query events($userId: Float!) {
    events(userId: $userId) {
      id
      tag
      createdAt
      data
    }
  }
`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEventsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<EventsQuery, EventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
}
export function useEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
}
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = ApolloReactCommon.QueryResult<EventsQuery, EventsQueryVariables>;
export const GetAllUploadsOnUserDocument = gql`
  query getAllUploadsOnUser($id: Float!) {
    adminUser(id: $id) {
      id
      uploads {
        ...upload
      }
    }
  }
  ${UploadFragmentDoc}
`;

/**
 * __useGetAllUploadsOnUserQuery__
 *
 * To run a query within a React component, call `useGetAllUploadsOnUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUploadsOnUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUploadsOnUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAllUploadsOnUserQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAllUploadsOnUserQuery,
    GetAllUploadsOnUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetAllUploadsOnUserQuery, GetAllUploadsOnUserQueryVariables>(
    GetAllUploadsOnUserDocument,
    options
  );
}
export function useGetAllUploadsOnUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllUploadsOnUserQuery,
    GetAllUploadsOnUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetAllUploadsOnUserQuery, GetAllUploadsOnUserQueryVariables>(
    GetAllUploadsOnUserDocument,
    options
  );
}
export type GetAllUploadsOnUserQueryHookResult = ReturnType<typeof useGetAllUploadsOnUserQuery>;
export type GetAllUploadsOnUserLazyQueryHookResult = ReturnType<
  typeof useGetAllUploadsOnUserLazyQuery
>;
export type GetAllUploadsOnUserQueryResult = ApolloReactCommon.QueryResult<
  GetAllUploadsOnUserQuery,
  GetAllUploadsOnUserQueryVariables
>;
export const AdminAppointmentDocument = gql`
  query adminAppointment($id: Float!) {
    adminAppointment(id: $id) {
      id
      status
      startTime
      endTime
      appointmentType
      description
      careType
      checkedInAt
      user {
        ...basicAppointmentUser
        provider {
          id
          name
          role
          careTypes
        }
        therapist {
          id
          name
          role
        }
        appointmentTemplates {
          ...appointmentTemplate
        }
        organization {
          ...organization
          parent {
            ...organization
          }
          entitlements {
            ...organizationEntitlement
          }
        }
        careFlows {
          careType
          paymentSource
        }
      }
      provider {
        id
        name
        careTypes
      }
    }
  }
  ${BasicAppointmentUserFragmentDoc}
  ${AppointmentTemplateFragmentDoc}
  ${OrganizationFragmentDoc}
  ${OrganizationEntitlementFragmentDoc}
`;

/**
 * __useAdminAppointmentQuery__
 *
 * To run a query within a React component, call `useAdminAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminAppointmentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminAppointmentQuery,
    AdminAppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminAppointmentQuery, AdminAppointmentQueryVariables>(
    AdminAppointmentDocument,
    options
  );
}
export function useAdminAppointmentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminAppointmentQuery,
    AdminAppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminAppointmentQuery, AdminAppointmentQueryVariables>(
    AdminAppointmentDocument,
    options
  );
}
export type AdminAppointmentQueryHookResult = ReturnType<typeof useAdminAppointmentQuery>;
export type AdminAppointmentLazyQueryHookResult = ReturnType<typeof useAdminAppointmentLazyQuery>;
export type AdminAppointmentQueryResult = ApolloReactCommon.QueryResult<
  AdminAppointmentQuery,
  AdminAppointmentQueryVariables
>;
export const OrganizationForStateAndCareTypeValidationDocument = gql`
  query organizationForStateAndCareTypeValidation($id: Float!) {
    organization(id: $id) {
      careFlows {
        careType
        referralRestrictedStates
        statesAvailable
      }
    }
  }
`;

/**
 * __useOrganizationForStateAndCareTypeValidationQuery__
 *
 * To run a query within a React component, call `useOrganizationForStateAndCareTypeValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationForStateAndCareTypeValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationForStateAndCareTypeValidationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationForStateAndCareTypeValidationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrganizationForStateAndCareTypeValidationQuery,
    OrganizationForStateAndCareTypeValidationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OrganizationForStateAndCareTypeValidationQuery,
    OrganizationForStateAndCareTypeValidationQueryVariables
  >(OrganizationForStateAndCareTypeValidationDocument, options);
}
export function useOrganizationForStateAndCareTypeValidationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationForStateAndCareTypeValidationQuery,
    OrganizationForStateAndCareTypeValidationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationForStateAndCareTypeValidationQuery,
    OrganizationForStateAndCareTypeValidationQueryVariables
  >(OrganizationForStateAndCareTypeValidationDocument, options);
}
export type OrganizationForStateAndCareTypeValidationQueryHookResult = ReturnType<
  typeof useOrganizationForStateAndCareTypeValidationQuery
>;
export type OrganizationForStateAndCareTypeValidationLazyQueryHookResult = ReturnType<
  typeof useOrganizationForStateAndCareTypeValidationLazyQuery
>;
export type OrganizationForStateAndCareTypeValidationQueryResult = ApolloReactCommon.QueryResult<
  OrganizationForStateAndCareTypeValidationQuery,
  OrganizationForStateAndCareTypeValidationQueryVariables
>;
export const OrganizationForEnrollPageDocument = gql`
  query organizationForEnrollPage(
    $id: Float!
    $includeProviders: Boolean!
    $nextTimeSlotEnd: String
  ) {
    organization(id: $id) {
      ...organization
      nextAvailableIntakeAppts: nextAvailableTimeSlots(
        appointmentType: "intake"
        endTime: $nextTimeSlotEnd
      ) {
        start
        end
        careType
      }
      careFlows {
        ...organizationCareFlow
      }
      providers @include(if: $includeProviders) {
        ...provider
      }
      entitlements {
        key
        config
      }
      careFlows {
        careType
        referralRestrictedStates
        statesAvailable
      }
      parent {
        ...organization
        providers @include(if: $includeProviders) {
          id
          name
          role
        }
      }
      children {
        ...organization
        providers @include(if: $includeProviders) {
          id
          name
          role
        }
      }
      director {
        email
      }
      referralCredits {
        availableCredits
        therapyCreditWeight
        psychiatryCreditWeight
        referralPeriodEnd
      }
    }
  }
  ${OrganizationFragmentDoc}
  ${OrganizationCareFlowFragmentDoc}
  ${ProviderFragmentDoc}
`;

/**
 * __useOrganizationForEnrollPageQuery__
 *
 * To run a query within a React component, call `useOrganizationForEnrollPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationForEnrollPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationForEnrollPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeProviders: // value for 'includeProviders'
 *      nextTimeSlotEnd: // value for 'nextTimeSlotEnd'
 *   },
 * });
 */
export function useOrganizationForEnrollPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrganizationForEnrollPageQuery,
    OrganizationForEnrollPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OrganizationForEnrollPageQuery,
    OrganizationForEnrollPageQueryVariables
  >(OrganizationForEnrollPageDocument, options);
}
export function useOrganizationForEnrollPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationForEnrollPageQuery,
    OrganizationForEnrollPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationForEnrollPageQuery,
    OrganizationForEnrollPageQueryVariables
  >(OrganizationForEnrollPageDocument, options);
}
export type OrganizationForEnrollPageQueryHookResult = ReturnType<
  typeof useOrganizationForEnrollPageQuery
>;
export type OrganizationForEnrollPageLazyQueryHookResult = ReturnType<
  typeof useOrganizationForEnrollPageLazyQuery
>;
export type OrganizationForEnrollPageQueryResult = ApolloReactCommon.QueryResult<
  OrganizationForEnrollPageQuery,
  OrganizationForEnrollPageQueryVariables
>;
export const OrganizationEntitlementsDocument = gql`
  query organizationEntitlements($id: Float!) {
    organization(id: $id) {
      entitlements {
        id
        key
        config
      }
    }
  }
`;

/**
 * __useOrganizationEntitlementsQuery__
 *
 * To run a query within a React component, call `useOrganizationEntitlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationEntitlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationEntitlementsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationEntitlementsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrganizationEntitlementsQuery,
    OrganizationEntitlementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OrganizationEntitlementsQuery,
    OrganizationEntitlementsQueryVariables
  >(OrganizationEntitlementsDocument, options);
}
export function useOrganizationEntitlementsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationEntitlementsQuery,
    OrganizationEntitlementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationEntitlementsQuery,
    OrganizationEntitlementsQueryVariables
  >(OrganizationEntitlementsDocument, options);
}
export type OrganizationEntitlementsQueryHookResult = ReturnType<
  typeof useOrganizationEntitlementsQuery
>;
export type OrganizationEntitlementsLazyQueryHookResult = ReturnType<
  typeof useOrganizationEntitlementsLazyQuery
>;
export type OrganizationEntitlementsQueryResult = ApolloReactCommon.QueryResult<
  OrganizationEntitlementsQuery,
  OrganizationEntitlementsQueryVariables
>;
export const OrganizationProvidersDocument = gql`
  query organizationProviders($id: Float!, $withMantraAdmins: Boolean!) {
    organization(id: $id) {
      ...organization
      providers {
        ...provider
      }
      parent {
        ...organization
        providers {
          id
          name
          role
        }
      }
      children {
        ...organization
        providers {
          id
          name
          role
        }
      }
    }
    mantraAdmins @include(if: $withMantraAdmins) {
      id
      name
      role
    }
  }
  ${OrganizationFragmentDoc}
  ${ProviderFragmentDoc}
`;

/**
 * __useOrganizationProvidersQuery__
 *
 * To run a query within a React component, call `useOrganizationProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationProvidersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withMantraAdmins: // value for 'withMantraAdmins'
 *   },
 * });
 */
export function useOrganizationProvidersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrganizationProvidersQuery,
    OrganizationProvidersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrganizationProvidersQuery, OrganizationProvidersQueryVariables>(
    OrganizationProvidersDocument,
    options
  );
}
export function useOrganizationProvidersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationProvidersQuery,
    OrganizationProvidersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationProvidersQuery,
    OrganizationProvidersQueryVariables
  >(OrganizationProvidersDocument, options);
}
export type OrganizationProvidersQueryHookResult = ReturnType<typeof useOrganizationProvidersQuery>;
export type OrganizationProvidersLazyQueryHookResult = ReturnType<
  typeof useOrganizationProvidersLazyQuery
>;
export type OrganizationProvidersQueryResult = ApolloReactCommon.QueryResult<
  OrganizationProvidersQuery,
  OrganizationProvidersQueryVariables
>;
export const AdminAvailabilityDocument = gql`
  query adminAvailability(
    $providerIds: [Float!]!
    $start: String!
    $duration: Float!
    $careType: CareType!
    $appointmentType: String!
    $userId: Float!
    $rescheduleId: Float
    $end: String
    $timezone: String
    $patientState: stateCodes
  ) {
    adminAvailability(
      providerIds: $providerIds
      start: $start
      duration: $duration
      userId: $userId
      careType: $careType
      appointmentType: $appointmentType
      rescheduleId: $rescheduleId
      end: $end
      timezone: $timezone
      patientState: $patientState
    ) {
      interval
      data {
        allocationId
        organizationId
        start
        end
        blockers
        isFeeForServiceTime
        provider {
          id
          name
          careTypes
        }
      }
      globalBlockers {
        providerId
        date
        type
      }
    }
  }
`;

/**
 * __useAdminAvailabilityQuery__
 *
 * To run a query within a React component, call `useAdminAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAvailabilityQuery({
 *   variables: {
 *      providerIds: // value for 'providerIds'
 *      start: // value for 'start'
 *      duration: // value for 'duration'
 *      careType: // value for 'careType'
 *      appointmentType: // value for 'appointmentType'
 *      userId: // value for 'userId'
 *      rescheduleId: // value for 'rescheduleId'
 *      end: // value for 'end'
 *      timezone: // value for 'timezone'
 *      patientState: // value for 'patientState'
 *   },
 * });
 */
export function useAdminAvailabilityQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminAvailabilityQuery,
    AdminAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminAvailabilityQuery, AdminAvailabilityQueryVariables>(
    AdminAvailabilityDocument,
    options
  );
}
export function useAdminAvailabilityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminAvailabilityQuery,
    AdminAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminAvailabilityQuery, AdminAvailabilityQueryVariables>(
    AdminAvailabilityDocument,
    options
  );
}
export type AdminAvailabilityQueryHookResult = ReturnType<typeof useAdminAvailabilityQuery>;
export type AdminAvailabilityLazyQueryHookResult = ReturnType<typeof useAdminAvailabilityLazyQuery>;
export type AdminAvailabilityQueryResult = ApolloReactCommon.QueryResult<
  AdminAvailabilityQuery,
  AdminAvailabilityQueryVariables
>;
export const AdminExistingCalendarEventsInRangeDocument = gql`
  query adminExistingCalendarEventsInRange(
    $providerId: Float!
    $start: String!
    $careType: CareType!
    $apptTypes: [String!]!
  ) {
    adminExistingCalendarEventsInRange(
      providerId: $providerId
      start: $start
      careTypes: [$careType]
      apptTypes: $apptTypes
    ) {
      startTime
      endTime
      appointmentType
    }
  }
`;

/**
 * __useAdminExistingCalendarEventsInRangeQuery__
 *
 * To run a query within a React component, call `useAdminExistingCalendarEventsInRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminExistingCalendarEventsInRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminExistingCalendarEventsInRangeQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      start: // value for 'start'
 *      careType: // value for 'careType'
 *      apptTypes: // value for 'apptTypes'
 *   },
 * });
 */
export function useAdminExistingCalendarEventsInRangeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminExistingCalendarEventsInRangeQuery,
    AdminExistingCalendarEventsInRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AdminExistingCalendarEventsInRangeQuery,
    AdminExistingCalendarEventsInRangeQueryVariables
  >(AdminExistingCalendarEventsInRangeDocument, options);
}
export function useAdminExistingCalendarEventsInRangeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminExistingCalendarEventsInRangeQuery,
    AdminExistingCalendarEventsInRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminExistingCalendarEventsInRangeQuery,
    AdminExistingCalendarEventsInRangeQueryVariables
  >(AdminExistingCalendarEventsInRangeDocument, options);
}
export type AdminExistingCalendarEventsInRangeQueryHookResult = ReturnType<
  typeof useAdminExistingCalendarEventsInRangeQuery
>;
export type AdminExistingCalendarEventsInRangeLazyQueryHookResult = ReturnType<
  typeof useAdminExistingCalendarEventsInRangeLazyQuery
>;
export type AdminExistingCalendarEventsInRangeQueryResult = ApolloReactCommon.QueryResult<
  AdminExistingCalendarEventsInRangeQuery,
  AdminExistingCalendarEventsInRangeQueryVariables
>;
export const AdminOrgIntakeAvailabilityDocument = gql`
  query adminOrgIntakeAvailability(
    $start: String!
    $end: String!
    $providerIds: [Float!]!
    $careType: CareType!
    $organizationId: Float
    $timezone: String
    $patientState: stateCodes
  ) {
    adminOrgIntakeAvailability(
      organizationId: $organizationId
      start: $start
      end: $end
      providerIds: $providerIds
      careType: $careType
      timezone: $timezone
      patientState: $patientState
    ) {
      interval
      duration
      data {
        allocationId
        start
        end
        blockers
        organizationId
        isFeeForServiceTime
        provider {
          id
          name
          careTypes
        }
      }
    }
  }
`;

/**
 * __useAdminOrgIntakeAvailabilityQuery__
 *
 * To run a query within a React component, call `useAdminOrgIntakeAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOrgIntakeAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOrgIntakeAvailabilityQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      providerIds: // value for 'providerIds'
 *      careType: // value for 'careType'
 *      organizationId: // value for 'organizationId'
 *      timezone: // value for 'timezone'
 *      patientState: // value for 'patientState'
 *   },
 * });
 */
export function useAdminOrgIntakeAvailabilityQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminOrgIntakeAvailabilityQuery,
    AdminOrgIntakeAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AdminOrgIntakeAvailabilityQuery,
    AdminOrgIntakeAvailabilityQueryVariables
  >(AdminOrgIntakeAvailabilityDocument, options);
}
export function useAdminOrgIntakeAvailabilityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminOrgIntakeAvailabilityQuery,
    AdminOrgIntakeAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminOrgIntakeAvailabilityQuery,
    AdminOrgIntakeAvailabilityQueryVariables
  >(AdminOrgIntakeAvailabilityDocument, options);
}
export type AdminOrgIntakeAvailabilityQueryHookResult = ReturnType<
  typeof useAdminOrgIntakeAvailabilityQuery
>;
export type AdminOrgIntakeAvailabilityLazyQueryHookResult = ReturnType<
  typeof useAdminOrgIntakeAvailabilityLazyQuery
>;
export type AdminOrgIntakeAvailabilityQueryResult = ApolloReactCommon.QueryResult<
  AdminOrgIntakeAvailabilityQuery,
  AdminOrgIntakeAvailabilityQueryVariables
>;
export const CounselorDashboardDocument = gql`
  query counselorDashboard($organizationId: Float!, $notesKey: String) {
    organization(id: $organizationId) {
      usersWithoutReferrals {
        ...userBasic
      }
      usersWithOnlyIncompleteReferrals {
        ...userBasic
      }
      highRiskUsers {
        ...userBasic
        assessmentInfo {
          riskLevel
        }
      }
      suicidalRiskUsers {
        ...userBasic
      }
      latestAssessments(limit: 3) {
        createdAt
        user {
          ...userBasic
        }
      }
      latestNotes(limit: 3, key: $notesKey) {
        createdAt
        user {
          ...userBasic
        }
      }
      incompletePlans: safetyPlans(complete: false) {
        id
        createdAt
        updatedAt
        user {
          ...userBasic
        }
      }
    }
  }
  ${UserBasicFragmentDoc}
`;

/**
 * __useCounselorDashboardQuery__
 *
 * To run a query within a React component, call `useCounselorDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounselorDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounselorDashboardQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      notesKey: // value for 'notesKey'
 *   },
 * });
 */
export function useCounselorDashboardQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CounselorDashboardQuery,
    CounselorDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CounselorDashboardQuery, CounselorDashboardQueryVariables>(
    CounselorDashboardDocument,
    options
  );
}
export function useCounselorDashboardLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CounselorDashboardQuery,
    CounselorDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CounselorDashboardQuery, CounselorDashboardQueryVariables>(
    CounselorDashboardDocument,
    options
  );
}
export type CounselorDashboardQueryHookResult = ReturnType<typeof useCounselorDashboardQuery>;
export type CounselorDashboardLazyQueryHookResult = ReturnType<
  typeof useCounselorDashboardLazyQuery
>;
export type CounselorDashboardQueryResult = ApolloReactCommon.QueryResult<
  CounselorDashboardQuery,
  CounselorDashboardQueryVariables
>;
export const MissedAppointmentsDocument = gql`
  query missedAppointments($organizationId: Float!) {
    organization(id: $organizationId) {
      missedAppointments {
        id
        startTime
        user {
          ...userBasic
        }
      }
    }
  }
  ${UserBasicFragmentDoc}
`;

/**
 * __useMissedAppointmentsQuery__
 *
 * To run a query within a React component, call `useMissedAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMissedAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMissedAppointmentsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useMissedAppointmentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    MissedAppointmentsQuery,
    MissedAppointmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MissedAppointmentsQuery, MissedAppointmentsQueryVariables>(
    MissedAppointmentsDocument,
    options
  );
}
export function useMissedAppointmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MissedAppointmentsQuery,
    MissedAppointmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MissedAppointmentsQuery, MissedAppointmentsQueryVariables>(
    MissedAppointmentsDocument,
    options
  );
}
export type MissedAppointmentsQueryHookResult = ReturnType<typeof useMissedAppointmentsQuery>;
export type MissedAppointmentsLazyQueryHookResult = ReturnType<
  typeof useMissedAppointmentsLazyQuery
>;
export type MissedAppointmentsQueryResult = ApolloReactCommon.QueryResult<
  MissedAppointmentsQuery,
  MissedAppointmentsQueryVariables
>;
export const LogoDocument = gql`
  query logo($organizationId: Float!) {
    organization(id: $organizationId) {
      id
      name
      logo {
        id
        url
      }
      parent {
        name
        logo {
          id
          url
        }
      }
    }
  }
`;

/**
 * __useLogoQuery__
 *
 * To run a query within a React component, call `useLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useLogoQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<LogoQuery, LogoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LogoQuery, LogoQueryVariables>(LogoDocument, options);
}
export function useLogoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LogoQuery, LogoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LogoQuery, LogoQueryVariables>(LogoDocument, options);
}
export type LogoQueryHookResult = ReturnType<typeof useLogoQuery>;
export type LogoLazyQueryHookResult = ReturnType<typeof useLogoLazyQuery>;
export type LogoQueryResult = ApolloReactCommon.QueryResult<LogoQuery, LogoQueryVariables>;
export const AdminCallMetaDataForAppointmentDocument = gql`
  query adminCallMetaDataForAppointment($userId: Float!, $appointmentId: Float) {
    adminAppointmentByUser(userId: $userId, appointmentId: $appointmentId) {
      id
      status
      startTime
      endTime
      videoSessionId
      videoToken
      appointmentType
      description
      adminHostCrisisId
      adminCrisisTeamNotifiedAt
      adminCrisisProtocolEngagedAt
      user {
        ...userBasic
        provider {
          id
          name
        }
        organization {
          currentCrisisHours {
            startTime
            endTime
          }
          crisisTeamProviders {
            role
          }
          entitlements {
            ...organizationEntitlement
          }
          ...organization
        }
        selfie {
          id
          url
        }
      }
      provider {
        id
        name
      }
    }
  }
  ${UserBasicFragmentDoc}
  ${OrganizationEntitlementFragmentDoc}
  ${OrganizationFragmentDoc}
`;

/**
 * __useAdminCallMetaDataForAppointmentQuery__
 *
 * To run a query within a React component, call `useAdminCallMetaDataForAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCallMetaDataForAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCallMetaDataForAppointmentQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useAdminCallMetaDataForAppointmentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminCallMetaDataForAppointmentQuery,
    AdminCallMetaDataForAppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AdminCallMetaDataForAppointmentQuery,
    AdminCallMetaDataForAppointmentQueryVariables
  >(AdminCallMetaDataForAppointmentDocument, options);
}
export function useAdminCallMetaDataForAppointmentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminCallMetaDataForAppointmentQuery,
    AdminCallMetaDataForAppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminCallMetaDataForAppointmentQuery,
    AdminCallMetaDataForAppointmentQueryVariables
  >(AdminCallMetaDataForAppointmentDocument, options);
}
export type AdminCallMetaDataForAppointmentQueryHookResult = ReturnType<
  typeof useAdminCallMetaDataForAppointmentQuery
>;
export type AdminCallMetaDataForAppointmentLazyQueryHookResult = ReturnType<
  typeof useAdminCallMetaDataForAppointmentLazyQuery
>;
export type AdminCallMetaDataForAppointmentQueryResult = ApolloReactCommon.QueryResult<
  AdminCallMetaDataForAppointmentQuery,
  AdminCallMetaDataForAppointmentQueryVariables
>;
export const PatientsDocument = gql`
  query patients($providerId: Float!) {
    adminProvider(providerId: $providerId) {
      patients {
        ...userBasic
      }
    }
  }
  ${UserBasicFragmentDoc}
`;

/**
 * __usePatientsQuery__
 *
 * To run a query within a React component, call `usePatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientsQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function usePatientsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PatientsQuery, PatientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PatientsQuery, PatientsQueryVariables>(
    PatientsDocument,
    options
  );
}
export function usePatientsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PatientsQuery, PatientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PatientsQuery, PatientsQueryVariables>(
    PatientsDocument,
    options
  );
}
export type PatientsQueryHookResult = ReturnType<typeof usePatientsQuery>;
export type PatientsLazyQueryHookResult = ReturnType<typeof usePatientsLazyQuery>;
export type PatientsQueryResult = ApolloReactCommon.QueryResult<
  PatientsQuery,
  PatientsQueryVariables
>;
export const AppointmentTemplatesForUserDocument = gql`
  query appointmentTemplatesForUser($userId: Float!) {
    adminUser(id: $userId) {
      id
      careFlows {
        careStatus
      }
      appointmentTemplates {
        ...appointmentTemplate
      }
    }
  }
  ${AppointmentTemplateFragmentDoc}
`;

/**
 * __useAppointmentTemplatesForUserQuery__
 *
 * To run a query within a React component, call `useAppointmentTemplatesForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentTemplatesForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentTemplatesForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAppointmentTemplatesForUserQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AppointmentTemplatesForUserQuery,
    AppointmentTemplatesForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AppointmentTemplatesForUserQuery,
    AppointmentTemplatesForUserQueryVariables
  >(AppointmentTemplatesForUserDocument, options);
}
export function useAppointmentTemplatesForUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AppointmentTemplatesForUserQuery,
    AppointmentTemplatesForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AppointmentTemplatesForUserQuery,
    AppointmentTemplatesForUserQueryVariables
  >(AppointmentTemplatesForUserDocument, options);
}
export type AppointmentTemplatesForUserQueryHookResult = ReturnType<
  typeof useAppointmentTemplatesForUserQuery
>;
export type AppointmentTemplatesForUserLazyQueryHookResult = ReturnType<
  typeof useAppointmentTemplatesForUserLazyQuery
>;
export type AppointmentTemplatesForUserQueryResult = ApolloReactCommon.QueryResult<
  AppointmentTemplatesForUserQuery,
  AppointmentTemplatesForUserQueryVariables
>;
export const SearchPharmacyDocument = gql`
  query SearchPharmacy($query: String!) {
    adminSearchPharmacy(query: $query) {
      ...pharmacy
    }
  }
  ${PharmacyFragmentDoc}
`;

/**
 * __useSearchPharmacyQuery__
 *
 * To run a query within a React component, call `useSearchPharmacyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPharmacyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPharmacyQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchPharmacyQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SearchPharmacyQuery, SearchPharmacyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SearchPharmacyQuery, SearchPharmacyQueryVariables>(
    SearchPharmacyDocument,
    options
  );
}
export function useSearchPharmacyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SearchPharmacyQuery,
    SearchPharmacyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SearchPharmacyQuery, SearchPharmacyQueryVariables>(
    SearchPharmacyDocument,
    options
  );
}
export type SearchPharmacyQueryHookResult = ReturnType<typeof useSearchPharmacyQuery>;
export type SearchPharmacyLazyQueryHookResult = ReturnType<typeof useSearchPharmacyLazyQuery>;
export type SearchPharmacyQueryResult = ApolloReactCommon.QueryResult<
  SearchPharmacyQuery,
  SearchPharmacyQueryVariables
>;
export const CareDashboardDocument = gql`
  query careDashboard($admin: Boolean!) {
    adminTimeAllocationsToday {
      id
      startTime
      endTime
      purpose
      provider {
        ...provider
        portrait {
          url
        }
      }
      organization {
        ...organization
        childrenCount
      }
      childOrganizations {
        id
      }
      appointmentTypes
    }
    adminAppointmentsToday {
      id
      startTime
      endTime
      appointmentType
      description
      user {
        ...userBasic
        identityVerified
        intakeComplete
        selfie {
          url
        }
        organization {
          id
        }
      }
      provider {
        ...provider
      }
    }
    adminPatientMonitoring {
      ...monitorUser
      ...appointmentAttendee
    }
    unsubmittedMedicalNotes @skip(if: $admin) {
      id
      updatedAt
      createdAt
      title
      dos: valueForKey(key: "date-of-service")
      user {
        ...userBasic
      }
    }
    safetyPlans(complete: false) @skip(if: $admin) {
      id
      createdAt
      updatedAt
      user {
        ...userBasic
      }
    }
    adminUrgentActionUsers {
      id
      firstName
      lastName
      email
      nextAppointmentTime
      careTypes
      taskStatus
      engagementTags
      lastOutreachAttempt
    }
  }
  ${ProviderFragmentDoc}
  ${OrganizationFragmentDoc}
  ${UserBasicFragmentDoc}
  ${MonitorUserFragmentDoc}
  ${AppointmentAttendeeFragmentDoc}
`;

/**
 * __useCareDashboardQuery__
 *
 * To run a query within a React component, call `useCareDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareDashboardQuery({
 *   variables: {
 *      admin: // value for 'admin'
 *   },
 * });
 */
export function useCareDashboardQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CareDashboardQuery, CareDashboardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CareDashboardQuery, CareDashboardQueryVariables>(
    CareDashboardDocument,
    options
  );
}
export function useCareDashboardLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CareDashboardQuery,
    CareDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CareDashboardQuery, CareDashboardQueryVariables>(
    CareDashboardDocument,
    options
  );
}
export type CareDashboardQueryHookResult = ReturnType<typeof useCareDashboardQuery>;
export type CareDashboardLazyQueryHookResult = ReturnType<typeof useCareDashboardLazyQuery>;
export type CareDashboardQueryResult = ApolloReactCommon.QueryResult<
  CareDashboardQuery,
  CareDashboardQueryVariables
>;
export const AdminNeedsPatientOutreachDocument = gql`
  query adminNeedsPatientOutreach {
    adminNeedsPatientOutreach {
      id
      firstName
      lastName
      email
      careTypes
      taskStatus
      engagementTags
      lastOutreachAttempt
    }
  }
`;

/**
 * __useAdminNeedsPatientOutreachQuery__
 *
 * To run a query within a React component, call `useAdminNeedsPatientOutreachQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminNeedsPatientOutreachQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminNeedsPatientOutreachQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminNeedsPatientOutreachQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminNeedsPatientOutreachQuery,
    AdminNeedsPatientOutreachQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AdminNeedsPatientOutreachQuery,
    AdminNeedsPatientOutreachQueryVariables
  >(AdminNeedsPatientOutreachDocument, options);
}
export function useAdminNeedsPatientOutreachLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminNeedsPatientOutreachQuery,
    AdminNeedsPatientOutreachQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminNeedsPatientOutreachQuery,
    AdminNeedsPatientOutreachQueryVariables
  >(AdminNeedsPatientOutreachDocument, options);
}
export type AdminNeedsPatientOutreachQueryHookResult = ReturnType<
  typeof useAdminNeedsPatientOutreachQuery
>;
export type AdminNeedsPatientOutreachLazyQueryHookResult = ReturnType<
  typeof useAdminNeedsPatientOutreachLazyQuery
>;
export type AdminNeedsPatientOutreachQueryResult = ApolloReactCommon.QueryResult<
  AdminNeedsPatientOutreachQuery,
  AdminNeedsPatientOutreachQueryVariables
>;
export const GetAsyncJobDocument = gql`
  query getAsyncJob($jobId: Float!) {
    adminGetJob(jobId: $jobId) {
      id
      status
      url
    }
  }
`;

/**
 * __useGetAsyncJobQuery__
 *
 * To run a query within a React component, call `useGetAsyncJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAsyncJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAsyncJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetAsyncJobQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetAsyncJobQuery, GetAsyncJobQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetAsyncJobQuery, GetAsyncJobQueryVariables>(
    GetAsyncJobDocument,
    options
  );
}
export function useGetAsyncJobLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAsyncJobQuery, GetAsyncJobQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetAsyncJobQuery, GetAsyncJobQueryVariables>(
    GetAsyncJobDocument,
    options
  );
}
export type GetAsyncJobQueryHookResult = ReturnType<typeof useGetAsyncJobQuery>;
export type GetAsyncJobLazyQueryHookResult = ReturnType<typeof useGetAsyncJobLazyQuery>;
export type GetAsyncJobQueryResult = ApolloReactCommon.QueryResult<
  GetAsyncJobQuery,
  GetAsyncJobQueryVariables
>;
export const GetClaimDocument = gql`
  query getClaim($claimId: Float!) {
    getClaim(id: $claimId) {
      id
      user {
        ...userBasic
        customerId
      }
      provider {
        name
      }
      payer {
        id
        name
      }
      internalStatus
      sentDate
      balanceCents
      visitDate
      geoState
      events {
        ...insuranceClaimEvent
      }
    }
  }
  ${UserBasicFragmentDoc}
  ${InsuranceClaimEventFragmentDoc}
`;

/**
 * __useGetClaimQuery__
 *
 * To run a query within a React component, call `useGetClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimQuery({
 *   variables: {
 *      claimId: // value for 'claimId'
 *   },
 * });
 */
export function useGetClaimQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetClaimQuery, GetClaimQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetClaimQuery, GetClaimQueryVariables>(
    GetClaimDocument,
    options
  );
}
export function useGetClaimLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClaimQuery, GetClaimQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetClaimQuery, GetClaimQueryVariables>(
    GetClaimDocument,
    options
  );
}
export type GetClaimQueryHookResult = ReturnType<typeof useGetClaimQuery>;
export type GetClaimLazyQueryHookResult = ReturnType<typeof useGetClaimLazyQuery>;
export type GetClaimQueryResult = ApolloReactCommon.QueryResult<
  GetClaimQuery,
  GetClaimQueryVariables
>;
export const GetClaimsDocument = gql`
  query getClaims($startDate: DateTime!, $endDate: DateTime!) {
    getClaims(startDate: $startDate, endDate: $endDate) {
      id
      visitDate
      geoState
      balanceCents
      internalStatus
      updatedAt
      payer {
        id
        name
      }
      provider {
        id
        name
      }
      user {
        ...userBasic
      }
    }
  }
  ${UserBasicFragmentDoc}
`;

/**
 * __useGetClaimsQuery__
 *
 * To run a query within a React component, call `useGetClaimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetClaimsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetClaimsQuery, GetClaimsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetClaimsQuery, GetClaimsQueryVariables>(
    GetClaimsDocument,
    options
  );
}
export function useGetClaimsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClaimsQuery, GetClaimsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetClaimsQuery, GetClaimsQueryVariables>(
    GetClaimsDocument,
    options
  );
}
export type GetClaimsQueryHookResult = ReturnType<typeof useGetClaimsQuery>;
export type GetClaimsLazyQueryHookResult = ReturnType<typeof useGetClaimsLazyQuery>;
export type GetClaimsQueryResult = ApolloReactCommon.QueryResult<
  GetClaimsQuery,
  GetClaimsQueryVariables
>;
export const NewClaimsCountDocument = gql`
  query newClaimsCount {
    newClaimsCount
  }
`;

/**
 * __useNewClaimsCountQuery__
 *
 * To run a query within a React component, call `useNewClaimsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewClaimsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewClaimsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewClaimsCountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<NewClaimsCountQuery, NewClaimsCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<NewClaimsCountQuery, NewClaimsCountQueryVariables>(
    NewClaimsCountDocument,
    options
  );
}
export function useNewClaimsCountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    NewClaimsCountQuery,
    NewClaimsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<NewClaimsCountQuery, NewClaimsCountQueryVariables>(
    NewClaimsCountDocument,
    options
  );
}
export type NewClaimsCountQueryHookResult = ReturnType<typeof useNewClaimsCountQuery>;
export type NewClaimsCountLazyQueryHookResult = ReturnType<typeof useNewClaimsCountLazyQuery>;
export type NewClaimsCountQueryResult = ApolloReactCommon.QueryResult<
  NewClaimsCountQuery,
  NewClaimsCountQueryVariables
>;
export const AdminUserPharmaciesDocument = gql`
  query adminUserPharmacies($userId: Float!) {
    adminUserPharmacies(userId: $userId) {
      ...pharmacy
    }
  }
  ${PharmacyFragmentDoc}
`;

/**
 * __useAdminUserPharmaciesQuery__
 *
 * To run a query within a React component, call `useAdminUserPharmaciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUserPharmaciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUserPharmaciesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminUserPharmaciesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminUserPharmaciesQuery,
    AdminUserPharmaciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminUserPharmaciesQuery, AdminUserPharmaciesQueryVariables>(
    AdminUserPharmaciesDocument,
    options
  );
}
export function useAdminUserPharmaciesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminUserPharmaciesQuery,
    AdminUserPharmaciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminUserPharmaciesQuery, AdminUserPharmaciesQueryVariables>(
    AdminUserPharmaciesDocument,
    options
  );
}
export type AdminUserPharmaciesQueryHookResult = ReturnType<typeof useAdminUserPharmaciesQuery>;
export type AdminUserPharmaciesLazyQueryHookResult = ReturnType<
  typeof useAdminUserPharmaciesLazyQuery
>;
export type AdminUserPharmaciesQueryResult = ApolloReactCommon.QueryResult<
  AdminUserPharmaciesQuery,
  AdminUserPharmaciesQueryVariables
>;
export const CareteamPreferencesDocument = gql`
  query careteamPreferences {
    configs {
      key
      title
      description
      subText
      value
    }
  }
`;

/**
 * __useCareteamPreferencesQuery__
 *
 * To run a query within a React component, call `useCareteamPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareteamPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareteamPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCareteamPreferencesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CareteamPreferencesQuery,
    CareteamPreferencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CareteamPreferencesQuery, CareteamPreferencesQueryVariables>(
    CareteamPreferencesDocument,
    options
  );
}
export function useCareteamPreferencesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CareteamPreferencesQuery,
    CareteamPreferencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CareteamPreferencesQuery, CareteamPreferencesQueryVariables>(
    CareteamPreferencesDocument,
    options
  );
}
export type CareteamPreferencesQueryHookResult = ReturnType<typeof useCareteamPreferencesQuery>;
export type CareteamPreferencesLazyQueryHookResult = ReturnType<
  typeof useCareteamPreferencesLazyQuery
>;
export type CareteamPreferencesQueryResult = ApolloReactCommon.QueryResult<
  CareteamPreferencesQuery,
  CareteamPreferencesQueryVariables
>;
export const OrganizationsListDocument = gql`
  query organizationsList {
    organizations {
      id
      name
      abbreviation
      entitlements {
        key
      }
      careFlows {
        careType
      }
      providers {
        id
        name
        role
      }
      activityStatus
    }
  }
`;

/**
 * __useOrganizationsListQuery__
 *
 * To run a query within a React component, call `useOrganizationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationsListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationsListQuery,
    OrganizationsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrganizationsListQuery, OrganizationsListQueryVariables>(
    OrganizationsListDocument,
    options
  );
}
export function useOrganizationsListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationsListQuery,
    OrganizationsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrganizationsListQuery, OrganizationsListQueryVariables>(
    OrganizationsListDocument,
    options
  );
}
export type OrganizationsListQueryHookResult = ReturnType<typeof useOrganizationsListQuery>;
export type OrganizationsListLazyQueryHookResult = ReturnType<typeof useOrganizationsListLazyQuery>;
export type OrganizationsListQueryResult = ApolloReactCommon.QueryResult<
  OrganizationsListQuery,
  OrganizationsListQueryVariables
>;
export const AdminCheckDocument = gql`
  query adminCheck($organizationId: Float!, $isMantraAdmin: Boolean!) {
    adminOrgs: organizations @include(if: $isMantraAdmin) {
      id
      name
      entitlements {
        key
      }
    }
    singleOrg: organization(id: $organizationId) @skip(if: $isMantraAdmin) {
      entitlements {
        id
        key
        config
      }
    }
  }
`;

/**
 * __useAdminCheckQuery__
 *
 * To run a query within a React component, call `useAdminCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCheckQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      isMantraAdmin: // value for 'isMantraAdmin'
 *   },
 * });
 */
export function useAdminCheckQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AdminCheckQuery, AdminCheckQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminCheckQuery, AdminCheckQueryVariables>(
    AdminCheckDocument,
    options
  );
}
export function useAdminCheckLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminCheckQuery, AdminCheckQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminCheckQuery, AdminCheckQueryVariables>(
    AdminCheckDocument,
    options
  );
}
export type AdminCheckQueryHookResult = ReturnType<typeof useAdminCheckQuery>;
export type AdminCheckLazyQueryHookResult = ReturnType<typeof useAdminCheckLazyQuery>;
export type AdminCheckQueryResult = ApolloReactCommon.QueryResult<
  AdminCheckQuery,
  AdminCheckQueryVariables
>;
export const OrganizationTopLevelDocument = gql`
  query organizationTopLevel($id: Float!) {
    organization(id: $id) {
      id
      name
      abbreviation
      activityStatus
      logo {
        url
      }
      entitlements {
        key
      }
    }
  }
`;

/**
 * __useOrganizationTopLevelQuery__
 *
 * To run a query within a React component, call `useOrganizationTopLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTopLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTopLevelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationTopLevelQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrganizationTopLevelQuery,
    OrganizationTopLevelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrganizationTopLevelQuery, OrganizationTopLevelQueryVariables>(
    OrganizationTopLevelDocument,
    options
  );
}
export function useOrganizationTopLevelLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationTopLevelQuery,
    OrganizationTopLevelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationTopLevelQuery,
    OrganizationTopLevelQueryVariables
  >(OrganizationTopLevelDocument, options);
}
export type OrganizationTopLevelQueryHookResult = ReturnType<typeof useOrganizationTopLevelQuery>;
export type OrganizationTopLevelLazyQueryHookResult = ReturnType<
  typeof useOrganizationTopLevelLazyQuery
>;
export type OrganizationTopLevelQueryResult = ApolloReactCommon.QueryResult<
  OrganizationTopLevelQuery,
  OrganizationTopLevelQueryVariables
>;
export const OrganizationOverviewDocument = gql`
  query organizationOverview($id: Float!) {
    organization(id: $id) {
      id
      name
      abbreviation
      logo {
        id
        url
      }
      entitlements {
        key
      }
      careFlows {
        careType
      }
      providers {
        ...provider
      }
      activityStatus
      departmentName
      departmentAddress
      departmentHours
      appointmentTemplates {
        id
        description
        appointmentType
        careType
        duration
      }
      director {
        id
        name
        firstName
        lastName
        postNominalTitles
        classification
        phone
        email
      }
      emailDomains
      safeOperatingHandbookUrl
    }
  }
  ${ProviderFragmentDoc}
`;

/**
 * __useOrganizationOverviewQuery__
 *
 * To run a query within a React component, call `useOrganizationOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationOverviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationOverviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrganizationOverviewQuery,
    OrganizationOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrganizationOverviewQuery, OrganizationOverviewQueryVariables>(
    OrganizationOverviewDocument,
    options
  );
}
export function useOrganizationOverviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationOverviewQuery,
    OrganizationOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationOverviewQuery,
    OrganizationOverviewQueryVariables
  >(OrganizationOverviewDocument, options);
}
export type OrganizationOverviewQueryHookResult = ReturnType<typeof useOrganizationOverviewQuery>;
export type OrganizationOverviewLazyQueryHookResult = ReturnType<
  typeof useOrganizationOverviewLazyQuery
>;
export type OrganizationOverviewQueryResult = ApolloReactCommon.QueryResult<
  OrganizationOverviewQuery,
  OrganizationOverviewQueryVariables
>;
export const OrganizationStaffDocument = gql`
  query organizationStaff($id: Float!) {
    organization(id: $id) {
      providers {
        ...provider
      }
    }
  }
  ${ProviderFragmentDoc}
`;

/**
 * __useOrganizationStaffQuery__
 *
 * To run a query within a React component, call `useOrganizationStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationStaffQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationStaffQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrganizationStaffQuery,
    OrganizationStaffQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrganizationStaffQuery, OrganizationStaffQueryVariables>(
    OrganizationStaffDocument,
    options
  );
}
export function useOrganizationStaffLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationStaffQuery,
    OrganizationStaffQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrganizationStaffQuery, OrganizationStaffQueryVariables>(
    OrganizationStaffDocument,
    options
  );
}
export type OrganizationStaffQueryHookResult = ReturnType<typeof useOrganizationStaffQuery>;
export type OrganizationStaffLazyQueryHookResult = ReturnType<typeof useOrganizationStaffLazyQuery>;
export type OrganizationStaffQueryResult = ApolloReactCommon.QueryResult<
  OrganizationStaffQuery,
  OrganizationStaffQueryVariables
>;
export const AdminAlertsDocument = gql`
  query adminAlerts($userId: Float!) {
    adminUser(id: $userId) {
      adminAlerts {
        type
        message
        careType
        resolveBy
      }
    }
  }
`;

/**
 * __useAdminAlertsQuery__
 *
 * To run a query within a React component, call `useAdminAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAlertsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminAlertsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AdminAlertsQuery, AdminAlertsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminAlertsQuery, AdminAlertsQueryVariables>(
    AdminAlertsDocument,
    options
  );
}
export function useAdminAlertsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminAlertsQuery, AdminAlertsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminAlertsQuery, AdminAlertsQueryVariables>(
    AdminAlertsDocument,
    options
  );
}
export type AdminAlertsQueryHookResult = ReturnType<typeof useAdminAlertsQuery>;
export type AdminAlertsLazyQueryHookResult = ReturnType<typeof useAdminAlertsLazyQuery>;
export type AdminAlertsQueryResult = ApolloReactCommon.QueryResult<
  AdminAlertsQuery,
  AdminAlertsQueryVariables
>;
export const SidebarDataDocument = gql`
  query sidebarData(
    $careTeamMessages: Boolean!
    $patientMessages: Boolean!
    $unverified: Boolean!
    $newTicketCount: Boolean!
  ) {
    unreadPatientMessages @include(if: $patientMessages)
    unreadCareTeamMessages @include(if: $careTeamMessages)
    unverifiedUserCount @include(if: $unverified)
    tickets(view: New) @include(if: $newTicketCount) {
      id
    }
  }
`;

/**
 * __useSidebarDataQuery__
 *
 * To run a query within a React component, call `useSidebarDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSidebarDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidebarDataQuery({
 *   variables: {
 *      careTeamMessages: // value for 'careTeamMessages'
 *      patientMessages: // value for 'patientMessages'
 *      unverified: // value for 'unverified'
 *      newTicketCount: // value for 'newTicketCount'
 *   },
 * });
 */
export function useSidebarDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SidebarDataQuery, SidebarDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SidebarDataQuery, SidebarDataQueryVariables>(
    SidebarDataDocument,
    options
  );
}
export function useSidebarDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SidebarDataQuery, SidebarDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SidebarDataQuery, SidebarDataQueryVariables>(
    SidebarDataDocument,
    options
  );
}
export type SidebarDataQueryHookResult = ReturnType<typeof useSidebarDataQuery>;
export type SidebarDataLazyQueryHookResult = ReturnType<typeof useSidebarDataLazyQuery>;
export type SidebarDataQueryResult = ApolloReactCommon.QueryResult<
  SidebarDataQuery,
  SidebarDataQueryVariables
>;
export const ProviderEmailsDocument = gql`
  query providerEmails($providerId: Float!) {
    providerEmails(providerId: $providerId) {
      ...sentEmail
    }
  }
  ${SentEmailFragmentDoc}
`;

/**
 * __useProviderEmailsQuery__
 *
 * To run a query within a React component, call `useProviderEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderEmailsQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useProviderEmailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ProviderEmailsQuery, ProviderEmailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ProviderEmailsQuery, ProviderEmailsQueryVariables>(
    ProviderEmailsDocument,
    options
  );
}
export function useProviderEmailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProviderEmailsQuery,
    ProviderEmailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ProviderEmailsQuery, ProviderEmailsQueryVariables>(
    ProviderEmailsDocument,
    options
  );
}
export type ProviderEmailsQueryHookResult = ReturnType<typeof useProviderEmailsQuery>;
export type ProviderEmailsLazyQueryHookResult = ReturnType<typeof useProviderEmailsLazyQuery>;
export type ProviderEmailsQueryResult = ApolloReactCommon.QueryResult<
  ProviderEmailsQuery,
  ProviderEmailsQueryVariables
>;
export const AllEventsDocument = gql`
  query allEvents($before: DateTime) {
    allEvents(before: $before) {
      id
      tag
      createdAt
      data
      user {
        id
        firstName
        lastName
        preferredName
      }
    }
  }
`;

/**
 * __useAllEventsQuery__
 *
 * To run a query within a React component, call `useAllEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEventsQuery({
 *   variables: {
 *      before: // value for 'before'
 *   },
 * });
 */
export function useAllEventsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllEventsQuery, AllEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllEventsQuery, AllEventsQueryVariables>(
    AllEventsDocument,
    options
  );
}
export function useAllEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEventsQuery, AllEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllEventsQuery, AllEventsQueryVariables>(
    AllEventsDocument,
    options
  );
}
export type AllEventsQueryHookResult = ReturnType<typeof useAllEventsQuery>;
export type AllEventsLazyQueryHookResult = ReturnType<typeof useAllEventsLazyQuery>;
export type AllEventsQueryResult = ApolloReactCommon.QueryResult<
  AllEventsQuery,
  AllEventsQueryVariables
>;
export const CouponsDocument = gql`
  query coupons {
    coupons {
      ...coupon
    }
  }
  ${CouponFragmentDoc}
`;

/**
 * __useCouponsQuery__
 *
 * To run a query within a React component, call `useCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCouponsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CouponsQuery, CouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options);
}
export function useCouponsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CouponsQuery, CouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CouponsQuery, CouponsQueryVariables>(
    CouponsDocument,
    options
  );
}
export type CouponsQueryHookResult = ReturnType<typeof useCouponsQuery>;
export type CouponsLazyQueryHookResult = ReturnType<typeof useCouponsLazyQuery>;
export type CouponsQueryResult = ApolloReactCommon.QueryResult<CouponsQuery, CouponsQueryVariables>;
export const OrganizationEntitlementsPageDocument = gql`
  query organizationEntitlementsPage($organizationId: Float!) {
    organization(id: $organizationId) {
      name
      entitlementsCoupledToParent
      parent {
        ...organization
      }
      entitlements {
        key
        config
      }
    }
    entitlementDefinitions {
      key
      name
      description
      config {
        key
        name
        type
        placeholder
        size
        required
        smallText
        maxLength
      }
    }
  }
  ${OrganizationFragmentDoc}
`;

/**
 * __useOrganizationEntitlementsPageQuery__
 *
 * To run a query within a React component, call `useOrganizationEntitlementsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationEntitlementsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationEntitlementsPageQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationEntitlementsPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrganizationEntitlementsPageQuery,
    OrganizationEntitlementsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OrganizationEntitlementsPageQuery,
    OrganizationEntitlementsPageQueryVariables
  >(OrganizationEntitlementsPageDocument, options);
}
export function useOrganizationEntitlementsPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationEntitlementsPageQuery,
    OrganizationEntitlementsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationEntitlementsPageQuery,
    OrganizationEntitlementsPageQueryVariables
  >(OrganizationEntitlementsPageDocument, options);
}
export type OrganizationEntitlementsPageQueryHookResult = ReturnType<
  typeof useOrganizationEntitlementsPageQuery
>;
export type OrganizationEntitlementsPageLazyQueryHookResult = ReturnType<
  typeof useOrganizationEntitlementsPageLazyQuery
>;
export type OrganizationEntitlementsPageQueryResult = ApolloReactCommon.QueryResult<
  OrganizationEntitlementsPageQuery,
  OrganizationEntitlementsPageQueryVariables
>;
export const OrganizationResourcesDocument = gql`
  query organizationResources($organizationId: Float!) {
    organization(id: $organizationId) {
      emergencyResources {
        id
        name
        description
        resourceType
        tags
      }
    }
  }
`;

/**
 * __useOrganizationResourcesQuery__
 *
 * To run a query within a React component, call `useOrganizationResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationResourcesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationResourcesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrganizationResourcesQuery,
    OrganizationResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrganizationResourcesQuery, OrganizationResourcesQueryVariables>(
    OrganizationResourcesDocument,
    options
  );
}
export function useOrganizationResourcesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationResourcesQuery,
    OrganizationResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationResourcesQuery,
    OrganizationResourcesQueryVariables
  >(OrganizationResourcesDocument, options);
}
export type OrganizationResourcesQueryHookResult = ReturnType<typeof useOrganizationResourcesQuery>;
export type OrganizationResourcesLazyQueryHookResult = ReturnType<
  typeof useOrganizationResourcesLazyQuery
>;
export type OrganizationResourcesQueryResult = ApolloReactCommon.QueryResult<
  OrganizationResourcesQuery,
  OrganizationResourcesQueryVariables
>;
export const OrganizationCarePeriodsDocument = gql`
  query organizationCarePeriods($organizationId: Float!) {
    organization(id: $organizationId) {
      parent {
        ...organization
      }
      carePeriods {
        id
        startDate
        endDate
        periodType
      }
    }
  }
  ${OrganizationFragmentDoc}
`;

/**
 * __useOrganizationCarePeriodsQuery__
 *
 * To run a query within a React component, call `useOrganizationCarePeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCarePeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationCarePeriodsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationCarePeriodsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrganizationCarePeriodsQuery,
    OrganizationCarePeriodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OrganizationCarePeriodsQuery,
    OrganizationCarePeriodsQueryVariables
  >(OrganizationCarePeriodsDocument, options);
}
export function useOrganizationCarePeriodsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationCarePeriodsQuery,
    OrganizationCarePeriodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationCarePeriodsQuery,
    OrganizationCarePeriodsQueryVariables
  >(OrganizationCarePeriodsDocument, options);
}
export type OrganizationCarePeriodsQueryHookResult = ReturnType<
  typeof useOrganizationCarePeriodsQuery
>;
export type OrganizationCarePeriodsLazyQueryHookResult = ReturnType<
  typeof useOrganizationCarePeriodsLazyQuery
>;
export type OrganizationCarePeriodsQueryResult = ApolloReactCommon.QueryResult<
  OrganizationCarePeriodsQuery,
  OrganizationCarePeriodsQueryVariables
>;
export const OrganizationUtilizationDocument = gql`
  query organizationUtilization($organizationId: Float!) {
    organization(id: $organizationId) {
      parent {
        ...organization
      }
      careFlows {
        careType
      }
      ...organizationUtilization
    }
  }
  ${OrganizationFragmentDoc}
  ${OrganizationUtilizationFragmentDoc}
`;

/**
 * __useOrganizationUtilizationQuery__
 *
 * To run a query within a React component, call `useOrganizationUtilizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUtilizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationUtilizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationUtilizationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrganizationUtilizationQuery,
    OrganizationUtilizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OrganizationUtilizationQuery,
    OrganizationUtilizationQueryVariables
  >(OrganizationUtilizationDocument, options);
}
export function useOrganizationUtilizationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationUtilizationQuery,
    OrganizationUtilizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationUtilizationQuery,
    OrganizationUtilizationQueryVariables
  >(OrganizationUtilizationDocument, options);
}
export type OrganizationUtilizationQueryHookResult = ReturnType<
  typeof useOrganizationUtilizationQuery
>;
export type OrganizationUtilizationLazyQueryHookResult = ReturnType<
  typeof useOrganizationUtilizationLazyQuery
>;
export type OrganizationUtilizationQueryResult = ApolloReactCommon.QueryResult<
  OrganizationUtilizationQuery,
  OrganizationUtilizationQueryVariables
>;
export const ListContractsDocument = gql`
  query listContracts($organizationId: Float!) {
    listContracts(organizationId: $organizationId) {
      contracts {
        id
        startDate
        endDate
      }
    }
  }
`;

/**
 * __useListContractsQuery__
 *
 * To run a query within a React component, call `useListContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListContractsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useListContractsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ListContractsQuery, ListContractsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ListContractsQuery, ListContractsQueryVariables>(
    ListContractsDocument,
    options
  );
}
export function useListContractsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListContractsQuery,
    ListContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ListContractsQuery, ListContractsQueryVariables>(
    ListContractsDocument,
    options
  );
}
export type ListContractsQueryHookResult = ReturnType<typeof useListContractsQuery>;
export type ListContractsLazyQueryHookResult = ReturnType<typeof useListContractsLazyQuery>;
export type ListContractsQueryResult = ApolloReactCommon.QueryResult<
  ListContractsQuery,
  ListContractsQueryVariables
>;
export const GetContractDocument = gql`
  query getContract($organizationId: Float!, $contractId: Float!) {
    organization(id: $organizationId) {
      careFlows {
        careType
      }
      parent {
        id
      }
    }
    getContract(id: $contractId) {
      __typename
      ... on Contract {
        id
        scope {
          startDate
          endDate
          therapySessions
          therapyPatients
          psychiatrySessions
          psychiatryPatients
        }
        utilization {
          therapyUsage {
            ...contractUsageByCareType
          }
          psychiatryUsage {
            ...contractUsageByCareType
          }
        }
        therapyWeeks {
          past {
            ...contractWeek
          }
          current {
            ...contractWeek
          }
          upcoming {
            ...contractWeek
          }
        }
        psychiatryWeeks {
          past {
            ...contractWeek
          }
          current {
            ...contractWeek
          }
          upcoming {
            ...contractWeek
          }
        }
      }
      ... on GetContractError {
        errorCode
        message
      }
    }
  }
  ${ContractUsageByCareTypeFragmentDoc}
  ${ContractWeekFragmentDoc}
`;

/**
 * __useGetContractQuery__
 *
 * To run a query within a React component, call `useGetContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetContractQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetContractQuery, GetContractQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetContractQuery, GetContractQueryVariables>(
    GetContractDocument,
    options
  );
}
export function useGetContractLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractQuery, GetContractQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetContractQuery, GetContractQueryVariables>(
    GetContractDocument,
    options
  );
}
export type GetContractQueryHookResult = ReturnType<typeof useGetContractQuery>;
export type GetContractLazyQueryHookResult = ReturnType<typeof useGetContractLazyQuery>;
export type GetContractQueryResult = ApolloReactCommon.QueryResult<
  GetContractQuery,
  GetContractQueryVariables
>;
export const OrganizationCareFlowsDocument = gql`
  query organizationCareFlows($organizationId: Float!) {
    organization(id: $organizationId) {
      id
      parent {
        id
        name
      }
      careFlows {
        id
        careType
        sponsoredSessionsLimitType
        sponsoredSessions
        canSelfRefer
        canReferrerUsersRefer
        canMcpUsersRefer
        defaultPaymentSource
        referralRestrictedStates
        hideSessionCount
      }
    }
  }
`;

/**
 * __useOrganizationCareFlowsQuery__
 *
 * To run a query within a React component, call `useOrganizationCareFlowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCareFlowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationCareFlowsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationCareFlowsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrganizationCareFlowsQuery,
    OrganizationCareFlowsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrganizationCareFlowsQuery, OrganizationCareFlowsQueryVariables>(
    OrganizationCareFlowsDocument,
    options
  );
}
export function useOrganizationCareFlowsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationCareFlowsQuery,
    OrganizationCareFlowsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationCareFlowsQuery,
    OrganizationCareFlowsQueryVariables
  >(OrganizationCareFlowsDocument, options);
}
export type OrganizationCareFlowsQueryHookResult = ReturnType<typeof useOrganizationCareFlowsQuery>;
export type OrganizationCareFlowsLazyQueryHookResult = ReturnType<
  typeof useOrganizationCareFlowsLazyQuery
>;
export type OrganizationCareFlowsQueryResult = ApolloReactCommon.QueryResult<
  OrganizationCareFlowsQuery,
  OrganizationCareFlowsQueryVariables
>;
export const OrganizationsSimpleDocument = gql`
  query organizationsSimple {
    organizations {
      id
      name
      children {
        id
        name
      }
    }
  }
`;

/**
 * __useOrganizationsSimpleQuery__
 *
 * To run a query within a React component, call `useOrganizationsSimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsSimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsSimpleQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationsSimpleQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationsSimpleQuery,
    OrganizationsSimpleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrganizationsSimpleQuery, OrganizationsSimpleQueryVariables>(
    OrganizationsSimpleDocument,
    options
  );
}
export function useOrganizationsSimpleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationsSimpleQuery,
    OrganizationsSimpleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrganizationsSimpleQuery, OrganizationsSimpleQueryVariables>(
    OrganizationsSimpleDocument,
    options
  );
}
export type OrganizationsSimpleQueryHookResult = ReturnType<typeof useOrganizationsSimpleQuery>;
export type OrganizationsSimpleLazyQueryHookResult = ReturnType<
  typeof useOrganizationsSimpleLazyQuery
>;
export type OrganizationsSimpleQueryResult = ApolloReactCommon.QueryResult<
  OrganizationsSimpleQuery,
  OrganizationsSimpleQueryVariables
>;
export const PayersDocument = gql`
  query payers {
    payers {
      ...payer
    }
  }
  ${PayerFragmentDoc}
`;

/**
 * __usePayersQuery__
 *
 * To run a query within a React component, call `usePayersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayersQuery({
 *   variables: {
 *   },
 * });
 */
export function usePayersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PayersQuery, PayersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PayersQuery, PayersQueryVariables>(PayersDocument, options);
}
export function usePayersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayersQuery, PayersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PayersQuery, PayersQueryVariables>(PayersDocument, options);
}
export type PayersQueryHookResult = ReturnType<typeof usePayersQuery>;
export type PayersLazyQueryHookResult = ReturnType<typeof usePayersLazyQuery>;
export type PayersQueryResult = ApolloReactCommon.QueryResult<PayersQuery, PayersQueryVariables>;
export const AllPayersDocument = gql`
  query allPayers {
    allPayers {
      ...payer
    }
  }
  ${PayerFragmentDoc}
`;

/**
 * __useAllPayersQuery__
 *
 * To run a query within a React component, call `useAllPayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPayersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllPayersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllPayersQuery, AllPayersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllPayersQuery, AllPayersQueryVariables>(
    AllPayersDocument,
    options
  );
}
export function useAllPayersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllPayersQuery, AllPayersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllPayersQuery, AllPayersQueryVariables>(
    AllPayersDocument,
    options
  );
}
export type AllPayersQueryHookResult = ReturnType<typeof useAllPayersQuery>;
export type AllPayersLazyQueryHookResult = ReturnType<typeof useAllPayersLazyQuery>;
export type AllPayersQueryResult = ApolloReactCommon.QueryResult<
  AllPayersQuery,
  AllPayersQueryVariables
>;
export const OrganizationReportDocument = gql`
  query organizationReport(
    $organizationId: Float!
    $referralSource: ReferralSource
    $carePeriodId: Float
  ) {
    organization(id: $organizationId) {
      ...organization
      reporting(referralSource: $referralSource, carePeriodId: $carePeriodId) {
        ...baseReport
      }
    }
  }
  ${OrganizationFragmentDoc}
  ${BaseReportFragmentDoc}
`;

/**
 * __useOrganizationReportQuery__
 *
 * To run a query within a React component, call `useOrganizationReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationReportQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      referralSource: // value for 'referralSource'
 *      carePeriodId: // value for 'carePeriodId'
 *   },
 * });
 */
export function useOrganizationReportQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrganizationReportQuery,
    OrganizationReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrganizationReportQuery, OrganizationReportQueryVariables>(
    OrganizationReportDocument,
    options
  );
}
export function useOrganizationReportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationReportQuery,
    OrganizationReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrganizationReportQuery, OrganizationReportQueryVariables>(
    OrganizationReportDocument,
    options
  );
}
export type OrganizationReportQueryHookResult = ReturnType<typeof useOrganizationReportQuery>;
export type OrganizationReportLazyQueryHookResult = ReturnType<
  typeof useOrganizationReportLazyQuery
>;
export type OrganizationReportQueryResult = ApolloReactCommon.QueryResult<
  OrganizationReportQuery,
  OrganizationReportQueryVariables
>;
export const DiagnosesBreakdownDocument = gql`
  query diagnosesBreakdown($organizationId: Float!, $referralSource: ReferralSource) {
    organization(id: $organizationId) {
      reporting(referralSource: $referralSource) {
        ...diagnosisReport
      }
    }
  }
  ${DiagnosisReportFragmentDoc}
`;

/**
 * __useDiagnosesBreakdownQuery__
 *
 * To run a query within a React component, call `useDiagnosesBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiagnosesBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiagnosesBreakdownQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      referralSource: // value for 'referralSource'
 *   },
 * });
 */
export function useDiagnosesBreakdownQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DiagnosesBreakdownQuery,
    DiagnosesBreakdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DiagnosesBreakdownQuery, DiagnosesBreakdownQueryVariables>(
    DiagnosesBreakdownDocument,
    options
  );
}
export function useDiagnosesBreakdownLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DiagnosesBreakdownQuery,
    DiagnosesBreakdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DiagnosesBreakdownQuery, DiagnosesBreakdownQueryVariables>(
    DiagnosesBreakdownDocument,
    options
  );
}
export type DiagnosesBreakdownQueryHookResult = ReturnType<typeof useDiagnosesBreakdownQuery>;
export type DiagnosesBreakdownLazyQueryHookResult = ReturnType<
  typeof useDiagnosesBreakdownLazyQuery
>;
export type DiagnosesBreakdownQueryResult = ApolloReactCommon.QueryResult<
  DiagnosesBreakdownQuery,
  DiagnosesBreakdownQueryVariables
>;
export const OverviewReportDocument = gql`
  query overviewReport($scope: ReportScope!) {
    adminOverviewReport(scope: $scope) {
      ...baseReport
    }
  }
  ${BaseReportFragmentDoc}
`;

/**
 * __useOverviewReportQuery__
 *
 * To run a query within a React component, call `useOverviewReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewReportQuery({
 *   variables: {
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useOverviewReportQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<OverviewReportQuery, OverviewReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OverviewReportQuery, OverviewReportQueryVariables>(
    OverviewReportDocument,
    options
  );
}
export function useOverviewReportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OverviewReportQuery,
    OverviewReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OverviewReportQuery, OverviewReportQueryVariables>(
    OverviewReportDocument,
    options
  );
}
export type OverviewReportQueryHookResult = ReturnType<typeof useOverviewReportQuery>;
export type OverviewReportLazyQueryHookResult = ReturnType<typeof useOverviewReportLazyQuery>;
export type OverviewReportQueryResult = ApolloReactCommon.QueryResult<
  OverviewReportQuery,
  OverviewReportQueryVariables
>;
export const OverviewDiagnosesBreakdownDocument = gql`
  query overviewDiagnosesBreakdown($scope: ReportScope!) {
    adminOverviewReport(scope: $scope) {
      ...diagnosisReport
    }
  }
  ${DiagnosisReportFragmentDoc}
`;

/**
 * __useOverviewDiagnosesBreakdownQuery__
 *
 * To run a query within a React component, call `useOverviewDiagnosesBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewDiagnosesBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewDiagnosesBreakdownQuery({
 *   variables: {
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useOverviewDiagnosesBreakdownQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OverviewDiagnosesBreakdownQuery,
    OverviewDiagnosesBreakdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OverviewDiagnosesBreakdownQuery,
    OverviewDiagnosesBreakdownQueryVariables
  >(OverviewDiagnosesBreakdownDocument, options);
}
export function useOverviewDiagnosesBreakdownLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OverviewDiagnosesBreakdownQuery,
    OverviewDiagnosesBreakdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OverviewDiagnosesBreakdownQuery,
    OverviewDiagnosesBreakdownQueryVariables
  >(OverviewDiagnosesBreakdownDocument, options);
}
export type OverviewDiagnosesBreakdownQueryHookResult = ReturnType<
  typeof useOverviewDiagnosesBreakdownQuery
>;
export type OverviewDiagnosesBreakdownLazyQueryHookResult = ReturnType<
  typeof useOverviewDiagnosesBreakdownLazyQuery
>;
export type OverviewDiagnosesBreakdownQueryResult = ApolloReactCommon.QueryResult<
  OverviewDiagnosesBreakdownQuery,
  OverviewDiagnosesBreakdownQueryVariables
>;
export const SuperAdminReferralsDocument = gql`
  query superAdminReferrals($organizationId: Float!) {
    organization(id: $organizationId) {
      ...organization
      children {
        ...organization
        reporting {
          patientMonitoring {
            ...monitorUser
            campusTeam {
              provider {
                id
              }
            }
          }
          referralCompletionRate
          careTeamReferrals {
            referralsMade
            activePatients
            activeReferrals
            referralCompletionRate
            provider {
              id
              name
              role
            }
          }
        }
      }
    }
  }
  ${OrganizationFragmentDoc}
  ${MonitorUserFragmentDoc}
`;

/**
 * __useSuperAdminReferralsQuery__
 *
 * To run a query within a React component, call `useSuperAdminReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuperAdminReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuperAdminReferralsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSuperAdminReferralsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SuperAdminReferralsQuery,
    SuperAdminReferralsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SuperAdminReferralsQuery, SuperAdminReferralsQueryVariables>(
    SuperAdminReferralsDocument,
    options
  );
}
export function useSuperAdminReferralsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SuperAdminReferralsQuery,
    SuperAdminReferralsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SuperAdminReferralsQuery, SuperAdminReferralsQueryVariables>(
    SuperAdminReferralsDocument,
    options
  );
}
export type SuperAdminReferralsQueryHookResult = ReturnType<typeof useSuperAdminReferralsQuery>;
export type SuperAdminReferralsLazyQueryHookResult = ReturnType<
  typeof useSuperAdminReferralsLazyQuery
>;
export type SuperAdminReferralsQueryResult = ApolloReactCommon.QueryResult<
  SuperAdminReferralsQuery,
  SuperAdminReferralsQueryVariables
>;
export const FullMantraDocument = gql`
  query fullMantra {
    organizations {
      ...organization
      children {
        ...organization
      }
      providers {
        ...provider
        patients {
          ...userBasic
        }
      }
    }
  }
  ${OrganizationFragmentDoc}
  ${ProviderFragmentDoc}
  ${UserBasicFragmentDoc}
`;

/**
 * __useFullMantraQuery__
 *
 * To run a query within a React component, call `useFullMantraQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullMantraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullMantraQuery({
 *   variables: {
 *   },
 * });
 */
export function useFullMantraQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<FullMantraQuery, FullMantraQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FullMantraQuery, FullMantraQueryVariables>(
    FullMantraDocument,
    options
  );
}
export function useFullMantraLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FullMantraQuery, FullMantraQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FullMantraQuery, FullMantraQueryVariables>(
    FullMantraDocument,
    options
  );
}
export type FullMantraQueryHookResult = ReturnType<typeof useFullMantraQuery>;
export type FullMantraLazyQueryHookResult = ReturnType<typeof useFullMantraLazyQuery>;
export type FullMantraQueryResult = ApolloReactCommon.QueryResult<
  FullMantraQuery,
  FullMantraQueryVariables
>;
export const AdminUsersListFiltersDocument = gql`
  query adminUsersListFilters {
    organizations {
      id
      name
    }
    providers(roles: ["provider"]) {
      id
      firstName
      lastName
      postNominalTitles
    }
  }
`;

/**
 * __useAdminUsersListFiltersQuery__
 *
 * To run a query within a React component, call `useAdminUsersListFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUsersListFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUsersListFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminUsersListFiltersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminUsersListFiltersQuery,
    AdminUsersListFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminUsersListFiltersQuery, AdminUsersListFiltersQueryVariables>(
    AdminUsersListFiltersDocument,
    options
  );
}
export function useAdminUsersListFiltersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminUsersListFiltersQuery,
    AdminUsersListFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminUsersListFiltersQuery,
    AdminUsersListFiltersQueryVariables
  >(AdminUsersListFiltersDocument, options);
}
export type AdminUsersListFiltersQueryHookResult = ReturnType<typeof useAdminUsersListFiltersQuery>;
export type AdminUsersListFiltersLazyQueryHookResult = ReturnType<
  typeof useAdminUsersListFiltersLazyQuery
>;
export type AdminUsersListFiltersQueryResult = ApolloReactCommon.QueryResult<
  AdminUsersListFiltersQuery,
  AdminUsersListFiltersQueryVariables
>;
export const CapacityDropdownsDocument = gql`
  query capacityDropdowns {
    organizations {
      id
      name
      parent {
        id
      }
      providers {
        id
        name
        role
      }
    }
    providers(roles: ["provider"]) {
      id
      name
      careTypes
    }
  }
`;

/**
 * __useCapacityDropdownsQuery__
 *
 * To run a query within a React component, call `useCapacityDropdownsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCapacityDropdownsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapacityDropdownsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCapacityDropdownsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CapacityDropdownsQuery,
    CapacityDropdownsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CapacityDropdownsQuery, CapacityDropdownsQueryVariables>(
    CapacityDropdownsDocument,
    options
  );
}
export function useCapacityDropdownsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CapacityDropdownsQuery,
    CapacityDropdownsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CapacityDropdownsQuery, CapacityDropdownsQueryVariables>(
    CapacityDropdownsDocument,
    options
  );
}
export type CapacityDropdownsQueryHookResult = ReturnType<typeof useCapacityDropdownsQuery>;
export type CapacityDropdownsLazyQueryHookResult = ReturnType<typeof useCapacityDropdownsLazyQuery>;
export type CapacityDropdownsQueryResult = ApolloReactCommon.QueryResult<
  CapacityDropdownsQuery,
  CapacityDropdownsQueryVariables
>;
export const CapacityDocument = gql`
  query capacity($startTime: DateTime!, $weeks: Int!, $organizationId: Int, $providerId: Int) {
    weeklyCapacityUsage(
      startTime: $startTime
      weeks: $weeks
      organizationId: $organizationId
      providerId: $providerId
    ) {
      startTime
      endTime
      entries {
        careType
        minutesUsed
        minutesAvailable
      }
    }
  }
`;

/**
 * __useCapacityQuery__
 *
 * To run a query within a React component, call `useCapacityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCapacityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapacityQuery({
 *   variables: {
 *      startTime: // value for 'startTime'
 *      weeks: // value for 'weeks'
 *      organizationId: // value for 'organizationId'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useCapacityQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CapacityQuery, CapacityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CapacityQuery, CapacityQueryVariables>(
    CapacityDocument,
    options
  );
}
export function useCapacityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CapacityQuery, CapacityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CapacityQuery, CapacityQueryVariables>(
    CapacityDocument,
    options
  );
}
export type CapacityQueryHookResult = ReturnType<typeof useCapacityQuery>;
export type CapacityLazyQueryHookResult = ReturnType<typeof useCapacityLazyQuery>;
export type CapacityQueryResult = ApolloReactCommon.QueryResult<
  CapacityQuery,
  CapacityQueryVariables
>;
export const SamlConfigurationDocument = gql`
  query samlConfiguration($organizationId: Float!, $type: SamlConfigurationType!) {
    organization(id: $organizationId) {
      id
      entitlements {
        id
        key
      }
      samlConfiguration(type: $type) {
        id
        entryPoint
        certificates
        metadata
        profileEmailField
        profileUniqueIdField
        profileMatchingField
        issuer
        authnRequestBinding
        disableRequestedAuthnContext
        enableManualUniqueIdEdits
        automaticallyUpdateEmail
        profileCampusField
        campusOrgMappings {
          campus
          organizationId
        }
      }
    }
  }
`;

/**
 * __useSamlConfigurationQuery__
 *
 * To run a query within a React component, call `useSamlConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSamlConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSamlConfigurationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSamlConfigurationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SamlConfigurationQuery,
    SamlConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SamlConfigurationQuery, SamlConfigurationQueryVariables>(
    SamlConfigurationDocument,
    options
  );
}
export function useSamlConfigurationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SamlConfigurationQuery,
    SamlConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SamlConfigurationQuery, SamlConfigurationQueryVariables>(
    SamlConfigurationDocument,
    options
  );
}
export type SamlConfigurationQueryHookResult = ReturnType<typeof useSamlConfigurationQuery>;
export type SamlConfigurationLazyQueryHookResult = ReturnType<typeof useSamlConfigurationLazyQuery>;
export type SamlConfigurationQueryResult = ApolloReactCommon.QueryResult<
  SamlConfigurationQuery,
  SamlConfigurationQueryVariables
>;
export const AppointmentsForNoteSelectorDocument = gql`
  query appointmentsForNoteSelector($userId: Float!, $startTime: DateTime!) {
    adminAppointments(userId: $userId, startTime: $startTime) {
      id
      startTime
      description
      status
      provider {
        id
      }
      medicalNote {
        id
      }
      appointmentType
    }
  }
`;

/**
 * __useAppointmentsForNoteSelectorQuery__
 *
 * To run a query within a React component, call `useAppointmentsForNoteSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsForNoteSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsForNoteSelectorQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      startTime: // value for 'startTime'
 *   },
 * });
 */
export function useAppointmentsForNoteSelectorQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AppointmentsForNoteSelectorQuery,
    AppointmentsForNoteSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AppointmentsForNoteSelectorQuery,
    AppointmentsForNoteSelectorQueryVariables
  >(AppointmentsForNoteSelectorDocument, options);
}
export function useAppointmentsForNoteSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AppointmentsForNoteSelectorQuery,
    AppointmentsForNoteSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AppointmentsForNoteSelectorQuery,
    AppointmentsForNoteSelectorQueryVariables
  >(AppointmentsForNoteSelectorDocument, options);
}
export type AppointmentsForNoteSelectorQueryHookResult = ReturnType<
  typeof useAppointmentsForNoteSelectorQuery
>;
export type AppointmentsForNoteSelectorLazyQueryHookResult = ReturnType<
  typeof useAppointmentsForNoteSelectorLazyQuery
>;
export type AppointmentsForNoteSelectorQueryResult = ApolloReactCommon.QueryResult<
  AppointmentsForNoteSelectorQuery,
  AppointmentsForNoteSelectorQueryVariables
>;
export const TraitCategoriesDocument = gql`
  query traitCategories {
    traitCategories {
      category
      values
    }
  }
`;

/**
 * __useTraitCategoriesQuery__
 *
 * To run a query within a React component, call `useTraitCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTraitCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTraitCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTraitCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TraitCategoriesQuery,
    TraitCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TraitCategoriesQuery, TraitCategoriesQueryVariables>(
    TraitCategoriesDocument,
    options
  );
}
export function useTraitCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TraitCategoriesQuery,
    TraitCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TraitCategoriesQuery, TraitCategoriesQueryVariables>(
    TraitCategoriesDocument,
    options
  );
}
export type TraitCategoriesQueryHookResult = ReturnType<typeof useTraitCategoriesQuery>;
export type TraitCategoriesLazyQueryHookResult = ReturnType<typeof useTraitCategoriesLazyQuery>;
export type TraitCategoriesQueryResult = ApolloReactCommon.QueryResult<
  TraitCategoriesQuery,
  TraitCategoriesQueryVariables
>;
export const ProviderProfileDocument = gql`
  query providerProfile($providerId: Float!) {
    adminProvider(providerId: $providerId) {
      id
      personalTitle
      activityStatus
      firstName
      lastName
      postNominalTitles
      email
      phone
      gender
      pronouns
      funFact
      geoStates
      role
      guaranteedPay
      classification
      traits {
        category
        values
      }
      careTypes
      providerType
      npi
      dosespotId
      organizations {
        id
        name
        entitlements {
          key
          config
        }
        children {
          id
          name
        }
      }
      organizationCrisisTeams {
        crisisPhone
        organization {
          id
        }
      }
      insuranceCredentials {
        id
        state
        payer {
          id
          name
        }
      }
      portrait {
        url
      }
    }
  }
`;

/**
 * __useProviderProfileQuery__
 *
 * To run a query within a React component, call `useProviderProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderProfileQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useProviderProfileQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ProviderProfileQuery,
    ProviderProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ProviderProfileQuery, ProviderProfileQueryVariables>(
    ProviderProfileDocument,
    options
  );
}
export function useProviderProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProviderProfileQuery,
    ProviderProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ProviderProfileQuery, ProviderProfileQueryVariables>(
    ProviderProfileDocument,
    options
  );
}
export type ProviderProfileQueryHookResult = ReturnType<typeof useProviderProfileQuery>;
export type ProviderProfileLazyQueryHookResult = ReturnType<typeof useProviderProfileLazyQuery>;
export type ProviderProfileQueryResult = ApolloReactCommon.QueryResult<
  ProviderProfileQuery,
  ProviderProfileQueryVariables
>;
export const OrganizationEligibilityDocument = gql`
  query organizationEligibility($organizationId: Float!) {
    organization(id: $organizationId) {
      eligibilityCriteria {
        id
        minimumAge
        states
        permittedCareTypes
      }
    }
  }
`;

/**
 * __useOrganizationEligibilityQuery__
 *
 * To run a query within a React component, call `useOrganizationEligibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationEligibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationEligibilityQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationEligibilityQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrganizationEligibilityQuery,
    OrganizationEligibilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OrganizationEligibilityQuery,
    OrganizationEligibilityQueryVariables
  >(OrganizationEligibilityDocument, options);
}
export function useOrganizationEligibilityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationEligibilityQuery,
    OrganizationEligibilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationEligibilityQuery,
    OrganizationEligibilityQueryVariables
  >(OrganizationEligibilityDocument, options);
}
export type OrganizationEligibilityQueryHookResult = ReturnType<
  typeof useOrganizationEligibilityQuery
>;
export type OrganizationEligibilityLazyQueryHookResult = ReturnType<
  typeof useOrganizationEligibilityLazyQuery
>;
export type OrganizationEligibilityQueryResult = ApolloReactCommon.QueryResult<
  OrganizationEligibilityQuery,
  OrganizationEligibilityQueryVariables
>;
export const SuggestedPharmacyDocument = gql`
  query suggestedPharmacy($organizationId: Float!) {
    organization(id: $organizationId) {
      suggestedPharmacy {
        ...pharmacy
      }
    }
  }
  ${PharmacyFragmentDoc}
`;

/**
 * __useSuggestedPharmacyQuery__
 *
 * To run a query within a React component, call `useSuggestedPharmacyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedPharmacyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedPharmacyQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSuggestedPharmacyQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SuggestedPharmacyQuery,
    SuggestedPharmacyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SuggestedPharmacyQuery, SuggestedPharmacyQueryVariables>(
    SuggestedPharmacyDocument,
    options
  );
}
export function useSuggestedPharmacyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SuggestedPharmacyQuery,
    SuggestedPharmacyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SuggestedPharmacyQuery, SuggestedPharmacyQueryVariables>(
    SuggestedPharmacyDocument,
    options
  );
}
export type SuggestedPharmacyQueryHookResult = ReturnType<typeof useSuggestedPharmacyQuery>;
export type SuggestedPharmacyLazyQueryHookResult = ReturnType<typeof useSuggestedPharmacyLazyQuery>;
export type SuggestedPharmacyQueryResult = ApolloReactCommon.QueryResult<
  SuggestedPharmacyQuery,
  SuggestedPharmacyQueryVariables
>;
export const AuthInstructionsForEmailDocument = gql`
  query authInstructionsForEmail($email: String!) {
    authInstructionsForEmail(email: $email, type: Provider) {
      authMethod
      samlEntryPoint
    }
  }
`;

/**
 * __useAuthInstructionsForEmailQuery__
 *
 * To run a query within a React component, call `useAuthInstructionsForEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthInstructionsForEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthInstructionsForEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAuthInstructionsForEmailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AuthInstructionsForEmailQuery,
    AuthInstructionsForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AuthInstructionsForEmailQuery,
    AuthInstructionsForEmailQueryVariables
  >(AuthInstructionsForEmailDocument, options);
}
export function useAuthInstructionsForEmailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AuthInstructionsForEmailQuery,
    AuthInstructionsForEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AuthInstructionsForEmailQuery,
    AuthInstructionsForEmailQueryVariables
  >(AuthInstructionsForEmailDocument, options);
}
export type AuthInstructionsForEmailQueryHookResult = ReturnType<
  typeof useAuthInstructionsForEmailQuery
>;
export type AuthInstructionsForEmailLazyQueryHookResult = ReturnType<
  typeof useAuthInstructionsForEmailLazyQuery
>;
export type AuthInstructionsForEmailQueryResult = ApolloReactCommon.QueryResult<
  AuthInstructionsForEmailQuery,
  AuthInstructionsForEmailQueryVariables
>;
export const OrganizationCrisisTeamDocument = gql`
  query organizationCrisisTeam($organizationId: Float!) {
    organization(id: $organizationId) {
      crisisHours {
        dayOfWeek
        startTime
        endTime
      }
      crisisTeamProviders {
        id
        name
        role
        portrait {
          url
        }
      }
    }
  }
`;

/**
 * __useOrganizationCrisisTeamQuery__
 *
 * To run a query within a React component, call `useOrganizationCrisisTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCrisisTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationCrisisTeamQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationCrisisTeamQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrganizationCrisisTeamQuery,
    OrganizationCrisisTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OrganizationCrisisTeamQuery,
    OrganizationCrisisTeamQueryVariables
  >(OrganizationCrisisTeamDocument, options);
}
export function useOrganizationCrisisTeamLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationCrisisTeamQuery,
    OrganizationCrisisTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationCrisisTeamQuery,
    OrganizationCrisisTeamQueryVariables
  >(OrganizationCrisisTeamDocument, options);
}
export type OrganizationCrisisTeamQueryHookResult = ReturnType<
  typeof useOrganizationCrisisTeamQuery
>;
export type OrganizationCrisisTeamLazyQueryHookResult = ReturnType<
  typeof useOrganizationCrisisTeamLazyQuery
>;
export type OrganizationCrisisTeamQueryResult = ApolloReactCommon.QueryResult<
  OrganizationCrisisTeamQuery,
  OrganizationCrisisTeamQueryVariables
>;
export const OrganizationHandbookDocument = gql`
  query organizationHandbook($organizationId: Float!) {
    organizationHandbook(organizationId: $organizationId) {
      ...organizationHandbook
    }
  }
  ${OrganizationHandbookFragmentDoc}
`;

/**
 * __useOrganizationHandbookQuery__
 *
 * To run a query within a React component, call `useOrganizationHandbookQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationHandbookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationHandbookQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationHandbookQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrganizationHandbookQuery,
    OrganizationHandbookQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrganizationHandbookQuery, OrganizationHandbookQueryVariables>(
    OrganizationHandbookDocument,
    options
  );
}
export function useOrganizationHandbookLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationHandbookQuery,
    OrganizationHandbookQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationHandbookQuery,
    OrganizationHandbookQueryVariables
  >(OrganizationHandbookDocument, options);
}
export type OrganizationHandbookQueryHookResult = ReturnType<typeof useOrganizationHandbookQuery>;
export type OrganizationHandbookLazyQueryHookResult = ReturnType<
  typeof useOrganizationHandbookLazyQuery
>;
export type OrganizationHandbookQueryResult = ApolloReactCommon.QueryResult<
  OrganizationHandbookQuery,
  OrganizationHandbookQueryVariables
>;
export const OrganizationAnnouncementsByOrgIdDocument = gql`
  query organizationAnnouncementsByOrgId($organizationId: Float!, $limit: Float) {
    organizationAnnouncementsByOrgId(organizationId: $organizationId, limit: $limit) {
      id
      createdByProviderName
      title
      details
      createdAt
      inCurrentCarePeriod
    }
  }
`;

/**
 * __useOrganizationAnnouncementsByOrgIdQuery__
 *
 * To run a query within a React component, call `useOrganizationAnnouncementsByOrgIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationAnnouncementsByOrgIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationAnnouncementsByOrgIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useOrganizationAnnouncementsByOrgIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrganizationAnnouncementsByOrgIdQuery,
    OrganizationAnnouncementsByOrgIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OrganizationAnnouncementsByOrgIdQuery,
    OrganizationAnnouncementsByOrgIdQueryVariables
  >(OrganizationAnnouncementsByOrgIdDocument, options);
}
export function useOrganizationAnnouncementsByOrgIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationAnnouncementsByOrgIdQuery,
    OrganizationAnnouncementsByOrgIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationAnnouncementsByOrgIdQuery,
    OrganizationAnnouncementsByOrgIdQueryVariables
  >(OrganizationAnnouncementsByOrgIdDocument, options);
}
export type OrganizationAnnouncementsByOrgIdQueryHookResult = ReturnType<
  typeof useOrganizationAnnouncementsByOrgIdQuery
>;
export type OrganizationAnnouncementsByOrgIdLazyQueryHookResult = ReturnType<
  typeof useOrganizationAnnouncementsByOrgIdLazyQuery
>;
export type OrganizationAnnouncementsByOrgIdQueryResult = ApolloReactCommon.QueryResult<
  OrganizationAnnouncementsByOrgIdQuery,
  OrganizationAnnouncementsByOrgIdQueryVariables
>;
export const OrganizationAnnouncementsByDaysDocument = gql`
  query organizationAnnouncementsByDays($organizationId: Float!, $days: Float) {
    organizationAnnouncementsByDays(organizationId: $organizationId, days: $days) {
      id
      createdByProviderName
      title
      details
      createdAt
      inCurrentCarePeriod
    }
  }
`;

/**
 * __useOrganizationAnnouncementsByDaysQuery__
 *
 * To run a query within a React component, call `useOrganizationAnnouncementsByDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationAnnouncementsByDaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationAnnouncementsByDaysQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      days: // value for 'days'
 *   },
 * });
 */
export function useOrganizationAnnouncementsByDaysQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrganizationAnnouncementsByDaysQuery,
    OrganizationAnnouncementsByDaysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OrganizationAnnouncementsByDaysQuery,
    OrganizationAnnouncementsByDaysQueryVariables
  >(OrganizationAnnouncementsByDaysDocument, options);
}
export function useOrganizationAnnouncementsByDaysLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationAnnouncementsByDaysQuery,
    OrganizationAnnouncementsByDaysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrganizationAnnouncementsByDaysQuery,
    OrganizationAnnouncementsByDaysQueryVariables
  >(OrganizationAnnouncementsByDaysDocument, options);
}
export type OrganizationAnnouncementsByDaysQueryHookResult = ReturnType<
  typeof useOrganizationAnnouncementsByDaysQuery
>;
export type OrganizationAnnouncementsByDaysLazyQueryHookResult = ReturnType<
  typeof useOrganizationAnnouncementsByDaysLazyQuery
>;
export type OrganizationAnnouncementsByDaysQueryResult = ApolloReactCommon.QueryResult<
  OrganizationAnnouncementsByDaysQuery,
  OrganizationAnnouncementsByDaysQueryVariables
>;
export const CrisisReportsForOrgDocument = gql`
  query crisisReportsForOrg {
    crisisReportsForOrg {
      id
      ddhFileEntityId
      organizationId
      reportDate
      reports {
        id
        ddhFileSuccessId
        callId
        callStart
      }
    }
  }
`;

/**
 * __useCrisisReportsForOrgQuery__
 *
 * To run a query within a React component, call `useCrisisReportsForOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrisisReportsForOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrisisReportsForOrgQuery({
 *   variables: {
 *   },
 * });
 */
export function useCrisisReportsForOrgQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CrisisReportsForOrgQuery,
    CrisisReportsForOrgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CrisisReportsForOrgQuery, CrisisReportsForOrgQueryVariables>(
    CrisisReportsForOrgDocument,
    options
  );
}
export function useCrisisReportsForOrgLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CrisisReportsForOrgQuery,
    CrisisReportsForOrgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CrisisReportsForOrgQuery, CrisisReportsForOrgQueryVariables>(
    CrisisReportsForOrgDocument,
    options
  );
}
export type CrisisReportsForOrgQueryHookResult = ReturnType<typeof useCrisisReportsForOrgQuery>;
export type CrisisReportsForOrgLazyQueryHookResult = ReturnType<
  typeof useCrisisReportsForOrgLazyQuery
>;
export type CrisisReportsForOrgQueryResult = ApolloReactCommon.QueryResult<
  CrisisReportsForOrgQuery,
  CrisisReportsForOrgQueryVariables
>;
export const CrisisReportsZipDocument = gql`
  query crisisReportsZip($ddhFileSuccessId: Int!) {
    crisisReportsZip(ddhFileSuccessId: $ddhFileSuccessId) {
      zipLink
    }
  }
`;

/**
 * __useCrisisReportsZipQuery__
 *
 * To run a query within a React component, call `useCrisisReportsZipQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrisisReportsZipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrisisReportsZipQuery({
 *   variables: {
 *      ddhFileSuccessId: // value for 'ddhFileSuccessId'
 *   },
 * });
 */
export function useCrisisReportsZipQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CrisisReportsZipQuery,
    CrisisReportsZipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CrisisReportsZipQuery, CrisisReportsZipQueryVariables>(
    CrisisReportsZipDocument,
    options
  );
}
export function useCrisisReportsZipLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CrisisReportsZipQuery,
    CrisisReportsZipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CrisisReportsZipQuery, CrisisReportsZipQueryVariables>(
    CrisisReportsZipDocument,
    options
  );
}
export type CrisisReportsZipQueryHookResult = ReturnType<typeof useCrisisReportsZipQuery>;
export type CrisisReportsZipLazyQueryHookResult = ReturnType<typeof useCrisisReportsZipLazyQuery>;
export type CrisisReportsZipQueryResult = ApolloReactCommon.QueryResult<
  CrisisReportsZipQuery,
  CrisisReportsZipQueryVariables
>;
export const AdminScreenUserResultDocument = gql`
  query adminScreenUserResult($userId: Float!) {
    adminScreenUserResult(userId: $userId) {
      screenedOut
      screenedOutFromHub
    }
  }
`;

/**
 * __useAdminScreenUserResultQuery__
 *
 * To run a query within a React component, call `useAdminScreenUserResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminScreenUserResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminScreenUserResultQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminScreenUserResultQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminScreenUserResultQuery,
    AdminScreenUserResultQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminScreenUserResultQuery, AdminScreenUserResultQueryVariables>(
    AdminScreenUserResultDocument,
    options
  );
}
export function useAdminScreenUserResultLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminScreenUserResultQuery,
    AdminScreenUserResultQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminScreenUserResultQuery,
    AdminScreenUserResultQueryVariables
  >(AdminScreenUserResultDocument, options);
}
export type AdminScreenUserResultQueryHookResult = ReturnType<typeof useAdminScreenUserResultQuery>;
export type AdminScreenUserResultLazyQueryHookResult = ReturnType<
  typeof useAdminScreenUserResultLazyQuery
>;
export type AdminScreenUserResultQueryResult = ApolloReactCommon.QueryResult<
  AdminScreenUserResultQuery,
  AdminScreenUserResultQueryVariables
>;
export const ConnectNowCallReportsDocument = gql`
  query connectNowCallReports($params: ConnectNowReportsInput!) {
    connectNowOrgSessions(params: $params) {
      items {
        id
        callStartTime
        callType
        callerFirstName
        callerPreferredName
        callerLastName
        healthieNoteId
        userId
        customerId
        organization {
          id
          name
        }
      }
      hasMore
      cursor
      count
      limitCountExceeded
    }
  }
`;

/**
 * __useConnectNowCallReportsQuery__
 *
 * To run a query within a React component, call `useConnectNowCallReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectNowCallReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectNowCallReportsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useConnectNowCallReportsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ConnectNowCallReportsQuery,
    ConnectNowCallReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConnectNowCallReportsQuery, ConnectNowCallReportsQueryVariables>(
    ConnectNowCallReportsDocument,
    options
  );
}
export function useConnectNowCallReportsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ConnectNowCallReportsQuery,
    ConnectNowCallReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ConnectNowCallReportsQuery,
    ConnectNowCallReportsQueryVariables
  >(ConnectNowCallReportsDocument, options);
}
export type ConnectNowCallReportsQueryHookResult = ReturnType<typeof useConnectNowCallReportsQuery>;
export type ConnectNowCallReportsLazyQueryHookResult = ReturnType<
  typeof useConnectNowCallReportsLazyQuery
>;
export type ConnectNowCallReportsQueryResult = ApolloReactCommon.QueryResult<
  ConnectNowCallReportsQuery,
  ConnectNowCallReportsQueryVariables
>;
export const HealthieProviderSyncedDocument = gql`
  query healthieProviderSynced($id: Float!) {
    healthieProviderSynced(id: $id)
  }
`;

/**
 * __useHealthieProviderSyncedQuery__
 *
 * To run a query within a React component, call `useHealthieProviderSyncedQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthieProviderSyncedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthieProviderSyncedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthieProviderSyncedQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    HealthieProviderSyncedQuery,
    HealthieProviderSyncedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    HealthieProviderSyncedQuery,
    HealthieProviderSyncedQueryVariables
  >(HealthieProviderSyncedDocument, options);
}
export function useHealthieProviderSyncedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    HealthieProviderSyncedQuery,
    HealthieProviderSyncedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    HealthieProviderSyncedQuery,
    HealthieProviderSyncedQueryVariables
  >(HealthieProviderSyncedDocument, options);
}
export type HealthieProviderSyncedQueryHookResult = ReturnType<
  typeof useHealthieProviderSyncedQuery
>;
export type HealthieProviderSyncedLazyQueryHookResult = ReturnType<
  typeof useHealthieProviderSyncedLazyQuery
>;
export type HealthieProviderSyncedQueryResult = ApolloReactCommon.QueryResult<
  HealthieProviderSyncedQuery,
  HealthieProviderSyncedQueryVariables
>;
export const PaginatedIopReferralsDocument = gql`
  query paginatedIopReferrals($params: PaginatedIopReferralInput!) {
    paginatedIopReferrals(params: $params) {
      items {
        id
        userId
        email
        firstName
        preferredName
        lastName
        referralDateTime
        organizationId
        organizationName
        status
      }
      hasMore
      cursor
      count
    }
  }
`;

/**
 * __usePaginatedIopReferralsQuery__
 *
 * To run a query within a React component, call `usePaginatedIopReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedIopReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedIopReferralsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function usePaginatedIopReferralsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PaginatedIopReferralsQuery,
    PaginatedIopReferralsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PaginatedIopReferralsQuery, PaginatedIopReferralsQueryVariables>(
    PaginatedIopReferralsDocument,
    options
  );
}
export function usePaginatedIopReferralsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaginatedIopReferralsQuery,
    PaginatedIopReferralsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PaginatedIopReferralsQuery,
    PaginatedIopReferralsQueryVariables
  >(PaginatedIopReferralsDocument, options);
}
export type PaginatedIopReferralsQueryHookResult = ReturnType<typeof usePaginatedIopReferralsQuery>;
export type PaginatedIopReferralsLazyQueryHookResult = ReturnType<
  typeof usePaginatedIopReferralsLazyQuery
>;
export type PaginatedIopReferralsQueryResult = ApolloReactCommon.QueryResult<
  PaginatedIopReferralsQuery,
  PaginatedIopReferralsQueryVariables
>;
export const PriceConfigurationDocument = gql`
  query priceConfiguration {
    pricesInCents {
      psychiatryIntakePriceInCents
      psychiatryFollowUpPriceInCents
      therapyIntakePriceInCents
      therapyFollowUpPriceInCents
      noShowFeeInCents
      lateCancellationFeeInCents
    }
  }
`;

/**
 * __usePriceConfigurationQuery__
 *
 * To run a query within a React component, call `usePriceConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePriceConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function usePriceConfigurationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PriceConfigurationQuery,
    PriceConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PriceConfigurationQuery, PriceConfigurationQueryVariables>(
    PriceConfigurationDocument,
    options
  );
}
export function usePriceConfigurationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PriceConfigurationQuery,
    PriceConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PriceConfigurationQuery, PriceConfigurationQueryVariables>(
    PriceConfigurationDocument,
    options
  );
}
export type PriceConfigurationQueryHookResult = ReturnType<typeof usePriceConfigurationQuery>;
export type PriceConfigurationLazyQueryHookResult = ReturnType<
  typeof usePriceConfigurationLazyQuery
>;
export type PriceConfigurationQueryResult = ApolloReactCommon.QueryResult<
  PriceConfigurationQuery,
  PriceConfigurationQueryVariables
>;
export const ConnectNowSessionDocumentDocument = gql`
  query connectNowSessionDocument($id: String!) {
    connectNowSessionReport(id: $id) {
      document {
        expiringUrl
      }
    }
  }
`;

/**
 * __useConnectNowSessionDocumentQuery__
 *
 * To run a query within a React component, call `useConnectNowSessionDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectNowSessionDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectNowSessionDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConnectNowSessionDocumentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ConnectNowSessionDocumentQuery,
    ConnectNowSessionDocumentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ConnectNowSessionDocumentQuery,
    ConnectNowSessionDocumentQueryVariables
  >(ConnectNowSessionDocumentDocument, options);
}
export function useConnectNowSessionDocumentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ConnectNowSessionDocumentQuery,
    ConnectNowSessionDocumentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ConnectNowSessionDocumentQuery,
    ConnectNowSessionDocumentQueryVariables
  >(ConnectNowSessionDocumentDocument, options);
}
export type ConnectNowSessionDocumentQueryHookResult = ReturnType<
  typeof useConnectNowSessionDocumentQuery
>;
export type ConnectNowSessionDocumentLazyQueryHookResult = ReturnType<
  typeof useConnectNowSessionDocumentLazyQuery
>;
export type ConnectNowSessionDocumentQueryResult = ApolloReactCommon.QueryResult<
  ConnectNowSessionDocumentQuery,
  ConnectNowSessionDocumentQueryVariables
>;
export const McpCurrentContractUtilizationDocument = gql`
  query McpCurrentContractUtilization {
    mcpCurrentContractUtilization {
      utilization {
        scope {
          startDate
          endDate
          therapySessions
          therapyPatients
          psychiatrySessions
          psychiatryPatients
        }
        psychiatryUsage {
          studentCapacityRemaining
          studentCapacityUtilized
          sessionsScheduled
          sessionsUtilized
          sessionsRemaining
        }
        therapyUsage {
          studentCapacityRemaining
          studentCapacityUtilized
          sessionsScheduled
          sessionsUtilized
          sessionsRemaining
        }
      }
    }
  }
`;

/**
 * __useMcpCurrentContractUtilizationQuery__
 *
 * To run a query within a React component, call `useMcpCurrentContractUtilizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMcpCurrentContractUtilizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMcpCurrentContractUtilizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useMcpCurrentContractUtilizationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    McpCurrentContractUtilizationQuery,
    McpCurrentContractUtilizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    McpCurrentContractUtilizationQuery,
    McpCurrentContractUtilizationQueryVariables
  >(McpCurrentContractUtilizationDocument, options);
}
export function useMcpCurrentContractUtilizationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    McpCurrentContractUtilizationQuery,
    McpCurrentContractUtilizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    McpCurrentContractUtilizationQuery,
    McpCurrentContractUtilizationQueryVariables
  >(McpCurrentContractUtilizationDocument, options);
}
export type McpCurrentContractUtilizationQueryHookResult = ReturnType<
  typeof useMcpCurrentContractUtilizationQuery
>;
export type McpCurrentContractUtilizationLazyQueryHookResult = ReturnType<
  typeof useMcpCurrentContractUtilizationLazyQuery
>;
export type McpCurrentContractUtilizationQueryResult = ApolloReactCommon.QueryResult<
  McpCurrentContractUtilizationQuery,
  McpCurrentContractUtilizationQueryVariables
>;
export const DeactivateOrganizationDocument = gql`
  mutation deactivateOrganization($id: Int!) {
    deactivateOrganization(id: $id)
  }
`;
export type DeactivateOrganizationMutationFn = ApolloReactCommon.MutationFunction<
  DeactivateOrganizationMutation,
  DeactivateOrganizationMutationVariables
>;

/**
 * __useDeactivateOrganizationMutation__
 *
 * To run a mutation, you first call `useDeactivateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateOrganizationMutation, { data, loading, error }] = useDeactivateOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeactivateOrganizationMutation,
    DeactivateOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeactivateOrganizationMutation,
    DeactivateOrganizationMutationVariables
  >(DeactivateOrganizationDocument, options);
}
export type DeactivateOrganizationMutationHookResult = ReturnType<
  typeof useDeactivateOrganizationMutation
>;
export type DeactivateOrganizationMutationResult =
  ApolloReactCommon.MutationResult<DeactivateOrganizationMutation>;
export type DeactivateOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeactivateOrganizationMutation,
  DeactivateOrganizationMutationVariables
>;
