import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MantraAccordion, MantraAccordionPanel } from '../../Components/Accordion';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useCurrentProvider } from '../../Components/Permissions';
import { Text, colors } from '../../globalStyles';
import {
  EditOrganizationHandbookInput,
  OrganizationHandbookQuery,
  useEditOrganizationHandbookMutation,
  useOrganizationHandbookQuery,
  useOrganizationTopLevelQuery,
} from '../../graphQL';
import { useOrganizationId } from '../Organizations/util';
import { UnexpectedError } from '../Shared';
import { HandbookNavigation } from './HandbookNavigation';
import {
  titleAccommodations,
  titleCampusResources,
  titleCareAndCollaboration,
  titleContinuityOfCare,
  titleEmergencyProtocol,
  titleMedical,
} from './Section/sectionConstants';
import { HANDBOOK_PANELS } from './panels';

type HandbookProps = {
  organizationId: number;
};

export function HandbookFromParams() {
  const organizationId = useOrganizationId();
  return <Handbook organizationId={organizationId} />;
}

export type HandBookInfo = OrganizationHandbookQuery['organizationHandbook'];
export type EditableSectionRowInfo = {
  label: string;
  info?: string;
  editInputKey: keyof EditOrganizationHandbookInput;
  displayKey: keyof HandBookInfo;
};

export function Handbook({ organizationId }: HandbookProps) {
  const { pathname, hash, key } = useLocation();

  const orgName = useOrganizationTopLevelQuery({ variables: { id: organizationId } }).data
    ?.organization.name;

  const [editOrganizationHandbook] = useEditOrganizationHandbookMutation();
  const { hasPermission } = useCurrentProvider();

  // set the field being edited
  const [selectedField, setSelectedField] = useState<keyof HandBookInfo | null>(null);

  // navigation
  const [activeNav, setActiveNav] = useState<string>(titleCareAndCollaboration);
  const navigationIds = [
    { navId: titleCareAndCollaboration },
    { navId: titleCampusResources },
    { navId: titleAccommodations },
    { navId: titleContinuityOfCare },
    { navId: titleMedical },
    { navId: titleEmergencyProtocol, emergency: true },
  ];

  // populate handbook information
  const { data, refetch, error, loading } = useOrganizationHandbookQuery({
    variables: { organizationId },
  });

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '').replaceAll('%20', ' ');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key, loading]);

  if (loading) return <LoadingPage />;
  if (!data || error) return <UnexpectedError />;
  const handbookInfo = data.organizationHandbook;

  // function for submitting changes to a particular field
  const onSave = async (editFieldName: keyof EditOrganizationHandbookInput, value: string) => {
    await editOrganizationHandbook({
      variables: {
        organizationId,
        input: { [editFieldName]: value.trim() },
      },
    });
    refetch();
  };

  return (
    <HandbookDiv className="mh4 mv4">
      <HandbookNavDiv>
        <Text.h1 className="mr4">{orgName} Handbook</Text.h1>
        <HandbookNavigation
          activeNav={activeNav}
          setActiveNav={setActiveNav}
          navigationIds={navigationIds}
        />
      </HandbookNavDiv>
      <MantraAccordion multi>
        {HANDBOOK_PANELS.map(
          ({ title, editPermission, Component, editableSectionRowInfo }, idx) => {
            const editable = hasPermission(editPermission);
            return (
              <MantraAccordionPanel
                key={`panel-${idx}-${title}`}
                startOpen
                title={
                  <Text.h3 id={title} className="mr4">
                    {title}
                  </Text.h3>
                }
              >
                <Component
                  verticalDisplay
                  organizationId={organizationId}
                  editable={editable}
                  handbookInfo={handbookInfo}
                  selectedField={selectedField}
                  editableSectionRowInfo={editableSectionRowInfo}
                  onStartEdit={
                    editable
                      ? (fieldKey: keyof HandBookInfo) => setSelectedField(fieldKey)
                      : undefined
                  }
                  onCancelEdit={editable ? () => setSelectedField(null) : undefined}
                  onSave={editable ? onSave : undefined}
                />
              </MantraAccordionPanel>
            );
          }
        )}
      </MantraAccordion>
    </HandbookDiv>
  );
}

const HandbookDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .mantra-accordion {
    margin-right: 2.5rem;
  }

  .mantra-accordion-panel {
    background-color: ${colors.white};
    border: 1px solid ${colors.grey.lightBorder};
    padding: 2rem;
  }

  .mantra-accordion-panel-content {
    padding-top: 0;
    padding-bottom: 0;

    &.panel-open {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;

const HandbookNavDiv = styled.div`
  min-width: 18rem;
  position: sticky;
  top: 2em;
  height: auto;
`;

export const parseTextForLinks = (text: string | boolean) => {
  if (typeof text === 'boolean') {
    return text;
  }

  const splitText = text.split(/([\s\r\n\t])/);
  const parsedText = splitText.map(word => {
    if (/^[\s\r\n\t]$/.test(word)) {
      return word;
    }

    // if the word is a valid URL, return a link
    if (isValidUrl(word)) {
      return (
        <Text.externalLink href={word} target="_blank">
          {word}
        </Text.externalLink>
      );
    }
    return word;
  });

  return <Text.bodySmall>{parsedText}</Text.bodySmall>;
};

const isValidUrl = (url: string) => {
  if (!url.startsWith('http')) {
    return false;
  }

  try {
    new URL(url);

    return true;
  } catch (error) {
    return false;
  }
};
