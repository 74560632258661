import React from 'react';
import { Text } from '../../globalStyles';

type Props = {
  interpretationLanguage: string;
};

export const InterpretationRequested: React.FC<Props> = ({ interpretationLanguage }) => {
  return (
    <Text.bodyBold kind="orange">Interpreter Requested: {interpretationLanguage}</Text.bodyBold>
  );
};
